const ALPHA_VALIDATION = /[^A-Z a-z]+/;
const NUMBER_VALIDATION = /^\d+$/
const MOBILE_NUM_VALIDATION = /[^0-9+-]+/
const EMAIL_VALIDATION = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/
const GST_NO_VALIDATION = /^[a-zA-Z0-9]+$/
const DECIMAL_NUMBER = /^(\d{0,3})((\.(\d{0,2})?)?)$/
const DECIMAL_NUMBER_THREE_DEMICAL = /^(\d{0,6})((\.(\d{0,3})?)?)$/
const RANGE_DECIMAL_NUMBER = /^(\d{0,7})((\.(\d{0,2})?)?)$/
const NUMBER_WITH_DOT = /^(\d{0,10})((\.(\d{0,2})?)?)$/
const DECIMAL_NUMBER_FIVE = /^(\d{0,7})((\.(\d{0,5})?)?)$/
const DECIMAL_NUMBER_TWO = /^(\d{0,2})((\.(\d{0,2})?)?)$/
const ALPHA_NUMARIC = /^[a-zA-Z 0-9]+$/
const ALPHA_NUM_SPLChar = /^[ A-Za-z0-9_@./&+-]*$/
const NUM_SPLChar = /^[ 0-9.]*$/
const PASSWORDTEXT = /^[A-Za-z0-9_@./&+-]*$/
const DECIMAL_NUMBER_ONE = /^(\d{0,2})((\.(\d{0,1})?)?)$/
const PASSWORD_VAL = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d.*)(?=.*\W.*)[a-zA-Z0-9\S]{6,14}$/
const UPI_ID = /^[\w.-]+@[\w.-]+$/
const AlphaSpl = /^[A-Z@~`!@#$%^&*()_=+\\\\';:\"\\/?>.<,-]*$/i
const ifscRegex = /^[A-Z]{4}0[A-Z0-9]{6}$/;
const service = /^[ a-zA-Z0-9()\/\-]+$/
const NUMBER_WITH_DOT_THREE = /^(\d{0,10})((\.(\d{0,3})?)?)$/
const ALPHA_NUM = /^[ a-zA-Z0-9]+$/
const BILL_NO_VALIDATION = /^[A-Za-z0-9_/-]*$/
const GST_NEW_REGEX = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[0-9]{1}[A-Z]{1}[0-9A-Z]{1}$/


export default class CommonValidation {
    //Only Alphabet Validation
    static alphabetOnly = (value) => {
        return !ALPHA_VALIDATION.test(value)
    }

    //Only Number's Validation
    static numberOnly = (value) => {
        return NUMBER_VALIDATION.test(value)
    }

    //Mobile Number Validation
    static mobileNumber = (value) => {
        return !MOBILE_NUM_VALIDATION.test(value)
    }

    //Email Validation
    static emailValidation = (value) => {
        return EMAIL_VALIDATION.test(value)
    }

    //Decimal Number Validation (Discounts)
    static DecimalNumber = (value) => {
        return DECIMAL_NUMBER.test(value)
    }

    //Decimal Number Validation 
    static DecimalNumberThreeDigit = (value) => {
        return DECIMAL_NUMBER_THREE_DEMICAL.test(value)
    }

    //Decimal Number Validation (Result config range)
    static RangeDecimalNumber = (value) => {
        return RANGE_DECIMAL_NUMBER.test(value)
    }

    //Decimal Number Validation With Five 
    static RangeDecimalNumberFive = (value) => {
        return DECIMAL_NUMBER_FIVE.test(value)
    }

    //Decimal Number Validation 
    static NumberWithDot = (value) => {
        return NUMBER_WITH_DOT.test(value)
    }

    //Decimal Three Number Validation 
    static NumberWithDotThree = (value) => {
        return NUMBER_WITH_DOT_THREE.test(value)
    }

    //GSt Number Validation
    static gstnumberValidation = (value) => {
        return GST_NO_VALIDATION.test(value)
    }

    static ALPHA_NUM_SPLCHAR = (value) => {
        return ALPHA_NUM_SPLChar.test(value)
    }

    static ALPHA_NUMARIC = (value) => {
        return ALPHA_NUM.test(value)
    }

    //Alpha Numaric Validation
    static alphanumValidation = (value) => {
        return ALPHA_NUMARIC.test(value)
    }

    //Alphabet and special character
    static AlphaSpl = (value) => {
        return AlphaSpl.test(value)
    }

    //Percentage Calculation
    static calculatePercentage = (discount, total) => {

        if (+discount == 0 || +total == 0) return ""

        // return +(Math.round((+discount / 100) * +total))
        return +(((+discount / 100) * +total).toFixed(2))
    }

    //Percentage Calculation without riundoff
    static calculatePercentageWithoutRoundoff = (discount, total) => {

        if (+discount == 0 || +total == 0) return ""

        return +(((+discount / 100) * +total).toFixed(2))
    }

    //start and End Date of Month
    static getStartAndEndDateOfMonth = (date) => {
        date = new Date(date)
        let startDate = new Date(date.getFullYear(), date.getMonth());

        let endDate = new Date(new Date(date.getFullYear(), date.getMonth() + 1).getTime() - 1);

        return { startDate, endDate };
    }

    //Start Date of Month
    static getStartDateOfMonth = (todayDate) => {

        let firstDay = new Date(todayDate.getFullYear(), todayDate.getMonth());

        return firstDay;
    }

    //End date of Month
    static getEndDateOfMonth = (todayDate) => {
        let lastDay = new Date(todayDate.getFullYear(), todayDate.getMonth() + 1);

        return lastDay;
    }

    //GEet how many days in month
    static getDaysInMonth = (date) => {
        return new Date(date.getFullYear(), date.getMonth() + 1, 0).getDate();
    }

    //Previous month start date
    static getPreviousStartDateOfMonth = (date) => {
        date.setDate(0);
        date.setDate(1);
        date.setHours(0, 0, 0, 0);
        return date;
    }

    //Previous month end date
    static getPreviousEndDateOfMonth = (date) => {

        date.setDate(0);
        date.setHours(23, 59, 59, 999)
        return date;
    }

    //Amount after decimal point fixed value 2 (eg:1.23)
    static formatAmount = (value, n = 2) => {
        if (!value) return 0
        return +((+value).toFixed(n))
    }

    //Amount after decimal point fixed value 2 even if it string
    static formatAmountStringStrict = (value, n = 2) => {
        if (!value) return "0"
        return (+value).toFixed(n)
    }

    //removing space from Front and back text
    static removeSpace = (value) => {
        if (typeof (value) === "string") {
            let Text = value === null ? "" : value;
            return Text.replace(/\s+/g, ' ').trim()
        }
        else {
            return value
        }
    }

    // two digits with two decimal
    static DecimalNumberTwo = (value) => {
        return DECIMAL_NUMBER_TWO.test(value)
    }

    // one digits with one decimal
    static DecimalNumberOne = (value) => {
        return DECIMAL_NUMBER_ONE.test(value)
    }

    static amountPercentage = (discount, total) => {

        if (+discount == 0 || +total == 0) return ""

        // return Math.round(((+discount / +total) * 100))
        return +(((+discount / +total) * 100).toFixed(2))
    }

    static roundOff = (totalAmount, discountedAmount) => {
        let value = discountedAmount ? discountedAmount : 0
        let amountOne = totalAmount - value
        let valOne = Math.round(+amountOne)
        let valTwo = totalAmount - value
        let roundOff = valOne - valTwo
        let roundOffValue = (roundOff).toFixed(2)
        return roundOffValue
    }

    //Password Validation
    static checkPassword = (value) => {
        return PASSWORD_VAL.test(value)
    }
    static PasswordInput = (value) => {
        return PASSWORDTEXT.test(value)
    }

    //UPI ID Validation
    static CheckUPIId = (value) => {
        return UPI_ID.test(value)
    }

    //IFSC Validation
    static CheckIFSC = (value) => {
        return ifscRegex.test(value)
    }


    static updateOrInsertList = (array, newValue, selectedIndex) => {
        if (array.length === 0) {
            return [newValue]
        } else {
            const obj = array.find((item, index) => index === selectedIndex)
            if (!obj) {
                return [...array, newValue]
            } else {
                if (JSON.stringify(obj) === JSON.stringify(newValue)) {
                    return [...array.filter((object, index) => index !== selectedIndex), newValue]
                } else {
                    return [...array.filter((object, index) => index !== selectedIndex), newValue]
                }
            }
        }
    }

    static NumandSpl = (value) => {
        return NUM_SPLChar.test(value)
    }

    static capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    static serviceType = (value) => {
        return service.test(value)
    }

    static billNo = (value) => {
        return BILL_NO_VALIDATION.test(value)
    }

    static getDateBefore(days) {
        var currentDate = new Date();
        var pastDate = new Date(currentDate);
        pastDate.setDate(currentDate.getDate() - days);
        return pastDate;
    }

    static getDateBeforeWeeks(weeks) {
        var currentDate = new Date();
        var pastDate = new Date(currentDate);
        pastDate.setDate(currentDate.getDate() - (weeks * 7));
        return pastDate;
    }

    static getDateBeforeMonths(months) {
        var currentDate = new Date();
        var pastDate = new Date(currentDate);
        pastDate.setMonth(currentDate.getMonth() - months);
        return pastDate;
    }

    static DateConvertionWithTime(timestamp) {
        let year = timestamp.getFullYear();
        let month = ('0' + (timestamp.getMonth() + 1)).slice(-2); // Adding 1 because getMonth() returns zero-based month index
        let day = ('0' + timestamp.getDate()).slice(-2);
        let hours = ('0' + timestamp.getHours()).slice(-2);
        let minutes = ('0' + timestamp.getMinutes()).slice(-2);
        let seconds = ('0' + timestamp.getSeconds()).slice(-2);

        // Formatted date-time string
        let formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}`;
        return formattedDateTime;
    }

    static DateConvertionOnlyDate(timestamp) {
        let year = timestamp.getFullYear();
        let month = ('0' + (timestamp.getMonth() + 1)).slice(-2);
        let day = ('0' + timestamp.getDate()).slice(-2);

        let formattedDate = year + '-' + month + '-' + day;
        return formattedDate
    }

    static newGstValidation = (value) => {
        return GST_NEW_REGEX.test(value)
    }
}