import React, { Component } from "react";
import '../Configuration.css';
import { Box, Paper, TextField, Button, Stack, Grid, IconButton, Menu } from "@mui/material";
import { DataGrid, GridActionsCellItem } from '@mui/x-data-grid';
import { withTranslation } from 'react-i18next';
import { CommonEditButton } from "../../../Components/Common Components/CommonComponents";
import EditIcon from '@mui/icons-material/Edit';
import CancelIcon from '@mui/icons-material/Close';
import { ImagePaths } from "../../../Utility/ImagePaths";
import { Colors } from "../../../Styles/Colors";
import ToastMsg from '../../../Components/ToastMsg/ToastMsg';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { CheckAccessFunc } from "../../../Components/CommonFunctions/CommonFunctions";
class TokenPrefix extends Component {
    constructor(props) {
        super(props);
        this.state = {
            tokenDetail: [],
            dupTockenDetail: [],
            selectedIndex: -1,
            selectedLineItem: {},
            token_for: '',
            isEdit: false,
            selectedItem: {},
            prefix: "",
            anchorEl: null,
            cellModesModel: {},
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: '',
            page: 0,
            pageSize: 10,
            rowCount: 0,
            rightSideData: [],
            editedIds: [],

        }
    }
    componentDidMount = () => {
        this.getTokenPrefixDetails();
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    getTokenPrefixDetails = () => {
        try {
            this.LoaderFunction(true)
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPTOKENPREFIX)
                .then(response => {
                    if (response.data.status === "successs") {
                        this.setState({
                            tokenDetail: response?.data?.data ? response?.data?.data : [],
                            dupTockenDetail: response?.data?.data ? JSON.stringify(response?.data?.data) : [],
                            token_for: response?.data?.data ? response?.data?.data[0]?.token_for : ''
                        })
                        this.LoaderFunction(false)
                    }
                })
                .catch(error => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error?.response?.data?.message);
                    } else {
                        this.errorMessage(error?.message)
                    }
                });
        } catch (e) {
            this.LoaderFunction(false)
            this.errorMessage(e.message);
        }
    }
    handleAccordion = () => {
        const { t } = this.props;
        let { tokenDetail, token_for } = this.state;

        let accordionColumn = [
            { field: 's.no', headerName: t('SNo'), sortable: false, flex: 0.13, renderCell: (index) => index.api.getRowIndex(index.row.id) + 1 },
            { field: token_for === "Specialization" ? 'specialization_name' : 'doctor_name', headerName: (tokenDetail?.length > 0 && tokenDetail[0]?.token_for) === "Specialization" ? t('Specialization') : t('Doctor Name'), sortable: false, hideable: false, flex: 0.57 },
            { field: 'prefix', headerName: t('Prefix'), flex: 0.30, sortable: false, hideable: false },
        ]

        return (
            tokenDetail[0]?.data?.length > 0 ?
                <div id="eMed_srvConfig_accTbl">
                    <DataGrid
                        rows={tokenDetail[0]?.data}
                        columns={accordionColumn}
                        getRowId={(row) => row?.id}
                        autoHeight
                        disableColumnMenu
                        hideFooter
                        components={{
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        headerHeight={40}
                    />
                </div>
                : null
        )

    }

    //Start of left table Function
    handleEdit = (item) => {
        let states = this.state
        states["token_for"] = item?.token_for;
        states["rightSideData"] = item?.data;
        states['isEdit'] = true
        this.setState({
            states
        })
    }
    // End of left table Function

    //Start of right div Function
    handleEditTbl = (item) => {
        this.setState({
            selectedItem: JSON.parse(JSON.stringify(item)),
        })
    }

    handleSaveClick = (id) => {
        let { rightSideData, selectedItem, editedIds } = this.state;
        if (this.state.selectedItem?.prefix < 1) {
            this.errorMessage("Need to enter alteast one character")
        } else {
            let newList = rightSideData?.map((item) => {
                if (item?.id === id) {
                    item = selectedItem
                }
                return item
            })
            if (editedIds?.includes(id) === false) {
                editedIds?.push(id)
            }
            this.setState({
                rightSideData: newList,
                selectedItem: {},
                editedIds: editedIds,
            })
        }
    };

    handleCancelClick = (id) => {
        this.setState({ selectedItem: {} })
    };

    handleClear = () => {
        let data = this.state.dupTockenDetail?.length > 0 ? JSON.parse(this.state.dupTockenDetail) : []
        this.setState({
            rightSideData: data?.length > 0 ? data[0]?.data : [],
            editedIds: []
        })
    }

    handleSave = () => {
        let { rightSideData, editedIds } = this.state;
        let lineItems = rightSideData?.filter((item) => editedIds?.includes(item?.id))
        let datas = {
            "prefix_source": this.state.token_for ? this.state.token_for : '',
            "line_item": lineItems
        }
        try {
            this.LoaderFunction(true)
            RestAPIService.create(datas, Serviceurls.FO_CONFIG_OPTOKENPREFIX)
                .then(response => {
                    if (response.data.status === "successs") {
                        this.getTokenPrefixDetails();
                        this.LoaderFunction(false)
                        this.setState({ disableBtn: false, editedIds: [] })
                        this.successMessage(response.data?.message);
                    }
                })
                .catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.LoaderFunction(false)
                        this.errorMessage(error.response.data.message);
                    } else {
                        this.errorMessage(error?.message)
                    }
                    this.setState({ disableBtn: false })
                });
        } catch (e) {
            this.LoaderFunction(false)
            this.setState({ disableBtn: false })
            this.errorMessage(e?.message);
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }
    render() {
        const { t } = this.props;
        let { tokenDetail, anchorEl, token_for, rightSideData, selectedItem } = this.state;
        tokenDetail?.forEach((element, index) => element.sno = index + 1)
        let open = Boolean(anchorEl);
        let disableSave = this.state.editedIds?.length === 0 || this.state.disableBtn
        const columns = [
            {
                field: "sno", headerName: t("S No"), flex: 0.1, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.sno ? params?.row?.sno : "-"}</Box>)
            }, {
                field: 'token_for', headerName: t('TokenFor'), flex: 0.70, renderCell: ({ row }) => {
                    return (
                        <Box>{row?.token_for ? row.token_for : ''}</Box>
                    )
                }
            },
            {
                field: 'actions', headerAlign: 'center', headerName: t('Action'), flex: 0.20, sortable: false, hideable: false, align: "center", renderCell: ({ row }) => {
                    return (
                        <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={2}>
                            <CommonEditButton disable={!CheckAccessFunc("front_office", "Settings", "Hospital Configuration", "Token Prefix", "SubTab")?.editAccess} size="1.5vw" emed_tid='tokenPrefix_mainEditBtn_testId' onClick={this.handleEdit.bind(this, row)} />
                            <div>
                                <IconButton emed_tid ={"downArrow_TestID"} size="small" onClick={(event) => { this.setState({ anchorEl: event.currentTarget, selectedIndex: row.index, selectedLineItem: row }) }}><img className="eMed_arrowBtn" src={ImagePaths.downArrow.default} style={{ transform: this.state.selectedIndex === row.index ? "rotate(180deg)" : null, }} alt="Arrow" /></IconButton>
                                <Menu
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={(e) => {
                                        this.setState({
                                            anchorEl: null,
                                            selectedIndex: -1,
                                            selectedLineItem: {}
                                        })
                                    }}
                                    PaperProps={{
                                        style: {
                                            height: "fitContent",
                                            width: '55vw',
                                            marginLeft: "-36.5vw",
                                            marginTop: "0.80vw",
                                            boxShadow: "none"
                                        },
                                    }}
                                    MenuListProps={{
                                        disablePadding: true,

                                    }}
                                >
                                    {this.handleAccordion()}
                                </Menu>
                            </div>
                        </Stack>
                    )
                }
            }
        ]
        const rightColumn = [
            { field: token_for === "Specialization" ? 'specialization_name' : 'doctor_name', headerName: (tokenDetail?.length > 0 && tokenDetail[0]?.token_for) === "Specialization" ? t('Specialization') : t('Doctor Name'), sortable: false, hideable: false, flex: 0.28 },
            {
                field: 'prefix', headerName: t('Prefix'), flex: 0.25, sortable: false, hideable: false, renderCell: ({ row }) => {
                    if (selectedItem["id"] === row?.id) {
                        return (
                            <TextField value={selectedItem["prefix"] ? selectedItem["prefix"] : ""} inputProps={{ emed_tid: "prefix_TestID001"}}
                                onChange={(event) => {
                                    if (event.target.value?.length <= 4) {
                                        selectedItem["prefix"] = event.target.value?.trimStart();
                                        this.setState({ selectedItem })
                                    }
                                }} variant="standard" />
                        )
                    } else {
                        return row?.prefix
                    }
                }
            },
            {
                field: 'actions', type: 'actions', headerAlign: "center", headerName: t('Action'), flex: 0.17, sortable: false, hideable: false, align: "center", getActions: ({ id, row }) => {
                    if (this.state.selectedItem["id"] === id) {
                        return [
                            <GridActionsCellItem
                                icon={<img src={ImagePaths.Tick.default} />}
                                label="Save"
                                emed_tid="tokenPrefix_saveBtn_testId"
                                onClick={() => { this.handleSaveClick(id) }}
                                color="secondary"
                            />,
                            <GridActionsCellItem
                                icon={<CancelIcon />}
                                label="Cancel"
                                className="textPrimary"
                                emed_tid="tokenPrefix_cancelBtn_testId"
                                onClick={() => { this.handleCancelClick(id) }}
                                color="error"
                            />
                        ]
                    }
                    return [
                        <GridActionsCellItem
                            icon={<EditIcon />}
                            label="Edit"
                            className="textPrimary"
                            size="large"
                            emed_tid="tokenPrefix_editBtn_testId"
                            onClick={() => { this.handleEditTbl(row) }}
                            color="primary"
                        />
                    ];
                }
            }
        ]
        return (
            <Box id="eMed_srvConfig_Container" sx={{ backgroundColor: Colors.configHomeBg }}>
                <Grid container spacing={3} className="eMed_srvConfig_Box">
                    <Grid item xs={7.6}>
                        <Paper className="eMed_srvConfig_Div" >
                            <DataGrid
                                rows={tokenDetail}
                                columns={columns}
                                page={this.state.page}
                                pageSize={this.state.pageSize}
                                getRowId={(row) => row['sno']}
                                localeText={{
                                    toolbarColumns: "",
                                    toolbarDensity: "",
                                    toolbarExport: "",
                                    toolbarFilters: "",
                                    toolbarExportPrint: ""
                                }}
                                components={{
                                    NoRowsOverlay: () => (
                                        <Stack className='eMed_conf_nodata'>
                                            {t("NoRecordsFound")}
                                        </Stack>
                                    )
                                }}
                                rowCount={this.state.rowCount}
                                onPageChange={(newPage) => { this.setState({ page: newPage }, () => this.getTokenPrefixDetails()) }}
                                onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize }, () => this.getTokenPrefixDetails())}
                                rowsPerPageOptions={[10, 20, 30]}
                                headerHeight={40}
                                disableSelectionOnClick
                                pagination
                                paginationMode='server'
                                loading={this.state.isLoader}
                            />
                        </Paper>
                    </Grid>
                    <Grid item xs={4.4}>
                        <Paper className="eMed_srvConfig_Div">
                            <div className='eMed_srvConfig_rightHead' style={{ backgroundColor: Colors.DataTblHeaderbg }}>
                                <p className='eMed_srvConfig_rightHead_txt'>{t("TokenPrefix")}</p>
                            </div>
                            <div className="eMed_srvConfig_subDiv" style={{ overflow: 'auto', padding: '0.5vw', display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
                                <TextField size="small" value={token_for ? token_for : ""} disabled={true} sx={{ width: '29.8vw' }} inputProps={{ emed_tid: "tokenPrefix_tokenFor_testID" }} />
                                <div id="eMed_srvConfig_rightTbl2">
                                    <DataGrid
                                        rows={rightSideData}
                                        columns={rightColumn}
                                        getRowId={(row) => row?.id}
                                        disableColumnMenu
                                        hideFooter
                                        components={{
                                            NoRowsOverlay: () => (
                                                <Stack className='eMed_conf_nodata'>
                                                    {t("NoRecordsFound")}
                                                </Stack>
                                            )
                                        }}
                                        headerHeight={40}
                                    />
                                </div>
                            </div>
                            <Stack spacing={2} direction="row" id='eMed_srvConfig_rightBtnCon' sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center">
                                <Button variant='outlined' size="small" id="eMed_Config_btn" disabled={!this.state.isEdit || !CheckAccessFunc("front_office", "Settings", "Hospital Configuration", "Token Prefix", "SubTab")?.editAccess} emed_tid="TokenPrefix_reset_Btn" onClick={() => { this.handleClear() }}>{t("Reset")}</Button>
                                <Button disabled={disableSave ? true : !CheckAccessFunc("front_office", "Settings", "Hospital Configuration", "Token Prefix", "SubTab")?.editAccess} variant='contained' size="small" id="eMed_Config_btn" emed_tid="TokenPrefix_Save_Btn" onClick={() => { this.setState({ disableBtn: true }, () => { this.handleSave() }) }}>{t("Save")}</Button>
                            </Stack>
                        </Paper>
                    </Grid>
                </Grid>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(TokenPrefix);
