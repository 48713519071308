import { Autocomplete, Box, Button, Drawer, FormControl, Grid, InputLabel, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../reports.css';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../../Utility/ImagePaths';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import CommonGridHeader, { CommonGridToolBarWithFilterText, CommonTimeRangePicker } from "../../../../Components/Common Components/CommonComponents";
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { AmountFormat, formatDate, timeOnlyConvert } from "../../../../Components/CommonFunctions/CommonFunctions";
import { DateTime } from 'luxon';
import { CurrencySymbol } from '../../../../Utility/Constants';
import Loader from '../../../../Components/Loader';


class ServiceShare extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            doctorName: [],
            serviceName: [],
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            dateIndex: 1,
            serviceShareData: [],
            serviceTotalAmount: "",
            serviceDoctorName: [],
            serviceNameAndType: [],
            hospitalShare:'',
            doctorShare:'',
            FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilterDatas: {
                "startTime":null,
                "endTime":null,
                "onError":false,
            },
            timeError: false,
            printLoading: false,
            disableBtn: false,
        }
    }

    componentDidMount() {
        this.getServcieShareReportData()
        this.getServiceFilterDoctorList()
        this.getServiceNameAndTypeFilterList()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    onChangeHandler = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        }, ()=> {
            this.state.FilterDatas.endTime = null
            this.setState({
                FilterDatas:this.state.FilterDatas
            })
        })
    }
    
    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    getServiceFilterDoctorList = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME)
                .then((response) => {
                    if (response.data.status === 'success') {
                        this.setState({
                            serviceDoctorName: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getServiceNameAndTypeFilterList = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_IPSRCNAME)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            serviceNameAndType: response.data.data
                        },()=>{
                            let Anesthetist = {
                                data:{
                                    amount:0,
                                    is_edit_front_office:false,
                                    service_id:null,
                                    service_name:"Anesthetist Fee",
                                    service_type:"Anesthetist Fee"
                                },
                                service_name:"Anesthetist Fee"
                            }

                            let Surgeon = {
                                data:{
                                    amount:0,
                                    is_edit_front_office:false,
                                    service_id:null,
                                    service_name:"Surgeon Fee",
                                    service_type:"Surgeon Fee"
                                },
                                service_name:"Surgeon Fee"
                            }

                            this.state.serviceNameAndType.push(Anesthetist,Surgeon)
                            this.setState({serviceNameAndType:this.state.serviceNameAndType})
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getServcieShareReportData = () => {
        try{
            this.LoaderFunction(true)
            let doctorId = []; let serviceName = [];
            this.state.doctorName.forEach(element => doctorId.push(element.doctor_id))
            this.state.serviceName.forEach(element => serviceName.push(element.service_type))
            var {FilterDatas} = this.state
            let start_time = FilterDatas.startTime ? new Date(FilterDatas.startTime) : ''
            let end_time = FilterDatas.endTime ? new Date(FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            RestAPIService.getAll(Serviceurls.DOCTOR_SHARE_REPORT + `?from_date=${this.state.fromDate}&service_name=${serviceName}&doctor_id=${doctorId}&to_date=${this.state.toDate}&module_type=IP&start_time=${setStartTime}&end_time=${setEndTime}`)
            .then((response) => {
                if(response.data.status === "success"){
                    this.setState({
                        serviceShareData:response.data.data,
                        hospitalShare:response.data.hospital_share_amount,
                        doctorShare:response.data.doctor_share_amount,
                        FilteredFromDate: this.state.fromDate,
                        FilteredToDate: this.state.toDate,
                    },()=>{this.LoaderFunction(false)})
                }
            }).catch((error)=>{
                this.LoaderFunction(false)
                if(error?.response?.data?.message){
                    this.errorMessage(error?.response?.data?.message)
                }else{
                    this.errorMessage(error.message)
                }
            })
            
        }catch(error){
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    getServiceShareReportPrint = () => {
        try{
            let doctorId = []; let serviceName = [];
            this.state.doctorName.forEach(element => doctorId.push(element.doctor_id))
            this.state.serviceName.forEach(element => serviceName.push(element.service_type))
            var {FilterDatas} = this.state
            let start_time = FilterDatas.startTime ? new Date(FilterDatas.startTime) : ''
            let end_time = FilterDatas.endTime ? new Date(FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            this.setState({disableBtn: true, printLoading: true})
            RestAPIService.getAll(Serviceurls.DOCTOR_SHARE_REPORT + `?from_date=${this.state.fromDate}&service_name=${serviceName}&doctor_id=${doctorId}&to_date=${this.state.toDate}&export_type=pdf&module_type=IP&start_time=${setStartTime}&end_time=${setEndTime}`)
            .then((response) => {
                const file = new Blob(
                    [response.data],
                    { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
                this.setState({disableBtn: false, printLoading: false})
            }).catch((error) => {
                this.errorMessage(error.response?.data?.message)
                this.setState({disableBtn: false, printLoading: false})
            })
        }catch(error){
            this.errorMessage(error.message)
            this.setState({disableBtn: false, printLoading: false})
        }
    }

    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }

    selectedTime = (start, end,error) => {
        this.state.FilterDatas.startTime = start
        this.state.FilterDatas.endTime = end
        this.setState({ FilterDatas: this.state.FilterDatas, timeError: error })
    }
    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button emed_tid = {"LabCloseButton_ID"}  onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)}
                            emed_tid = {"FilterDate_ID"}
                            HideAllMenu={true} />
                    </Box>
                    <Box sx={{width: '22vw'}}>
                    <CommonTimeRangePicker
                        startTime={this.state.FilterDatas?.startTime}
                        endTime={this.state.FilterDatas?.endTime}
                        selectedTime={this.selectedTime.bind(this)}
                        emed_tid = {"selectedTime_testID"}
                        FromDate={this.state.fromDate}
                        ToDate={this.state.toDate}
                        onError={this.state.FilterDatas.onError}
                    />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={this.state.serviceDoctorName?.map((item) => item)}
                            getOptionLabel={(option) => (option?.doctor_name)}
                            value={this.state.doctorName}
                            onChange={(event, newValue) => {
                                this.setState({ doctorName: newValue })
                            }}
                            renderOption={(props, option) => (
                                <li {...props} key={option?.doctor_id}>
                                  {option?.doctor_name}
                                </li>
                            )}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{ ...params.inputProps,'emed_tid': 'select_docter' }}
                                    label={t('selectDoctor')}
                                    emed_tid = {"selectDoctor_testID"}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={this.state.serviceNameAndType?.map((item) => item)}
                            getOptionLabel={(option) => (option?.service_type)}
                            value={this.state.serviceName}
                            onChange={(event, newValue) => {
                                this.setState({ serviceName: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{ ...params.inputProps,'emed_tid': 'select_type' }}
                                    label={t("Select Service Type")}
                                    emed_tid = {"Service_testID"}
                                />
                            )}
                        />
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"  emed_tid = {"getServcieShareReportData_testID"}
                        onClick={() => {
                            this.setState({
                                doctorName: [],
                                serviceName: [],
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                FilterDatas: {
                                    "startTime":null,
                                    "endTime":null,
                                    "onError": false
                                },
                                timeError: false,
                            },()=>this.getServcieShareReportData())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small"  emed_tid = {"Search_testID"}
                    disabled={this.state.FilterDatas.startTime ? (this.state.FilterDatas.startTime && this.state.FilterDatas.endTime && this.state.timeError != true) ? false : true : false}
                        onClick={() => {
                            this.getServcieShareReportData()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>IPAdmissionList
            </Box>
        )
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }

    gridToolBar = () => {
        var { t } = this.props
        let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
        let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
        let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let TextArray = [
            { label: "From Date", value: formatDate(this.state.FilteredFromDate) },
            { label: "To Date", value: formatDate(this.state.FilteredToDate) },
        ]
        if (this.state.FilterDatas.startTime !== null && this.state.FilterDatas.endTime !== null) {
            TextArray.push(
                { label: "Start Time", value: timeOnlyConvert(setStartTime) },
                { label: "End Time", value: timeOnlyConvert(setEndTime) }
            )
        }
        return (
            <Box>
                <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
            </Box>

        )
    }

    render() {
        this.state.serviceShareData.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            {
                field: "created_date", headerName: 'Date', flex: 0.143,align:'center',headerAlign:'center',
                renderCell: (params) => <Box>{params?.row?.created_date ? formatDate(params?.row?.created_date) : '-'}</Box>
            },
            {
                field: "doctor_name", headerName: t("DoctorName"), flex: 0.143,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.doctor_name?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.doctor_name} arrow><div>{params?.row?.doctor_name.slice(0, 20) + "..."}</div></Tooltip>
                    : params?.row?.doctor_name ? params?.row?.doctor_name : "-"}</Box>)
            },
            {
                field: "service_type", headerName: t("ServiceType"), flex: 0.143,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.service_type?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.service_type} arrow><div>{params?.row?.service_type.slice(0, 20) + "..."}</div></Tooltip>
                    : params?.row?.service_type ? params?.row?.service_type : "-"}</Box>)
            },
            {
                field: "service_unit_count", headerName: t("Count"), flex: 0.143,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.service_unit_count?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.service_unit_count} arrow><div>{params?.row?.service_unit_count.slice(0, 20) + "..."}</div></Tooltip>
                    : params?.row?.service_unit_count ? params?.row?.service_unit_count : "-"}</Box>)
            },
            {
                field: "doctor_share_amount", headerName: t("DoctorShare"), flex: 0.143, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.doctor_share_amount ? AmountFormat(params?.row?.doctor_share_amount).replace(`${CurrencySymbol}`, "") : "0"}</Box>)
            },
            {
                field: "hospital_share_amount", headerName: t("HospitalShare"), flex: 0.143, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.hospital_share_amount ? AmountFormat(params?.row?.hospital_share_amount).replace(`${CurrencySymbol}`, "") : "0"}</Box>)
            },
            {
                field: "discount_amount", headerName: `${t("concession")}`, flex: 0.143, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.discount_amount ? AmountFormat(params?.row?.discount_amount).replace(`${CurrencySymbol}`, "") : "0"}</Box>)
            }
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {AmountsCard(t("DoctorShare"), this.state.doctorShare)}
                        {AmountsCard(t("HospitalShare"), this.state.hospitalShare)}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div' display={'flex'} padding={'0.5vw'} width={'7vw'}>
                            <Button className='eMed_usrconf_btn'  emed_tid = {"eMed_usrconf_btn_ID"}  onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                            <Button className='eMed_usrconf_btn' emed_tid = {"filter_btn_ID"}  disabled={this.state.disableBtn} onClick={() => { this.getServiceShareReportPrint() }}>
                                <img src={ImagePaths.LabPrintIcon.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={this.state.serviceShareData}
                        columns={columns}
                        getRowId={(row) => row['sno']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={this.state.printLoading} forPrint={true} label={"Building PDF..."} />
            </Box>
        )
    }
}


export default withTranslation()(ServiceShare)