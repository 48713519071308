import { Autocomplete, Box, Button, Chip, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import '../history.css';
import { Colors } from '../../../../Styles/Colors'
import { CommonPatientCard, CommonPatientDetails, DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import Loader from '../../../../Components/Loader';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { Medical_History_Blood_Group, Medical_History_Problems } from '../../../../Utility/Constants';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService';
import { DataGrid } from '@mui/x-data-grid';
import { CheckAccessFunc } from "../../../../Components/CommonFunctions/CommonFunctions";


export default class DocMedicalHistory extends Component {
  constructor(props) {
    super(props)
    this.state = {
      medicalHistoryData: [],
      medicalProblemsData: Medical_History_Problems,
      medicalBloodGroup: Medical_History_Blood_Group,
      selectedBloodgroup: null,
      selectedProblem: null,
      comments: '',
      patientDetails: {},
      clinicId: '',
      appointmentId: '',
      patientId: '',
      bloodGroup: '',
      selectedID: null,
      isEdit: false,
      deletePop: false,
      // for fo 
      withOutAppointment: {},
      doctor_id: "",
    }
  }

  componentDidMount() {
    let clinicId = getCachevalue('SelectedDoctorClinic')
    let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))
    let ipPatientData = JSON.parse(getCachevalue("IppatientData"));
    let appointmentDetail = JSON.parse(getCachevalue("withoutAppointment"));
    const newState = {
      clinicId: clinicId,
      appointmentId: appointmentId?.appointment_id,
      patientId: appointmentId?.patient_id || ipPatientData?.patient_id,
      doctor_id: ipPatientData?.doctor_id
    };
    if (appointmentDetail?.patient_id === ipPatientData?.patient_id) {
      newState.withOutAppointment = appointmentDetail;
    }
    this.setState({
      ...newState
    }, () => {
      this.getPatientDetailData()
      this.getDoctorMedicialHistory()
    })
  }

  getPatientDetailData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.state.patientId)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              patientDetails: response?.data?.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getDoctorMedicialHistory = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_MEDICAL_HISTORY + '?patient_id=' + this.state.patientId)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              medicalHistoryData: response?.data?.data?.patient_medical_history,
              bloodGroup: response.data.data?.blood_group,
              selectedBloodgroup : response.data.data?.blood_group,
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postDoctorMedicalHistoryData = () => {
    try {

      let data = {
        appointment_id: this.state.appointmentId,
        blood_group: this.state.selectedBloodgroup ? this.state.selectedBloodgroup : '',
        comments: this.state.comments,
        disease_names: this.state.selectedProblem,
        patient_id: this.state.patientId
      }
      if (this.state.selectedID) {
        data['id'] = this.state.selectedID
      }
      if(this.props.is_From_Fo){
        data['doctor_id'] = this.state.doctor_id
      }
      this.LoaderFunction(true)
      RestAPIService.create(data, Serviceurls.DOC_MEDICAL_HISTORY)
        .then((response) => {
          if (response.data.status === 'success') {
            this.LoaderFunction(false)
            this.successMessage(response.data.message)
            this.clearMedicalHistory()
            this.getDoctorMedicialHistory()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.LoaderFunction(false)
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.LoaderFunction(false)
      this.errorMessage(error.message)
    }
  }

  deleteMedicalHis = () => {
    try {

      let data = {
        id: this.state.selectedID,
        patient_id: this.state.patientId
      }
      this.setState({ removeData: true })
      this.LoaderFunction(true)
      RestAPIService.delete(Serviceurls.DOC_MEDICAL_HISTORY, data)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({ removeData: false })
            this.LoaderFunction(false)
            this.successMessage(response.data.message)
            this.clearMedicalHistory()
            this.getDoctorMedicialHistory()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ removeData: false })
            this.LoaderFunction(false)
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ removeData: false })
            this.LoaderFunction(false)
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ removeData: false })
      this.LoaderFunction(false)
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }



  clearMedicalHistory = () => {
    try {
      this.setState({
        selectedBloodgroup: this.state.bloodGroup,
        selectedProblem: null,
        comments: '',
        selectedID: null,
        isEdit: false,
        deletePop: false,
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }


  renderRightSide = () => {
    const enableAdd = this.state.selectedProblem?.length > 0
    const checkuserAccess = (CheckAccessFunc("front_office", "In Patients", "History", "Medical History", "SubTab")?.editAccess ?
    (this.state.withOutAppointment?.is_ip || true) && enableAdd : enableAdd);
    try {
      return (
        <Box component={'div'} width={'92.5vw'}>
          <Box component={'div'} height={'2vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Medical History</Typography>
          </Box>
          <Box component={'div'} height={'12vh'} sx={{ backgroundColor: Colors.white }} overflow={'scroll'} display={'flex'} flexDirection={'row'} alignItems={'center'} justifyContent={'space-between'}>
            <Box display={'flex'} flexDirection={'row'} width={'50vw'} justifyContent={'space-between'}>
              <Box component={'div'} ml={'0.5vw'}>
                <Autocomplete
                  clearIcon
                  size='small'
                  sx={{ width: '12vw' }}
                  options={this.state.medicalBloodGroup.map((item) => item.label)}
                  value={this.state.selectedBloodgroup}
                  autoComplete='off'
                  onChange={(event, newValue) => {
                    this.setState({ selectedBloodgroup: newValue })
                  }}
                  renderInput={(params) => <TextField {...params} label='Select Blood' />}
                />
                {this.state.bloodGroup ? <Typography fontWeight={600} marginTop={"0.6vw"}>{`Blood Group: ${this.state.bloodGroup}`}</Typography> : null}
              </Box>
              <Box component={'div'} >
                <Autocomplete
                  clearIcon
                  size='small'
                  sx={{ width: '18vw' }}
                  options={this.state.medicalProblemsData.map((item) => item.label)}
                  value={this.state.selectedProblem}
                  limitTags={3}
                  autoComplete='off'
                  onChange={(event, newValue) => {
                    this.setState({ selectedProblem: newValue })
                  }}
                  renderTags={(value, getTagProps) => (
                    value.map((option, index) => (<Chip variant="outlined" size='small' label={option} {...getTagProps({ index })} />))
                  )}
                  renderInput={(params) => <TextField {...params} label='Select Problems' inputProps={{...params.inputProps,emed_tid:'slt_prblm'}} />}
                />
              </Box>
              <Box component={'div'}>
                <TextField
                  size='small'
                  label='Comments'
                  sx={{ width: '16vw' }}
                  fullWidth
                  value={this.state.comments}
                  onChange={(e) => { this.setState({ comments: e.target.value }) }}
                />
              </Box>
            </Box>
            <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
              <Button
                sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                variant='outlined'
                onClick={() => this.clearMedicalHistory()}
              >Clear</Button>
              <Button
                disabled={this.props.is_From_Fo ? !checkuserAccess : !enableAdd}
                sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                variant='contained'
                onClick={() => this.postDoctorMedicalHistoryData()}
              >Add</Button>
            </Box>
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onEditHandler = (item) => {
    this.setState({
      selectedID: item.id,
      selectedProblem: item.disease_name,
      comments: item.comments,
      selectedBloodgroup: this.state.bloodGroup ? this.state.bloodGroup : null,
      isEdit: true,
    })
  }
  onDeleteHandler = (item) => {
    try {
      if (!this.state.isEdit) {
        this.setState({
          deletePop: true,
          selectedID: item?.id
        })
      } else {
        this.errorMessage("You have clicked edit Button.So,You cannot able to delete")
      }

    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderTbl = () => {
    try {
      const columns = [
        {
          field: 'created_date', headerName: 'Date', flex: 0.25,
          renderCell: (params) => (<Box>{params?.row?.created_date ? params?.row?.created_date : '-'}</Box>)
        },
        {
          field: 'disease_name', headerName: 'Problem', flex: 0.25,
          renderCell: (params) => (<Box>{params?.row?.disease_name ? params?.row?.disease_name?.length > 15 ?
            <Tooltip placement='top' title={params?.row?.disease_name}><div>{params?.row?.disease_name.slice(0, 15) + '...'}</div></Tooltip> :
            params?.row?.disease_name : '-'}</Box>)
        },
        {
          field: 'comments', headerName: 'Comments', flex: 0.25,
          renderCell: (params) => (<Box>{params?.row?.comments ? params?.row?.comments?.length > 20 ?
            <Tooltip placement='top' title={params?.row?.comments}><div>{params?.row?.comments.slice(0, 20) + '...'}</div></Tooltip> :
            params?.row?.comments : '-'}</Box>)
        },
        {
          field: 'action', headerName: 'Action', flex: 0.25, headerAlign: 'center', align: 'center',
          renderCell: (params) => (<Box>
            <Button className='Common_Btn_Min_Width'
              onClick={() => this.onEditHandler(params?.row)}
            >
              <Box height={'1.5vw'} width={'1.5vw'} component={'img'} src={ImagePaths.Edit.default} />
            </Button>
            <Button className='Common_Btn_Min_Width'
              onClick={() => this.onDeleteHandler(params?.row)}
            >
              <Box height={'1.5vw'} width={'1.5vw'} component={'img'} src={ImagePaths.Delete.default} />
            </Button>
          </Box>)
        }
      ]
      return (
        <DataGrid
          rows={this.state.medicalHistoryData}
          sx={{ backgroundColor: Colors.white }}
          columns={columns}
          getRowId={(row) => row?.id}
          page={this.state.page}
          pageSize={this.state.pageSize}
          // localeText={{
          //   toolbarColumns: "",
          //   toolbarDensity: "",
          //   toolbarExport: "",
          //   toolbarFilters: "",
          //   toolbarExportPrint: ""
          // }}
          components={{
            // Toolbar: CommonGridHeader.CommonToolBar,
            NoRowsOverlay: () => (
              <Box className='eMed_conf_nodata' height={'40vh'}>
                {'No Records To Be Shown'}
              </Box>
            )
          }}
          onPageChange={(newPage) => { this.setState({ page: newPage }) }}
          onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
          rowsPerPageOptions={[10, 20, 30]}
          pagination
          hideFooter
          headerHeight={40}
          disableSelectionOnClick
          loading={this.state.loading}
        />
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  popupClose = () => {
    try {
      this.setState({
        deletePop: false
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    let attender = {
      lineOne: "Attender Name",
      lineTwo: `${this.state.patientDetails?.attender_name ? this.state.patientDetails?.attender_name :
        this.state.patientDetails?.caretaker_name ? this.state.patientDetails?.caretaker_name : "-"}`,
      lineFour: 'Contact Number',
      LineFive: `${this.state.patientDetails?.attender_mobile ? this.state.patientDetails?.attender_mobile :
        this.state.patientDetails?.caretaker_mobile ? this.state.patientDetails?.caretaker_mobile : "-"}`
    }
    return (
      <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'74vh'} p={'0.5vw'}>
        <Box component={'div'} display={'flex'}>
          <Box component={'div'} className='doc_header_card' mr={'0.5vw'}>
            <CommonPatientDetails data={this.state.patientDetails} showDetailed={true} fromDoctor={true} />
          </Box>
          {/* <CommonPatientCard details={attender} showDetailed={true} /> */}
        </Box>
        <Box component={'div'} mt={'0.5vw'}>
          <Box component={'div'} flex={0.35} border={'1px solid lightgray'}>
            {this.renderRightSide()}
          </Box>
          <Box component={'div'} mt={'.5vw'} height={'44vh'}>
            {this.renderTbl()}
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.deletePop ? <DeletePopup
          DeletTitle={`Are you sure you want to delete ?`}
          deleteAlertPopupClose={this.popupClose.bind(this)}
          removeData={this.deleteMedicalHis.bind(this)}
          disable={this.state.removeData}
        /> : null}
        <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}
