import React, { Component } from "react";
import {
    Box, Paper, TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Button, Stack, Typography, Autocomplete, IconButton, Tooltip
} from "@mui/material";
import "./purchase.css";
import { Trans, withTranslation } from 'react-i18next';
import { ImagePaths } from '../../../Utility/ImagePaths';
import RestAPIService from "../../../Utility/Services/RestAPIService";
import { Serviceurls } from "../../../Utility/API/Serviceurls";
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTime } from "luxon";
import DirectPoRight from "./DirectPoRight";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { CurrencySymbol } from "../../../Utility/Constants";

let todate = new Date();
class DirectPo extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                vendor_name: "",
                vendor_id: "",
                delivery_date: DateTime.fromJSDate(todate).toFormat("yyyy-MM-dd"),
                billNo: "",
                billDate: DateTime.fromJSDate(todate).toFormat("yyyy-MM-dd"),
                gstAmt: "",
                billAmt: ""
            },
            tableData: [],
            vendor_list: [],
            selectedEntry: {},
            serial_no: 0,
            purchase_order_id: this.props.purchase_order_id ? this.props.purchase_order_id : null,
            purchase_status: this.props.purchase_status ? this.props.purchase_status : "",
            previous_vendor_bills: [],
            latest_vendor_bill: {},
            open: false,
            open1: false,
            isselectedLineItem: false,
            selectedIndex: null,
            screenType: this.props.screenType ? this.props.screenType : "",
            disableBtn: false,
            saveLoad:false
        }
    }
    componentDidMount() {
        this._getVendorList()
        if (this.state.purchase_order_id) {
            this._getDataFromPurchaseorder()
        }
    }
    _getVendorList = () => {
        this.setState({
            vendor_list: []
        })
        RestAPIService.getAll(Serviceurls.LIST_VENDOR)
            .then(response => {
                if (response.data.status == "success") {
                    this.setState({
                        vendor_list: response.data.data,
                    });
                }
            })
            .catch(error => {
                if (error.response.data.status == "fail") {
                    this.SuccessErrorBox(error.response.data.message, false);
                }
            });
    }
    SuccessErrorBox = (message, type) => {
        this.props.responseSuccessErrorMessage(message, type)
    }

    // post api

    addToStock = () => {
        try {
            const { t } = this.props;
            let { data, tableData, latest_vendor_bill } = this.state
            let today = new Date();

            let cgst_amount = parseFloat(data.gstAmt / 2).toFixed(2);

            if (!data.vendor_id) {
                this.SuccessErrorBox(t("EnterVendor"), false)
                this.setState({ saveLoad: false })
                return
            } else if (!data.delivery_date) {
                this.SuccessErrorBox(t("Enterdeliverydate"), false)
                this.setState({ saveLoad: false })
                return
            } else if (!data.billNo) {

                this.SuccessErrorBox(t("Enterpurchasebillnumber"), false)
                this.setState({ saveLoad: false })
                return
            } else if (!data.billDate) {

                this.SuccessErrorBox(t("Enterpurchasebilldate"), false)
                this.setState({ saveLoad: false })
                return
            } else if (!data.billAmt && data.billAmt != 0) {
                this.SuccessErrorBox(t("Enterpurchasebillamount"), false)
                this.setState({ saveLoad: false })
                return
            }

            let datas = {
                "vendor_id": data.vendor_id,
                "bill_date": data.billDate,
                "bill_no": data.billNo ? data.billNo : "",
                "cgst_amount": parseFloat(cgst_amount),
                "sgst_amount": parseFloat(cgst_amount),
                "purchase_order_date": data["purchase_order_date"] ? data["purchase_order_date"] : DateTime.fromJSDate(today).toFormat("yyyy-MM-dd"),      //yyyy-mm-dd
                "delivery_date": data.delivery_date,
                "total_amount": parseFloat(data.billAmt),
                "product_line_items": []
            }
            if (this.state.purchase_status == "Partially Delivered") {
                datas["bill_id"] = latest_vendor_bill["bill_id"] ? latest_vendor_bill["bill_id"] : 0
                datas["purchase_order_number"] = data["purchase_order_number"] ? data["purchase_order_number"] : 0
            } else if (this.state.purchase_status == "Placed Order") {
                datas["purchase_order_number"] = data["purchase_order_number"] ? data["purchase_order_number"] : 0
            }

            tableData.length > 0 && tableData.forEach((item) => {

                let is_edited = "isEdit" in item;
                if (is_edited || this.state.screenType == "directPo") {
                    if (item.product_ms_id && item.batch_no && item.expiry_date && item.pack_of_drugs && item.sales_unit && item.buying_price && item.mrp_per_pack) {
                        let tempDrug = {
                            "serial_no": parseInt(item.serial_no),
                            "brand_name": item.brand_name,
                            "material_type": item.material_type,
                            "product_name": item.product_name,
                            "product_type": item.product_type,
                            "unit": item.unit,
                            "batch_no": item.batch_no,
                            "expiry_alert_period": parseInt(item.expiry_alert_period),
                            "pack_of_drugs": parseInt(item.pack_of_drugs),
                            "sales_unit": parseInt(item.sales_unit),
                            "buying_price": parseFloat(item.buying_price),
                            "stock_in_quantity": parseInt(item.stock_in_quantity),
                            "free_quantity": item.free_quantity ? parseInt(item.free_quantity) : 0,
                            "total_buying_price": parseFloat(item.total_buying_price),
                            "cgst_percentage": parseFloat((item.gst_percentage / 2).toFixed(2)),
                            "cgst_amount": parseFloat((item.total_gst_amount / 2).toFixed(2)),
                            "sgst_percentage": parseFloat((item.gst_percentage / 2).toFixed(2)),
                            "sgst_amount": parseFloat((item.total_gst_amount / 2).toFixed(2)),
                            "mrp_per_pack": parseFloat(item.mrp_per_pack),
                            "mrp_per_quantity": parseFloat(item.mrp_per_quantity),
                            "discount_percentage": item.discount_percentage ? parseFloat(item.discount_percentage) : 0,
                            "discount_amount": item.discount_amount ? parseFloat(item.discount_amount) : 0,
                            "sales_discount": item.sales_discount ? parseFloat(item.sales_discount) : 0,
                            "sales_discount_amount": item.sales_discount_amount ? parseFloat(item.sales_discount_amount) : 0,
                            "reorder_threshold": parseInt(item.reorder_threshold),
                            "expiry_date": item.expiry_date,
                            "physical_box_no": item.physical_box_no ? item.physical_box_no : "",
                            "instructions": item.description,
                            "hsn_code": item.hsn_code,
                            "recommended_by": item.recommended_by,
                            "manufacturer_name": item.manufacturer_name,
                            "bar_code": item.bar_code,
                            "epr_value": parseFloat(item.epr_value)
                        }
                        if (this.state.purchase_status == "Partially Delivered") {
                            tempDrug["id"] = item.stock_id
                        }
                        // push data
                        datas['product_line_items'].push(tempDrug)
                    }
                }
            })

            if (this.state.purchase_order_id) {
                this.setState({ disableBtn: true })
                RestAPIService.create(datas, Serviceurls.LAB_ADD_TO_STOCK)
                    .then(response => {
                        if (response.data.status == "success") {
                            this.SuccessErrorBox(response.data.message, true);
                            this.setState({ saveLoad: false })
                            this.setState({
                                data: {
                                    vendor_name: "",
                                    vendor_id: "",
                                    delivery_date: null,
                                    billNo: "",
                                    billDate: null,
                                    gstAmt: "",
                                    billAmt: "",
                                    disableBtn: false,
                                },
                                tableData: [],
                                vendor_list: [],
                                selectedEntry: {},
                                serial_no: 0,
                                previous_vendor_bills: [],
                                latest_vendor_bill: {},
                            }, () => { this.props.goBack("purchase_order") })
                        }
                    })
                    .catch(error => {
                        if (error.response.data.status == "fail") {
                            this.SuccessErrorBox(error.response.data.message, false);
                            this.setState({ saveLoad: false })
                        }
                    })
            } else {
                RestAPIService.create(datas, Serviceurls.LAB_DIRECT_PO)
                    .then(response => {
                        if (response.data.status == "success") {
                            this.SuccessErrorBox(response.data.message, true);
                            this.setState({ saveLoad: false })
                            this.setState({
                                data: {
                                    vendor_name: "",
                                    vendor_id: "",
                                    delivery_date: null,
                                    billNo: "",
                                    billDate: null,
                                    gstAmt: "",
                                    billAmt: "",
                                    disableBtn: false,
                                },
                                tableData: [],
                                vendor_list: [],
                                selectedEntry: {},
                                serial_no: 0,
                                previous_vendor_bills: [],
                                latest_vendor_bill: {},
                            }, () => { this.props.goBack("purchase_order") })

                        }
                    })
                    .catch(error => {
                        this.setState({ saveLoad: false })
                        if (error.response.data.status == "fail") {
                            this.SuccessErrorBox(error.response.data.message, false);
                        }
                        this.setState({ disableBtn: false })
                    })
            }

        } catch (error) {
            this.setState({ saveLoad: false })
            this.SuccessErrorBox(error.message, false)
        }
    }

    // get from purchase order
    _getDataFromPurchaseorder = () => {
        let { purchase_order_id } = this.state;
        this.setState({
            data: {
                vendor_name: "",
                vendor_id: "",
                delivery_date: new Date(),
                billNo: "",
                billDate: new Date(),
                gstAmt: "",
                billAmt: ""
            },
            tableData: [],
            previous_vendor_bills: [],
            latest_vendor_bill: {}
        })
        if (purchase_order_id) {
            let { data } = this.state
            RestAPIService.getAll(`${Serviceurls.LAB_DATA_FROM_PURCHASE}?purchase_order_id=${purchase_order_id}`)
                .then(response => {
                    if (response.data.status == "success") {
                        let datas = response.data.data;
                        let gst_amount = datas["latest_vendor_bill"] && datas["latest_vendor_bill"]["cgst_amount"] ? (datas["latest_vendor_bill"]["cgst_amount"] * 2) : 0
                        data["vendor_name"] = datas["vendor_name"];
                        data["vendor_id"] = datas["vendor_id"];
                        data["purchase_order_id"] = datas["purchase_order_id"];
                        data["purchase_order_number"] = datas["purchase_order_number"];
                        data["purchase_order_date"] = datas["purchase_order_date"];
                        data["delivery_date"] = datas["latest_vendor_bill"] ? datas["latest_vendor_bill"]["delivery_date"] : new Date();
                        data["billDate"] = datas["latest_vendor_bill"] ? datas["latest_vendor_bill"]["bill_date"] : new Date();
                        data["billNo"] = datas["latest_vendor_bill"] ? datas["latest_vendor_bill"]["bill_no"] : "";
                        data["billAmt"] = datas["latest_vendor_bill"] ? datas["latest_vendor_bill"]["total_amount"] : "";
                        data["gstAmt"] = gst_amount ? gst_amount : ""
                        let partialArr = (datas.po_line_items).length > 0 && (datas.stock_items).length > 0 ? [...datas.po_line_items, ...datas.stock_items] : []
                        this.setState({
                            data,
                            tableData: (datas.po_line_items).length > 0 ? datas.po_line_items : (datas.stock_items).length > 0 ? datas.stock_items : partialArr.length > 0 ? partialArr : [],
                            previous_vendor_bills: datas.previous_vendor_bills ? datas.previous_vendor_bills : [],
                            latest_vendor_bill: datas.latest_vendor_bill ? datas.latest_vendor_bill : {},
                        })
                    }
                })
                .catch(error => {
                    if (error.response.data.status == "fail") {
                        this.SuccessErrorBox(error.response.data.message, false);
                    }
                });
        }
    }

    updateState = (event) => {
        const { t } = this.props;
        let { data, latest_vendor_bill } = this.state;
        let name = event.target.name;
        let value = event.target.value;
        data[name] = value
        if (name === "billNo") {
            if (latest_vendor_bill && latest_vendor_bill["bill_no"] == parseInt(value)) {

                data['billNo'] = latest_vendor_bill["bill_no"];
            }
        }
        this.setState({
            data
        })
    }
    _addItem = (itemIn, isSelected) => {

        let { tableData, data, serial_no } = this.state
        let drug_repeat = false;
        if (itemIn) {
            if (!(itemIn.serial_no)) {
                serial_no = serial_no + 1;
                itemIn["serial_no"] = serial_no;
                if (serial_no != 1) {
                    tableData.forEach((item, i) => {
                        if (
                            (item["brand_name"]).toLowerCase() === (itemIn.brand_name).toLowerCase() &&
                            (item["product_name"]).toLowerCase() === (itemIn.product_name).toLowerCase() &&
                            (item["product_type"]).toLowerCase() === (itemIn.product_type).toLowerCase() &&
                            (item["material_type"]).toLowerCase() === (itemIn.material_type).toLowerCase() &&
                            (item["unit"]).toLowerCase() === (itemIn.unit).toLowerCase() &&
                            (item["batch_no"]).toLowerCase() === (itemIn.batch_no).toLowerCase()
                        ) {
                            drug_repeat = true
                        }
                    })
                } else {
                    tableData.forEach((item, i) => {
                        if (
                            (item["brand_name"]).toLowerCase() === (itemIn.brand_name).toLowerCase() &&
                            (item["product_name"]).toLowerCase() === (itemIn.product_name).toLowerCase() &&
                            (item["product_type"]).toLowerCase() === (itemIn.product_type).toLowerCase() &&
                            (item["material_type"]).toLowerCase() === (itemIn.material_type).toLowerCase() &&
                            (item["unit"]).toLowerCase() === (itemIn.unit).toLowerCase() &&
                            (item["batch_no"]).toLowerCase() === (itemIn.batch_no).toLowerCase() &&
                            item["serial_no"] != itemIn.serial_no
                        ) {
                            drug_repeat = true
                        }
                    })
                }
                // update item detail to specific index
                if (itemIn['id'] != undefined && !drug_repeat) {
                    let temp_id = itemIn['id']
                    tableData[temp_id] = itemIn
                } else {
                    // no specific index, add it as new one
                    if (!drug_repeat) {
                        tableData.push(itemIn)
                    } else if (drug_repeat) {
                        this.SuccessErrorBox("Drug already in list", false)
                    }
                }
            } else {
                let index = tableData.findIndex(element => {
                    if (element.serial_no === itemIn.serial_no) {
                        return true;
                    }
                })
                tableData[index] = itemIn
            }

            this.setState({
                tableData,
                selectedEntry: {},
                serial_no,
                isselectedLineItem: isSelected
            }, () => {
                // GST Amount Calculation
                const totalGSTAmount = tableData && tableData.length > 0 && tableData.map(item => item.total_gst_amount ? parseFloat(item.total_gst_amount) : 0).reduce((prev, curr) => (prev + curr));

                // Total Bill Amount Calculation
                const totalBuying = tableData && tableData.length > 0 && tableData.map(item => parseFloat(item.total_buying_price)).reduce((prev, curr) => (prev + curr));
                data["billAmt"] = totalBuying ? Math.round(totalBuying) : 0
                data["gstAmt"] = totalGSTAmount ? totalGSTAmount : 0
                this.setState({
                    data
                })
            })
        }
    }

    onKeyDown = (e) => {
        e.preventDefault();
    };

    handleOpen = (statekey) => {
        let states = this.state;
        if (statekey === "delivery_date") {
            states["open"] = true
        } else if (statekey === "billDate") {
            states["open1"] = true
        }
        this.setState({
            states
        })
    }

    renderTextLeft(label, statekey, value = '', helperText = '') {
        const { t } = this.props
        let { data, vendor_list, open, open1, purchase_status } = this.state
        let states = this.state
        return (
            <div>
                {
                statekey === "vendor_name" ?
                <Autocomplete
                    disableClearable
                    readOnly = {purchase_status == "Completely Delivered" || purchase_status == "Partially Delivered" ? true : false}
                    sx={{ width:  "30.85vw" }}
                    value = {value ? value : ""}
                    options={vendor_list.length != 0 ? vendor_list : []}
                    getOptionLabel={option => typeof(option) === "string" ? option : option.name}
                    onChange={(event, newVendor) => {
                        data["vendor_name"] = (newVendor.name);
                        data["vendor_id"] = (newVendor.vendor_id);
                        this.setState({
                            data
                        }
                        )
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label={t(label) + "*"}
                            size="small"
                            InputProps={{
                                ...params.InputProps,
                                type: 'search',
                                emed_tid : "editStockList" 
                            }}
                        />
                    )}
                />
                :
                statekey === "delivery_date" || statekey === "billDate" ?
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                        <DatePicker
                            open={purchase_status == "Completely Delivered" ? false : statekey === "delivery_date" ? open : statekey === "billDate" ? open1 : false}
                            onOpen={(e) => this.handleOpen(statekey)}
                            onClose={() => {
                                if(statekey === "delivery_date"){
                                    states["open"] = false
                                }else if (statekey === "billDate"){
                                    states["open1"] = false
                                }
                                this.setState({
                                            states
                                })
                            }}
                            inputFormat= 'dd-MM-yyyy' 
                            label={t(label)+ "*"}
                            name={statekey}
                            value={value ? value : null}
                            autoComplete = "off"
                            maxDate = { DateTime.now()}
                            onChange={(value) => {
                                data[statekey] = DateTime.fromJSDate(value).toFormat("yyyy-MM-dd");
                                this.setState({
                                    data
                                }
                                )
                            }}
                            renderInput={(params) => <TextField size="small"    inputProps={{emed_tid: "onKeyDown_Test_Id"}} onKeyDown={(e)=>this.onKeyDown(e)} sx={{ width: "9.8vw" }} {...params} onClick = {(e)=>this.handleOpen(statekey)}/>}
                        />
                    </LocalizationProvider>
                    :
                    <TextField
                        label={t(label)}
                        required = {statekey === "billNo" ? true : false}
                        sx={{ width: "9.5vw" }}
                        size="small"
                        autoComplete="off"
                        name={statekey}
                        value={value ? value : ""}
                        InputProps={{
                            readOnly: statekey === "billAmt" || statekey === "purchase_order_number"|| purchase_status == "Completely Delivered" || statekey === 'gstAmt' ? true: false,
                        }}
                        inputProps={{emed_tid: "billNo_Test_Id"}}
                        error={helperText.length > 0 ? true : false}
                        helperText={helperText}
                        onChange={this.updateState}
                    />
                }
            </div>
        )
    }
    selectedLineItem = (data) => {
        let { selectedEntry } = this.state
        data['isEdit'] = true
        selectedEntry = JSON.parse(JSON.stringify(data))
        this.setState({
            selectedEntry,
        })
    }

    handleDelete = (data) => {
        let deletedArr = [...this.state.tableData]
        let index = deletedArr.indexOf(data)
        deletedArr.splice(index, 1)
        this.setState({
            tableData: deletedArr
        })
    }
    render() {
        let { data, tableData, purchase_status, latest_vendor_bill } = this.state
        const { t } = this.props
        let gst_amount = latest_vendor_bill.cgst_amount ? (latest_vendor_bill.cgst_amount * 2) : 0
        return (
            <Box id="emedhub_directpo_mainDiv">
                <div id="emedhub_directpo_leftDiv">
                    <Paper id="emedhub_directPo_paper1" elevation={1} >
                        <Stack direction="row"  alignItems="center">
                        <IconButton emed_tid = "purchase_order_Test_Id" size="small" onClick={() => { this.props.goBack("purchase_order") }} sx={{marginRight:"0.5vw"}}><img className="emedhub_popupIcon_img" src={ImagePaths.LabBackButton.default} alt={"Back"}  /></IconButton>
                            <Stack direction = "row" spacing = {2} sx={{ marginRight: "3.5vw" }}>
                                {this.renderTextLeft("VendorName", "vendor_name", data.vendor_name, "")}
                                {data.purchase_order_number ? this.renderTextLeft("Purchase Order No", "purchase_order_number", data.purchase_order_number, "") : null}
                            </Stack>
                            {
                                purchase_status == "Completely Delivered" ? null :
                                    <Button size="small" variant="contained" emed_tid='Direct_PO_Save_Btn' disabled={this.state.saveLoad} sx={{ marginRight: "1vw", width: "6vw", maxHeight: "2vw", textTransform: "capitalize" }} onClick={() => { this.setState({ saveLoad: true }, () => this.addToStock()) }}>{t("Save")}</Button>
                            }
                                <Tooltip title={"Print"} placement="top" arrow><IconButton size = "small"  emed_tid = "purchase_Print__Test_Id"><img className='eMed_pur_print' src={ImagePaths.PrintIcons.default} alt={"Print"} /></IconButton></Tooltip>
                        </Stack>
                        <div className="emedhub_directpo_subDiv">
                            {this.renderTextLeft("DeliveryDate", "delivery_date", data.delivery_date ? data.delivery_date : latest_vendor_bill.delivery_date, "")}
                            {this.renderTextLeft("BillNo", "billNo", data.billNo ? data.billNo : latest_vendor_bill.bill_no, "")}
                            {this.renderTextLeft("BillDate", "billDate", data.billDate ? data.billDate : latest_vendor_bill.bill_date, "")}
                            {this.renderTextLeft("GSTAmount", "gstAmt", data.gstAmt ? data.gstAmt : gst_amount, "")}
                            {this.renderTextLeft("BillAmount", "billAmt", data.billAmt ? data.billAmt : latest_vendor_bill.total_amount, "")}
                        </div>
                    </Paper>
                    <Paper id="emedhub_directpo_table" elevation={1}>
                        <TableContainer className='emedhub_directpoTable_Paper' sx={{ height: purchase_status == "Completely Delivered" ? "48vh" : null }}>
                            <Table stickyHeader size='small'>
                                <TableHead className="emedhub_directpoTable_head" sx={{ height: "5vh" }}>
                                    <TableRow>
                                        <TableCell sx={{ flex: 0.08 }} id="emedhub_directpoTable_headTxt">{t("SNo")}</TableCell>
                                        <TableCell sx={{ flex: 0.2 }} id="emedhub_directpoTable_headTxt">{t("ProductDetails")}</TableCell>
                                        <TableCell sx={{ flex: 0.1 }} id="emedhub_directpoTable_headTxt">{t(`MRP (${CurrencySymbol})`)}</TableCell>
                                        <TableCell sx={{ flex: 0.2 }} id="emedhub_directpoTable_headTxt">{t("Quantity")}</TableCell>
                                        <TableCell sx={{ flex: 0.3 }} id="emedhub_directpoTable_headTxt"> {t("PurchaseValue")}</TableCell>
                                        <TableCell sx={{ flex: 0.08 }} id="emedhub_directpoTable_headTxt">{t("NetAmount") + `(${CurrencySymbol})`}</TableCell>
                                        <TableCell sx={{ flex: 0.04 }} id="emedhub_directpoTable_headTxt">{t("")}</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody className="emedhub_directpoTable_head">
                                    {tableData.length > 0 && tableData.map((data, index) => {
                                        let sno = (index + 1)
                                        let gst_percentage = data.cgst_percentage ? (data.cgst_percentage * 2) : 0;
                                        let gst_amount = data.cgst_amount ? (data.cgst_amount * 2) : 0;
                                        let buying_price = data.total_buying_price && data.total_gst_amount ? (data.total_buying_price - data.total_gst_amount) : (data.buying_price - gst_amount);
                                        let today = data.expiry_date ? new Date(data.expiry_date) : "";
                                        let expiry_date = today ? DateTime.fromJSDate(today).toFormat("MM-yyyy") : null;
                                        return (
                                            <TableRow key={index} sx={{ backgroundColor: this.state.isselectedLineItem && this.state.selectedIndex == index ? '#e2e2e2' : null }}>
                                                <TableCell sx={{ flex: 0.08 }}>{sno ? sno : data.serial_no}</TableCell>
                                                <TableCell sx={{ flex: 0.2 }}>
                                                    <Stack direction="column">
                                                        <Typography id="emedhub_directpoTable_cell1">{data.product_name}</Typography>
                                                        <Typography className="emedhub_directpoTable_cell" sx={{ fontSize: "0.75vw" }}>Brand Name: {data.brand_name}</Typography>
                                                        <Typography className="emedhub_directpoTable_cell" sx={{ fontSize: "0.75vw" }}>Product Type: {data.product_type}</Typography>
                                                        {data.hsn_code ?
                                                            <Typography className="emedhub_directpoTable_cell" sx={{ fontSize: "0.75vw" }}>HSN Code:{data.hsn_code}</Typography>
                                                            : null}
                                                    </Stack>
                                                </TableCell>
                                                <TableCell sx={{ color: "#66686A", flex: 0.1 }}>{data.mrp_per_quantity}</TableCell>
                                                <TableCell sx={{ color: "#66686A", flex: 0.2 }}>
                                                    <Stack direction="column">
                                                        <Typography sx={{ textAlign: data.quantity && !(data.stock_in_quantity) ? "center" : null }}>{(data.stock_in_quantity && (data.quantity || data.purchase_quantity) && data.free_quantity) ? (`${data.stock_in_quantity ? data.stock_in_quantity : ""} / ${data.quantity ? data.quantity : data.purchase_quantity ? data.purchase_quantity : 0} + ${data.free_quantity}(Free) `) : (data.stock_in_quantity && data.free_quantity) ? (`${data.stock_in_quantity} + ${data.free_quantity}(Free) `) : (data.quantity || data.purchase_quantity) ? (`${data.stock_in_quantity ? data.stock_in_quantity : ""} / ${data.quantity ? data.quantity : data.purchase_quantity ? data.purchase_quantity : 0}`) : data.stock_in_quantity}</Typography>
                                                        {data.batch_no ? <Typography className="emedhub_directpoTable_cell" sx={{ fontSize: "0.75vw" }}>Batch No: {data.batch_no}</Typography> : null}
                                                        {expiry_date ? <Typography className="emedhub_directpoTable_cell" sx={{ fontSize: "0.75vw" }}>Exp Date: {expiry_date}</Typography> : null}
                                                    </Stack>
                                                </TableCell>
                                                <TableCell sx={{ color: "#66686A", flex: 0.3 }}>{(data.gst_percentage || gst_percentage) != 0 ? (`${buying_price ? Math.abs(buying_price) : 0} + ${data.gst_percentage ? data.gst_percentage : gst_percentage}% GST`) : (data.total_buying_price || data.buying_price) ? (`${data.total_buying_price ? Math.abs(data.total_buying_price) : data.buying_price ? Math.abs(data.buying_price) : ""} + 0% GST`) : ""}</TableCell>
                                                <TableCell sx={{ color: "#66686A", flex: 0.08 }}>{(data.total_buying_price || data.buying_price) ? (data.total_buying_price ? Math.abs(data.total_buying_price) : data.buying_price ? Math.abs(data.buying_price) : "") : ""}</TableCell>
                                                <TableCell sx={{ flex: 0.04 }}>
                                                    {(data["status"] == "Delivered" || purchase_status == "Completely Delivered") ? null :
                                                        <Stack direction="row" alignItems="center" justifyContent="center" spacing={1}>
                                                            <Tooltip placement="top" title={t("Edit")} arrow><IconButton size="small" onClick={() => this.setState({ isselectedLineItem: true, selectedIndex: index }, () => { this.selectedLineItem(data) })} ><img className="emedhub_popupIcon_img1" src={ImagePaths.LabEditButton.default} alt={"edit"} /></IconButton></Tooltip>
                                                            {this.state.purchase_order_id ? null : <Tooltip placement="top" title={t("Delete")} arrow><IconButton size="small" onClick={() => { this.handleDelete(data) }} ><img className="emedhub_popupIcon_img1" src={ImagePaths.LabDeleteButton.default} alt={"Delete"} /></IconButton></Tooltip>}
                                                        </Stack>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        )
                                    })
                                    }
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {purchase_status == "Completely Delivered" || purchase_status == "Partially Delivered" ?
                            <Stack direction="row" justifyContent="flex-end" sx={{ zIndex: 2, margin: "0.5vw" }}>
                                <p style={{ color: purchase_status == "Completely Delivered" ? "#1976d2" : "#B82121" }}>{purchase_status}</p>
                            </Stack>
                            : null
                        }
                    </Paper>
                </div>

                <DirectPoRight SuccessErrorBox={this.SuccessErrorBox.bind(this)} sendItem={this._addItem.bind(this)} selectedEntry={this.state.selectedEntry} purchase_status={this.state.purchase_status} screenType={this.state.screenType} />

            </Box>
        )
    }
}

export default withTranslation()(DirectPo);