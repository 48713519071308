import React, { Component } from 'react';
import {
    Tabs, Tab, Divider, IconButton, Tooltip, Box, Typography, FormControlLabel, Checkbox, Button, InputAdornment
} from "@mui/material";
import './CommonTabs.css';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { clearCachevalue, getCachevalue, localGetItem, setCachevalue } from '../../Utility/Services/CacheProviderService';
import { AmountFormat, CheckAccessFunc } from '../CommonFunctions/CommonFunctions';
import CommonDatePicker from '../Common Components/CommonDatePicker';
import ABHAPopUP from '../../Modules/ABHA/ABHAPopUP';

export default class CommonTabs extends Component {
    constructor(props) {
        super(props);
        this.state = {
            MainTabIndex: props.selectedTab ? props.selectedTab?.id : props.List[0]?.id,
            SubTabIndex: props.selectedTab ? (props.selectedTab?.subMenu.length > 0 ? props.selectedTab?.subMenu[0]?.id : 0) : 0,
            List: props.List ? props.List : [],
            SelectedItem: props.selectedTab ? props.selectedTab : props?.List[0],
            SelectedSubItem: props.selectedTab ? (props.selectedTab?.subMenu.length > 0 ? props.selectedTab?.subMenu[0] : null) : null,
            BackScreen: props.BackToHome ? props.BackToHome : null,
            isSubRendered: false,
            forcancel: false,
            isABHAClicked: false,
            openABHAPopUP: false,
        }
    }
    componentDidMount() {
        var props = this.props
        this.setState({
            List: props.List,
            SelectedItem: props.selectedTab ? props.selectedTab : props.List[0],
            MainTabIndex: props.selectedTab ? props.selectedTab?.id : props.List[0]?.id,
            BackScreen: props.BackToHome ? props.BackToHome : null,
            SubTabIndex: props.selectedTab ? (props.selectedTab?.subMenu.length !== 0 ? props.selectedTab?.subMenu[0]?.id : 0) : 0,
            SelectedSubItem: props.selectedTab ? (props.selectedTab?.subMenu.length > 0 ? props.selectedTab?.subMenu[0] : null) : null,
            forcancel: this.props.history?.location?.state?.isCancelBillFetched ? true : false
        })
    }
    componentDidUpdate(prevProps) {
        if (prevProps !== this.props && this.props) {
            if ((prevProps.selectedTab?.id != this.props.selectedTab?.id) || this.props?.history?.location?.state?.Reset) {
                this.setState({
                    forcancel: (this.props.history?.location?.state?.isCancelBillFetched) ? true : false,
                    List: this.props.List,
                    SelectedItem: this.props.selectedTab ? this.props.selectedTab : this.props.List[0],
                    MainTabIndex: this.props.selectedTab ? this.props.selectedTab?.id : this.props.List[0]?.id,
                    BackScreen: this.props.BackToHome ? this.props.BackToHome : null,
                    SubTabIndex: this.props.selectedTab ? (this.props.selectedTab?.subMenu.length !== 0 ? this.props.selectedTab?.subMenu[0]?.id : 0) : 0,
                    SelectedSubItem: this.props.selectedTab ? (this.props.selectedTab?.subMenu.length > 0 ? this.props.selectedTab?.subMenu[0] : null) : null,
                })
            } else {
                this.setState({
                    forcancel: (this.props.history?.location?.state?.isCancelBillFetched) ? true : false,
                    List: this.props.List,
                    SelectedItem: this.props.selectedTab ? this.props.selectedTab : this.props.List[0],
                    MainTabIndex: this.props.selectedTab ? this.props.selectedTab?.id : this.props.List[0]?.id,
                    BackScreen: this.props.BackToHome ? this.props.BackToHome : null,
                    SubTabIndex: this.state.SubTabIndex ? this.state.SubTabIndex : (this.props.selectedTab?.subMenu.length !== 0 ? this.props.selectedTab?.subMenu[0]?.id : 0),
                    SelectedSubItem: this.state.SubTabIndex ? this.state.SelectedSubItem : (this.props.selectedTab?.subMenu.length > 0 ? this.props.selectedTab?.subMenu[0] : null),
                })
            }
        }
    }
    handleChange = (event, newValue) => {
        this.setState({
            MainTabIndex: newValue,
            SelectedItem: this.state.List.filter((item) => item?.id == newValue)[0],
            isABHAClicked: false,
            openABHAPopUP: false,
        }, () => {
            if (getCachevalue('CurrentDoctorPatientData')) {
                setCachevalue(getCachevalue('CurrentDoctorPatientData'), "DoctorPatientData")
                clearCachevalue("CurrentDoctorPatientData");
            }
            let { history } = this.props
            history.push({ pathname: `${this.state.SelectedItem.path}` })
        })

    };
    handleChangesub = (event, newValue) => {
        this.setState({
            SubTabIndex: newValue,
            SelectedSubItem: this.state.SelectedItem.subMenu.filter((item) => item.id == newValue)[0]
        })
    };

    checkAccess = (data) => {
        try {
            let patientDatas = getCachevalue("patientData")
            let patients = JSON.parse(patientDatas)
            var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
            var UserData = myUser
            if (data.menuName === "Nurse Notes" && UserData?.module_name === "front_office") {
                if (patients?.appointment_id) { return true } else { return false }
            } else if (data.menuName === "Service Billing" || data.menuName === "Room Change" || data.menuName === "Receive Payment") {
                if (UserData?.module_name !== "nurse") { return true } else { return false }
            }
            // else if (data.menuName === "Billing") {
            //     if ((UserData?.module_name !== "nurse" && patients?.appointment_id) || (UserData?.module_name === "ot_management") || (UserData?.module_name === 'Doctor')) { return true } else { return false }
            // }
            else if (data.menuName === 'No Show Report') {
                if (localGetItem("noShowStatus") === "true") { return true } else { return false }
            }
            else {
                return true
            }
        }
        catch (e) {
            console.log(e?.message)
            return true
        }
    }

    SubTabcheckAccess = (data) => {
        try {
            var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
            var UserData = myUser
            if (data.menuName === "Doctor Notes" || data.menuName === "IV / IM Track" || data.menuName === "Pain Chart" || data.menuName === "Medication" || data.menuName === "Laboratory") {
                if (UserData?.module_name !== "nurse") { return true } else { return false }
            }
            else {
                return true
            }
        }
        catch (e) {
            return true
        }
    }

    FilterDate = (from, to, index) => {
        this.props.receiveDateChange(from, to, index)
    }

    CloseAbhaPopup = () => {
        this.setState({
            openABHAPopUP: false,
        })
    }

    renderMainComponent = (data, Type) => {
        if (data.Access?.viewAccess === true) {
            return data?.component
        }
        else if (data.Access?.viewAccess === false) {
            return <Typography className='eMed_NoAccess_Text' sx={{ paddingTop: Type === "Tab" ? "15.65vw" : "13vw" }}>No Access For This Screen</Typography>
        } else {
            return data?.component
        }
    }

    returnBackButtonName = () => {
        if (getCachevalue("OPCancelBilldata") || getCachevalue("IPCancelBilldata") || getCachevalue('RadCancelBillData')) {
            let CancelBillData = getCachevalue("OPCancelBilldata") ? JSON.parse(getCachevalue("OPCancelBilldata")) : JSON.parse(getCachevalue("IPCancelBilldata")) ? JSON.parse(getCachevalue("IPCancelBilldata")) : JSON.parse(getCachevalue("RadCancelBillData"))
            if (CancelBillData?.FromBills) {
                return "Back to bills"
            } else {
                return "Back to Overall bills"
            }
        } else if (getCachevalue("LabCancelBilldata")) {
            let CancelBillData = JSON.parse(getCachevalue("LabCancelBilldata"))
            if (CancelBillData?.FromBills) {
                return "Back to bills"
            } else if (CancelBillData?.FromStatus) {
                return "Back to Home"
            } else {
                return "Back to Overall bills"
            }
        }
    }

    CancellBackButton = () => {
        let { history } = this.props
        return (
            <Button
                startIcon={<ChevronLeftIcon />}
                size='small'
                variant='contained'
                sx={{ textTransform: 'capitalize', }}
                onClick={() => {
                    if (getCachevalue("OPCancelBilldata")) {
                        let CancelBillData = JSON.parse(getCachevalue("OPCancelBilldata"))
                        if (CancelBillData?.FromBills) {
                            clearCachevalue("OPCancelBilldata");
                            clearCachevalue("isForCancelBill");
                            this.props.changeScreen()
                            this.setState({
                                MainTabIndex: 1.2,
                                SelectedItem: this.state.List.filter((item) => item.id == 1.2)[0],
                            }, () => {
                                setTimeout(() => {
                                    this.setState({
                                        SubTabIndex: 1.23,
                                        SelectedSubItem: this.state.SelectedItem.subMenu.filter((item) => item.id == 1.23)[0]
                                    })
                                }, 500)
                            })
                        } else {
                            clearCachevalue("OPCancelBilldata");
                            clearCachevalue("isForCancelBill");
                            history.push({ pathname: '/BillingTransaction/OverAllBills' })
                        }
                    } else if (getCachevalue("IPCancelBilldata")) {
                        let CancelBillData = JSON.parse(getCachevalue("IPCancelBilldata"))
                        if (CancelBillData?.FromBills) {
                            clearCachevalue("IPCancelBilldata");
                            clearCachevalue("isForCancelBill");
                            this.props.changeScreen()
                            this.setState({
                                MainTabIndex: 6.2,
                                SelectedItem: this.state.List.filter((item) => item.id == 6.2)[0],
                            }, () => {
                                setTimeout(() => {
                                    this.setState({
                                        SubTabIndex: 6.23,
                                        SelectedSubItem: this.state.SelectedItem.subMenu.filter((item) => item.id == 6.23)[0]
                                    })
                                }, 500)
                            })
                        } else {
                            clearCachevalue("IPCancelBilldata");
                            clearCachevalue("isForCancelBill");
                            history.push({ pathname: '/BillingTransaction/OverAllBills' })
                        }
                    } else if (getCachevalue("LabCancelBilldata")) {
                        let CancelBillData = JSON.parse(getCachevalue("LabCancelBilldata"))
                        if (CancelBillData?.FromBills) {
                            clearCachevalue("LabCancelBilldata");
                            clearCachevalue("isForCancelBill");
                            if (getCachevalue("patientData")) {
                                this.props.changeScreen()
                                history.push({ pathname: '/OPHome/PatientDetails/Billing' })
                                this.setState({
                                    MainTabIndex: 1.2,
                                    SelectedItem: this.state.List.filter((item) => item.id == 1.2)[0],
                                }, () => {
                                    setTimeout(() => {
                                        this.setState({
                                            SubTabIndex: 1.23,
                                            SelectedSubItem: this.state.SelectedItem.subMenu.filter((item) => item.id == 1.23)[0]
                                        })
                                    }, 500)
                                })
                            } else {
                                this.props.changeScreen()
                                history.push({ pathname: '/IPHome/PatientDetails/ServiceBilling' })
                                this.setState({
                                    MainTabIndex: 6.2,
                                    SelectedItem: this.state.List.filter((item) => item.id == 6.2)[0],
                                }, () => {
                                    setTimeout(() => {
                                        this.setState({
                                            SubTabIndex: 6.23,
                                            SelectedSubItem: this.state.SelectedItem.subMenu.filter((item) => item.id == 6.23)[0]
                                        })
                                    }, 500)
                                })
                            }
                        } else if (CancelBillData?.FromStatus) {
                            clearCachevalue("LabCancelBilldata");
                            clearCachevalue("isForCancelBill");
                            this.props.changeScreen()
                            history.push({ pathname: '/MainHome' })
                        } else {
                            clearCachevalue("LabCancelBilldata");
                            clearCachevalue("isForCancelBill");
                            history.push({ pathname: '/BillingTransaction/OverAllBills' })
                        }
                    } else if (getCachevalue("RadCancelBillData")) {
                        let CancelBillData = JSON.parse(getCachevalue("RadCancelBillData"))
                        if (JSON.parse(localGetItem("loggedInUserInfo"))?.module_name === "front_office") {
                            if (CancelBillData?.FromBills) {
                                clearCachevalue("LabCancelBilldata");
                                clearCachevalue("isForCancelBill");
                                clearCachevalue("RadCancelBillData");
                                if (getCachevalue("patientData")) {
                                    this.props.changeScreen()
                                    history.push({ pathname: '/OPHome/PatientDetails/Billing' })
                                    this.setState({
                                        MainTabIndex: 1.2,
                                        SelectedItem: this.state.List.filter((item) => item.id == 1.2)[0],
                                    }, () => {
                                        setTimeout(() => {
                                            this.setState({
                                                SubTabIndex: 1.23,
                                                SelectedSubItem: this.state.SelectedItem.subMenu.filter((item) => item.id == 1.23)[0]
                                            })
                                        }, 500)
                                    })
                                } else {
                                    this.props.changeScreen()
                                    history.push({ pathname: '/IPHome/PatientDetails/ServiceBilling' })
                                    this.setState({
                                        MainTabIndex: 6.2,
                                        SelectedItem: this.state.List.filter((item) => item.id == 6.2)[0],
                                    }, () => {
                                        setTimeout(() => {
                                            this.setState({
                                                SubTabIndex: 6.23,
                                                SelectedSubItem: this.state.SelectedItem.subMenu.filter((item) => item.id == 6.23)[0]
                                            })
                                        }, 500)
                                    })
                                }
                            } else {
                                clearCachevalue("RadCancelBillData");
                                clearCachevalue("isForCancelBill");
                                clearCachevalue('RadPatientData')
                                history.push({ pathname: '/BillingTransaction/OverAllBills' })
                            }
                        } else {
                            let CancelBillData = JSON.parse(getCachevalue("RadCancelBillData"))
                            if (CancelBillData?.FromBills) {
                                clearCachevalue("RadCancelBillData");
                                clearCachevalue("isForCancelBill");
                                history.push({ state: { billSummaryId: '', fromModifyBill: false, fromCancelBill: false } })
                                this.props.changeScreen()
                                this.setState({
                                    MainTabIndex: 45.2,
                                    SelectedItem: this.state.List.filter((item) => item.id == 45.2)[0],
                                }, () => {
                                    setTimeout(() => {
                                        this.setState({
                                            SubTabIndex: 45.23,
                                            SelectedSubItem: this.state.SelectedItem.subMenu.filter((item) => item.id == 45.23)[0]
                                        })
                                    }, 500)
                                })
                            } else {
                                clearCachevalue("RadCancelBillData");
                                clearCachevalue("isForCancelBill");
                                clearCachevalue('RadPatientData')
                                history.push({ pathname: '/RadiologyBillingTransaction/OverallBills', state: { fromModifyBill: false, billSummaryId: '', fromCancelBill: false } })
                            }
                        }

                    }
                }}
            >{this.returnBackButtonName()}</Button>
        )
    }

    SendHealthID(Id, Address) {
        this.setState({
            openABHAPopUP: false,
            isABHAClicked: false,
        }, () => {
            this.props.changeAbhaData({ id: Id, Address: Address })
        })
    }

    render() {
        let { history } = this.props
        return (
            <div>
                <div className='eMed_tabWrapper'>
                    {
                        this.state.BackScreen === null ? null :
                            <div>
                                <Tooltip title="Back" placement='top' arrow>
                                    <IconButton
                                        disabled={(getCachevalue("isForCancelBill") === "true") || (getCachevalue("isForVisitreport") === "true") || (getCachevalue("IsForCheckedOut") === "true") || (getCachevalue("IsPORequestEdit") === "true") || (getCachevalue("isCanteenOverall") === "true")}
                                        emed_tid="back_button"
                                        onClick={() => {
                                            history.push({ pathname: `${this.state.BackScreen}` })
                                            if (this.state.BackScreen !== "/IPHome/Demographics/AdmissionHistory") {
                                                clearCachevalue("patientData");
                                                clearCachevalue("IppatientData");
                                                clearCachevalue("OP_CreateAppointment_PatientId");
                                                clearCachevalue("OTpatientData");
                                                clearCachevalue("AppRequestPatientData");
                                                clearCachevalue("DoctorPatientData")
                                                clearCachevalue("CurrentDoctorPatientData")
                                                clearCachevalue('updatePatient')
                                                clearCachevalue('RadPatientData')
                                                clearCachevalue('QRRequestPatientData')
                                            }
                                            clearCachevalue("withoutAppointment");
                                        }}>
                                        <ChevronLeftIcon color={(getCachevalue("isForCancelBill") === "true") || (getCachevalue("isForVisitreport") === "true") || (getCachevalue("IsForCheckedOut") === "true") || (getCachevalue("IsPORequestEdit") === "true") || (getCachevalue("isCanteenOverall") === "true") ? "grey" : 'primary'} />
                                    </IconButton>
                                </Tooltip>
                            </div>
                    }
                    <div >
                        {/* <Box> */}
                        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'} width={"90vw"}>
                            <Tabs
                                value={this.state.MainTabIndex}
                                onChange={this.handleChange}
                                variant="scrollable"
                                scrollButtons={false}
                                sx={{ minHeight: '1vw' }}
                            >
                                {this.state.List && this.state.List.length > 0 && this.state.List.map((data, index) => {
                                    if (this.checkAccess(data)) {
                                        return (
                                            <Tab
                                                sx={{ textTransform: 'capitalize', minHeight: '1vw', fontWeight: 600 }}
                                                key={data.id}
                                                value={data.id}
                                                label={data.menuName}
                                                emed_tid={data.testID ? data.testID : data.menuName}
                                                disabled={getCachevalue("isForCancelBill") === "true" || (getCachevalue("isForVisitreport") === "true") || (getCachevalue("IsForCheckedOut") === "true") || (getCachevalue("IsPORequestEdit") === "true") || (getCachevalue("isCanteenOverall") === "true") || (getCachevalue("isInRadiologyBilling") === "true")} />
                                        )
                                    }
                                }
                                )}
                            </Tabs>
                            {this.state?.List[0]?.menuName === "Quick Billing & Appointment" || this.state?.List[1]?.menuName === "Create Appointment" ?
                                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
                                    <Box width={"33vw"} display={Object.keys(this.props?.VisitDateBalAmount ? this.props?.VisitDateBalAmount : {}).length > 0 ? 'flex' : "none"}>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: "0.5vw" }}>
                                            <Typography fontSize={"0.9vw"}>Previous Credit Balance : </Typography>
                                            <Typography fontSize={"0.9vw"} fontWeight={600} marginLeft={"0.3vw"}>{AmountFormat(this.props?.VisitDateBalAmount?.total_outstanding)}</Typography>
                                        </Box>
                                        <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: "1.5vw" }}>
                                            <Typography fontSize={"0.9vw"}>Previous Advance Balance : </Typography>
                                            <Typography fontSize={"0.9vw"} fontWeight={600} marginLeft={"0.3vw"}>{AmountFormat(this.props?.VisitDateBalAmount?.advance_balance)}</Typography>
                                        </Box>
                                    </Box>
                                    <FormControlLabel checked={this.props.nurseState} control={<Checkbox />} onClick={(e) => {
                                        this.props.changeNurse(e.target.checked)
                                    }} label="Nurse" />
                                </Box> : null}
                            <Box marginRight={'1vw'} display={(this.state?.MainTabIndex === 1.6 || this.state?.MainTabIndex === 1.7) ? "inline" : "none"}>
                                <FormControlLabel checked={this.state.isABHAClicked} control={<Checkbox />} onClick={(e) => { this.setState({ isABHAClicked: e.target.checked }) }} label="ABDM" />
                                <Button size='small' disabled={!this.state.isABHAClicked} variant='contained' onClick={() => { this.setState({ openABHAPopUP: true }) }}>Create or Link ABHA</Button>
                            </Box>
                            {this.state.SelectedItem?.menuName === "In Patient List" ?
                                <Box sx={{ position: "relative", top: "0.4vw", width: "13vw" }}>
                                    <CommonDatePicker
                                        FromDate={this.props.dateDetails?.fromDate}
                                        ToDate={this.props.dateDetails?.toDate}
                                        DateIndex={this.props.dateDetails?.dateIndex}
                                        SelectedDates={this.FilterDate.bind(this)}
                                        HideAllMenu={true}
                                        HideDate={true}
                                        Future={true}
                                        AllowAllDate={true} />
                                </Box> : null}
                            {(getCachevalue("isForCancelBill") === "true" && (getCachevalue("LabCancelBilldata") || ((getCachevalue("RadCancelBillData")) && JSON.parse(localGetItem("loggedInUserInfo"))?.module_name === "front_office"))) ? this.CancellBackButton() : null}
                            {getCachevalue("isForVisitreport") === "true" ?
                                <Button
                                    startIcon={<ChevronLeftIcon />}
                                    size='small'
                                    variant='contained'
                                    emed_tid='Back to Visit Report'
                                    sx={{ textTransform: 'capitalize', }}
                                    onClick={() => {
                                        clearCachevalue("isForVisitreport");
                                        this.props.history.push({ pathname: "/DoctorHome/Reports/DoctorVisit" })
                                    }}
                                >Back to Visit Report</Button> : null}
                            {(getCachevalue("IsForCheckedOut") === "true") ? <Button
                                startIcon={<ChevronLeftIcon />}
                                size='small'
                                variant='contained'
                                emed_tid='Back to Checkout'
                                sx={{ textTransform: 'capitalize', }}
                                onClick={() => {
                                    clearCachevalue("IsForCheckedOut");
                                    clearCachevalue('IppatientData')
                                    this.props.history.push({ pathname: "/IPHome" })
                                }}
                            >Back to Checkout</Button> : null}
                            {(getCachevalue("IsPORequestEdit") === "true") ? <Button
                                startIcon={<ChevronLeftIcon />}
                                size='small'
                                variant='contained'
                                emed_tid='Back to PO Request'
                                sx={{ textTransform: 'capitalize', }}
                                onClick={() => {
                                    clearCachevalue("IsPORequestEdit");
                                    this.props.history.push({ pathname: "/PharmacyPurchase/PurchaseOrder/RequestList" })
                                }}
                            >Back to PO Request</Button> : null}
                            {(getCachevalue("isCanteenOverall") === "true") ? <Button
                                startIcon={<ChevronLeftIcon />}
                                size='small'
                                variant='contained'
                                sx={{ textTransform: 'capitalize', }}
                                onClick={() => {
                                    clearCachevalue("isCanteenOverall")
                                    this.props.history.push({ pathname: "/CanteenBillingTransaction" })
                                }}
                            >Back to Overall Bill</Button> : null}
                        </Box>
                        {/* </Box> */}
                    </div>
                </div>

                <div>
                    {
                        Object.keys(this.state.SelectedItem).length !== 0 ?
                            this.state.SelectedItem.subMenu?.length === 0 ?
                                <div className='eMed_MainComponentDiv'>
                                    {this.renderMainComponent(this.state.SelectedItem, "Tab")}
                                </div>
                                :
                                <div>
                                    {
                                        this.state.SubTabIndex != 0 ?
                                            <div>
                                                <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                                                    <Tabs
                                                        value={this.state.SubTabIndex}
                                                        onChange={this.handleChangesub}
                                                        variant="scrollable"
                                                        scrollButtons={false}
                                                        sx={{ minHeight: '1vw' }}
                                                    >
                                                        {
                                                            this.state.SelectedItem.subMenu.map((data, index) => {
                                                                // if(this.SubTabcheckAccess(data)){
                                                                let ipPatientData = getCachevalue("IppatientData")
                                                                let patientData = JSON.parse(ipPatientData)
                                                                return (
                                                                    <Tab sx={{ textTransform: 'capitalize', minHeight: '1vw', fontWeight: 600 }} disabled={(getCachevalue('RadCancelBill') && data?.id !== 45.21)}
                                                                        key={data.id} value={data.id} label={data.menuName} emed_tid={data.testID ? data.testID : data.menuName} />
                                                                )
                                                                // }

                                                            })
                                                        }
                                                    </Tabs>
                                                    {((this.state.SubTabIndex == 1.21) || (this.state.SubTabIndex == 6.21)) && !this.state.forcancel ?
                                                        <Box>
                                                            <Button sx={{ width: '10vw', textTransform: 'capitalize', height: '2vw' }} variant='contained' size='small' onClick={() => {
                                                                if (this.state?.List[1]?.menuName === "Billing") {
                                                                    setCachevalue(true, 'isInRadiologyBilling')
                                                                    let patientDatas = getCachevalue("patientData")
                                                                    let patients = JSON.parse(patientDatas)
                                                                    this.props.history.push({ pathname: "/Billing", state: { patientData: patients, FromPatientBilling :true } })
                                                                } else if (this.state?.List[1]?.menuName === "Service Billing") {
                                                                    let patientDatas = getCachevalue("IppatientData")
                                                                    let IPpatients = JSON.parse(patientDatas)
                                                                    setCachevalue(true, 'isInRadiologyBilling')
                                                                    this.props.history.push({ pathname: "/Billing", state: { IP_patientData: IPpatients, FromPatientBilling :true } })                                                                    
                                                                }
                                                            }}>{"laboratory"}</Button>
                                                            {localGetItem("Show_Radiology_Bills_In_FO") === "true" ? <Button sx={{ width: '10vw', textTransform: 'capitalize', height: '2vw', marginLeft: "1vw" }} variant='contained' size='small' onClick={() => {
                                                                if (this.state?.List[1]?.menuName === "Billing") {
                                                                    let patientDatas = getCachevalue("patientData")
                                                                    let patients = JSON.parse(patientDatas)
                                                                    setCachevalue(true, 'isInRadiologyBilling')
                                                                    this.props.history.push({ pathname: "/Billing/RadiologyBilling", state: { BillFromOP: true, patientData: patients } })
                                                                } else if (this.state?.List[1]?.menuName === "Service Billing") {
                                                                    let patientDatas = getCachevalue("IppatientData")
                                                                    let IPpatients = JSON.parse(patientDatas)
                                                                    setCachevalue(true, 'isInRadiologyBilling')
                                                                    this.props.history.push({ pathname: "/Billing/RadiologyBilling", state: { BillFromIP: true, patientData: IPpatients } })
                                                                }
                                                            }}>{"Radiology"}</Button> : null}
                                                        </Box>
                                                        : null}
                                                    {(getCachevalue("isForCancelBill") === "true") ? this.CancellBackButton() : null}
                                                </Box>
                                                <div className='eMed_SubComponentDiv'>
                                                    {this.renderMainComponent(this.state.SelectedSubItem, "SubTab")}
                                                </div>
                                            </div> : null
                                    }
                                </div>
                            : null
                    }
                </div>
                {
                    this.state.openABHAPopUP ?
                        <ABHAPopUP
                            CloseAbhaPopup={this.CloseAbhaPopup.bind(this)}
                            SendHealthID={this.SendHealthID.bind(this)}
                        />
                        : null
                }
            </div>
        )
    }
}
