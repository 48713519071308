import React, { Component } from 'react';
import { Box, Button, Tooltip, Stack, TextField, Typography, Autocomplete, Drawer, Select, InputLabel, FormControl, MenuItem } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid'
import { ImagePaths } from '../../../../Utility/ImagePaths';
import { withTranslation } from 'react-i18next';
import '../reports.css';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import { CommonGridToolBarWithFilterText, CommonGridHeaderOnlySearch, CommonTimeRangePicker } from "../../../../Components/Common Components/CommonComponents";
import { formatDate, timeOnlyConvert } from '../../../../Components/CommonFunctions/CommonFunctions';
import { DateTime } from 'luxon';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { localGetItem } from '../../../../Utility/Services/CacheProviderService';
import Loader from '../../../../Components/Loader';

const genderList = [
  { label: "Male", value: "m" },
  { label: "Female", value: "f" },
  { label: "TransGender", value: "t" }
]
class ReasonDiagnosisReport extends Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 0,
      pageSize: 10,
      rdpage: 0,
      rdpageSize: 10,
      reasonOrDiagnosisList: [],
      RoDlist: [],
      FilterOpen: false,
      fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      patientName: "",
      dateIndex: 1,
      doctorName: [],
      doctorNameList: [],
      isErrorMsg: false,
      isErrorMsgText: "",
      purposeOfVisit: "",
      POVList: [],
      fromAge: "",
      toAge: "",
      gender: [],
      FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
      FilterDatas: {
        "startTime":null,
        "endTime":null,
        "onError":false,
      },
      timeError: false,
      printLoading: false,
      disableBtn: false,
    };
  };
  componentDidMount() {
    this.getDoctorNameList()
    this.getReasonOrDiagnosisList()
    let POVList = localGetItem("ipComplaintsDataList")
    let array = []
    let List = POVList?.length > 0 ? POVList : "[]"
    JSON.parse(List).forEach(element => array.push(element));
    if (array.length > 0) {
      this.setState({
        POVList: array
      })
    }
  }

  LoaderFunction = (key) => {
    this.setState({
        isLoader: key
    })
}

  getReasonOrDiagnosisList = () => {
    try {
      this.LoaderFunction(true)
      let doctorId = [];
      this.state.doctorName.forEach(element => doctorId.push(element.doctor_id))
      let gender = [];
      this.state.gender.forEach(element => { gender.push(element.value) });
      var {FilterDatas} = this.state
      let start_time = FilterDatas.startTime ? new Date(FilterDatas.startTime) : ''
      let end_time = FilterDatas.endTime ? new Date(FilterDatas.endTime) : ''
      let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
      let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
      var url = ""
      if(this.state.fromAge != "" && this.state.toAge != "") {
        url = Serviceurls.FO_REPORTS_REASON_DIAGNOSIS + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&complaints=${this.state.purposeOfVisit}&doctor_id=${doctorId}&patient_name=${this.state.patientName}&gender=${gender}&from_age=${this.state.fromAge}&to_age=${this.state.toAge}&start_time=${setStartTime}&end_time=${setEndTime}`
      } else if(this.state.fromAge != "") {
        url = Serviceurls.FO_REPORTS_REASON_DIAGNOSIS + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&complaints=${this.state.purposeOfVisit}&doctor_id=${doctorId}&patient_name=${this.state.patientName}&gender=${gender}&from_age=${this.state.fromAge}&start_time=${setStartTime}&end_time=${setEndTime}`
      } else if(this.state.toAge != "") {
        url = Serviceurls.FO_REPORTS_REASON_DIAGNOSIS + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&complaints=${this.state.purposeOfVisit}&doctor_id=${doctorId}&patient_name=${this.state.patientName}&gender=${gender}&to_age=${this.state.toAge}&start_time=${setStartTime}&end_time=${setEndTime}`
      } else {
        url = Serviceurls.FO_REPORTS_REASON_DIAGNOSIS + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&complaints=${this.state.purposeOfVisit}&doctor_id=${doctorId}&patient_name=${this.state.patientName}&gender=${gender}&start_time=${setStartTime}&end_time=${setEndTime}`
      }
     
      RestAPIService.getAll(url)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              reasonOrDiagnosisList: response.data.data,
            }, () => {
              let Keys = Object.keys(response.data.complaint_values);
              let ArrayData = []
              if (Keys.length > 0) {
                Keys.map((item, index) => {
                  ArrayData.push({ "rod": item, "count": response.data.complaint_values[item], "sno": index })
                })
              }
              this.setState({
                RoDlist: ArrayData,
                FilteredFromDate: this.state.fromDate,
                FilteredToDate: this.state.toDate,
              },()=>{this.LoaderFunction(false)})
            })
          }
        }).catch((error) => {
          this.LoaderFunction(false)
          if (error?.response?.data?.status === 'fail') {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.LoaderFunction(false)
      this.errorMessage(error.message)
    }
  }
  getDoctorNameList = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_CONFIG_OPDOCTORNAME)
        .then((response) => {
          if (response.data.status === "success") {
            this.setState({
              doctorNameList: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.status === 'fail') {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }
  printReports = () => {
    try {
      let doctorId = [];
      this.state.doctorName.forEach(element => doctorId.push(element.doctor_id))
      let gender = [];
      this.state.gender.forEach(element => { gender.push(element.value) });
      var {FilterDatas} = this.state
      let start_time = FilterDatas.startTime ? new Date(FilterDatas.startTime) : ''
      let end_time = FilterDatas.endTime ? new Date(FilterDatas.endTime) : ''
      let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
      let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
      var url = ""
      if(this.state.fromAge != "" && this.state.toAge != "") {
        url = Serviceurls.FO_REPORTS_REASON_DIAGNOSIS + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&complaints=${this.state.purposeOfVisit}&doctor_id=${doctorId}&patient_name=${this.state.patientName}&gender=${gender}&from_age=${this.state.fromAge}&to_age=${this.state.toAge}&export_type=pdf&start_time=${setStartTime}&end_time=${setEndTime}`
      } else if(this.state.fromAge != "") {
        url = Serviceurls.FO_REPORTS_REASON_DIAGNOSIS + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&complaints=${this.state.purposeOfVisit}&doctor_id=${doctorId}&patient_name=${this.state.patientName}&gender=${gender}&from_age=${this.state.fromAge}&export_type=pdf&start_time=${setStartTime}&end_time=${setEndTime}`
      } else if(this.state.toAge != "") {
        url = Serviceurls.FO_REPORTS_REASON_DIAGNOSIS + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&complaints=${this.state.purposeOfVisit}&doctor_id=${doctorId}&patient_name=${this.state.patientName}&gender=${gender}&to_age=${this.state.toAge}&export_type=pdf&start_time=${setStartTime}&end_time=${setEndTime}`
      } else {
        url = Serviceurls.FO_REPORTS_REASON_DIAGNOSIS + `?from_date=${this.state.fromDate}&to_date=${this.state.toDate}&complaints=${this.state.purposeOfVisit}&doctor_id=${doctorId}&patient_name=${this.state.patientName}&gender=${gender}&export_type=pdf&start_time=${setStartTime}&end_time=${setEndTime}`
      }
      this.setState({disableBtn: true, printLoading: true})
      RestAPIService.getAll(url)
            .then((response) => {
                const file = new Blob(
                    [response.data],
                    { type: 'application/pdf' });
                const fileURL = URL.createObjectURL(file);
                window.open(fileURL);
                this.setState({disableBtn: false, printLoading: false})
            }).catch((error) => {
                this.errorMessage(error.message)
                this.setState({disableBtn: false, printLoading: false})
            })
    } catch (error) {
        this.errorMessage(error.message)
        this.setState({disableBtn: false, printLoading: false})
    }
}
  gridToolBar = () => {
    var { t } = this.props
    let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
    let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
    let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
    let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
    let TextArray = [
      { label: "From Date", value: formatDate(this.state.FilteredFromDate) },
      { label: "To Date", value: formatDate(this.state.FilteredToDate) },
    ]
    if (this.state.FilterDatas.startTime !== null && this.state.FilterDatas.endTime !== null) {
      TextArray.push(
          { label: "Start Time", value: timeOnlyConvert(setStartTime) },
          { label: "End Time", value: timeOnlyConvert(setEndTime) }
      )
  }
    return (
      <Box>
        <CommonGridToolBarWithFilterText FilterTextArray={TextArray} />
      </Box>

    )
  }
  renderTableView = () => {
    const { t } = this.props
    this.state.reasonOrDiagnosisList.forEach((element, index) => { element.sno = index + 1 })
    const columns = [
      {
        field: "admission_date", headerName: t("AdmissionDate"), flex: 0.08, align: "center", headerAlign: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.admission_date && params?.row?.admission_date?.length > 15 ?
          <Tooltip placement='top' title={params?.row?.admission_date} arrow><div>{params?.row?.admission_date.slice(0, 13) + "..."}</div></Tooltip> : params?.row?.admission_date ? params?.row?.admission_date : "-"}</Box>)
      },
      {
        field: "discharge_date", headerName: t("DischargeDate"), flex: 0.08, align: "center", headerAlign: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.discharge_date && params?.row?.discharge_date?.length > 15 ?
          <Tooltip placement='top' title={params?.row?.discharge_date} arrow><div>{params?.row?.discharge_date.slice(0, 13) + "..."}</div></Tooltip> : params?.row?.discharge_date ? params?.row?.discharge_date : "-"}</Box>)
      },
      {
        field: "name", headerName: t("PatientName"), flex: 0.1, align: "left", headerAlign: "left",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.name && params?.row?.name?.length > 19 ?
          <Tooltip placement='top' title={params?.row?.name} arrow><div>{params?.row?.name.slice(0, 18) + "..."}</div></Tooltip> : params?.row?.name ? params?.row?.name : "-"}</Box>)
      },
      {
        field: "patient_account_number", headerName: t("UHID"), flex: 0.08, align: "center", headerAlign: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.patient_account_number ? params?.row?.patient_account_number : "-"}</Box>)
      },
      {
        field: "patient_age", headerName: t("Age/Gender"), flex: 0.06, headerAlign: "center", align: "center",
        renderCell: (params) => {
          let details = `${params?.row?.patient_age ? params?.row?.patient_age : "-"} / ${params?.row?.patient_gender ? (params?.row?.patient_gender === "M" || params?.row?.patient_gender === "m" || params?.row?.patient_gender === "Male" ? "Male" : params?.row?.patient_gender === "F" || params?.row?.patient_gender === "f" || params?.row?.patient_gender === "Female" ? "Female" : 'TransGender') : "-"}`;
          return (<Box component={'div'}>{details?.length > 15 ? <Tooltip placement='top' title={details} arrow><div>{details.slice(0, 13) + "..."}</div></Tooltip> : details ? details : "-"}</Box>)
        }
      },
      {
        field: "complaints", headerName: t("ReasonOrDiagnosis"), flex: 0.1, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.complaints && params?.row?.complaints?.length > 20 ?
          <Tooltip placement='top' title={params?.row?.complaints} arrow><div>{params?.row?.complaints.slice(0, 20) + "..."}</div></Tooltip> : params?.row?.complaints ? params?.row?.complaints : "-"}</Box>)
      },
      {
        field: "doctor_name", headerName: t("DoctorName"), flex: 0.1, headerAlign: "left", align: "left",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.doctor_name && params?.row?.doctor_name?.length > 18 ?
          <Tooltip placement='top' title={params?.row?.doctor_name} arrow><div>{params?.row?.doctor_name.slice(0, 16) + "..."}</div></Tooltip> : params?.row?.doctor_name ? params?.row?.doctor_name : "-"}</Box>)
      }
    ]
    const PoVColumn = [
      {
        field: "rod", headerName: t("ReasonOrDiagnosis"), flex: 0.7, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.rod && params?.row?.rod?.length > 35 ?
          <Tooltip placement='top' title={params?.row?.rod} arrow><div>{params?.row?.rod.slice(0, 33) + "..."}</div></Tooltip> : params?.row?.rod ? params?.row?.rod : "-"}</Box>)
      },
      {
        field: "count", headerName: t("Count"), flex: 0.3, headerAlign: "center", align: "center",
        renderCell: (params) => (<Box component={'div'}>{params?.row?.count ? params?.row?.count : "-"}</Box>)
      }
    ]
    return (
      <Box sx={{ display: 'flex', flexDirection: 'row' }}>
        <Box component={'div'} className='eMed_rts_table' sx={{ flex: 0.7 }}>
          <DataGrid
            rows={this.state.reasonOrDiagnosisList}
            columns={columns}
            getRowId={(row) => row.sno}
            page={this.state.page}
            pageSize={this.state.pageSize}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: this.gridToolBar,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            onPageChange={(newPage) => { this.setState({ page: newPage }) }}
            onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            headerHeight={40}
            disableSelectionOnClick
            loading={this.state.isLoader}
          />
        </Box>
        <Box component={'div'} className='eMed_rts_table' sx={{ flex: 0.28, }}>
          <DataGrid
            rows={this.state.RoDlist}
            columns={PoVColumn}
            getRowId={(row) => row.sno}
            page={this.state.rdpage}
            pageSize={this.state.rdpageSize}
            localeText={{
              toolbarColumns: "",
              toolbarDensity: "",
              toolbarExport: "",
              toolbarFilters: "",
              toolbarExportPrint: ""
            }}
            components={{
              Toolbar: CommonGridHeaderOnlySearch.CommonToolBarOnlySearch,
              NoRowsOverlay: () => (
                <Stack className='eMed_conf_nodata'>
                  {t("NoRecordsFound")}
                </Stack>
              )
            }}
            onPageChange={(newPage) => { this.setState({ rdpage: newPage }) }}
            onPageSizeChange={(newPageSize) => this.setState({ rdpageSize: newPageSize })}
            rowsPerPageOptions={[10, 20, 30]}
            pagination
            headerHeight={40}
            disableSelectionOnClick
            loading={this.state.isLoader}
          />
        </Box>
      </Box>
    )
  }
  FilterDate = (from, to, index) => {
    this.setState({
      fromDate: from,
      toDate: to,
      dateIndex: index
    }, ()=> {
      this.state.FilterDatas.endTime = null
      this.setState({
          FilterDatas:this.state.FilterDatas
      })
  })
  }
  onChangeHandler = (e) => {
    const { name, value } = e.target
    this.setState({
      [name]: value
    })
  }

  selectedTime = (start, end,error) => {
    this.state.FilterDatas.startTime = start
    this.state.FilterDatas.endTime = end
    this.setState({ FilterDatas: this.state.FilterDatas, timeError: error })
}
  renderFilterScreen = () => {
    const { t } = this.props
    var { states } = this.state
    return (
      <Box component={"div"} className='eMed_rts_filter_container'>
        <Box component={"div"} className='eMed_filter_Top'>
          <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
          <Button  emed_tid = {"LabCloseButton_testID"} onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </Box>
        <Box className='eMed_rts_middle'>
          <Box className='eMed_date_filter'>
            <CommonDatePicker
              FromDate={this.state.fromDate}
              ToDate={this.state.toDate}
              DateIndex={this.state.dateIndex}
              emed_tid = {"FilterDate_testID"}
              SelectedDates={this.FilterDate.bind(this)}
              HideAllMenu={true} />
          </Box>
          <Box sx={{ width: '22vw' }}>
            <CommonTimeRangePicker
              startTime={this.state.FilterDatas?.startTime}
              endTime={this.state.FilterDatas?.endTime}
              selectedTime={this.selectedTime.bind(this)}
              FromDate={this.state.fromDate}
              emed_tid = {"TimeRangePicker_testID"}
              ToDate={this.state.toDate}
              onError={this.state.FilterDatas.onError}
            />
          </Box>
          <Box className='eMed_rts_dropdown'>
            <Autocomplete
              inputProps={{ maxLength: 250 }}
              options={this.state.POVList}
              getOptionLabel={(options) => options}
              onChange={(e, value) => {
                this.setState({
                  purposeOfVisit: value
                })
              }}
              clearIcon={false}
              size='small'
              value={this.state.purposeOfVisit}
              renderInput={(params) => <TextField onChange={(event) => {
                this.setState({
                  purposeOfVisit: event.target.value
                })
              }}
                {...params}
                inputProps={{ ...params.inputProps,'emed_tid': 'diagnosis_testid' }}
                placeholder="Reason / Diagnosis"
                emed_tid = {"Reason / Diagnosis_testID"}
              />}
            />
          </Box>
          <Box className='eMed_rts_dropdown'>
            <TextField
              inputProps={{ emed_tid: 'patient_name' }}
              size='small'
              fullWidth
              label="Patient Name"
              value={this.state.patientName}
              name="patientName"
              emed_tid = {"patientName_testID"}
              onChange={(e) => { this.onChangeHandler(e) }}
            />
          </Box>
          <Box className='eMed_rts_dropdown'>
            <Autocomplete
              multiple
              limitTags={1}
              size='small'
              options={genderList?.map((item) => item)}
              getOptionLabel={(option) => (option?.label)}
              value={this.state.gender}
              onChange={(event, newValue) => {
                // this.state.gender.push(newValue[0].value)
                this.setState({ gender: newValue})
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{ ...params.inputProps,'emed_tid': 'select_gender' }}
                  label={t("selectGender")}
                  emed_tid = {"selectGender_testID"}
                />
              )}
            />
          </Box>
          <Box className='eMed_age_container'>
            <TextField
              inputProps={{ emed_tid: 'from_age' }}
              size='small'
              fullWidth
              label="From Age"
              value={this.state.fromAge}
              name="fromAge"
              type={"number"}
              emed_tid = {"number_testID"}
              onChange={(e) => { this.onChangeHandler(e) }}
            />
            <TextField
              inputProps={{ emed_tid: 'to_age' }}
              size='small'
              sx={{ marginLeft: "0.5vw" }}
              fullWidth
              label="To Age"
              value={this.state.toAge}
              type={"number"}
              emed_tid = {"toAge_testID"}
              name="toAge"
              onChange={(e) => { this.onChangeHandler(e) }}
            />
          </Box>
          <Box className='eMed_rts_dropdown'>
            <Autocomplete
              multiple
              limitTags={1}
              size='small'
              options={this.state.doctorNameList?.map((item) => item)}
              getOptionLabel={(option) => (option?.doctor_name)}
              value={this.state.doctorName}
              onChange={(event, newValue) => {
                this.setState({ doctorName: newValue })
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  inputProps={{ ...params.inputProps,'emed_tid': 'select_docter' }}
                  label={t('selectDoctor')}
                  emed_tid = {"selectDoctor_testID"}
                />
              )}
              renderOption={(props, option) => (
                <li {...props} key={option?.doctor_id}>
                  {option?.doctor_name}
                </li>
              )}
            />
          </Box>
        </Box>
        <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
          <Button variant='outlined' size="small"     emed_tid = {"FilterDatas_testID"}
            onClick={() => {
              this.setState({
                patientName: "",
                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                dateIndex: 1,
                doctorName: [],
                purposeOfVisit: "",
                fromAge: "",
                toAge: "",
                gender: [],
                FilterDatas: {
                  "startTime":null,
                  "endTime":null,
                  "onError": false
              },
              timeError: false,
              }, () => {
                this.getReasonOrDiagnosisList()
              })
            }}
            id="eMed_Config_btn">{t("Clear")}</Button>
          <Button variant='contained' size="small" emed_tid = {"Search_testID"}
          disabled={this.state.FilterDatas.startTime ? (this.state.FilterDatas.startTime && this.state.FilterDatas.endTime && this.state.timeError != true) ? false : true : false}
            onClick={() => {
              this.getReasonOrDiagnosisList()
              this.setState({
                FilterOpen: false
              })
            }}
            id="eMed_Config_btn">{t("Search")}</Button>
        </Stack>
      </Box>
    )
  }
  closeFilter = () => {
    this.setState({ FilterOpen: false })
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
    })
  }

  render() {
    var { t } = this.props
    return (
      <Box component={'div'} className='eMed_rts_container'>
        <Box component={"div"} className='eMed_Top_Container'>
          <Box component={"div"} className='eMed_Cards_Container'></Box>
          <Box component={"div"} className='eMed_Action_Container'>
            <Box component={"div"} className='eMed_Filter_Icon_div'>
              <Tooltip title={t("Filter")} placement='top' arrow>
                <Button className='eMed_usrconf_btn' emed_tid = {"filter_testID"} onClick={() => { this.setState({ FilterOpen: true }) }}>
                  <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                </Button>
              </Tooltip>
            </Box>
            <Tooltip title={t("Print")} placement='top' arrow>
              <Button className='eMed_usrconf_btn' emed_tid = {"print_testID"}  disabled={this.state.disableBtn} onClick={() => { this.printReports() }}>
                <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
              </Button>
            </Tooltip>
            {/* <Button className='eMed_usrconf_btn'>
                <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
            </Button> */}
          </Box>
        </Box>
        <Box>
          {this.renderTableView()}
        </Box>
        <Drawer
          anchor={'right'}
          open={this.state.FilterOpen}
          ModalProps={{ onBackdropClick: this.closeFilter }}
        >
          {this.renderFilterScreen()}
        </Drawer>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        <Loader loaderOpen={this.state.printLoading} forPrint={true} label={"Building PDF..."} />
      </Box>
    );
  }
} export default withTranslation()(ReasonDiagnosisReport);
