import React, { Component } from 'react'
import Loader from '../../../../Components/Loader';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { Colors } from '../../../../Styles/Colors'
import { CommonPatientCard, CommonPatientDetails, DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import { ImagePaths } from '../../../../Utility/ImagePaths';
import { Autocomplete, Box, Button, Chip, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { Alcohol_List, Daily_Activity_List, Drug_List, Smoking_List, Yes_Or_No } from '../../../../Utility/Constants';
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService';
import { CheckAccessFunc } from "../../../../Components/CommonFunctions/CommonFunctions";

export default class DocSocialHistory extends Component {

  constructor(props) {
    super(props)
    this.state = {
      socialHistoryData: [],
      alcoholList: Alcohol_List,
      smokingList: Smoking_List,
      dailyActivityList: Daily_Activity_List,
      yesOrNo: Yes_Or_No,
      drugList: Drug_List,
      selectedAlcohol: '',
      selectedDailyActivity: '',
      selectedExercise: '',
      selectedMarried: '',
      selectedSmoking: '',
      selectedDrugs: [],
      occupation: '',
      selectedId: null,
      deletePopUp: false,
      isEdit: false,
      patientDetails: {},
      clinicId: '',
      appointmentId: '',
      patientId: '',
      newEnterDrug: null,
      // for fo 
      withOutAppointment: {},
      doctor_id: "",
    }
  }

  componentDidMount() {
    let clinicId = getCachevalue('SelectedDoctorClinic')
    let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))
    let ipPatientData = JSON.parse(getCachevalue("IppatientData"));
    let appointmentDetail = JSON.parse(getCachevalue("withoutAppointment"));
    const newState = {
      clinicId: clinicId,
      appointmentId: appointmentId?.appointment_id,
      patientId: appointmentId?.patient_id || ipPatientData?.patient_id,
      doctor_id: ipPatientData?.doctor_id
    };
    if (appointmentDetail?.patient_id === ipPatientData?.patient_id) {
      newState.withOutAppointment = appointmentDetail;
    }
    this.setState({
      ...newState
    }, () => {
      this.getPatientDetailData()
      this.getSocialHistoryData()
    })

  }

  getPatientDetailData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.state.patientId)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              patientDetails: response?.data?.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getSocialHistoryData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_SOCIAL_HISTORY + '?patient_id=' + this.state.patientId)
        .then((response) => {
          if (response?.data?.status === 'success') {
            let list = response?.data?.data
            if(list?.length > 0){
            this.setState({
              socialHistoryData: response?.data?.data,
              selectedAlcohol: list[0]?.alcohol,
              selectedDailyActivity: list[0]?.daily_activity,
              selectedSmoking: list[0]?.smoking,
              selectedDrugs: list[0]?.drugs[0]?.includes(',') ? list[0]?.drugs[0]?.split(',') : list[0]?.drugs?.map((item) => item),
              selectedExercise: list[0]?.is_exercise_done_daily === null ? '' : list[0]?.is_exercise_done_daily ? 'Yes' : 'No',
              selectedMarried: list[0]?.is_married === null ? '' : list[0]?.is_married ? 'Yes' : 'No',
              selectedId: list[0]?.id,
              occupation: list[0]?.occupation,
              isEdit: true,
            })
          }
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postSocialHistoryData = () => {
    try {
      let data = {
        alcohol: this.state.selectedAlcohol,
        appointment_id: this.state.appointmentId,
        daily_activity: this.state.selectedDailyActivity,
        drugs: this.state.selectedDrugs,
        is_exercise_done_daily: this.state.selectedExercise === '' ? null : this.state.selectedExercise === 'Yes' ? true : false,
        is_married: this.state.selectedMarried === '' ? null : this.state.selectedMarried === 'Yes' ? true : false,
        occupation: this.state.occupation,
        patient_id: this.state.patientId,
        smoking: this.state.selectedSmoking,
        id: this.state.selectedId ? this.state.selectedId : null
      }
      if(this.props.is_From_Fo){
        data['doctor_id'] = this.state.doctor_id
      }
      this.LoaderFunction(true)
      RestAPIService.create(data, Serviceurls.DOC_SOCIAL_HISTORY)
        .then((response) => {
          if (response.data.status === 'success') {
            this.LoaderFunction(false)
            this.successMessage(response.data.message)
            this.getSocialHistoryData()
            this.onClearSocialHistory()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.LoaderFunction(false)
            this.errorMessage(error.response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.LoaderFunction(false)
      this.errorMessage(error.message)
    }
  }

  deleteSocialHistoryData = () => {
    try {
      let data = {
        'patient_id': this.state.patientId,
        'id': this.state.selectedId
      }
      this.setState({ removeData: true })
      this.LoaderFunction(true)
      RestAPIService.delete(Serviceurls.DOC_SOCIAL_HISTORY, data)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({ removeData: false })
            this.LoaderFunction(false)
            this.successMessage(response.data.message)
            this.getSocialHistoryData()
            this.onClearSocialHistory()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ removeData: false })
            this.LoaderFunction(false)
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ removeData: false })
            this.LoaderFunction(false)
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ removeData: false })
      this.LoaderFunction(false)
      this.errorMessage(error.message)
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  checkMandatory = () => {
    try {
      let validate = false;
      if ((this.state.selectedAlcohol === '') && (this.state.selectedDailyActivity === '') && (this.state.selectedDrugs.length === 0)
        && (this.state.selectedExercise === '') && (this.state.selectedMarried === '') && (this.state.selectedSmoking === '') && (this.state.occupation === '')) {
        validate = true
      }
      //  else if (this.state.selectedAlcohol === '') {
      //   validate = true
      // } else if (this.state.selectedDailyActivity === '') {
      //   validate = true
      // } else if (this.state.selectedDrugs?.length < 0) {
      //   validate = true
      // } else if (this.state.selectedExercise === '') {
      //   validate = true
      // } else if (this.state.selectedMarried === '') {
      //   validate = true
      // }

      return validate;
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  popupClose = () => {
    try {
      this.setState({
        deletePopUp: false
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  onClearSocialHistory = () => {
    try {
      this.setState({
        selectedAlcohol: '',
        selectedDailyActivity: '',
        selectedExercise: '',
        selectedMarried: '',
        selectedSmoking: '',
        selectedDrugs: [],
        occupation: '',
        selectedId: null,
        isEdit: false,
        deletePopUp: false,
        newEnterDrug: null
      })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  renderRightSide = () => {
    const enableAdd = this.checkMandatory();
    const checkuserAccess = (CheckAccessFunc("front_office", "In Patients", "History", "Social History", "SubTab")?.editAccess ?
    this.state.withOutAppointment?.is_ip || true && !enableAdd : !enableAdd);
    try {
      return (
        <Box component={'div'} width={'92.5vw'}>
          <Box component={'div'} height={'2vw'} display={'flex'} pl={'0.5vw'} alignItems={'center'}>
            <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Social History</Typography>
          </Box>
          <Box component={'div'} height={'23vw'} sx={{ backgroundColor: Colors.white }} overflow={'scroll'}>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
              <Box component={'div'} pl={'0.5vw'} width={'25vw'}>
                <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'} mr={'1vw'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Alcohol</Typography>
                </Box>
                <Box>
                  {this.state.alcoholList?.map((item, index) => (
                    <Chip
                      className={`eMed_chip ${item.label === this.state.selectedAlcohol ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                      size='small'
                      label={item.label}
                      disabled={this.state.isEdit}
                      variant={item.label === this.state.selectedAlcohol ? 'contained' : 'outlined'}
                      key={index}
                      onClick={() => {
                        if (item.label === this.state.selectedAlcohol) {
                          this.setState({ selectedAlcohol: '' })
                        } else {
                          this.setState({ selectedAlcohol: item?.value })
                        }
                      }}
                      // color={'primary'}
                      clickable
                    />
                  ))}
                </Box>
              </Box>
              <Box component={'div'} pl={'0.5vw'} width={'25vw'}>
                <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'} mr={'1vw'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Smoking</Typography>
                </Box>
                <Box>
                  {this.state.smokingList?.map((item, index) => (
                    <Chip
                      className={`eMed_chip ${item.label === this.state.selectedSmoking ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                      size='small'
                      label={item.label}                      
                      disabled={this.state.isEdit}
                      variant={item.label === this.state.selectedSmoking ? 'contained' : 'outlined'}
                      key={index}
                      onClick={() => {
                        if (item.label === this.state.selectedSmoking) {
                          this.setState({ selectedSmoking: '' })
                        } else {
                          this.setState({ selectedSmoking: item?.value })
                        }
                      }}
                      // color={'primary'}
                      clickable
                    />
                  ))}
                </Box>
              </Box>
              <Box component={'div'} pl={'0.5vw'} width={'25vw'}>
                <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'} mr={'1vw'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Drugs</Typography>
                </Box>
                <Box>
                  <Autocomplete
                    clearIcon
                    size='small'
                    multiple
                    freeSolo                    
                    readOnly={this.state.isEdit}
                    sx={{ width: '20vw', mb: '0.5vw' }}
                    options={this.state.drugList?.map((item) => item?.label)}
                    // getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.label}
                    value={this.state.selectedDrugs}
                    limitTags={3}
                    autoComplete='off'
                    onChange={(event, newValue) => {
                      this.setState({
                        selectedDrugs: newValue
                      })
                    }}
                    renderTags={(value, getTagProps) => (
                      value?.map((option, index) => (<Chip variant="outlined" size='small' label={option} {...getTagProps({ index })} />))
                    )}
                    renderInput={(params) => <TextField {...params} label='Select Problems'
                    />}
                  />
                </Box>
              </Box>
            </Box>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} mt={'2vw'}>
              <Box component={'div'} pl={'0.5vw'} width={'25vw'}>
                <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'} mr={'1vw'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Rate Your Daily Activity</Typography>
                </Box>
                <Box>
                  {this.state.dailyActivityList?.map((item, index) => (
                    <Chip
                      className={`eMed_chip ${item.label === this.state.selectedDailyActivity ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                      size='small'
                      label={item.label}                      
                      disabled={this.state.isEdit}
                      variant={item.label === this.state.selectedDailyActivity ? 'contained' : 'outlined'}
                      key={index}
                      onClick={() => {
                        if (item.label === this.state.selectedDailyActivity) {
                          this.setState({ selectedDailyActivity: '' })
                        } else {
                          this.setState({ selectedDailyActivity: item?.value })
                        }
                      }}
                      // color={'primary'}
                      clickable
                    />
                  ))}
                </Box>
              </Box>
              <Box component={'div'} pl={'0.5vw'} width={'25vw'}>
                <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'} mr={'1vw'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Is Married</Typography>
                </Box>
                <Box>
                  {this.state.yesOrNo?.map((item, index) => (
                    <Chip
                      className={`eMed_chip ${item.label === this.state.selectedMarried ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                      size='small'
                      disabled={this.state.isEdit}
                      label={item.label}
                      variant={item.label === this.state.selectedMarried ? 'contained' : 'outlined'}
                      key={index}
                      onClick={() => {
                        if (item.label === this.state.selectedMarried) {
                          this.setState({ selectedMarried: '' })
                        } else {
                          this.setState({ selectedMarried: item?.value })
                        }
                      }}
                      // color={'primary'}
                      clickable
                    />
                  ))}
                </Box>
              </Box>
              <Box component={'div'} pl={'0.5vw'} width={'25vw'}>
                <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'} mr={'1vw'}>
                  <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Do You Exercise ?</Typography>
                </Box>
                <Box>
                  {this.state.yesOrNo?.map((item, index) => (
                    <Chip
                      className={`eMed_chip ${item.label === this.state.selectedExercise ? 'eMed_Chip_OnClick' : 'eMed_Chip_Border'}`}
                      size='small'
                      label={item.label}
                      disabled={this.state.isEdit}
                      variant={item.label === this.state.selectedExercise ? 'contained' : 'outlined'}
                      key={index}
                      onClick={() => {
                        if (item.label === this.state.selectedExercise) {
                          this.setState({ selectedExercise: '' })
                        } else {
                          this.setState({ selectedExercise: item?.value })
                        }
                      }}
                      // color={'primary'}
                      clickable
                    />
                  ))}
                </Box>
              </Box>
            </Box>
            <Box component={'div'} pl={'0.5vw'} mt={'2vw'}>
              <Box component={'div'} height={'2vw'} display={'flex'} alignItems={'center'} mr={'1vw'}>
                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Occupation</Typography>
              </Box>
              <TextField
                sx={{ width: '30vw' }}
                label='Occupation'
                value={this.state.occupation}
                InputProps={{readOnly: this.state.isEdit}}
                onChange={(e) => {
                  let value = e.target.value
                  if (value?.length <= 250 || value === '') {
                    this.setState({ occupation: e.target.value })
                  } else {
                    this.errorMessage('Allowed only 250 characters')
                  }
                }}
                inputProps={{emed_tid:'occupation'}}
              />
            </Box>
          </Box>
          <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
            {this.state.socialHistoryData?.length > 0 ? null : <Button
              sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
              variant='outlined'
              onClick={() => {
                this.onClearSocialHistory()
              }}
            >Clear</Button>}
            {this.state.isEdit ? <Button
              sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
              variant='contained'
              onClick={() => this.setState({isEdit: false})}
            >Edit</Button> :
             <Button
              sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
              variant='contained'
              disabled={this.props.is_From_Fo ? !checkuserAccess : enableAdd}
              onClick={() => this.postSocialHistoryData()}
            >Add</Button>}
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  render() {
    let attender = {
      lineOne: "Attender Name",
      lineTwo: `${this.state.patientDetails?.attender_name ? this.state.patientDetails?.attender_name :
        this.state.patientDetails?.caretaker_name ? this.state.patientDetails?.caretaker_name : "-"}`,
      lineFour: 'Contact Number',
      LineFive: `${this.state.patientDetails?.attender_mobile ? this.state.patientDetails?.attender_mobile :
        this.state.patientDetails?.caretaker_mobile ? this.state.patientDetails?.caretaker_mobile : "-"}`
    }
    return (
      <Box component={'div'} sx={{ backgroundColor: Colors.Background }} height={'74vh'} p={'0.5vw'}>
        <Box component={'div'} display={'flex'}>
          <Box component={'div'} className='doc_header_card' mr={'0.5vw'}>
            <CommonPatientDetails data={this.state.patientDetails} showDetailed={true} fromDoctor={true} />
          </Box>
          {/* <CommonPatientCard details={attender} showDetailed={true} /> */}
        </Box>
        <Box component={'div'} display={'flex'} mt={'0.5vw'} height={'61.5vh'}>
          <Box component={'div'} flex={0.35} border={'1px solid lightgray'}>
            {this.renderRightSide()}
          </Box>
        </Box>
        {this.state.isErrorMsg ?
          <ToastMsg
            severity={'error'}
            msg={this.state.isErrorMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.successMsg ?
          <ToastMsg
            severity={'success'}
            msg={this.state.successMsgText}
            msgPop={this.msgClose.bind(this)}
          />
          : null}
        {this.state.deletePopUp ? <DeletePopup
          DeletTitle={`Are you sure you want to delete ?`}
          deleteAlertPopupClose={this.popupClose.bind(this)}
          removeData={this.deleteSocialHistoryData.bind(this)}
          disabled={this.state.removeData}
        /> : null}
        <Loader loaderOpen={this.state.isLoader} />
      </Box>
    )
  }
}
