import {
  Autocomplete, Box, Button, Checkbox, FormControlLabel, Grid, InputAdornment, Paper, TextField, Typography,
  FormControl, RadioGroup, Radio
} from '@mui/material'
import React, { Component } from 'react';
import { withTranslation } from 'react-i18next'
import CommonValidation from '../../../../Components/CommonFunctions/CommonValidation';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { ImagePaths } from '../../../../Utility/ImagePaths';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateTime } from 'luxon';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { BasicPatientDetails } from '../../../../Components/Common Components/CommonComponents';
import './IPAppointments.css';
import Barcode from 'react-barcode';
import LocalPrintshopIcon from '@mui/icons-material/LocalPrintshop';
import Loader from '../../../../Components/Loader';
import { Colors } from '../../../../Styles/Colors';
const BarCodePrintCount = [];
for (let i = 1; i <= 30; i++) { BarCodePrintCount.push(i) }

class IPBilling extends Component {

  constructor(props) {
    super(props)
    this.state = {
      PayableAmount: "",
      PayMadeBy: "",
      isCash: false,
      isCard: false,
      isUPI: false,
      isCheque: false,
      isBankTranfer: false,
      PaymentDate: new Date(),
      CashAmount: "",
      CardAmount: "",
      CardTransactionNumber: "",
      UPINumber: "",
      UPIAmount: "",
      BankTfrAmount: "",
      BankTfrNumber: "",
      ChequeNumber: "",
      ChequeAmount: "",
      BarCodePrintCounts: 1,
      AdmissionDetails: this.props.AdmissionDetails,
      corporatePay: "",
      employerID: this.props.PatientDetails.employer_id,
      InsuranceName: null,
      PolicyNumber: "",
      InsuranceList: [],
      NewInsurance: false
    }
  }

  componentDidMount = () => {
    this.getInsuranceList()
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message,
      DisableBtn : false
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message,
      DisableBtn : false
    })
  }

  lodaerFunction = (key) => {
    this.setState({
      isLoader: key
    }, () => {
      setTimeout(() => {
        this.setState({
          isLoader: false
        })
      }, 2000);
    })
  }

  BarcodePrint() {
    try {
      RestAPIService.PrintPDF(Serviceurls.IP_BARCODE_PRINT + "?ip_admission_id=" + this.state.AdmissionDetails?.ip_admission_id).
        then((response) => {
          this.setState({ DisablePrnt: false })
          if (response) {
            //Create a Blob from the PDF Stream
            const file = new Blob(
              [response.data],
              { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
          }
        }).catch((error) => {
          this.setState({ DisablePrnt: false })
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (e) {
      console.log(e.message)
    }
  }

  getInsuranceList = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_INSURANCE_LIST).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              InsuranceList: response.data.data,
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }


  renderComponentRight() {
    const { t } = this.props
    let BarCodeValue = `${this.state.AdmissionDetails?.ip_number}`
    let data = {
      patient_name: this.state.AdmissionDetails?.patient_name,
      patient_gender: "Male",
      text: this.state.AdmissionDetails?.ip_number,
      photo_url: this.state.AdmissionDetails?.photo_url
    }

    return (
      <Paper className='eMed_IPappoint_right'>
        <Typography className='eMed_IPappoint_title'>{t("PatientDetails")}</Typography>
        <Box component={'div'} className='eMed_IPappoint_Patient_Details'><BasicPatientDetails data={data} /></Box>
        <Box component={'div'} className="eMed_IP_BarCode_Box">
          <Typography className='eMed_IP_barcode_title'>{t("InPatientlabel")}</Typography>
          <Box className='eMed_IP_barcode_img'> <Barcode value={BarCodeValue} width={1.3} /></Box>
        </Box>
        <Box component={'div'} className="eMed_IP_BarCode_PrintBox">
          {/* <Autocomplete
            size='small'
            options={BarCodePrintCount}
            getOptionLabel={(option) => option}
            value={this.state.BarCodePrintCounts}
            onChange={(event, newValue) => {
              this.setState({
                BarCodePrintCounts: newValue
              })
            }}
            renderInput={(params) => (
              <TextField
                sx={{ width: '8vw' }}
                {...params}
                label={t("NoofPrint")}
                placeholder={t("NoofPrint")}
              />
            )}
          /> */}
          <Button emed_tid ="BarcodePrint_TestID" size='small' disabled={this.state.DisablePrnt} variant='contained' color='primary' onClick={() => { this.setState({ DisablePrnt: true }, ()=>{this.BarcodePrint()}) }}>
            <LocalPrintshopIcon />
          </Button>
        </Box>
        {/* <FormControlLabel className="eMed_IP_BarCode_PrintBox" control={<Checkbox sx={{ fontSize: '0.4vw' }} checked={this.state.RememberPrintCount} onChange={(e) => { this.setState({ RememberPrintCount: e.target.checked }) }} size='small' />} label={t("RememberPrintCount")} /> */}
      </Paper>
    )
  }
  renderTextBox(label, value, Key, maxLength, width, multiline, disable) {
    let states = this.state;
    return (
      <TextField
        disabled={disable}
        inputProps={{ maxLength: maxLength, emed_tid :label }}
        sx={{ width: width, marginLeft: '2vw' }}
        size='small'
        label={label}
        value={value}
        onChange={(e) => {
          if (Key === "PayableAmount") {
            let number = CommonValidation.NumberWithDot(e.target.value);
            if ((number && +e.target.value > 0) || e.target.value === "") {
              states[Key] = e.target.value
              if (e.target.value === "") {
                this.setState({
                  isCash: false,
                  isCard: false,
                  isUPI: false,
                  isCheque: false,
                  isBankTranfer: false,
                  CashAmount: "",
                  CardAmount: "",
                  CardTransactionNumber: "",
                  UPINumber: "",
                  UPIAmount: "",
                  BankTfrAmount: "",
                  BankTfrNumber: "",
                  ChequeNumber: "",
                  ChequeAmount: "",
                })
              }
            }
          }
          else {
            states[Key] = e.target.value
          }

          this.setState({ states })
        }}
        multiline={multiline}
        rows={4}
      />
    )
  }

  renderAmountTextFields = (label, key, Icon) => {
    var states = this.state
    let { t } = this.props;
    return (
      <TextField
        sx={{ padding: 0, margin: 0 }}
        inputProps={{emed_tid : label}}
        className='eMed_CrdPop_Amount_TextFields'
        size='small'
        label={t(label)}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <img src={Icon} alt={label} className='eMed_action_img_small' />
            </InputAdornment>
          ),
        }}
        value={states[key]}
        onChange={(e) => {
          let number = CommonValidation.NumberWithDot(e.target.value);
          if (number || e.target.value === "") {
            states[key] = e.target.value
            this.setState({
              states
            })
          }
        }}
      />
    )
  }

  renderNumberTextFields = (label, key, Right = false) => {
    var states = this.state
    let { t } = this.props;
    return (
      <TextField
        className='eMed_CrdPop_Amount_TextFields'
        sx={{ marginLeft: Right ? "1vw" : 0 }}
        inputProps={{ maxLength: 20, emed_tid : label}}
        size='small'
        label={t(label)}
        value={states[key]}
        onChange={(e) => {
          let number = CommonValidation.gstnumberValidation(e.target.value);
          if (number || e.target.value === "") {
            states[key] = e.target.value.toUpperCase()
            this.setState({
              states
            })
          }
        }}
      />
    )
  }

  handleCheckBox = (e, key) => {
    var states = this.state
    states[key] = e.target.checked
    this.setState({ states }, () => {
      if (!states.isCash) { this.setState({ CashAmount: "" }) }
      if (!states.isCard) { this.setState({ CardAmount: "", CardTransactionNumber: "" }) }
      if (!states.isUPI) { this.setState({ UPIAmount: "", UPINumber: "" }) }
      if (!states.isCheque) { this.setState({ ChequeAmount: "", ChequeNumber: "" }) }
      if (!states.isBankTranfer) { this.setState({ BankTfrAmount: "", BankTfrNumber: "" }) }
      if (states.isCash && !states.isCard && !states.isUPI && !states.isBankTranfer) {
        this.setState({ CashAmount: this.state.PayableAmount, })  // Fetch Full amount in Cash Amount, when only the cash is selected
    }
    })
  }

  CalculateAmount() {
    try {
      var states = this.state
      let CashAmt = states.CashAmount === "" ? 0 : +states.CashAmount;
      let CardAmt = states.CardAmount === "" ? 0 : +states.CardAmount;
      let UPIAmt = states.UPIAmount === "" ? 0 : +states.UPIAmount;
      let bankTransferAmt = states.BankTfrAmount === "" ? 0 : +states.BankTfrAmount;
      let ChequeAmt = states.ChequeAmount === "" ? 0 : +states.ChequeAmount;
      if (states.PayableAmount === "") {
        return false
      }
      else if (+states.PayableAmount === (CashAmt + CardAmt + UPIAmt + bankTransferAmt + ChequeAmt)) {
        return true
      }
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  // postNewInsurance = () => {
  //   var states = this.state

  //   var data = {
  //     "insurance_name": states.insurance_company_name
  //   }

  //   RestAPIService.create(data, Serviceurls.FO_INSURANCE_LIST)
  //     .then((response) => {
  //       if (response.data.status === 'success') {
  //       }
  //     }).catch((e) => {
  //       if (e?.response?.data?.message) {
  //         this.errorMessage(e.response.data.message)
  //       } else {
  //         this.errorMessage(e.message)
  //       }
  //     })
  // }

  receivePayment() {
    try {
      let states = this.state;
      let Paymethod = [];
      if (states.CashAmount !== "") { Paymethod.push(1) }
      if (states.CardAmount !== "") { Paymethod.push(2) }
      if (states.UPIAmount !== "") { Paymethod.push(3) }
      if (states.BankTfrAmount !== "") { Paymethod.push(5) }
      if (states.ChequeAmount !== "") { Paymethod.push(4) }

      if (states.PayableAmount !== "" &&
        states.PayableAmount !== 0 &&
        ((states.corporatePay == "Insurance" && states.InsuranceName !== "" && states.PolicyNumber !== "") || (states.corporatePay == "Corporate" || states.corporatePay == "")) &&
        this.CalculateAmount() &&
        DateTime.fromJSDate(states.PaymentDate).toFormat('yyyy-MM-dd') !== "Invalid DateTime" &&
        ((states.isCash || states.isCard || states.isBankTranfer || states.isUPI || states.isCheque)) &&
        !((this.state.CardAmount !== "" && this.state.CardTransactionNumber === "" ||
          this.state.UPIAmount !== "" && this.state.UPINumber === "" ||
          this.state.ChequeAmount !== "" && this.state.ChequeNumber === "" ||
          this.state.BankTfrAmount !== "" && this.state.BankTfrNumber === ""))) {
        this.lodaerFunction(true)
        var data = {
          "patient_id": this.state.AdmissionDetails?.patient_id,
          "ip_admission_id": this.state.AdmissionDetails?.ip_admission_id,
          "patient_account_number": this.state.AdmissionDetails?.patient_account_number,
          "payable_amount": +states.PayableAmount,
          "cash_amount": +states.CashAmount,
          "card_amount": +states.CardAmount,
          "upi_amount": +states.UPIAmount,
          "bank_transfer_amount": +states.BankTfrAmount,
          "cheque_amount": +states.ChequeAmount,
          "payment_date": DateTime.fromJSDate(states.PaymentDate).toFormat('yyyy-MM-dd'),
          "payment_made_by": CommonValidation.removeSpace(states.PayMadeBy),
          "payment_mode": Paymethod,
          "card_ref_no": states.CardTransactionNumber,
          "upi_ref_no": states.UPINumber,
          "bank_ref_no": states.BankTfrNumber,
          "cheque_ref_no": states.ChequeNumber,
          "employer_id": states.employerID ? states.employerID : "",
          "primary_payment_type": states.corporatePay === "Insurance" ? "Insurance credit" : states.corporatePay === "Corporate" ? "Corprate credit" : "Patient credit",
          "insurance_company_name": states?.InsuranceName ? states?.InsuranceName : null,
          "policy_number": states?.PolicyNumber ? states?.PolicyNumber : null
        }
        RestAPIService.create(data, Serviceurls.IP_ADVANCE_PAYMENT).
          then((response) => {
            if (response.data.status === "success") {
              this.setState({ DisableBtn : false})
              if ((states.InsuranceName != "" && states.InsuranceName != null) && states.NewInsurance) {
                this.postNewInsurance()
                this.lodaerFunction(false)
              }
              this.successMessage(response.data.message)
              setTimeout(() => { this.props.history.push({ pathname: "/IPHome" }) }, 2000)
            } else {
              this.lodaerFunction(false)
              this.errorMessage(response.data.message)
            }
          }).catch(error => {
            this.lodaerFunction(false)
            if (error?.response?.data?.status === "fail") {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
          })
      } else {
        this.setState({
          DisableBtn : false
        }, ()=>{
          if (states.PayableAmount === "" || states.PayableAmount === 0) {
            this.errorMessage("Please Enter the Payable Amount")
          }
          else if (DateTime.fromJSDate(states.PaymentDate).toFormat('yyyy-MM-dd') === "Invalid DateTime") {
            this.errorMessage("Invalid Payment Date. Please Select the Correct Date")
          }
          else if (!(states.isCash || states.isCard || states.isBankTranfer || states.isUPI || states.isCheque)) {
            this.errorMessage("Please Select any Payment Methods")
          }
          else if (this.state.CardAmount !== "" && this.state.CardTransactionNumber === "" ||
            this.state.UPIAmount !== "" && this.state.UPINumber === "" ||
            this.state.ChequeAmount !== "" && this.state.ChequeNumber === "" ||
            this.state.BankTfrAmount !== "" && this.state.BankTfrNumber === "") {
            this.errorMessage("Please,Enter Reference Number")
          }
          else if (!this.CalculateAmount()) {
            this.errorMessage("Amount Not Matched ")
          }
          else if (states.corporatePay == "Insurance" && (states.InsuranceName == "" || states.PolicyNumber == "")) {
            this.errorMessage("Insurance details should be Mandatory ")
          }
        })
        
      }
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }
  renderComponentLeft() {
    const { t } = this.props
    let states = this.state
    var corporate = {}
    var Insurance = {}
    if (this.props?.PatientDetails?.company_name || this.props?.PatientDetails?.insurance_company_name) {
      corporate["company_name"] = this.props?.PatientDetails?.company_name
      corporate["id_no"] = this.props?.PatientDetails?.id_no
      corporate["discount_percent"] = this.props?.PatientDetails?.discount_percent
      Insurance["insurance_company_name"] = this.props?.PatientDetails?.insurance_company_name
      Insurance["policy_number"] = this.props?.PatientDetails?.policy_number
    } else {
      corporate = this.props.PatientDetails?.primary_payment?.corporate_detail ? this.props.PatientDetails?.primary_payment.corporate_detail : "-"
      Insurance = this.props.PatientDetails?.primary_payment?.insurance_detail ? this.props.PatientDetails?.primary_payment.insurance_detail : "-"
    }
    return (
      <Paper className='eMed_IPappoint_left'>
        <Box>
          {/* <Typography className='eMed_IPappoint_title'>{t("PatientInsCov")}</Typography> */}
          <Box component={'div'} className='eMed_cor_txt'>
            {/* Commented and Moved To Admission Details */}
            {/* <FormControl>
              <RadioGroup value={this.state.corporatePay}
                onClick={(e) => {
                  if (e.target.value === this.state.corporatePay) {
                    this.setState({
                      corporatePay: "",
                      totalDiscountedPercentage: "",
                      totalDiscountedAmount: ""
                    })
                  } else {
                    if (e.target.value === "Corporate") {
                      let CorporateName = this.props.PatientDetails?.primary_payment?.corporate_detail?.company_name || this.props?.PatientDetails?.company_name;
                      if (CorporateName !== "" && CorporateName !== null && CorporateName !== undefined) {
                        this.setState({
                          corporatePay: e.target.value,
                          totalDiscountedPercentage: corporate?.discount_percent ? corporate?.discount_percent : "-"
                        })
                      }
                      else {
                        this.errorMessage("Patient is not Mapped with Any Corporate. Please Map the Corporate in Registration Page")
                      }
                    } else if (e.target.value === "Insurance") {
                      let newInsuranceKey = this.props.PatientDetails?.insurance_company_name ? this.props.PatientDetails?.insurance_company_name : '-'
                      let InsuranceName = this.props.PatientDetails?.primary_payment?.insurance_detail?.insurance_company_name ? this.props.PatientDetails?.primary_payment.insurance_detail?.insurance_company_name : newInsuranceKey
                      if (InsuranceName !== "" && InsuranceName !== null && InsuranceName !== undefined && InsuranceName !== "-") {
                        this.setState({
                          corporatePay: e.target.value,
                          InsuranceName: InsuranceName,
                          PolicyNumber: this.props.PatientDetails?.primary_payment?.insurance_detail?.policy_number ? this.props.PatientDetails?.primary_payment?.insurance_detail?.policy_number : this.props.PatientDetails?.policy_number ? this.props.PatientDetails?.policy_number : '-'
                        })
                      } else {
                        this.errorMessage("Patient is not Registered with Any Insurance. Please Register the Insurance in Registration Page")
                      }
                    } else {
                      this.setState({
                        corporatePay: e.target.value,
                        totalDiscountedPercentage: corporate?.discount_percent ? corporate?.discount_percent : "-"
                      })
                    }
                  }
                }}
              >
                <FormControlLabel value={"Corporate"} control={<Radio size="small" />} label={<Typography id="eMed_recPayment_radioCorpTxt">
                  <span id="eMed_recPayment_radioCorpTxt" style={{ fontWeight: 600 }}>{`${t("Corporate")}: `}</span>{`${t("PatientUnder")} `}
                  <span className='eMed_cor_font'>
                    {corporate?.company_name ? corporate?.company_name : "-"}
                  </span>  {`${t("CorporatePlan")} (${t("ID.No.")}`}
                  <span className='eMed_cor_font'>{corporate?.id_no ? corporate?.id_no : "-"}</span> {") "}
                  <span className='eMed_cor_font'>
                    {corporate?.discount_percent ? corporate?.discount_percent : "0"}
                    %</span>
                  {` ${t("corporateTxt")}`}</Typography>} />
                <FormControlLabel value={"Insurance"} control={<Radio size="small" />} label={<Typography id="eMed_recPayment_radioCorpTxt">
                  <span id="eMed_recPayment_radioCorpTxt" style={{ fontWeight: 600 }}>{`${t("Insurance")}: `}</span>{`${t("PatientRegisteredUnder")} `}
                  <span className='eMed_cor_font'>{Insurance?.insurance_company_name ? Insurance?.insurance_company_name : "-"}</span>
                  {`${t(" RegNo")}`} <span className='eMed_cor_font'>{Insurance?.policy_number ? Insurance?.policy_number : "-"}</span>
                </Typography>} />
              </RadioGroup>
            </FormControl> */}
            {Insurance?.insurance_company_name == "" && Insurance?.policy_number == "" && this.state.corporatePay == "Insurance" ?
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginLeft: "1.8vw" }}>
                <Autocomplete
                  sx={{ width: "15vw" }}
                  size="small"
                  options={states.InsuranceList}
                  getOptionLabel={(option) => (typeof (option) === "string" ? option : option.insurance_name)}
                  onChange={(event, value) => {
                    states["InsuranceName"] = value?.insurance_name ? value?.insurance_name : ""
                    states["NewInsurance"] = false
                    this.setState({
                      states
                    })
                  }}
                  clearIcon={false}
                  value={states.InsuranceName}
                  renderInput={(params) => (
                    <TextField
                      label={t("Insurance Company Name") + " *"} InputProps={{ ...params.InputProps }}
                      // onChange={(event) => {
                      //   states["InsuranceName"] = event.target.value
                      //   states["NewInsurance"] = true
                      //   this.setState({
                      //     states
                      //   })
                      // }}
                      {...params}
                      inputProps={{...params.inputProps,'emed_tid': 'Insurance_Company_Name_TestID', }}
                    />
                  )}
                />
                <TextField
                  label={t("Policy Number *")}
                  inputProps={{emed_tid : "Policy_Number_TestID"}}
                  size="small"
                  sx={{ width: "15vw", marginLeft: "0.5vw" }}
                  value={this.state.PolicyNumber}
                  onChange={(e) => {
                    let number = CommonValidation.numberOnly(e.target.value);
                    if ((number) || e.target.value === "") {
                      this.setState({ PolicyNumber: e.target.value })
                    }
                  }}
                />
              </div> : null}
          </Box>
        </Box>
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'}>
          <Typography className='eMed_IPappoint_title' sx={{backgroundColor:Colors.white}}>{t("PaymentDetails")}</Typography>
          <Box className="eMed_CrdPop_Check_Group">
            <FormControlLabel sx={{ width: '16vw' }} control={<Checkbox inputProps={{emed_tid : "Receive_Advance_TestID" }} checked={true} size='small' />} label={t("Receive As Advance")} />
          </Box>
        </Box>

        <Box component={'div'} display={'flex'} flexDirection={"row"} marginTop={"1vw"} >
          {this.renderTextBox(`${t("PayableAmount")}`, this.state.PayableAmount, "PayableAmount", 50, "20vw", false, false)}
          <LocalizationProvider dateAdapter={AdapterDayjs} >
            <DatePicker
              inputFormat='DD-MM-YYYY'
              label={t("PaymentDate")}
              value={this.state.PaymentDate}
              name="PaymentDate"
              minDate={new Date()}
              maxDate={new Date()}
              disabled
              onChange={(newDate) => {
                if (newDate === null) {
                  this.setState({ PaymentDate: new Date() })
                }
                else if (newDate > new Date()) {
                  this.setState({ PaymentDate: new Date() })
                }
                else {
                  this.setState({ PaymentDate: newDate?.$d })
                }
              }}
              renderInput={(params) => <TextField style={{ width: '20vw', marginLeft: '2vw' }} size='small' {...params} inputProps={{ ...params.inputProps,'emed_tid': 'PaymentDate_TestID_OP'}}/>}
            />
          </LocalizationProvider>
        </Box>
        <Box component={'div'} display={'flex'} flexDirection={"row"} marginTop={"1vw"} marginBottom={"1vw"}>
          {this.renderTextBox(`${t("PaymentMadeBy")}`, this.state.PayMadeBy, "PayMadeBy", 100, "42vw", false, false)}
        </Box>
        <Typography className='eMed_IPappoint_title'>{t("PaymentMethods")}</Typography>
        <Box display={'flex'} flexDirection={"row"} height={'40vh'} >
          <Box flex={0.6} display={'flex'} flexDirection={'column'} alignItems={'flex-start'} justifyContent={"flex-start"} paddingLeft={"2vw"}>
            <Box className="eMed_CrdPop_Check_Group">
              <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' disabled={(this.state.PayableAmount === "" || this.state.PayableAmount == 0)} control={<Checkbox inputProps={{emed_tid :"isCash_TestID"}} checked={this.state.isCash} onChange={(e) => { this.handleCheckBox(e, "isCash") }} size='small' />} label={t("Cash")} />
              <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' disabled={(this.state.PayableAmount === "" || this.state.PayableAmount == 0)} control={<Checkbox inputProps={{emed_tid :"isCard_TestID"}} checked={this.state.isCard} onChange={(e) => { this.handleCheckBox(e, "isCard") }} size='small' />} label={t("Card")} />
              <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' disabled={(this.state.PayableAmount === "" || this.state.PayableAmount == 0)} control={<Checkbox inputProps={{emed_tid :"isUPI_TestID"}} checked={this.state.isUPI} onChange={(e) => { this.handleCheckBox(e, "isUPI") }} size='small' />} label={t("UPI")} />
              <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' disabled={(this.state.PayableAmount === "" || this.state.PayableAmount == 0)} sx={{ width: "10vw" }} control={<Checkbox inputProps={{emed_tid :"isBankTranfer_TestID"}}  checked={this.state.isBankTranfer} onChange={(e) => { this.handleCheckBox(e, "isBankTranfer") }} size='small' />} label={t("BankTransfer")} />
              <FormControlLabel className='eMed_CrdPop_CheckBoxDiv' disabled={(this.state.PayableAmount === "" || this.state.PayableAmount == 0)} control={<Checkbox inputProps={{emed_tid :"payableAmount_TestID"}}  checked={this.state.isCheque} onChange={(e) => { this.handleCheckBox(e, "isCheque") }} size='small' />} label={t("Cheque")} />
            </Box>
            <Box component={'div'}>
              {
                this.state.isCash ? this.renderAmountTextFields("CashAmount", "CashAmount", ImagePaths.CashIcon.default) : null
              }
              {
                this.state.isCard ?
                  <Box component={"div"} display={"flex"} flexDirection={"row"}>
                    {this.renderAmountTextFields("CardAmount", "CardAmount", ImagePaths.CardIcon.default)}
                    {this.renderNumberTextFields("CardTransactionNumber", "CardTransactionNumber", true)}
                  </Box>
                  : null
              }
              {
                this.state.isUPI ?
                  <Box component={"div"} display={"flex"} flexDirection={"row"}>
                    {this.renderAmountTextFields("UPIAmount", "UPIAmount", ImagePaths.UPIIcon.default)}
                    {this.renderNumberTextFields("UPITransactionNumber", "UPINumber", true)}
                  </Box>
                  : null
              }
              {
                this.state.isBankTranfer ?
                  <Box component={"div"} display={"flex"} flexDirection={"row"}>
                    {this.renderAmountTextFields("BankTransferAmount", "BankTfrAmount", ImagePaths.BankTransferIcon.default)}
                    {this.renderNumberTextFields("BankTransactionNumber", "BankTfrNumber", true)}
                  </Box>
                  : null
              }
              {
                this.state.isCheque ?
                  <Box component={"div"} display={"flex"} flexDirection={"row"}>
                    {this.renderAmountTextFields("ChequeAmount", "ChequeAmount", ImagePaths.ChequeIcon.default)}
                    {this.renderNumberTextFields("ChequeTransactionNumber", "ChequeNumber", true)}
                  </Box>
                  : null
              }
            </Box>
          </Box>
          {/* <Box position={'fixed'} flex={0.4} display={'flex'} alignItems={'flex-end'} justifyContent={"flex-end"} right={"35vw"} top={"82%"}>
            <Button size='small' id="eMed_invd_Button" variant='contained' onClick={() => { this.receivePayment("") }}>{t("ReceivePayment")}</Button>
          </Box> */}
        </Box>
      </Paper>
    )
  }

  ClearData() {
    this.setState({
      PayableAmount: "",
      PayMadeBy: "",
      isCash: false,
      isCard: false,
      isUPI: false,
      isCheque: false,
      isBankTranfer: false,
      PaymentDate: new Date(),
      CashAmount: "",
      CardAmount: "",
      CardTransactionNumber: "",
      UPINumber: "",
      UPIAmount: "",
      BankTfrAmount: "",
      BankTfrNumber: "",
      ChequeNumber: "",
      ChequeAmount: "",
    })
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }

  CreditTypePost() {
    try {
      this.lodaerFunction(true)
      let data = {
        "patient_id": this.state.AdmissionDetails?.patient_id,
        "module_id": this.state.AdmissionDetails?.ip_admission_id,
        "module_type": "In Patient",
        "credit_type": this.state.corporatePay === "Insurance" ? "Insurance credit" : this.state.corporatePay === "Corporate" ? "Corprate credit" : "Patient credit",
      }
      RestAPIService.create(data, Serviceurls.CREDIT_TYPE_CHANGE).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({ DisableBtn : false })
            this.successMessage("IP Admission Created Succesfully")
            this.lodaerFunction(false)
            setTimeout(() => { this.props.history.push({ pathname: "/IPHome" }) }, 1500)
          }
          else {
            this.lodaerFunction(false)
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.lodaerFunction(false)
          if (error?.response?.data?.status) {
            this.errorMessage(error.response?.data?.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  render() {
    try {
      let { t } = this.props
      return (
        <Box component={"div"} className='eMed_Ipappoint_container'>
          <Grid container spacing={1} className='eMed_IPappoint_content' >
            <Grid item xs={8} sx={{ padding: 0 }}>
              {this.renderComponentLeft()}
            </Grid>
            <Grid item xs={4}>
              {this.renderComponentRight()}
            </Grid>
            <Grid item xs={12}>
              <Box className='eMed_Ipappoint_btn_div'>
                <Button id="eMed_invd_Button" size='small' variant='outlined' emed_tid = "AdmissionDetails_Back_TestID" onClick={() => { this.props.BactToAdmissionDetials(this.state.AdmissionDetails) }}>{t("Back")}</Button>
                <Button id="eMed_invd_Button" size='small' variant='outlined' emed_tid = "ClearData_TestID" onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
                <Button id="eMed_invd_Button" emed_tid="Billing_Complete_Btn" size='small' sx={{ width: "10vw" }} variant='contained' disabled={this.state.isLoader || this.state.DisableBtn} onClick={() => {
                  this.setState({
                    DisableBtn : true
                  }, ()=>{
                    if ((this.state.PayableAmount === "" || this.state.PayableAmount == 0)) {
                      if (this.state.corporatePay === "") {
                        this.successMessage("IP Admission Created Succesfully")
                        setTimeout(() => { this.props.history.push({ pathname: "/IPHome" }) }, 1500)
                      } else {
                        this.CreditTypePost()
                      }
                    } else {
                      this.receivePayment("")
                    }
                  })
                }}>{(this.state.PayableAmount === "" || this.state.PayableAmount == 0) ? t("Complete") : "Receive & Complete"}</Button>
              </Box>
            </Grid>
          </Grid>
          {this.state.isErrorMsg ?
            <ToastMsg
              severity={'error'}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.successMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.successMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          <Loader loaderOpen={this.state.isLoader} />
        </Box>
      )
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }
}
export default withTranslation()(IPBilling)

