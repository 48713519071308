import { Autocomplete, Box, Button, Drawer, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import '../reports.css';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../../Utility/ImagePaths';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import { CommonGridToolBarWithFilterText, CommonPatientDetails, CommonTimeRangePicker } from "../../../../Components/Common Components/CommonComponents";
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { AmountFormat, formatDate, timeOnlyConvert } from "../../../../Components/CommonFunctions/CommonFunctions";
import { DateTime } from 'luxon';
import { CurrencySymbol } from '../../../../Utility/Constants';
import Loader from '../../../../Components/Loader';


class IPLegerReport extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            legerData: [],
            BedList: [],
            BedList_dup: [],
            FloorList: [],
            FloorList_View: [],
            wardType: null,
            blockType: null,
            floorType: null,
            BlockList_view: [],
            BlockList: [],
            creditType: '',
            printLoading: false,
            disableBtn: false,
        }
    }

    componentDidMount() {
        this.getLegerDetails()
        this.GetBedList()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    onChangeHandler = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    GetBedList = () => {
        try {
            RestAPIService.getAll(Serviceurls.LEGER_REPORT_BED_AVAILABLE_GET).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            BedList: this.RemoveDuplicates(response.data.data, 'ward_id'),
                            BedList_dup: response.data.data,
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        }
        catch (error) {
            if (error?.response?.data?.status === "fail") {
                this.errorMessage(error.response.data.message)
            } else {
                this.errorMessage(error.message)
            }
        }
    }

    getLegerDetails = () => {
        try {
            this.LoaderFunction(true)
            let Ward = this.state.wardType?.ward__ward_name ? this.state.wardType?.ward__ward_name : ''
            let Block = this.state.blockType?.block ? this.state.blockType?.block : ''
            let Floor = this.state.floorType?.floor ? this.state.floorType?.floor : ''
            RestAPIService.getAll(Serviceurls.FO_REPORTS_IP_LEGER_REPORT + `?block=${Block}&floor=${Floor}&ward=${Ward}&credit_type=${this.state.creditType}`).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            legerData: response.data.data,
                            serviceTotalAmount: response.data.total_amount,
                        },()=>{this.LoaderFunction(false)})
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    IPLegerReportPrint = () => {
        try {
            let Ward = this.state.wardType?.ward__ward_name ? this.state.wardType?.ward__ward_name : ''
            let Block = this.state.blockType?.block ? this.state.blockType?.block : ''
            let Floor = this.state.floorType?.floor ? this.state.floorType?.floor : ''
            this.setState({disableBtn: true, printLoading: true})
            RestAPIService.getAll(Serviceurls.FO_REPORTS_IP_LEGER_REPORT + `?block=${Block}&floor=${Floor}&ward=${Ward}&credit_type=${this.state.creditType}&export_type=pdf`)
                .then((response) => {
                    if (response) {
                        //Create a Blob from the PDF Stream
                        const file = new Blob(
                            [response.data],
                            { type: 'application/pdf' });
                        //Build a URL from the file
                        const fileURL = URL.createObjectURL(file);
                        //Open the URL on new Window
                        window.open(fileURL);
                        this.setState({disableBtn: false, printLoading: false})
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                    this.setState({disableBtn: false, printLoading: false})
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({disableBtn: false, printLoading: false})
        }
    }

    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }

    RemoveDuplicates(Array, key) {
        const FilteredArray = Array.reduce((accumulator, current) => {
            if (!accumulator.find((item) => item[key] === current[key])) {
                accumulator.push(current);
            }
            return accumulator;
        }, []);

        return FilteredArray
    }

    renderFilterScreen = () => {
        const { t } = this.props
        let wardData = []
        wardData = this.state.BedList
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button emed_tid = {"LabCloseButton_TestID"}  onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            options={wardData}
                            getOptionLabel={(option) => (option?.ward__ward_name)}
                            value={this.state?.wardType}
                            onChange={(event, newValue) => {
                                this.setState({
                                    wardType: newValue,
                                    blockType: null,
                                    floorType: null,
                                }, () => {
                                    var states = this.state
                                    let block = this.RemoveDuplicates(states.BedList_dup.filter((item) => item.ward_id === states?.wardType?.ward_id), 'block_no_id')
                                    let block_view = states.BedList_dup.filter((item) => item.ward_id === states?.wardType?.ward_id)
                                    this.setState({
                                        BlockList: block,
                                        BlockList_view: block_view
                                    })
                                })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{ ...params.inputProps,'emed_tid': 'select_ward' }}
                                    label={t('Select Ward')}
                                    emed_tid = {"Ward_TestID"}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            disabled={this.state.BlockList?.length === 0}
                            options={this.state?.BlockList}
                            getOptionLabel={(option) => (option?.block)}
                            value={this.state.BlockList?.length > 0 ? this.state?.blockType : null}
                            onChange={(event, newValue) => {
                                this.setState({
                                    blockType: newValue,
                                    floorType: null,
                                }, () => {
                                    var states = this.state
                                    let floor = this.RemoveDuplicates(states.BedList_dup?.filter((item) => (item.ward_id === states.wardType.ward_id && item.block_no_id === states.blockType?.block_no_id)), 'floor')
                                    let floor_view = states.BedList_dup?.filter((item) => (item.ward_id === states.wardType.ward_id && item.block_no_id === states.blockType?.block_no_id))
                                    this.setState({
                                        FloorList: floor,
                                        FloorList_View: floor_view
                                    })

                                })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{ ...params.inputProps,'emed_tid': 'select_block' }}
                                    label={t('Select Block')}
                                    emed_tid = {"SSelect Block_TestID"}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            size='small'
                            disabled={this.state.FloorList?.length === 0}
                            options={this.state?.FloorList}
                            getOptionLabel={(option) => (option?.floor)}
                            value={this.state.FloorList?.length > 0 ? this.state?.floorType : null}
                            onChange={(event, newValue) => {
                                this.setState({
                                    floorType: newValue,
                                })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{ ...params.inputProps,'emed_tid': 'select_floor' }}
                                    label={t('Select Floor')}
                                    emed_tid = {"Select Floor_TestID"}
                                />
                            )}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <FormControl size='small' fullWidth>
                            <InputLabel id="demo-simple-select-label">{'Select Credit Type'}</InputLabel>
                            <Select
                                emed_tid = {"Select Credit Type_TestID"}
                                labelId="demo-simple-select-label"
                                id="demo-simple-select"
                                value={this.state.creditType}
                                label="Select Credit Type"
                                inputProps={{ emed_tid: 'credit_type'}}
                                onChange={(event)=>{
                                    this.setState({
                                        creditType: event.target.value
                                    })
                                }}
                            >
                                <MenuItem value={'Patient credit'}>{'Patient Credit'}</MenuItem>
                                <MenuItem value={'Insurance credit'}>{'Insurance Credit'}</MenuItem>
                                <MenuItem value={'Corprate credit'}>{'Corporate Credit'}</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"  emed_tid = {"getLegerDetails_TestID"}
                        onClick={() => {
                            this.setState({
                                wardType: null,
                                BlockList: [],
                                blockType: null,
                                FloorList: [],
                                floorType: null,
                                creditType: '',
                            }, () => this.getLegerDetails())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small" emed_tid = {"Search_TestID"}
                        onClick={() => {
                            this.getLegerDetails()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }
    gridToolBar = () => {


        return (
            <Box>
                <CommonGridToolBarWithFilterText />
            </Box>

        )
    }
    render() {
        this.state.legerData.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            {
                field: "patient_name", headerName: t("PatientDetails"), flex: 0.17,
                renderCell: (params) => (<Box component={'div'}>
                    <CommonPatientDetails disablePhoto={true} data={params?.row} />
                </Box>)
            },
            {
                field: "ip_number", headerName: t("AdmissionDetails"), flex: 0.17,
                renderCell: (params) => (<Box component={'div'}>
                    <Typography id='eMed_Com_Patient_Name'>
                        {params?.row?.ip_number?.length > 20 ?
                            <Tooltip placement='top' title={params?.row?.ip_number} arrow><div id='eMed_Com_Patient_Name'>{params?.row?.ip_number.slice(0, 20) + "..."}</div></Tooltip>
                            : params?.row?.ip_number ? params?.row?.ip_number : "-"}
                    </Typography>
                    <Typography id='eMed_Com_Patient_UhidText'>{(params?.row?.admission_date ? params?.row?.admission_date : "-") + (" | ") + (params?.row?.bed_number ? params?.row?.bed_number : "-")}</Typography>
                </Box>)
            },
            {
                field: "ip_total", headerName: `${t("IP")} (${CurrencySymbol})`, flex: 0.14,
                renderCell: (params) => (<Box component={'div'}>{params?.row ?
                    <Box sx={{ flexDirection: 'column' }}>
                        <Typography id='eMed_Com_Patient_Name'>{"Bill: "} {params?.row?.ip_total ? AmountFormat(params?.row?.ip_total).replace(`${CurrencySymbol}`, "") : "0"}</Typography>
                        <Typography id='eMed_Com_Patient_UhidText'>{"Room Rent: "} {params?.row?.room_rent ? AmountFormat(params?.row?.room_rent).replace(`${CurrencySymbol}`, "") : "0"}</Typography>
                    </Box>
                    : "-"}</Box>)
            },
            {
                field: "ip_admission_credit_balance", headerName: `${t("Credit Limit")} (${CurrencySymbol})`, flex: 0.11, headerAlign: "center", type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.ip_admission_credit_balance ? params?.row?.ip_admission_credit_balance : '0'}</Box>)
            },
            {
                field: "lab_total", headerName: `${t("Lab")} (${CurrencySymbol})`, flex: 0.10, headerAlign: "center", type:'number',
                renderCell: (params) => (<Box component={'div'}>{params?.row?.lab_total ? AmountFormat(params?.row?.lab_total).replace(`${CurrencySymbol}`, "") : "0"}</Box>)
            },
            {
                field: "pharmacy_balance", headerName: `${t("Pharmacy")} (${CurrencySymbol})`, flex: 0.13,
                renderCell: (params) => (<Box component={'div'}>{params?.row ?
                    <Box sx={{ flexDirection: 'column' }}>
                        <Typography id='eMed_Com_Patient_Name'>{"Bill: "} {params?.row?.pharmacy_bill ? AmountFormat(params?.row?.pharmacy_bill).replace(`${CurrencySymbol}`, "") : "0"}</Typography>
                        <Typography id='eMed_Com_Patient_UhidText'>{"Cr. Bal: "} {params?.row?.ip_pharmacy_credit_balance ? AmountFormat(params?.row?.ip_pharmacy_credit_balance).replace(`${CurrencySymbol}`, "") : "0"}</Typography>
                    </Box>
                    : "-"}</Box>)
            },
            {
                field: "grand_total", headerName: `${t("Total")} (${CurrencySymbol})`, flex: 0.11, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.grand_total ? AmountFormat(params?.row?.grand_total).replace(`${CurrencySymbol}`, "") : "0"}</Box>)
            },
            {
                field: "receipt_total", headerName: `${t("Received Amt")} (${CurrencySymbol})`, flex: 0.11, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.receipt_total ? AmountFormat(params?.row?.receipt_total).replace(`${CurrencySymbol}`, "") : "0"}</Box>)
            },
            {
                field: "primary_payment_type", headerName: `${t("Credit Type")}`, flex: 0.11, type: "string",
                renderCell: (params) => (params.row.primary_payment_type === 'Patient credit' ?  <Box component={'div'}>{params.row.primary_payment_type ? params.row.primary_payment_type : '-'}</Box> : 
                params.row.primary_payment_type === 'Insurance credit' ?  <Box component={'div'}>
                    <Typography>{params.row.primary_payment_type ? params.row.primary_payment_type : '-'}</Typography>
                    {params.row.insurance_company?.length > 12 ? <Tooltip title={params.row.insurance_company} placement='top'><Typography>{params.row.insurance_company.slice(0, 12) + '...'}</Typography></Tooltip> : <Typography>{params.row.insurance_company ? params.row.insurance_company : '-'}</Typography>}
                </Box> : params.row.primary_payment_type === 'Insurance credit' ?  <Box component={'div'}>
                    <Typography>{params.row.primary_payment_type ? params.row.primary_payment_type : '-'}</Typography>
                    {params.row.corporate_company?.length > 12 ? <Tooltip title={params.row.corporate_company} placement='top'><Typography>{params.row.corporate_company.slice(0, 12) + '...'}</Typography></Tooltip> : <Typography>{params.row.corporate_company ? params.row.corporate_company : '-'}</Typography>}
                </Box> : null)
            },
            {
                field: "balance", headerName: `${t("Balance")} (${CurrencySymbol})`, flex: 0.11, type: "number",
                renderCell: (params) => (<Box sx={{ color: (Math.sign(params?.row?.balance) == '-1') ? 'red' : (Math.sign(params?.row?.balance) == '1') ? 'green' : '' }} component={'div'}>{params?.row?.balance ? AmountFormat(params?.row?.balance).replace(`${CurrencySymbol}`, "") : "0"}</Box>)
            }
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {/* {AmountsCard(t("TotalAmount"), this.state.serviceTotalAmount)} */}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' emed_tid = {"eMed_action_img_TestID"}  onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                        <Button className='eMed_usrconf_btn'  emed_tid = {"print_TestID"}  disabled={this.state.disableBtn} onClick={() => this.IPLegerReportPrint()}>
                            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                        </Button>
                        {/* <Button className='eMed_usrconf_btn'>
                            <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
                        </Button> */}
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={this.state.legerData}
                        columns={columns}
                        getRowId={(row) => row['sno']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={this.state.printLoading} forPrint={true} label={"Building PDF..."} />
            </Box>
        )
    }
}


export default withTranslation()(IPLegerReport)

