import { Autocomplete, Box, Button, Chip, Divider, Stack, TextField, Typography } from '@mui/material'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import '../../ClinicalNotes/DoctorNotes.css'
import { Colors } from '../../../../../../Styles/Colors';
import ToastMsg from '../../../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../../../Utility/API/Serviceurls';
import { DateTime } from 'luxon';
import { CheckAccessFunc } from '../../../../../../Components/CommonFunctions/CommonFunctions';
import { localGetItem } from '../../../../../../Utility/Services/CacheProviderService';

class DiagnosisRight extends Component {
    constructor(props) {
        super(props)
        this.state = {
            DiagnosisDate: new Date(),
            ProvDiagnosis: "",
            FrequentData: [],
            IcdSearchKey: "",
            ICDdatas: [],
            selecticd: null,
            withOutAppointment : this.props?.withOutAppointment,
        }
    }

    componentDidMount() {
        this.getICDcode()
        this.GetICDFrequents()
    }

    getICDcode = () => {
        try {
            RestAPIService.getAll(Serviceurls.GET_ICD_CODE + '?search_key=' + this.state.IcdSearchKey)
                .then(response => {
                    if (response.data.status === 'success') {
                        this.setState({
                            ICDdatas: response.data ? response.data.data : []
                        })
                    }
                }).catch(e => {
                    if (e.response?.data?.status === "fail") {
                        this.errorMessage(e.response.data.message)
                    } else {
                        this.errorMessage(e.message)
                    }
                })

        } catch (e) {
            this.errorMessage(e.message)
        }
    }

    GetICDFrequents() {
        let url = `${Serviceurls.IP_DOCNOTES_DIAGNOSIS_FREQUENT}`;
        if (this.props.is_From_IpNotes && this.props.clinic_id) {
            url = `${url}?clinic_id=${this.props.clinic_id}`
        }
        try {
            RestAPIService.getAll(url).
                then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            FrequentData: response.data.data
                        })
                    }
                    else {
                        this.errorMessage(response.data.message)
                    }
                }).catch((error) => {
                    this.errorMessage(error.message)
                })
        }
        catch (e) {
            this.errorMessage(e.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    ClearData() {
        this.setState({
            DiagnosisDate: new Date(),
            ProvDiagnosis: "",
            IcdSearchKey: "",
            ICDdatas: [],
            selecticd: null,
        })
    }

    PostExamination = () => {
        try {
            var states = this.state
            const myUser = JSON.parse(localGetItem("loggedInUserInfo"));
            let date = new Date(states.DiagnosisDate)
            const year = date.getFullYear();
            const month = String(date.getMonth() + 1).padStart(2, '0');
            const day = String(date.getDate()).padStart(2, '0');
            const hours = String(date.getHours()).padStart(2, '0');
            const minutes = String(date.getMinutes()).padStart(2, '0');
            const seconds = String(date.getSeconds()).padStart(2, '0');
            let newDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;           
            var data = {
                "icd_code_id": states.selecticd?.icd_code,
                "clinic_id": this.props.clinic_id,
                "doctor_id": this.props.is_From_IpNotes ? myUser?.doctor_id : this.props?.IPadmissionDetails?.doctor_id,               
                "ip_admission_id": this.props?.IpPatientData?.id,
                "diagnosis_date": newDate
            }

            RestAPIService.create(data, Serviceurls.IP_DOCNOTES_DIAGNOSIS).
                then((response) => {
                    if (response.data.status === "success") {
                        this.successMessage(response.data.message)
                        this.ClearData()
                        this.GetICDFrequents()
                        this.props.CallgetApi('isDiagnosis')
                    } else {
                        this.errorMessage(response.data.message)
                    }
                }).catch(error => {
                    if (error?.response?.data?.status === "fail") {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }
    msgClose(){
        this.setState({
          isErrorMsg: false,
          isErrorMsgText: '',
          successMsg: false,
          successMsgText: ''
        })
      }
    

    render() {
        const { t, IpPatientData, IPadmissionDetails } = this.props
        var states = this.state;
        const checkuserAccess = this.props.is_From_IpNotes ? false :
            CheckAccessFunc("front_office", "In Patients", "Clinical Notes", "Doctor Notes", "SubTab")?.editAccess ? this.state.withOutAppointment?.is_ip : true;

        const currentDate = new Date();
        const admissionDate = IPadmissionDetails?.admitted_date ? new Date(IPadmissionDetails?.admitted_date)
            : IpPatientData?.admission_date ? new Date(IpPatientData?.admission_date)
                : currentDate;
        const dischargeDate = IpPatientData?.discharge_date && (new Date(IpPatientData?.discharge_date) <= currentDate) ?
            new Date(IpPatientData?.discharge_date) : currentDate;

        return (
            <Box>
                <Box height={"52dvh"} sx={{ overflowY: 'scroll' }}>
                    <Typography className='eMed_refdoc_title'>{t("Diagnosis")}</Typography>
                    <Box>
                        <Autocomplete
                            noOptionsText={states.IcdSearchKey === '' ? 'Please Search...' : 'No Data Found...'}
                            options={states.ICDdatas}
                            getOptionLabel={(options) => options.icd_code + " | " + options.icd_short_desc + " | " + options.icd_desc}
                            onChange={(e, value) => {
                                this.setState({
                                    testRow: value,
                                    selecticd: value
                                })
                            }}
                            value={states.selecticd}
                            size='small'
                            className='eMed_DocNotes_icd_TxtBox'
                            disablePortal
                            id="combobox-demo"
                            renderInput={(params) => <TextField onChange={(event) => {
                                const value = event.target.value
                                if (value === "" || value != "") { this.setState({ IcdSearchKey: value }, () => { this.getICDcode() }) }
                            }} {...params} placeholder="Enter ICD Code / Short Description / Long Description" 
                            inputProps={{...params.inputProps,'emed_tid': 'Enter_ICD_Code', }}
                            />}
                        />
                    </Box>
                    <Divider textAlign="left" sx={{ marginTop: '0.5vw' }}><Typography sx={{ fontSize: '0.8vw' }}>ICD Codes</Typography></Divider>
                    <Box className="eMed_DocNotes_FreqChips_Wrapp">
                        {
                            this.state.FrequentData.length > 0 ? this.state.FrequentData.map((item, index) => (
                                <Chip
                                    size='small'
                                    className="eMed_DocNotes_FreqChip"
                                    key={index}
                                    emed_tid = {item.icd_code}
                                    label={item.icd_code}
                                    color='primary'
                                    variant={this.state.selecticd?.icd_code === item?.icd_code ? 'contained' : "outlined"}
                                    onClick={() => { this.setState({ selecticd: item }) }}
                                />
                            )) : null
                        }
                    </Box>
                    <Divider textAlign="left" sx={{ marginTop: '0.5vw' }}><Typography sx={{ fontSize: '0.8vw' }}>Short Description</Typography></Divider>
                    <Box className="eMed_DocNotes_FreqChips_Wrapp">
                        {
                            this.state.FrequentData.length > 0 ? this.state.FrequentData.map((item, index) => (
                                <Chip
                                    size='small'
                                    className="eMed_DocNotes_FreqChip"
                                    key={index}
                                    label={item.icd_short_desc}
                                    emed_tid = {item.icd_short_desc}
                                    color='primary'
                                    variant={this.state.selecticd?.icd_code === item?.icd_code ? 'contained' : "outlined"}
                                    onClick={() => { this.setState({ selecticd: item }) }}
                                />
                            )) : null
                        }
                    </Box>
                    <Divider textAlign="left" sx={{ marginTop: '0.5vw' }}><Typography sx={{ fontSize: '0.8vw' }}>Long Description</Typography></Divider>
                    <Box className="eMed_DocNotes_FreqChips_Wrapp">
                        {
                            this.state.FrequentData.length > 0 ? this.state.FrequentData.map((item, index) => (
                                <Chip
                                    size='small'
                                    className="eMed_DocNotes_FreqChip"
                                    key={index}
                                    label={item.icd_desc}
                                    emed_tid = {item.icd_desc}
                                    color='primary'
                                    variant={this.state.selecticd?.icd_code === item?.icd_code ? 'contained' : "outlined"}
                                    onClick={() => { this.setState({ selecticd: item }) }}
                                />
                            )) : null
                        }
                    </Box>


                    <Box component={'div'} sx={{ backgroundColor: Colors.ThemeLightColor, marginLeft: "1.5vw", marginBottom: '0.5vw' }} className='eMed_DocNotes_DateTime' >
                        <Typography marginLeft={'0.5vw'} color='#616161' fontWeight={'600'}>{t("DiagnosisDate")}</Typography>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                                inputFormat='DD-MM-YYYY & hh:mm A'
                                value={this.state.DiagnosisDate}
                                minDate={admissionDate}
                                maxDate={dischargeDate}
                                onChange={(newDate) => { this.setState({ DiagnosisDate: newDate }) }}
                                renderInput={(params) => <TextField size='small'
                                    {...params}
                                    fullWidth
                                    variant='standard'
                                    className='eMed_DocNotes_datePic_Textfield'
                                    inputProps={{ ...params.inputProps,'emed_tid': 'DiagnosisDate_TestID_OP'}}
                                />}
                            />
                        </LocalizationProvider>
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"6vh"}>
                    <Button emed_tid = {"Clear_testID001"} variant='outlined' disabled = {checkuserAccess} size="small" id="eMed_Config_btn" onClick={() => { this.ClearData() }}>{t("Clear")}</Button>
                    <Button emed_tid = {"Add_testID002"} variant='contained' disabled = {checkuserAccess || !states.selecticd } size="small" id="eMed_Config_btn" onClick={() => { this.PostExamination() }}>{t("Add")}</Button>
                </Stack>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                {this.state.successMsg ?
                    <ToastMsg
                        severity={'success'}
                        msg={this.state.successMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
}
export default withTranslation()(DiagnosisRight)
