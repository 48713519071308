import * as React from 'react';
import { Typography, OutlinedInput, InputAdornment, FormControl, InputLabel, Select, Popover, Badge, ListItemText, Tab, Tabs, Autocomplete, Grid, Checkbox, FormControlLabel, FormGroup, Table, TableHead, TableRow, TableCell, TableBody, DialogTitle, DialogContent, TableContainer, SnackbarContent } from '@mui/material';
import { styled, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import { withRouter } from "react-router-dom";
import SearchIcon from '@mui/icons-material/Search';
import RouterComponent from '../Utility/RouterComponent';
import './NavbarApp.css';
import Avatar from '@mui/material/Avatar';
import MenuItem from '@mui/material/MenuItem';
import Tooltip from '@mui/material/Tooltip';
import Menu from '@mui/material/Menu';
import ExitToAppIcon from '@mui/icons-material/ExitToApp';
import { localGetItem, localClearAll, clearAllCachevalue, localClearItem, clearCachevalue, localSetItem, setCachevalue, getCachevalue } from '../Utility/Services/CacheProviderService';
import Dialog from '@mui/material/Dialog';
import { NewSideMenu } from '../Utility/SideMenuList';
import TextField from '@mui/material/TextField';
import { Trans, withTranslation } from 'react-i18next';
import { Colors } from "../Styles/Colors";
import Loader from '../Components/Loader';
import { AppBarMenus } from './AppBarMenus';
import Settings from '../Shell/MenuComponents/Settings';
import Button from '@mui/material/Button';
import { Modal, Paper } from '@mui/material';
import Success from '../Assets/Animation/Success.gif'
import DialogPop from '../Components/Dialog'
import { ImagePaths } from '../Utility/ImagePaths';
import Saved_Bill from '../Modules/Laboratory/Home/Saved_Bill';
import Transmit from '../Modules/Laboratory/Home/Transmit';
import { Serviceurls } from '../Utility/API/Serviceurls';
import RestAPIService from '../Utility/Services/RestAPIService';
import ToastMsg from '../Components/ToastMsg/ToastMsg';
import '../Styles/CommonTableStyles.css';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import GridViewIcon from '@mui/icons-material/GridView';
import CenterFocusWeakIcon from '@mui/icons-material/CenterFocusWeak';
import { CommonPatientDetails, CommonPopUp, DeletePopup } from '../Components/Common Components/CommonComponents';
import PersonAddAlt1Icon from '@mui/icons-material/PersonAddAlt1';
import Webcam from "react-webcam";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { CalculateAgeFromDob, formatDate, formatDateAndTimeMeth2, timeOnlyConvert } from "../Components/CommonFunctions/CommonFunctions";
import { Edit, HelpOutline } from '@mui/icons-material';
import { DateTime } from 'luxon';
import ErrorIcon from '@mui/icons-material/Error';
import { appointment_Status_opt, DOC_PRINT_DEFAULT, NO_Internet_Connection } from '../Utility/Constants';
import CloseIcon from '@mui/icons-material/Close';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import moment from 'moment';
import Prescription from '../Modules/Pharmacy/PharmacyHome/HeaderComponents/Prescription';
import SavedBill from '../Modules/Pharmacy/PharmacyHome/HeaderComponents/SavedBill';
import IPReturns from '../Modules/Pharmacy/PharmacyHome/HeaderComponents/IPReturns';
import IPRequest from '../Modules/Pharmacy/PharmacyHome/HeaderComponents/IPRequest';
import Appointments from '../Modules/Pharmacy/PharmacyHome/HeaderComponents/Appointments';
import CommonValidation from '../Components/CommonFunctions/CommonValidation';
import CircularProgress from '@mui/material/CircularProgress';
import { DateTimePicker } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import LabIPList from '../Modules/Laboratory/Home/LabIPList';
import { Close } from '@material-ui/icons';
import { DataGrid } from '@mui/x-data-grid';
import WarningIcon from '@mui/icons-material/Warning';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import DrQueuePopup from './MenuComponents/DrQueuePopup';
import { enqueueSnackbar, SnackbarProvider } from 'notistack';
import VisibilityIcon from '@mui/icons-material/Visibility';

const openedMixin = (theme) => ({
  width: '20vw',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(9)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: '20vw',
    width: `calc(100% - 20vw)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: '20vw',
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

let flag = true
class MiniDrawer extends React.Component {
  constructor(props) {
    super(props);
    this.webcamRef = React.createRef();
    this.state = {
      drawer_Open: false,
      anchorElUser: false,
      loggedInuserInfo: JSON.parse(localGetItem("loggedInUserInfo")),
      checked: false,
      globalSearch: '',
      menuId: null,
      selectedMenuId: null,
      selectedSubMenuId: null,
      isLoader: false,
      isSavedBill: false,
      isHelpdeskClicked: false,
      helpDescription: "",
      helpSubject: "",
      isTranmitedBill: false,
      searchkey: "",
      searchPatientDetails: {},
      selectedPageName: "",
      isPatientSearch: false,
      configSearch: '',
      selectedSubScreen: "",
      purchaseSearch: '',
      inventorySearch: "",
      reportSearch: "",
      clinicData: [],
      clinic_name: null,
      clinicId: null,
      DoctorsList: [],
      SelectedDoctor: 0,
      PatientSearchClicked: false,
      AddressFilterClicked: false,
      SearchedPatientList: [],
      PatientNameSearchKey: "",
      PatientAddressKey: "",
      PatientFathHusKey: "",
      SelectedIPDoctor: 0,
      temp_patient_img: null,
      patient_img: null,
      FoRequestList: [],
      logedUsername: "",
      LabPatientSearch: false,
      searchPatientDatas: [],
      selectedPatientData: {},
      filterDate: new Date(),
      datePicker: new Date(),
      isOnline: window.navigator.onLine,
      RequestPopUp: false,
      DatePickerOpen: false,
      ph_returns: false,
      ph_Prescrition: false,
      ph_SavedBill: false,
      ph_request: false,
      ph_Appointments: false,
      pharmaPatientList: [],
      pharmaSearchClicked: false,
      pharmaNameSearchKey: '',
      isPatientisSearching: false,
      SelectedRequestTab: "appointment_request",
      RequestMobileNumber: "",
      SearchedRequestPatientList: [],
      SelectedRequestPatient: {},
      RequestPatientMenuPop: false,
      doctorList: [],
      filteredDocList: [],
      spcInfo: [],
      RequestSelectedDocID: null,
      RequestSelectedSpecID: null,
      RequestPatientName: "",
      RequestPatientAge: "",
      RequestAppointDatetym: new Date(),
      POVList: [],
      RequestpurposeOfVisit: "",
      SelectedDoctorClinic: '',
      SearchDoctorPatientList: [],
      ClinicList: [],
      //Doctor Module Keys
      doctorAnchor: null,
      openDoctorPrint: false,
      all: true,
      booked: true,
      arrived: true,
      withNurse: true,
      withDoctor: true,
      completed: true,
      cancelled: true,
      doctorClinicId: '',
      doctorAppointmentDate: new Date(),
      doctorAppointmentAnchor: null,
      doctorAppointmentData: [],
      openDoctorAppointment: false,
      doctorTab: 'one',
      patientDetails: {},
      postLoad: false,
      doctorPatientAppointmentId: '',
      doctorPatientId: '',
      openDoctorNotesPrintAnchor: null,
      openDoctorNotesPrint: false,
      fullReport: false,
      symptoms: true,
      allergies: false,
      vitals: false,
      examination: false,
      diagnosis: true,
      prescription: true,
      prevPrescrip: false,
      test: false,
      plan: false,
      counselling: true,
      generalPatient: true,
      complaints: true,
      ocular: true,
      Illness: true,
      vision: true,
      gonioscopy: true,
      autoRefract: false,
      prevGlassPower: false,
      subjectiveRefract: false,
      retinoscopicRefract: false,
      IntraPressure: true,
      Anterior: true,
      AnteriorImg: true,
      Posterior: true,
      fundus: false,
      Keratometry: false,
      medication: true,
      glassPrescrib: true,
      Review: true,
      Treatment: true,
      investigation: true,
      OtherInvestigation: true,
      editAppointmentId: null,
      isClked: false,
      isappointRequest: false,
      editPatientId: null,
      docDateOpen: false,
      docSelectedRequestTab: 'appointment_request',
      docDatePickerOpen: false,
      docRequestMobileNumber: "",
      docSelectedRequestPatient: {},
      docRequestSelectedDocID: null,
      docRequestSelectedSpecID: null,
      docRequestPatientAge: "",
      docRequestPatientName: "",
      docRequestAppointDatetym: new Date(),
      docRequestpurposeOfVisit: "",
      docfilteredDocList: [],
      doceditAppointmentId: null,
      doceditPatientId: null,
      docisClked: false,
      docDatePicker: new Date(),
      isDocAppointRequest: false,
      docSearchedRequestPatientList: [],
      docRequestPatientMenuPop: false,
      docRequestPatientListAnchor: null,
      ph_IpPatientList: false,
      ipPatientListData: {},
      Lab_IpPatientList: false,
      App_DocID: null,
      isOPT: false,
      isOptometry: false,
      transferReqData: [],
      unlockDocData: [],
      followUpData: [],
      follow_up_date: new Date(),
      followDatePickerOpen: false,
      TransAppoint: false,
      OTrequestPopup: false,
      OTallocationPopup: false,
      OTreqData: [],
      allocationDetails: {},
      AcceptRequest: false,
      OTRoomData: [],
      selectedOT: null,
      selectedSlot: null,
      OTdetails: [],
      disableBtn: false,
      // Radiology Keys
      searchPatientRadiologyList: [],
      rad_request: false,
      rad_appointment: false,
      radAppAnchorEL: null,
      radReqAnchorEl: null,
      radAppointmentList: [],
      radIpList: [],
      searchRadAppointment: '',
      searchRadIpList: '',
      dupIpListData: '[]',
      dupAppointmentList: '[]',
      radAppointmentDate: new Date(),
      radIpListDate: new Date(),
      radDatePickerOpen: false,
      radSavedBillListData: [],
      dupRadSavedBill: '[]',
      rad_saved_bill: false,
      radSavedBillAnchcor: null,
      saveReason: false,
      removeData: false,
      selectedSavedId: null,

      overallNotes: false,
      deletePopup: false,
      selectedId: null,
      patientSearch: '',
      duplicateFoReqList: [],
      unlockDocDataCount: 0,
      PatientMotherKey: "",
      error: false,
      errorFathhus: false,
      labHistoryData: [],
      labHistoryPopup: false,
      labPatientData: null,
      page: 0,
      pageSize: 10,
      viewCultureData: [],
      isViewHistory: false,
      IdleAlertPopup: false,
      OcularResponse: false,
      common_uhid: false,
      rowsPerPage: 5,
      page: 0,
      badgeCount: {},
      FoNotifyList: [],
      notifyPopup: false,
      drQueueData: [],
      isInfoMsg: false,
      CallPatientTrigger: false,
      isInfoMsgText: '',

      // New Print States In Doctor Notes
      doctorPrintList: {},
      dupDocPrintList: '{}',
      printLoad: false,
      QrEntryList: [],
      QRPatientName: "",
      RegistrationDate: new Date(),
      QREntryPopUp: false,
      smsAlert: false,
      printOTApp: false,

      // radiology transmit 
      rad_transmit: false,
      radTransAnchorEL: null,
      radTransmitList: [],
      srchRadTranskey: "",
      radTransFltDate: new Date(),
      is_labOpList_clked: false,
      isFrom_fo_lab : false,
      transmitFlag : false,
      disableTransmitIcon : false,
    }
    setCachevalue(0, "SelectedDoctorID")
    setCachevalue(0, "SelectedIPDoctorID")
    this.IdleTimer = null;
    this.resetIdleTimer = this.resetIdleTimer.bind(this);
    this.AutoLogOutOnIdle = this.AutoLogOutOnIdle.bind(this);
    this.EmergencySocket = null;
    this.DocRunningLate = null;
    this.DocOutofOfficeLate = null;
    this.PaitentCall = null;
    this.DocCallPatient = null;
    this.FoToDocSocket = null;
    this.FoToDocTokenSocket = null;
  }
  /* Login UserName Api Function start  */
  componentDidMount() {
    this.startTimer()
    window.addEventListener('mousemove', this.resetIdleTimer);
    window.addEventListener('mousedown', this.resetIdleTimer);
    window.addEventListener('touchstart', this.resetIdleTimer);
    window.addEventListener('touchmove', this.resetIdleTimer);
    window.addEventListener('click', this.resetIdleTimer);
    window.addEventListener('keydown', this.resetIdleTimer);
    window.addEventListener('scroll', this.resetIdleTimer, true);
    if (window.location.pathname === "/") {
      this.logout()
    }
    if (localGetItem("isLoggedIn")) {

      if (window.location.pathname === "/") {
        this.logout()
      }
      let smsAlert = JSON.parse(localGetItem("show_sms_alert"))
      var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
      var UserData = myUser
      this.setState({
        loginName: UserData?.display_name,
        loginType: UserData?.module_name,
        labType: myUser?.laboratory_type,
        loggedInuserInfo: JSON.parse(localGetItem("loggedInUserInfo")),
        logedUsername: localGetItem("LogedUserName"),
        doctorClinicId: localGetItem('SelectedDoctorClinicId'),
        doctorAppointmentDate: localGetItem('DoctorAppointmentDate'),
        DoctorsList: localGetItem("DoctorsList") ? JSON.parse(localGetItem("DoctorsList")) : [],
        isOPT: UserData?.service_type === "OPT" ? true : false,
        vitals: UserData?.service_type === "OPT" ? true : false,
        isOptometry: UserData?.service_type === "OPTOMETRY" ? true : false,
        SelectedDoctorClinic: localGetItem('SelectedDoctorClinicId') ? localGetItem('SelectedDoctorClinicId') : (UserData?.service_type === "OPTOMETRY" ? "all" : ""),
        smsAlert: smsAlert ? smsAlert : false,
      }, () => {
        if ((this.state.labType === "Inhouse Laboratory") && (window.location.pathname === "/MainHome")) {
          this.getClinicdata()
        }
        if ((window.location.pathname === "/OPHome") || (window.location.pathname === "/IPHome")) {
          // this.getDoctorsList()
          this.getAppointRequests()
          this.getQREntryList()
        }
        if ((window.location.pathname === "/OPHome" || window.location.pathname === "/OTHome") && localGetItem("ipComplaintsDataList") === null) {
          this.getIPcomlaintsdata()
        }
        if (((window.location.pathname === "/OPHome") || (window.location.pathname === '/RadiologyHome')) && (localGetItem("noShowStatus") === null || localGetItem("Allow_Pharma_CreditBill_In_FO") === null || localGetItem("Show_Pharmacy_Bills_In_FO") === null)) {
          this.GetCustomConfig()
        }
        if ((window.location.pathname === "/DoctorHome")) {
          if (this.state.loggedInuserInfo?.module_name === "Doctor") {
            this.getDoctorClinicData()
            this.getTransferAppointmentData()
            this.getUnlockNotesData()
            this.getDoctorNotesPrintTemplate()
          } else if (this.state.loggedInuserInfo?.module_name === "Optometry") {
            this.getDoctorClinicData(true)
          }
        }
        if(window.location.pathname === '/DoctorHome/DoctorNotes'){
          this.getDoctorNotesPrintTemplate()
        }
        if (window.location.pathname === "/OTHome" && this.state.loggedInuserInfo?.module_name === "ot_management") {
          this.getotRequestList()
        }
      })
    }
    if (window.location.pathname === "/MainHome") {
      this.getBadgeCount()
    }
    if(((window.location.pathname === "/Billing/LabBilling") || (window.location.pathname === "/Billing") )){
      this.getBadgeCount()
    }
    if (((window.location.pathname === "/OPHome")) && localGetItem("PurposeOfVisit") === null) {
      this.getPurposeOfVisitList()
    }
    if ((window.location.pathname === "/DoctorHome") && localGetItem("PurposeOfVisit") === null) {
      this.getPurposeOfVisitList()
    }
    if ((window.location.pathname === "/RadiologyHome") && localGetItem("PurposeOfVisit") === null) {
      this.getPurposeOfVisitList()
    }
    if ((window.location.pathname === "/RadiologyHome")) {
      this.getRadIPListData()
      this.getRadAppointmentListData()
      this.getRadSavedBillList()
    }
    if(window.location.pathname === "/Billing/RadiologyBilling" || window.location.pathname === "/RadiologyHome"){
      this.getRadTransmitList()
    }
  }

  /* Login UserName Api Function end  */

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (window.location.pathname === "/") {
        this.logout()
      }
      if (this.state.unlockDate != new Date() && window.location.pathname != "/DoctorHome") {
        var currentDate = new Date();
        var formattedDate = currentDate.toLocaleDateString('en-GB', { year: 'numeric', month: '2-digit', day: '2-digit' });
        formattedDate = formattedDate.split("/").reverse().join("-")
        this.setState({
          unlockDate: formattedDate
        })
      }
      if (((window.location.pathname === "/Billing/LabBilling") || (window.location.pathname === "/Billing"))) {
        if(this.props?.history?.location?.state?.FromPatientBilling && !this.state.disableTransmitIcon){
          this.setState({ disableTransmitIcon: true })
        }else if ((!this.props?.history?.location?.state?.FromPatientBilling) && this.state.disableTransmitIcon) { 
          this.setState({ disableTransmitIcon: false }) 
        }
        this.getBadgeCount()
      }
      if (localGetItem("isLoggedIn")) {
        let smsAlert = JSON.parse(localGetItem("show_sms_alert"))
        var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
        var UserData = myUser
        this.setState({
          loginName: UserData?.display_name,
          loginType: UserData?.module_name,
          labType: myUser?.laboratory_type,
          loggedInuserInfo: JSON.parse(localGetItem("loggedInUserInfo")),
          logedUsername: localGetItem("LogedUserName"),
          DoctorsList: localGetItem("DoctorsList") ? JSON.parse(localGetItem("DoctorsList")) : [],
          isOPT: UserData?.service_type === "OPT" ? true : false,
          vitals: UserData?.service_type === "OPT" ? true : false,
          isOptometry: UserData?.service_type === "OPTOMETRY" ? true : false,
          SelectedDoctorClinic: localGetItem('SelectedDoctorClinicId') ? localGetItem('SelectedDoctorClinicId') : (UserData?.service_type === "OPTOMETRY" ? "all" : ""),
          smsAlert: smsAlert ? smsAlert : false
        }, () => {
          if (this.state.labType === "Inhouse Laboratory" && (window.location.pathname === "/MainHome")) {
            this.getClinicdata()
          }
          if ((window.location.pathname === "/OPHome") || (window.location.pathname === "/IPHome")) {
            // this.getDoctorsList()
          }
          if ((window.location.pathname === "/OPHome")) {
            this.getAppointRequests()
            this.getQREntryList()
            this.getBadgeCount()
            this.getFollowUpData()
            if(myUser?.is_qms_enabled) { 
              this.getNotificationData()
              this.getDoctorQueueData()
              this.WebSocketConnention() 
            }
          }
          if((window.location.pathname === "/OPHome") || (window.location.pathname === "/DoctorHome")){
            if(myUser?.is_qms_enabled) { this.FoCallPatientConnect() }
          }
          if ((window.location.pathname === "/OPHome") && localGetItem("PurposeOfVisit") === null) {
            this.getPurposeOfVisitList()
          }
          if ((window.location.pathname === "/OPHome" || window.location.pathname === "/OTHome") && localGetItem("ipComplaintsDataList") === null) {
            this.getIPcomlaintsdata()
          }
          if (((window.location.pathname === "/OPHome") || (window.location.pathname === '/RadiologyHome')) && (localGetItem("noShowStatus") === null || localGetItem("Allow_Pharma_CreditBill_In_FO") === null || localGetItem("Show_Pharmacy_Bills_In_FO") === null)) {
            this.GetCustomConfig()
          }
          if (((window.location.pathname === "/PharmacyBilling") || (window.location.pathname === "/SubLocationBilling" || window.location.pathname === "/RadiologyHome")) && Object.keys(this.state.badgeCount)?.length === 0) {
            this.getBadgeCount()
          }
          if ((window.location.pathname === "/DoctorHome")) {
            if (this.state.loggedInuserInfo?.module_name === "Doctor") {
              this.getDoctorNotesPrintTemplate()
              this.getDoctorClinicData()
              this.getTransferAppointmentData()
              this.getUnlockNotesData()
            } else if (this.state.loggedInuserInfo?.module_name === "Optometry") {
              this.getDoctorClinicData(true)
            }
          }
          if(window.location.pathname === '/DoctorHome/DoctorNotes'){
            this.getDoctorNotesPrintTemplate()
          }
          if ((window.location.pathname === "/DoctorHome") && localGetItem("PurposeOfVisit") === null) {
            this.getPurposeOfVisitList()
          }
          if ((window.location.pathname === "/RadiologyHome") && localGetItem("PurposeOfVisit") === null) {
            this.getPurposeOfVisitList()
          }
          if ((window.location.pathname === "/RadiologyHome")) {
            this.getRadIPListData()
            this.getRadAppointmentListData()
            this.getRadSavedBillList()
          }
          if (window.location.pathname === "/OTHome" && this.state.loggedInuserInfo?.module_name === "ot_management") {
            this.getotRequestList()
          }
          if (window.location.pathname === "/MainHome") {
            this.getBadgeCount()
          }
          if (((window.location.pathname === "/Billing") || (window.location.pathname === "/Billing/LabBilling"))) {
            this.getBadgeCount()
          }
          if(window.location.pathname === "/Billing/RadiologyBilling" || window.location.pathname === "/RadiologyHome"){
            this.getRadTransmitList()
          }
        })
      }
      this.setState({
        selectedPatientData: {}
      })
    }
    window.addEventListener("online", this.handleOnlineStatus);
    window.addEventListener("offline", this.handleOfflineStatus);
  }

  componentWillUnmount() {
    this.clearTimer();
    this.WebSocketDisconnect()
    window.removeEventListener('mousemove', this.resetIdleTimer);
    window.removeEventListener('mousedown', this.resetIdleTimer);
    window.removeEventListener('touchstart', this.resetIdleTimer);
    window.removeEventListener('touchmove', this.resetIdleTimer);
    window.removeEventListener('click', this.resetIdleTimer);
    window.removeEventListener('keydown', this.resetIdleTimer);
    window.removeEventListener('scroll', this.resetIdleTimer, true);
    window.removeEventListener("online", this.handleOnlineStatus);
    window.removeEventListener("offline", this.handleOfflineStatus);
  }

  WebSocketConnention = () => {
    let userData = JSON.parse(localGetItem("loggedInUserInfo"))
    let emergencyChannel = userData?.emergency_channel_name
    let docCallChannel = userData?.doctor_call_channel_name
    let lateByChannel = userData?.late_channel_name
    let outOfficeChannel = userData?.out_channel_name
    
    if(this.EmergencySocket === null){
      this.EmergencySocket = new WebSocket(`${Serviceurls.WEB_SOCKET_DOMAIN}${Serviceurls.DOC_EMERGENCY_WEBSOCKET}${emergencyChannel}/`);
    }
    if(this.DocRunningLate === null){
      this.DocRunningLate = new WebSocket(`${Serviceurls.WEB_SOCKET_DOMAIN}${Serviceurls.DOC_RUNNINGLATE_WEBSOCKET}${lateByChannel}/`);
    }
    if(this.DocOutofOfficeLate === null){
      this.DocOutofOfficeLate = new WebSocket(`${Serviceurls.WEB_SOCKET_DOMAIN}${Serviceurls.DOC_OUTOFOFFICE_WEBSOCKET}${outOfficeChannel}/`);
    }
    if(this.DocCallPatient === null){
      this.DocCallPatient = new WebSocket(`${Serviceurls.WEB_SOCKET_DOMAIN}${Serviceurls.DOC_PATIENTCALL_WEBSOCKET}${docCallChannel}/`);
    }

    const socketMapping = {
      EmergencySocket: this.EmergencySocket,
      DocRunningLate: this.DocRunningLate,
      DocOutofOfficeLate: this.DocOutofOfficeLate,
      DocCallPatient: this.DocCallPatient,
    };

    Object.entries(socketMapping)?.forEach(([name, socket]) => {
      if (socket) {
        socket.onopen = () => {
          console.log(`${name} WebSocket connected`);
        }
        socket.onclose = () => {
          console.log(`${name} WebSocket disconnected`);
        }
        socket.onerror = (error) => {
          console.log(`${name} WebSocket Error`, error);
          socket.close()
        }
        socket.onmessage = (e) => {
          const data = JSON.parse(e.data);
          if (data) {
            if ((name !== "DocCallPatient" || localGetItem("show_notification_banner_fo") === "true") && window.location.pathname !== "/DoctorHome") {
              enqueueSnackbar(data?.message)
            }
            if (name === "DocCallPatient") {
              this.getDoctorQueueData()
            }
            if (name === "EmergencySocket" || name === "DocRunningLate" || name === "DocOutofOfficeLate") {
              this.getNotificationData()
            }
          }
          console.log(`${name} WebSocket Message`, data);
        }
      }
    })
  }

  FoCallPatientConnect = () => {
    let userData = JSON.parse(localGetItem("loggedInUserInfo"))
    let callPatientChannel = userData?.fo_call_channel_name
    let tokenScreen = userData?.token_screen_channel_name
    
    if(this.PaitentCall === null){
      this.PaitentCall = new WebSocket(`${Serviceurls.WEB_SOCKET_DOMAIN}${Serviceurls.FO_CALLPATIENT_WEBSOCKET}${callPatientChannel}/`);
    }
    if (this.FoToDocTokenSocket === null && tokenScreen) {
      this.FoToDocTokenSocket = new WebSocket(`${Serviceurls.WEB_SOCKET_DOMAIN}${Serviceurls.TOKENSCREEN_WEBSOCKET}${tokenScreen}/`);
    }

    this.PaitentCall.onopen = () => {
      console.log(`Patien Call WebSocket connected`);
    }
    this.PaitentCall.onclose = () => {
      console.log(`Patien Call WebSocket disconnected`);
    }
    this.PaitentCall.onerror = (error) => {
      console.log(`Patien Call WebSocket Error`, error);
      this.PaitentCall.close()
    }
    this.PaitentCall.onmessage = (e) => {
      const data = JSON.parse(e.data);
      if (data && this.state.loggedInuserInfo?.module_name === "Doctor" && data?.doctor_id === userData?.doctor_id) {
        this.infoMessage(data?.message)
        if (data?.status === "Cancelled") {
          if (this.FoToDocTokenSocket && tokenScreen) {
            this.FoToDocTokenSocket.send(JSON.stringify({
              message_id: data?.message_id,
              action: "remove_message"
            }))
          }
          this.setState({
            CallPatientTrigger: true
          })
        }
      }
      console.log(`Patien Call WebSocket Message`, data);
    }
  }

  WebSocketDisconnect = () => {
    let userData = JSON.parse(localGetItem("loggedInUserInfo"))
    if (userData?.is_qms_enabled) {
      
      if(this.EmergencySocket){
        this.EmergencySocket.close()
        this.EmergencySocket = null
      }
      if(this.DocRunningLate){
        this.DocRunningLate.close()
        this.DocRunningLate = null
      }
      if(this.DocOutofOfficeLate){
        this.DocOutofOfficeLate.close()
        this.DocOutofOfficeLate = null
      }
      if(this.PaitentCall){
        this.PaitentCall.close()
        this.PaitentCall = null
      }
      if(this.DocCallPatient){
        this.DocCallPatient.close()
        this.DocCallPatient = null
      }
      if(this.FoToDocSocket){
        this.FoToDocSocket.close()
        this.FoToDocSocket = null
      }
      if(this.FoToDocTokenSocket){
        this.FoToDocTokenSocket.close()
        this.FoToDocTokenSocket = null
      }
    }
  }

  startTimer() {
    if (this.state.IdleAlertPopup) {
      this.setState({
        IdleAlertPopup: false
      })
    }
    let customLogoutTime = localGetItem("auto_log_out_time") ? +localGetItem("auto_log_out_time") : 15
    this.IdleTimer = setTimeout(this.AutoLogOutOnIdle, customLogoutTime * 60 * 1000); // auto logout on 15 mins idle
    this.IdleAlertTimer = setTimeout(() => { this.setState({ IdleAlertPopup: true }) }, (customLogoutTime - 3) * 60 * 1000); // alert popup on 12 mins idle
  }

  resetIdleTimer() {
    clearTimeout(this.IdleTimer);
    clearTimeout(this.IdleAlertTimer);
    this.startTimer();
  }

  clearTimer() {
    clearTimeout(this.IdleTimer);
    clearTimeout(this.IdleAlertTimer);
  }

  AutoLogOutOnIdle() {
    if (window.location.pathname === "/") {
      this.clearTimer()
    } else {
      this.logout()
      this.props?.history.push('/')
    }
  }

  handleOnlineStatus = () => {
    this.setState({ isOnline: true });
  };

  handleOfflineStatus = () => {
    this.setState({ isOnline: false });
  };

  cancelTransRequest = (id, status) => {
    const { history } = this.props;
    try {
      var data = {
        "id": id,
        "status": status
      }
      RestAPIService.updateWithOutId(data, Serviceurls.DOC_TRANSFER_REQUEST).
        then((response) => {
          if (response.data.status === "success") {
            this.successMessage(response.data.message)
            this.setState({
              TransAppoint: false
            }, () => {
              this.getTransferAppointmentData()
              history.push({ pathname: "/DoctorHome", state: { NewAppointmentCreated: true, NewMessage: response.data?.message, reload: true } })
            })
          } else {
            this.errorMessage(response.data.message)
          }
        }).catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  removeDataList = (item) => {
    let data = {
      doctor_id: item?.doctor_id ? item?.doctor_id : null
    }
    try {
      RestAPIService.create(data, Serviceurls.DOC_FOLLOWUP_DLT + item?.id + "/").
        then((response) => {
          if (response.data.status === "success") {
            this.getFollowUpData();
          } else {
            this.errorMessage(response.data.message)
          }
        })
        .catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message);
          }
        });
    } catch (e) {
      this.errorMessage(e.message, false);
    }
  }

  getBadgeCount = () => {
    try {
      let moduleName = this.state.loggedInuserInfo?.module_name ? this.state.loggedInuserInfo?.module_name : ''
      let clinicId = this.state.loggedInuserInfo?.clinic_id ? this.state.loggedInuserInfo?.clinic_id : null
      RestAPIService.getAll(Serviceurls.FO_BADGE_COUNT_GET + `?module_name=${moduleName}&clinic_id=${clinicId}`)
        .then((response) => {
          if (response?.data?.status === "success") {
            this.setState({
              badgeCount: response?.data?.data ? response?.data?.data : 0,
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.errorMessage(error?.message)
          }
        })

    } catch (e) {
      this.errorMessage(e?.message)
    }
  }

  getDoctorsList(ForRequest = false) {
    try {
      let API_URL = ""
      if (ForRequest) {
        var date = new Date(this.state.RequestAppointDatetym)
        var appointment_date = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')
        API_URL = Serviceurls.FO_CONFIG_OPDOCTORNAME + "?date=" + appointment_date
      }
      //  else {
      //   API_URL = Serviceurls.FO_CONFIG_OPDOCTORNAME
      // }
      RestAPIService.getAll(API_URL).
        then((response) => {
          if (response.data.status === "success") {
            if (ForRequest) {
              this.setState({
                doctorList: response.data.data.filter((item) => (item.specialization_active === true))
              })
            }
            //  else {
            //   this.setState({
            //     DoctorsList: response.data.data.filter((item) => (item.specialization_active === true))
            //   })
            // }
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }
  getIPcomlaintsdata() {
    try {
      RestAPIService.getAll(Serviceurls.IP_COMPLAINTS_DATASET).
        then((response) => {
          if (response.data.status === "success") {
            let Array = []
            response.data.data.forEach((element) => { Array.push(element.reason) })
            localSetItem("ipComplaintsDataList", JSON.stringify(Array))
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  getQREntryList = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_QR_ENTRY_LIST + `?patient_name=${this.state.QRPatientName}&date=${DateTime.fromJSDate(new Date(this.state.RegistrationDate)).toFormat('yyyy-MM-dd')}`).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              QrEntryList: response?.data?.data
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }


  GetCustomConfig() {
    try {
      RestAPIService.getAll(Serviceurls.CUSTOM_CONFIG).
        then((response) => {
          if (response.data.status === "success") {
            localSetItem("noShowStatus", response.data.data?.allow_no_show_update ? response.data.data?.allow_no_show_update : false);
            localSetItem("Allow_Pharma_CreditBill_In_FO", response.data.data?.allow_fo_pharmacy_bill_complete ? response.data.data?.allow_fo_pharmacy_bill_complete : false);
            localSetItem("Show_Pharmacy_Bills_In_FO", response.data.data?.show_pharmacy_bills_in_fo ? response.data.data?.show_pharmacy_bills_in_fo : false);
            localSetItem("allow_billing_date_fo", response.data.data?.allow_billing_date_fo ? response.data.data?.allow_billing_date_fo : false);
            localSetItem("allow_billing_date_range", response.data.data?.allow_billing_date_range ? response.data.data?.allow_billing_date_range : "Default");
            localSetItem("default_PayFromAdvance_On_Checkout", response.data.data?.default_pay_from_advance_for_checkout ? response.data.data?.default_pay_from_advance_for_checkout : false);
            localSetItem("allow_doctor_fees_split_up", response.data.data?.allow_doctor_fees_split_up ? response.data.data?.allow_doctor_fees_split_up : false);
            localSetItem("Copy_IP_Mediction_To_DischargeSummery", response.data.data?.copy_ip_medication_to_discharge_summary ? response.data.data?.copy_ip_medication_to_discharge_summary : false);
            localSetItem('day_end_value', response.data.data?.day_end_type ? response.data.data?.day_end_type : 1)
            localSetItem("Show_Radiology_Bills_In_FO", response.data.data?.block_radiology_bills_in_fo ? response.data.data?.block_radiology_bills_in_fo : false)
            localSetItem('billing_concession_type', response.data.data?.billing_concession_type ? response.data.data?.billing_concession_type : 1)
            localSetItem('op_ip_service_code',response.data.data?.op_ip_service_code ? response.data.data?.op_ip_service_code : false)
            localSetItem('appointment_status',JSON.stringify(response.data.data?.appointment_status ? response.data.data?.appointment_status : appointment_Status_opt))
            localSetItem('lab_test_code',response.data.data?.lab_test_code ? response.data.data?.lab_test_code : false)
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }
  getAppointRequests(Clicked = false) {
    try {
      let DocId = this.state.App_DocID?.doctor_id ? this.state.App_DocID?.doctor_id : ''
      RestAPIService.getAll(Serviceurls.FO_APPOINT_REQUESTS + "?date=" + DateTime.fromJSDate(new Date(this.state.datePicker)).toFormat('yyyy-MM-dd') + '&doctor_id=' + DocId).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              FoRequestList: response.data.data ? response.data.data : [],
              duplicateFoReqList: response?.data?.data,
              // SelectedRequestTab: "appointment_request",
            }, () => {
              if (this.state.patientSearch) {
                this.handlePatientSearch()
              }
              // if (Clicked && response.data.data.length === 0) {
              //   this.errorMessage("No Records To Be Shown")
              // }
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
      if (Clicked) {
        RestAPIService.getAll(Serviceurls.FO_CONFIG_OPSPECIALIZATION)
          .then(response => {
            if (response.data.status === "success") {
              this.setState({
                spcInfo: response.data.data.filter((item) => (item.is_active === true)),
              });
            }
          })
          .catch(error => {

          });
        this.getDoctorsList(true)
      }
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  getDoctorQueueData = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_DOC_QUEUE_GET + `?appointment_date=${DateTime.fromJSDate(new Date(this.state.datePicker))?.toFormat('yyyy-MM-dd')}`)
        .then((response) => {
          if (response?.data?.status === "success") {
            this.setState({
              drQueueData: response.data.data ? response.data.data : []
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response?.data?.message)
          } else {
            this.errorMessage(error?.message)
          }
        })
    } catch (e) {
      this.errorMessage(e?.message)
    }
  }

  getNotificationData = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_NOTIFICATION_GET + `?date=${DateTime.fromJSDate(new Date(this.state.datePicker)).toFormat('yyyy-MM-dd')}`)
        .then((response) => {
          if (response?.data?.status === "success") {
            this.setState({
              FoNotifyList: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response?.data?.message)
          } else {
            this.errorMessage(error?.message)
          }
        })
    } catch (e) {
      this.errorMessage(e?.message)
    }
  }

  getAppointRequestsPrint = () => {
    try {
      let DocId = this.state.App_DocID?.doctor_id ? this.state.App_DocID?.doctor_id : ''
      let LineItemIDs = []
      this.state.FoRequestList.forEach((list) => {
        LineItemIDs.push(list?.id)
      })
      RestAPIService.getAll(`${Serviceurls.FO_APPOINT_REQUESTS}?date=${DateTime.fromJSDate(new Date(this.state.datePicker)).toFormat('yyyy-MM-dd')}&doctor_id=${DocId}&ids=${LineItemIDs}&export_type=pdf`).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          this.errorMessage(error.response?.data?.message)
        })
    } catch (error) {
      this.errorMessage("Error occured on print")
    }
  }

  resetState = () => {
    this.setState({
      drawer_Open: false,
      anchorElUser: false,
      loggedInuserInfo: "",
      checked: false,
      globalSearch: '',
      menuId: null,
      selectedMenuId: null,
      selectedSubMenuId: null,
      isLoader: false,
      selectedPageName: "",
      searchkey: "",
      searchPatientDatas: [],
      selectedPatientData: {},
      pharmaNameSearchKey: '',
      PatientNameSearchKey: '',
      PatientFathHusKey: '',
      PatientAddressKey: '',
      PatientMotherKey: '',
      IdleAlertPopup: false,
      common_uhid: false,
      // unit : 10,
    })
  }
  Menuitem = (id) => {
    this.setState({
      meunId: id
    })
  }
  handleChange = () => {
    this.setState({
      checked: true
    })
  };

  handleDrawerOpen = () => {
    this.setState({
      drawer_Open: true,

    })
  };
  handleDrawerClose = () => {
    this.setState({
      drawer_Open: false,
    })
  };
  handleOpenUserMenu = (e) => {
    this.setState({
      anchorElUser: true,
      RequestAnchor: e.currentTarget
    })
  };
  handleCloseUserMenu = (event) => {
    this.setState({
      anchorElUser: false
    })
  };
  logout = () => {
    this.resetState();
    localClearAll();
    clearAllCachevalue();
  }

  /*Routing and Loader Functions Start*/
  onClick = () => {
    setTimeout(() => {
      this.setState({
        isLoader: false,
      })
    }, 1000)
    if (this.state.selectedMenuId == 3) {
      var open = this.state.checked
      this.setState({
        checked: !open
      })
    } else if (this.state.selectedMenuId == 4) {
      var open = this.state.checked
      this.setState({
        checked: !open
      })
    } else if (this.state.selectedMenuId == 5) {
      var open = this.state.checked
      this.setState({
        checked: !open
      })
    }
  }
  /*Routing and Loader Functions End*/

  search = (event) => {
    this.setState({
      globalSearch: event.target.value,
    });
  };
  clearSearch = () => {
    this.setState({
      globalSearch: ''
    })
  }
  ModuleDialogClose = () => {
    this.setState({ menuComp: false })
  }
  dialogCloseFunction = () => {
    this.setState({ ModuleSucessPop: false })
  }

  ModuleSuccess = () => {
    this.setState({ ModuleSucessPop: true })
    setTimeout(() => {
      this.setState({ ModuleSucessPop: false })
    }, 2000)
  }
  onClickSavedBills = (flag) => {
    this.setState({
      isSavedBill: flag
    })
  }
  savedBillClose = () => {
    this.setState({
      isSavedBill: false
    })
  }
  transmitedClose = () => {
    this.setState({
      isTranmitedBill: false,
      isFrom_fo_lab: false,
    })
  }
  onClickTranmittedBills = (flag) => {
    this.setState({
      isTranmitedBill: flag,
      isFrom_fo_lab: false,
    })
  }
  returnClose = () => {
    this.setState({
      ph_returns: false
    })
  }
  PrescriptionClose = () => {
    this.setState({
      ph_Prescrition: false,
      DocQueuePopup: false
    })
  }
  onClickSavedBillClose = (flag) => {
    this.setState({
      ph_SavedBill: flag
    })
  }
  savedBillClose = () => {
    this.setState({
      ph_SavedBill: false
    })
  }
  requestClose = () => {
    this.setState({
      ph_request: false
    })
  }
  onClickPrescriptionClose = (flag) => {
    this.setState({
      ph_Prescrition: flag
    })
  }
  onClickrequestClose = (flag) => {
    this.setState({
      ph_request: flag
    })
  }
  onClickreturnClose = (flag) => {
    this.setState({
      ph_returns: flag
    })
  }
  clearSearch = () => {
    this.setState({
      configSearch: '',
      searchkey: '',
      purchaseSearch: '',
      inventorySearch: "",
      reportSearch: ""
    })
  }

  onChangeHandler = (e) => {
    const { name, value } = e.target
    this.setState({
      [name]: value
    })
  }

  CancelQrRequest = (item) => {
    try {
      let data = {
        "id": item?.id,
      }
      RestAPIService.create(data, Serviceurls.FO_QR_ENTRY_LIST)
        .then(response => {
          if (response.data.status === "success") {
            this.setState({
              QRPatientName: ""
            }, () => { this.getQREntryList() })
          }
        })
        .catch(e => {
          if (e?.response?.data?.message) {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        });
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  helpDeskPost = () => {
    try {
      var states = this.state
      let data = {
        "subject": states.helpSubject,
        "content": states.helpDescription,
      }
      if (states.helpSubject && states.helpDescription) {
        RestAPIService.create(data, Serviceurls.HELPDESK)
          .then(response => {
            if (response.data.status === "success") {
              this.setState({
                helpDescription: "",
                helpSubject: "",
                isHelpdeskClicked: false
              })
            }
          })
          .catch(e => {
            if (e?.response?.data?.message) {
              this.errorMessage(e.response.data.message)
            } else {
              this.errorMessage(e.message)
            }
          });
      } else {
        this.errorMessage("Please enter the All Details")
      }
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  renderHelpDeskPopup = () => {
    const { t } = this.props
    return (
      <>
        <Modal open={this.state.isHelpdeskClicked} >
          <Box className='eMed_rts_popup'>
            <Box className='eMed_rts_home_header'>
              <Typography component={'label'} variant='h6' fontWeight={600}>{t("Customer Support")}</Typography>
              <Typography component={'p'} className='eMed_rts_home_txt_one'>{t("Kindly describe the issue which you are facing. We will help you.")}</Typography>
            </Box>
            <Box component={'div'} margin="1vw" >
              <TextField
                variant='outlined'
                size='small'
                fullWidth
                placeholder='Subject'
                className='eMed_rts_home_txt'
                value={this.state.helpSubject}
                name='helpSubject'
                onChange={(e) => this.onChangeHandler(e)}
              />
            </Box>
            <Box component={'div'} margin="1vw" >
              <TextField
                variant='outlined'
                size='small'
                multiline
                rows={3}
                fullWidth
                placeholder='Description'
                className='eMed_rts_home_txt'
                value={this.state.helpDescription}
                name='helpDescription'
                onChange={(e) => this.onChangeHandler(e)}
              />
            </Box>
            <Box component='div' margin={'1vw'} display={'flex'} justifyContent='flex-end'>
              <Button variant='outlined'
                className='eMed_rts_home_btn'
                size='small'
                onClick={() => {
                  this.setState({
                    helpDescription: "",
                    helpSubject: "",
                    isHelpdeskClicked: false
                  })
                }} >{t("Back")}</Button>
              <Button
                variant='contained'
                className='eMed_rts_home_btn_two'
                size='small'
                onClick={() => { this.helpDeskPost() }}
              >{t("Send")}</Button>
            </Box>
          </Box>
        </Modal>
      </>
    )
  }

  changeScreen = (screenId) => {
    var { selectedMenuId, selectedPageName } = this.state
    if (parseInt(screenId) == 1) {
      selectedMenuId = 1
      selectedPageName = 'MainHome'
    } else if (screenId == 2) {
      selectedMenuId = 2
      selectedPageName = 'Reports'
    } else if (screenId == 3) {
      selectedMenuId = 3
      selectedPageName = 'Inventory'
    } else if (screenId == 4) {
      selectedMenuId = 4
      selectedPageName = 'Purchase'
    } else if (screenId == 5) {
      selectedMenuId = 5
      selectedPageName = 'Config'
    }

    this.setState({
      selectedMenuId,
      selectedPageName
    })
  }

  subScreenChange = (screen) => {
    this.setState({
      selectedSubScreen: screen,
      searchkey: ""
    })
  }

  getClinicdata = () => {
    try {
      RestAPIService.getAll(Serviceurls.LAB_CLINIC_LIST_GET).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              clinicData: response.data.data,
              clinicId: response.data.data[0].clinic_id
            }, () => {
            })
          } else {
            this.setState({
              isErrorMsg: true,
              isErrorMsgText: response?.data?.message
            }, () => {
              setTimeout(() => {
                this.setState({ isErrorMsg: false })
              }, 2000);
            })
          }
        }).catch((error) => {
          this.setState({
            isErrorMsg: true,
            isErrorMsgText: error?.response?.data?.message
          }, () => {
            setTimeout(() => {
              this.setState({ isErrorMsg: false })
            }, 2000);
          })
        })
    } catch (error) {
      this.setState({
        isErrorMsg: true,
        isErrorMsgText: error
      }, () => {
        setTimeout(() => {
          this.setState({ isErrorMsg: false })
        }, 2000);
      })
    }
  }

  isSearchClear = (flag) => {
    const { history } = this.props;
    if (flag) {
      this.setState({
        searchkey: ""
      }, () => {
        history.push({ pathname: "/MainHome", state: { search_key: "" } })
      })
    }
  }
  returnSideMenus = () => {
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    var UserData = myUser

    if (UserData?.module_name === "front_office") {
      return NewSideMenu.FO()
    }
    else if (UserData?.module_name === "nurse") {
      return NewSideMenu.Nurse()
    }
    else if (UserData?.module_name === "Laboratory") {
      return NewSideMenu.lab()
    }
    else if (UserData?.module_name === 'Pharmacy') {
      return NewSideMenu.Pharmacy()
    }
    else if (UserData?.module_name === 'sub_location') {
      return NewSideMenu.SubLocation()
    }
    else if (UserData?.module_name === "ot_management") {
      return NewSideMenu.OT()
    }
    else if (UserData?.module_name === "counsellor") {
      return NewSideMenu.Counsellor()
    }
    else if (UserData?.module_name === "Doctor" || UserData?.module_name === "Optometry") {
      return NewSideMenu.Doctor()
    }
    else if (UserData?.module_name === 'Radiology') {
      return NewSideMenu.Radiology()
    }
    else if (UserData?.module_name === "Organization") {
      return NewSideMenu.OrganizationAdmin()
    }
    else if (UserData?.module_name === "canteen") {
      return NewSideMenu.Canteen()
    }
    else if (UserData?.module_name === "asset_management") {
      return NewSideMenu.Asset()
    }
    else {
      return []
    }
  }

  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }

  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }
  
  infoMessage = (message) => {
    this.setState({
      isInfoMsg: true,
      isInfoMsgText: message
    })
  }

  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
      isInfoMsg: false,
      isInfoMsgText: '',
    })
  }

  FOPatientSearch(ForRequest = false, MobNum = null) {
    try {
      let { t } = this.props;
      let API_URL = ""
      if (ForRequest) {
        API_URL = Serviceurls.FO_PATIENT_SEARCH + `?search_key=${MobNum}&address_key=&father_husband_key=`
        this.AppointMobileSrch(true);
      } else {
        API_URL = Serviceurls.FO_PATIENT_SEARCH + `?search_key=${this.state.PatientNameSearchKey}&address_key=${this.state.PatientAddressKey}&father_husband_key=${this.state.PatientFathHusKey}&mother_name_key=${this.state.PatientMotherKey}`
        this.isPatientisSearching(true)
      }
      // this.isPatientisSearching(true)
      RestAPIService.getAll(API_URL).
        then((response) => {
          if (response.data.status === "success") {
            this.isPatientisSearching(false)
            this.AppointMobileSrch(false);
            if (ForRequest) {
              this.setState({
                SearchedRequestPatientList: response.data.data,
                RequestPatientMenuPop: response.data.data.length > 0 ? true : false
              })
            } else {
              this.setState({
                SearchedPatientList: response.data.data,
                common_uhid: response?.data?.common_uhid ? response?.data?.common_uhid : false
              }, () => {
                if (this.state.PatientNameSearchKey !== "" || this.state.PatientAddressKey !== "" || this.state.PatientFathHusKey !== "" || this.state.PatientMotherKey !== "") {
                  if (response.data.data.length > 0) {
                    this.setState({ PatientSearchClicked: true, AddressFilterClicked: false })
                  } else {
                    this.errorMessage(t("NoRecordsFound"))
                  }
                }
              })
            }
          }
          else {
            this.isPatientisSearching(false)
            this.AppointMobileSrch(false);
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.isPatientisSearching(false)
          this.AppointMobileSrch(false);
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  getIpPatientData = (patientId) => {
    try {
      RestAPIService.getAll(Serviceurls.FO_PATIENT_UPDATE + '?patient_id=' + patientId).
        then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              ipPatientListData: response?.data?.data
            }, () => {
              this.props.history.push({ pathname: "/IPHome/CreateAppointment", state: { SelectedPatientDetails: this.state.ipPatientListData, PatientClicked: true } })
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.errorMessage(error?.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  FOheaderSearch = () => {
    const { t } = this.props;
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    var UserData = myUser
    let DoctorsList = this.state.DoctorsList?.length > 0 ? this.state.DoctorsList : localGetItem("DoctorsList") ? JSON.parse(localGetItem("DoctorsList")) : []
    return (
      <Box sx={{ marginLeft: '3.2vw' }}>
        <Box component={'div'} display={'flex'} flexDirection={'row'} sx={{ borderRadius: "0.3vw" }}>
          <FormControl size='small' sx={{ width: "13vw", borderRadius: '0.2vw', }} >
            <Select
              className='mui_header_card_dropdown_fo'
              value={window.location.pathname === "/OPHome" ? this.state.SelectedDoctor : this.state.SelectedIPDoctor}
              onChange={(e) => {
                if ((window.location.pathname === "/OPHome")) {
                  this.setState({ SelectedDoctor: e.target.value }, () => {
                    setCachevalue(this.state.SelectedDoctor, "SelectedDoctorID")
                    this.props.history.push({ pathname: "/OPHome", state: { SelectedDoctorID: this.state.SelectedDoctor } })
                  })
                }
                else if ((window.location.pathname === "/IPHome")) {
                  this.setState({ SelectedIPDoctor: e.target.value }, () => {
                    setCachevalue(this.state.SelectedIPDoctor, "SelectedIPDoctorID")
                    this.props.history.push({ pathname: "/IPHome", state: { SelectedDoctorID: this.state.SelectedIPDoctor } })
                  })
                }
              }}
              sx={{ backgroundColor: 'white' }}
              MenuProps={{ style: { maxHeight: 250 } }}
            >
              <MenuItem value={0} >{"All Doctors"}</MenuItem>
              {DoctorsList && DoctorsList.length > 0 && DoctorsList.map((item) => (
                <MenuItem key={item.doctor_id} value={item.doctor_id} >{item.doctor_name}</MenuItem>
              ))}
            </Select>
          </FormControl>
          {UserData?.module_name !== "nurse" ?
            <OutlinedInput
              placeholder={t("SearchPatients")}
              onKeyDown={(event) => {
                if (event.key === "Enter" && event.target.value != "" && event.target.value.length > 2) {
                  this.FOPatientSearch()
                }
              }}
              onChange={(e) => {
                this.setState({
                  PatientNameSearchKey: e.target.value
                })
              }}
              value={this.state.PatientNameSearchKey}
              size="small"
              className='eMed_header_Input'
              endAdornment={
                <Box component={'div'} display={'flex'} flexDirection={'row'}>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  {this.state.isPatientisSearching ?
                    <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center", width: '2.5vw' }}>
                      <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment>
                    </Box> :
                    <Tooltip title={t("Search")} arrow>
                      <IconButton disabled={this.state.PatientNameSearchKey === "" && this.state.PatientAddressKey === "" && this.state.PatientFathHusKey === "" && this.state.PatientMotherKey === ""}
                        onClick={(e) => {
                          if ((this.state.PatientNameSearchKey !== "" && this.state.PatientNameSearchKey.length > 2) || this.state.PatientAddressKey !== "" || this.state.PatientFathHusKey !== "" || this.state.PatientMotherKey !== "") {
                            this.FOPatientSearch()
                          }
                        }} position="end">
                        <SearchIcon />
                      </IconButton>
                    </Tooltip>}
                  <Tooltip title={t("Filter")} arrow>
                    <IconButton onClick={() => { this.setState({ AddressFilterClicked: true }) }} position="end">
                      <Badge color="error" size='small' variant="dot" max={999} invisible={this.state.PatientAddressKey === "" && this.state.PatientFathHusKey === "" && this.state.PatientMotherKey === ""}>
                        <FilterAltIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("FaceRecognition")} arrow>
                    <IconButton onClick={() => { this.setState({ FaceRecogClicked: true }) }} position="end">
                      <CenterFocusWeakIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              }
            /> : null}
        </Box>
        <Menu
          open={this.state.PatientSearchClicked}
          className="eMed_Header_Search_Pop"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            this.setState({
              PatientSearchClicked: false,
              // PatientNameSearchKey: "",
              PatientAddressKey: "",
              PatientFathHusKey: "",
              PatientMotherKey: ""
            })
          }}
        >
          {
            this.state.SearchedPatientList.length > 0 ?
              this.state.SearchedPatientList.map((list) => {
                let PatientFullName = `${list.first_name} ${list.last_name}`
                list["patient_name"] = PatientFullName
                list["patient_mobile_number"] = list.mobile_number
                list["patient_photo_url"] = list.photo_url
                return (
                  <Box>
                    <Box className="eMed_Header_Search_item">
                      <CommonPatientDetails data={list} fromSearch={true} isCommonUhid={this.state.common_uhid} />
                      {
                        ((window.location.pathname === "/OPHome")) ?
                          (list.appointment_date === "" || list.appointment_status === "Cancelled") ?
                            <Box component={'div'} className="eMed_Header_Search_icons">
                              <Tooltip title={t("EditPatientDetails")} arrow>
                                <IconButton onClick={() => {
                                  this.setState({
                                    PatientNameSearchKey: "",
                                    PatientAddressKey: "",
                                    PatientFathHusKey: "",
                                    PatientMotherKey: "",
                                    PatientSearchClicked: false,
                                    SearchedPatientList: [],
                                    common_uhid: false,
                                  }, () => {
                                    setCachevalue(JSON.stringify(list), "patientData")
                                    this.props.history.push({ pathname: "/OPHome/PatientDetails/Demographics" })
                                  })
                                }}
                                  position="end">
                                  <Edit color='primary' />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={t("CreateAppointment")} arrow>
                                <IconButton onClick={() => {
                                  this.setState({
                                    PatientNameSearchKey: "",
                                    PatientAddressKey: "",
                                    PatientFathHusKey: "",
                                    PatientMotherKey: "",
                                    PatientSearchClicked: false,
                                    SearchedPatientList: [],
                                    common_uhid: false,
                                  }, () => {
                                    setCachevalue(list.patient_id, "OP_CreateAppointment_PatientId")
                                    this.props.history.push({ pathname: "/OPHome/Billing/QuickBilling" })
                                  })
                                }}
                                  position="end">
                                  <PersonAddAlt1Icon color='primary' />
                                </IconButton>
                              </Tooltip>
                            </Box> :
                            <Box component={'div'} className="eMed_Header_Search_icons">
                              <Tooltip title={t("EditPatientDetails")} arrow>
                                <IconButton onClick={() => {
                                  this.setState({
                                    PatientNameSearchKey: "",
                                    PatientAddressKey: "",
                                    PatientFathHusKey: "",
                                    PatientMotherKey: "",
                                    PatientSearchClicked: false,
                                    SearchedPatientList: [],
                                    common_uhid: false,
                                  }, () => {
                                    setCachevalue(JSON.stringify(list), "patientData")
                                    this.props.history.push({ pathname: "/OPHome/PatientDetails/Demographics" })
                                  })
                                }}
                                  position="end">
                                  <Edit color='primary' />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={t("CreateAppointment")} arrow>
                                <IconButton onClick={() => {
                                  this.setState({
                                    PatientNameSearchKey: "",
                                    PatientAddressKey: "",
                                    PatientFathHusKey: "",
                                    PatientMotherKey: "",
                                    PatientSearchClicked: false,
                                    SearchedPatientList: [],
                                    common_uhid: false
                                  }, () => {
                                    setCachevalue(list.patient_id, "OP_CreateAppointment_PatientId")
                                    this.props.history.push({ pathname: "/OPHome/Billing/QuickBilling" })
                                  })
                                }}
                                  position="end">
                                  <PersonAddAlt1Icon color='primary' />
                                </IconButton>
                              </Tooltip>
                              {/* <Typography>{list.appointment_date && formatDate(list.appointment_date)}</Typography> */}
                            </Box>
                          :
                          <Box component={'div'} className="eMed_Header_Search_icons">
                            <Tooltip title={t("ViewPatientDetails")} arrow>
                              <IconButton onClick={() => {
                                this.setState({
                                  PatientNameSearchKey: "",
                                  PatientAddressKey: "",
                                  PatientFathHusKey: "",
                                  PatientMotherKey: "",
                                  PatientSearchClicked: false,
                                  SearchedPatientList: [],
                                  common_uhid: false
                                }, () => {
                                  setCachevalue(JSON.stringify(list), "IppatientData")
                                  setCachevalue(JSON.stringify(list), "patientData")
                                  this.props.history.push({ pathname: "/IPHome/Demographics/Demographics" })
                                })
                              }}
                                position="end">
                                <img src={ImagePaths.View.default} />
                              </IconButton>
                            </Tooltip>
                            {list.is_admitted ? <Typography>{list.admission_date && formatDate(list.admission_date)}</Typography> :
                              <Tooltip title={t("CreateAdmission")} arrow>
                                <IconButton disabled={list.is_admitted}
                                  onClick={() => {
                                    this.setState({
                                      PatientNameSearchKey: "",
                                      PatientAddressKey: "",
                                      PatientFathHusKey: "",
                                      PatientMotherKey: "",
                                      PatientSearchClicked: false,
                                      SearchedPatientList: [],
                                      PatientMotherKey: "",
                                      common_uhid: false,
                                    }, () => {
                                      this.getIpPatientData(list?.patient_id)
                                    })
                                  }} position="end">
                                  <PersonAddAlt1Icon sx={{ color: list.is_admitted ? Colors.disableLabelColor : Colors.themeDark }} />
                                </IconButton>
                              </Tooltip>}
                          </Box>
                      }
                    </Box>
                    <Divider />
                  </Box>
                )
              }) : null
          }
        </Menu>
        <Menu
          open={this.state.AddressFilterClicked}
          className="eMed_Header_Filter_Pop"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            this.setState({ AddressFilterClicked: false })
          }}
        >
          <Box className="eMed_Header_Filter_item" sx={{ height: this.state.error && this.state.errorFathhus ? "30vh" : "28vh" }}>
            <TextField
              label={t("SearchByAddress")}
              inputProps={{ maxLength: 50 }}
              size='small'
              value={this.state.PatientAddressKey}
              onChange={(e) => { this.setState({ PatientAddressKey: e.target.value }) }}
              onKeyDown={(e) => e.stopPropagation()} />
            <TextField
              label={t("SearchByFatherHusbandName")}
              inputProps={{ maxLength: 50 }}
              size='small'
              value={this.state.PatientFathHusKey}
              onChange={(e) => {
                var isSpecial = /^[ A-Za-z.,/]*$/.test(e.target.value)
                if (isSpecial) {
                  this.setState({ PatientFathHusKey: e.target.value, errorFathhus: false, error: false })
                } else {
                  this.setState({ errorFathhus: true, error: false })
                }
              }}
              error={this.state.errorFathhus}
              helperText={this.state.errorFathhus ? "Enter Only Alphabets" : null}
              onKeyDown={(e) => e.stopPropagation()} />
            <TextField
              label={t("Search By Mother Name")}
              inputProps={{ maxLength: 50 }}
              size='small'
              value={this.state.PatientMotherKey}
              onChange={(e) => {
                var isSpecial = /^[ A-Za-z.,/]*$/.test(e.target.value)
                if (isSpecial) {
                  this.setState({ PatientMotherKey: e.target.value, error: false, errorFathhus: false })
                } else {
                  this.setState({ error: true, errorFathhus: false })
                }
              }}
              error={this.state.error}
              helperText={this.state.error ? "Enter Only Alphabets" : null}
              onKeyDown={(e) => e.stopPropagation()} />
            <Box component={'div'} display='flex' flexDirection='row' justifyContent={'center'}  >
              <Button id="eMed_Config_btn" variant='contained' size='small' disabled={(this.state.PatientAddressKey === "" && this.state.PatientFathHusKey === "" && this.state.PatientMotherKey === "") || (this.state.error || this.state.errorFathhus)} onClick={() => { (window.location.pathname === "/DoctorHome") ? this.DoctorPatientSearch() : this.FOPatientSearch() }}>Search</Button>
              <Button id="eMed_Config_btn" variant='outlined' size='small' sx={{ marginLeft: "2vw" }} onClick={() => { this.setState({ PatientAddressKey: "", PatientFathHusKey: "", PatientMotherKey: "", error: false, errorFathhus: false }) }}>Clear</Button></Box>
          </Box>
        </Menu>
      </Box>
    )
  }

  isPatientisSearching(flag) {
    this.setState({ isPatientisSearching: flag })
  }

  AppointMobileSrch(flag) {
    this.setState({ isappointRequest: flag, isDocAppointRequest: flag })
  }

  PharmaPatientSearch() {
    try {
      var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
      var UserData = myUser
      if (UserData?.pharmacy_type === 'Standalone Pharmacy') {
        var url = Serviceurls.PHARMA_HEADER_SEARCH
      } else {
        var url = Serviceurls.FO_PATIENT_SEARCH
      }
      let { t } = this.props;
      this.isPatientisSearching(true)
      RestAPIService.getAll(url + `?search_key=${CommonValidation.removeSpace(this.state.pharmaNameSearchKey)}`).
        then((response) => {
          if (response.data.status === "success") {
            this.setState({
              pharmaPatientList: response.data.data,
              common_uhid: response?.data?.common_uhid,
            }, () => {
              this.isPatientisSearching(false)
              if (CommonValidation.removeSpace(this.state.pharmaNameSearchKey) !== "") {
                if (response.data.data.length > 0) {
                  this.setState({ pharmaSearchClicked: true })
                } else {
                  this.errorMessage(t("NoRecordsFound"))
                }
              }
            })
          }
          else {
            this.isPatientisSearching(false)
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          if (error?.response?.data?.status === 'fail') {
            this.errorMessage(error.response.data.message)
          }
          this.isPatientisSearching(false)
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }
  PharmaheaderSearch = () => {
    const { t } = this.props;
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    var UserData = myUser
    return (
      <Box sx={{ marginLeft: '3.2vw' }}>
        <Box component={'div'} display={'flex'} flexDirection={'row'} sx={{ borderRadius: "0.3vw" }}>
          {UserData?.module_name !== "nurse" ?
            <OutlinedInput
              placeholder={t("SearchPatients")}
              onKeyDown={(event) => {
                if (event.key === "Enter" && event.target.value != "" && event.target.value.length > 2) {
                  this.PharmaPatientSearch()
                }
              }}
              onChange={(e) => {
                this.setState({
                  pharmaNameSearchKey: e.target.value
                })
              }}
              value={this.state.pharmaNameSearchKey}
              size="small"
              className='eMed_Pharmaheader_Input'
              endAdornment={
                <Box component={'div'} display={'flex'} flexDirection={'row'}>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  {
                    this.state.isPatientisSearching ?
                      <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                        <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment>
                      </Box> :
                      <Tooltip title={t("Search")} arrow>
                        <IconButton disabled={CommonValidation.removeSpace(this.state.pharmaNameSearchKey) === ""}
                          onClick={(e) => {
                            if (CommonValidation.removeSpace(this.state.pharmaNameSearchKey) !== "" && this.state.pharmaNameSearchKey?.length > 2) {
                              this.PharmaPatientSearch()
                            }
                          }} position="end">
                          <SearchIcon />
                        </IconButton>
                      </Tooltip>
                  }
                </Box>
              }
            /> : null}
        </Box>
        <Menu
          open={this.state.pharmaSearchClicked}
          className="eMed_pharmaHeader_Search_Pop"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            this.setState({
              pharmaSearchClicked: false,
              // pharmaNameSearchKey: "",
            })
          }}
        >
          {

            this.state.pharmaPatientList.length > 0 ?
              this.state.pharmaPatientList.map((list) => {
                let PatientFullName = `${list.first_name} ${list.last_name}`
                list["patient_name"] = PatientFullName
                list["patient_mobile_number"] = list.mobile_number
                list["patient_photo_url"] = list.photo_url
                return (
                  <Box>
                    <Box className="eMed_Header_Search_item" width={"28vw"}>
                      <CommonPatientDetails data={list} fromSearch={true} isCommonUhid={this.state.common_uhid} />
                      <Box component={'div'} className="eMed_Header_Search_icons">
                        <Tooltip title={t('CreateBill')} placement='top' arrow>
                          <IconButton size='small' variant='contained' onClick={() => {
                            this.setState({
                              pharmaNameSearchKey: "",
                              pharmaSearchClicked: false,
                              pharmaPatientList: [],
                              common_uhid: false,
                            }, () => {
                              this.props.history.push({ pathname: "/PharmacyBilling", state: { Patient_ID: list?.patient_id, showHistory: true } })
                            })
                          }}
                            position="end">
                            <img style={{ width: '1.5vw', height: '1.5vw' }} src={ImagePaths.BillCreated.default} />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </Box>
                  </Box>
                )
              }) : null
          }
        </Menu>
      </Box>
    )
  }

  dataURItoBlob = (dataURI) => {
    var byteString = atob(dataURI.split(",")[1]);
    var mimeString = dataURI.split(",")[0].split(":")[1].split(";")[0];
    var ab = new ArrayBuffer(byteString.length);
    var ia = new Uint8Array(ab);
    for (var i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    return new Blob([ab], { type: mimeString });
  };

  processFaceRecog(FaceData, fromDoc = false) {
    try {
      var states = this.state
      const dataForm = new FormData();

      dataForm.append("face_photo", FaceData === null ? "" : this.dataURItoBlob(FaceData))
      let params = ''
      if (fromDoc) {
        let clinicId = localGetItem('SelectedDoctorClinicId')
        params = Serviceurls.FACE_RECOG_SEARCH + '?clinic_id=' + clinicId
      } else {
        params = Serviceurls.FACE_RECOG_SEARCH
      }
      RestAPIService.create(dataForm, params)
        .then((response) => {
          if (response.data.status === 'success') {
            if (response?.data?.data?.length > 0) {
              this.setState({
                SearchedPatientList: response.data.data,
                PatientSearchClicked: true,
                FaceRecogClicked: false,
                temp_patient_img: null,
                docFaceRecogClicked: false
              })
            } else {
              this.errorMessage("Face Not Matched ! ")
            }
          }
        }).catch((e) => {
          if (e?.response?.data?.message) {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })


    } catch (e) {
      if (e?.response?.data?.message) {
        this.errorMessage(e.response.data.message)
      } else {
        this.errorMessage(e.message)
      }

    }
  }

  OpenFaceRecogPop(fromDoc = false) {
    try {
      return (
        <Box>
          <Modal open={true}>
            <Box className="eMed_WebCamPop_Main">
              <Box component={'div'} className="eMed_WebCam_Header">
                <Typography variant='h6'>{"Patient Profile "}</Typography>
                <Button onClick={() => { this.setState({ temp_patient_img: this.state.patient_img, docFaceRecogClicked: false, FaceRecogClicked: false }) }} >
                  <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                </Button>
              </Box>
              <Box component={'div'}>
                {
                  this.state.temp_patient_img === null ?
                    <Webcam
                      className='eMed_WebCam_Div'
                      audio={false}
                      mirrored={true}
                      ref={this.webcamRef}
                      screenshotFormat="image/jpeg"
                    // videoConstraints={videoConstraints}
                    /> :
                    <img className='eMed_WebCam_Img_Div' src={this.state.temp_patient_img} />
                }
              </Box>
              <Box component={'div'} className="eMed_WebCam_Btn_div_faceRecog">
                <Button variant='contained' size='small' id="eMed_Config_btn" onClick={() => { this.setState({ temp_patient_img: this.webcamRef.current.getScreenshot() }, () => { this.processFaceRecog(this.state.temp_patient_img, fromDoc) }) }}>Process</Button>
                <Button variant='contained' size='small' id="eMed_Config_btn" onClick={() => { this.setState({ temp_patient_img: null }) }}>Retake</Button>
              </Box>
            </Box>
          </Modal>
        </Box>
      );
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  postRequest(list, Status) {
    try {
      var states = this.state
      const convertTime12to24 = moment(list?.appointment_time, ["h:mm A"]).format("HH:mm");
      let data = {
        "id": list?.id,
        "status": Status,
        "patient_id": list?.patient_id,
        "doctor_id": list?.doctor_id,
        "patient_account_number": list?.patient_account_number,
        "appointment_date": list?.appointment_date,
        "appointment_start_time": convertTime12to24,
        "purpose_of_visit": list?.message,
        "patient_appointment_type": list?.booked_through === "Mobile" ? "Mobile Appointment" : "Web Appointment"
      }
      RestAPIService.create(data, Serviceurls.FO_APPOINT_REQUESTS)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              RequestPopUp: false,
              patientSearch: '',
              App_DocID: null,
            }, () => { this.getAppointRequests() })
            this.props.history.push({ pathname: "/OPHome", state: { RequestBool: true, RequestMessage: response?.data?.message } })
          }
        }).catch((e) => {
          if (e?.response?.data?.message) {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      if (e?.response?.data?.message) {
        this.errorMessage(e.response.data.message)
      } else {
        this.errorMessage(e.message)
      }

    }
  }

  // Print Appointment list for 4.0 start
  printAppList() {
    try {
      var date = getCachevalue('OpAppDate')
      var opstatus = getCachevalue('OPStatus')
      let DoctorId = this.state.SelectedDoctor === 0 ? "" : this.state.SelectedDoctor
      this.setState({ disableBtn: true })
      RestAPIService.PrintPDF(Serviceurls.FO_APPINMENT_PRIENT + "?appointment_date=" + date + "&doctor_id=" + DoctorId + "&appointment_status=" + opstatus).
        then((response) => {
          if (response) {
            //Create a Blob from the PDF Stream
            const file = new Blob(
              [response.data],
              { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
            this.setState({ disableBtn: false })
          }
        }).catch((error) => {
          this.setState({ disableBtn: false })
          console.log(error.message)
        })
    } catch (e) {
      this.setState({ disableBtn: false })
      console.log(e.message)
    }
  }
  CounsellerModulePrint() {
    try {
      let fromDate = getCachevalue('Counseller From Date')
      let toDate = getCachevalue('Counseller To Date')
      let ClinicID = getCachevalue('Counseller Clinic ID')
      let selectedCard = getCachevalue('Counseller Status Data')
      RestAPIService.PrintPDF(Serviceurls.GET_COUNSELLOR_APPOINTMENTS + "?from_date=" + fromDate + "&to_date=" + toDate + "&clinic_id=" + ClinicID + "&status_data=" + selectedCard + "&export_type=pdf").
        then((response) => {
          if (response) {
            //Create a Blob from the PDF Stream
            const file = new Blob(
              [response.data],
              { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
            this.setState({ disableBtn: false })
          }
        }).catch((error) => {
          this.setState({ disableBtn: false })
          console.log(error.message)
        })
    } catch (e) {
      this.setState({ disableBtn: false })
    }
  }
  // Print Appointment list for 4.0 end
  // Print IP-Appointment list for 4.0 start
  printIPAppList(fromDocModule = false) {
    try {
      var fromDate = getCachevalue('FormDate')
      var toDate = getCachevalue('ToDate')
      var status = getCachevalue('Status')
      var ward_name = getCachevalue('wardName')
      var block_name = getCachevalue('blockName')
      var floor_number = getCachevalue('floorNumber')
      let DoctorId = this.state.SelectedDoctor === 0 ? "" : this.state.SelectedDoctor
      const clinic_id = JSON.parse(localGetItem('SelectedDoctorClinicId'));
      this.setState({ disableBtn: true })
      let url = `${Serviceurls.IP_APP_PRINT}?from_date=${fromDate}&to_date=${toDate}&doctor_id=${DoctorId}&admission_status=${status}`;
      if(fromDocModule){
        url = `${url}&clinic_id=${clinic_id}&ward_name=${ward_name || ""}&block_name=${block_name || ""}&floor_number=${floor_number || ""}`
      }
      RestAPIService.getAll(url).
        then((response) => {
          if (response) {
            //Create a Blob from the PDF Stream
            const file = new Blob(
              [response.data],
              { type: 'application/pdf' });
            //Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            //Open the URL on new Window
            window.open(fileURL);
            this.setState({ disableBtn: false })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.errorMessage(error.message)
          }
          this.setState({ disableBtn: false })
        })
    } catch (e) {
      this.setState({ disableBtn: false })
      console.log(e.message)
    }
  }
  // Print IP-Appointment list for 4.0 end

  getPatientSearchData = (FromFO) => {
    try {
      const { t } = this.props
      var search_key = this.state.searchkey ? this.state.searchkey : ""
      let serviceUrl = ""
      if (FromFO) {
        serviceUrl = Serviceurls.FO_PATIENT_SEARCH + `?search_key=${search_key}`
      } else if (this.state.labType === "Standalone Laboratory") {
        serviceUrl = Serviceurls.LAB_SEARCH_PATIENT + "?search_key=" + search_key
      } else {
        serviceUrl = Serviceurls.LAB_PATIENT_TRANSMIT_SEARCH + "?search_key=" + search_key

      }
      this.isPatientisSearching(true)
      RestAPIService.getAll(serviceUrl)
        .then(response => {
          if (response.data.status == "success") {
            var data = response.data.data
            if (data.length > 0) {
              this.setState({ searchPatientDatas: data, isPatientisSearching: false, common_uhid: response?.data?.common_uhid })
            } else {
              this.isPatientisSearching(false)
              this.errorMessage(t("NoRecordsFound"))
            }
          }
        })
        .catch(e => {
          if (e?.response?.data?.message) {
            this.isPatientisSearching(false)
            this.errorMessage(e?.response?.data?.message)
          } else {
            this.isPatientisSearching(false)
            this.errorMessage(e?.message)
          }
        });
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  LabHeaderSearch = (FromFO = false, radiology = false) => {
    const { t, history } = this.props;
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    var UserData = myUser
    return (
      <>{
        // this.props?.history?.location?.pathname !== "/Results" ? 
        <Box sx={{ marginLeft: '3.2vw' }} className='Lab_Home_Patient_Search'>

          <Box component={'div'} display={'flex'} flexDirection={'row'} sx={{ borderRadius: "0.3vw" }}>
            {FromFO && this.state.labType !== "Inhouse Laboratory" ? null :
              <FormControl size='small' sx={{ width: "13vw", borderRadius: '0.2vw', }} >
                <Select
                  className='mui_header_card_dropdown_fo'
                  value={this.state.clinicId}
                  onChange={(e) => {
                    this.setState({ clinicId: e.target.value })
                  }}
                  sx={{ backgroundColor: '#E4F2F0' }}
                  MenuProps={{ style: { maxHeight: 250 } }}
                >
                  {this.state.clinicData && this.state.clinicData.length > 0 && this.state.clinicData.map((item) => (
                    <MenuItem key={item.clinic_id} value={item.clinic_id} >{item.clinic_name}</MenuItem>
                  ))}
                </Select>
              </FormControl>}
            <OutlinedInput
              placeholder="Search Patients Name, Number, UHID"
              onKeyDown={(event) => {
                if (event.key === "Enter" && event.target.value != "" && event.target.value.length > 2) {
                  this.setState({ LabPatientSearch: true }, () => this.getPatientSearchData(FromFO))

                  // history.push({ pathname: "/MainHome", state: { search_key: this.state.searchkey ? this.state.searchkey : "" } })
                }
              }}
              onChange={(e) => {
                var data = e.target.value
                if (data == "") {
                  // history.push({ pathname: "/MainHome", state: { search_key: data } })
                }
                this.setState({
                  searchkey: e.target.value
                })
              }}
              value={this.state.searchkey}
              size="small"
              className='eMed_header_Input'
              sx={{ backgroundColor: FromFO ? "white" : '#E4F2F0' }}
              endAdornment={
                <Box component={'div'} display={'flex'} flexDirection={'row'}>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  {this.state.isPatientisSearching ?
                    <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center" }}>
                      <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment>
                    </Box> :
                    <Tooltip title={t("Search")} arrow>
                      <IconButton onClick={() => {
                        this.setState({ LabPatientSearch: true }, () => {
                          if (this.state.searchkey != '' && this.state.searchkey?.length > 2) {
                            this.getPatientSearchData(FromFO)
                          }
                        })
                        // history.push({ pathname: "/MainHome", state: { search_key: this.state.searchkey ? this.state.searchkey : "" } })
                      }} position="end">
                        <i class="fas fa-search"></i>
                      </IconButton>
                    </Tooltip>}
                </Box>
              }
            />
          </Box>
          <Menu
            sx={{ left: this.state.labType === "Inhouse Laboratory" ? "3vw" : "-9.5vw" }}
            open={this.state.LabPatientSearch && this.state.searchPatientDatas?.length > 0}
            className="eMed_Header_Search_Pop"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={() => {
              this.setState({
                LabPatientSearch: false,
                searchPatientDatas: [],
                common_uhid: false,
                // searchkey: ""
              })
            }}
          >
            {this.state.searchPatientDatas?.length > 0 ?
              this.state.searchPatientDatas.map((item) => {
                let PatientNameText = item.first_name || item.patient_name
                let PatientAgeText = `(${item?.patient_age ? item?.patient_age : ""}/${item?.gender ? item?.gender.toUpperCase() : ""})`
                let branchName = item?.branch_name ? item.branch_name : "-"
                return (
                  <Box width={"25vw"} padding={'0.5vw'}>
                    <Box display={'flex'} alignItems={'center'}>
                      <Avatar id='eMed_IconAvatar' src={item.photo_url || item.patient_photo_url ? item.photo_url || item.patient_photo_url : ImagePaths.Default} sx={{ boxShadow: 3, minWidth: '13%', marginTop: '1vh', marginRight: '2vh', marginBottom: '1vh' }} />
                      <div style={{ minWidth: '55%' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center' }}>
                          <Box component={'div'}>
                            {PatientNameText ? PatientNameText?.length > 16 ?
                              <Tooltip placement='top' title={PatientNameText} arrow>
                                <Typography sx={{ margin: "0vw", fontSize: '0.9vw' }}>{PatientNameText.slice(0, 15) + "..."}</Typography></Tooltip> :
                              <Typography sx={{ margin: "0vw", fontSize: '0.9vw' }}>{PatientNameText}</Typography> : "-"}</Box>
                          <Typography sx={{ margin: "0vw", marginLeft: "0.5vw", fontSize: '0.8vw' }}>{PatientAgeText}</Typography>
                        </Box>
                        <div className='search_sub_container'>
                          <Typography className='eMed_Com_Patient_UhidText' sx={{ fontSize: "0.8vw" }}>{item.mobile_number || item.patient_mobile_number}</Typography>
                          <Typography className='eMed_Com_Patient_UhidText' sx={{ fontSize: "0.8vw", marginLeft: "0.5vw" }}>{" | " + item.patient_account_number ? item.patient_account_number : ""}</Typography>
                        </div>
                        <div>
                          {this.state.common_uhid ? <Typography className='eMed_Com_Patient_UhidText' sx={{ fontSize: "0.8vw" }}>Branch Name: {branchName?.length > 22 ? <Tooltip placement="top" title={branchName} arrow><span id='eMed_Com_Patient_UhidText'>{branchName?.slice(0, 19) + "..."}</span></Tooltip> : branchName ? branchName : "-"}</Typography> : null}
                        </div>
                      </div>
                      <button size="small" className='Lab_Search_Button' onClick={() => {
                        this.setState({
                          selectedPatientData: item,
                          LabPatientSearch: false,
                          searchkey: "",
                          searchPatientDatas: [],
                          common_uhid: false,
                        }, () => {
                          if (FromFO && !radiology) {
                            history.push({ pathname: "/Billing", state: { selectedPatientDetails: this.state.selectedPatientData } })
                            // history.push({ pathname: "/Billing/LabBilling", state: { selectedPatientData: this.state.selectedPatientData } })
                          } else if (FromFO && radiology) {
                            history.push({ pathname: "/Billing/RadiologyBilling", state: { selectedPatientDetails: this.state.selectedPatientData } })
                          } else {
                            history.push({ pathname: "/MainHome", state: { selectedPatientData: this.state.selectedPatientData } })
                          }
                        })
                      }}><p className='Lab_saved_Bill_Text'>{t("Proceed")}</p></button>
                      <Button
                        className='Common_Btn_Min_Width'
                        sx={{ height: "2vw", width: '2vw', ml: '1vw' }}
                        onClick={() => this.setState({ labHistoryPopup: true, labPatientData: item }, () => this.getLabPateintHistoryData())}
                      >
                        <Box component={'img'} src={ImagePaths.ResultHistory.default} height={'2vw'} width={'2vw'} />
                      </Button>
                    </Box>
                    <Divider />
                  </Box>

                )
              }) : <Box component={'div'} padding={"1vw"}>{t("NoRecordsFound")}</Box>
            }
          </Menu>
        </Box>
      }</>

    )
  }

  getLabPateintHistoryData = () => {
    try {
      let laboratoryId = getCachevalue("Laboratory_id")
      RestAPIService.getAll(Serviceurls.LAB_PATIENT_TEST_HISTORY + `?patient_id=${this.state.labPatientData?.patient_id}&laboratory_id=${laboratoryId}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              labHistoryData: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  PopUpClose = () => {
    this.setState({
      isViewHistory: false,
      viewCultureData: [],
      testName: "",
    })
  }

  viewCultureTest = (data) => {
    return (
      <div style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        width: "100%",
        height: "47vh",
        overflowY: "auto",
      }}
      >
        {data?.map((items, indexs) => {
          return (
            <div key={indexs} style={{ width: "70vw", marginBottom: "1vw" }}>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginTop: "1vw", marginBottom: "1vw" }}>
                <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <Box component={'div'} sx={{ display: "flex" }}>
                    <Typography>{`ORGANISM ISOLATED 0${indexs + 1} : `}</Typography>
                    <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                      {items?.bacteria ? items?.bacteria?.length > 20 ?
                        <Tooltip placement='top' title={items?.bacteria} arrow>
                          <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                            {items?.bacteria.slice(0, 20) + '...'}
                          </Typography>
                        </Tooltip> :
                        <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>{items?.bacteria}</Typography> : '-'}
                    </Typography>
                  </Box>
                </div>
                <Box component={'div'} sx={{ display: "flex" }}>
                  <Typography>{`MICROSCOPY : `}</Typography>
                  <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                    {items?.microscopy ? items?.microscopy?.length > 20 ?
                      <Tooltip placement='top' title={items?.microscopy} arrow>
                        <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                          {items?.microscopy.slice(0, 20) + '...'}
                        </Typography>
                      </Tooltip> :
                      <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>{items?.microscopy}</Typography> : '-'}
                  </Typography>
                </Box>
                <Box component={'div'} sx={{ display: "flex" }}>
                  <Typography>{`COLONY COUNT : `}</Typography>
                  <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                    {items?.colony_count ? items?.colony_count?.length > 20 ?
                      <Tooltip placement='top' title={items?.colony_count} arrow>
                        <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                          {items?.colony_count.slice(0, 20) + '...'}
                        </Typography>
                      </Tooltip> :
                      <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>{items?.colony_count}</Typography> : '-'}
                  </Typography>
                </Box>
                <Box component={'div'} sx={{ display: "flex" }}>
                  <Typography>{`METHOD : `}</Typography>
                  <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                    {items?.method_type ? items?.method_type?.length > 20 ?
                      <Tooltip placement='top' title={items?.method_type} arrow>
                        <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>
                          {items?.method_type.slice(0, 20) + '...'}
                        </Typography>
                      </Tooltip> :
                      <Typography sx={{ fontWeight: 600, marginLeft: "0.3vw" }}>{items?.method_type}</Typography> : '-'}
                  </Typography>
                </Box>
              </div>
              <TableContainer style={{ maxHeight: "35vh" }}>
                <Table style={{ border: "1px solid gray" }} stickyHeader>
                  <TableHead>
                    <TableRow>
                      <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{"SNo"}</b></TableCell>
                      <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{"Antibiotic"}</b></TableCell>
                      <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{"MIC Result/Range"}</b></TableCell>
                      <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{"Zone Result/Range"}</b></TableCell>
                      <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{"Interpretaion"}</b></TableCell>
                      <TableCell size='small' sx={{ backgroundColor: "#F5F8F7" }}><b>{"Efficiency Ration"}</b></TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {items?.antibacteria?.length > 0 ?
                      items?.antibacteria?.map((item, index) => (
                        <TableRow key={index}>
                          <TableCell>{index + 1}</TableCell>
                          <TableCell>
                            {item?.antibiotic ? item?.antibiotic?.length > 20 ?
                              <Tooltip placement='top' title={item?.antibiotic} arrow>
                                <Typography>
                                  {item?.antibiotic.slice(0, 20) + '...'}
                                </Typography>
                              </Tooltip> :
                              <Typography>{item?.antibiotic}</Typography> : '-'}
                          </TableCell>
                          <TableCell>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                              <Typography>{item?.mic_break_point ? item?.mic_break_point?.length > 20 ?
                                <Tooltip placement='top' title={item?.mic_break_point} arrow>
                                  <div>{item?.mic_break_point.slice(0, 20) + '...' + " "}</div>
                                </Tooltip> : (item?.mic_break_point + " ") : '-'}</Typography>
                              {item?.mic_break_point_std ?
                                <Typography style={{ marginLeft: "0.25vw" }}>{item?.mic_break_point_std ? item?.mic_break_point_std?.length > 20 ?
                                  <Tooltip placement='top' title={item?.mic_break_point_std} arrow>
                                    <div>{" / " + item?.mic_break_point_std.slice(0, 20) + '...'}</div>
                                  </Tooltip> : (" / " + item?.mic_break_point_std) : '-'}</Typography> : null}
                            </div>
                          </TableCell>
                          <TableCell>
                            <div style={{ display: "flex", flexDirection: "row" }}>
                              <Typography>{item?.zone_size ? item?.zone_size?.length > 20 ?
                                <Tooltip placement='top' title={item?.zone_size} arrow>
                                  <div>{item?.zone_size.slice(0, 20) + '...' + " "}</div>
                                </Tooltip> : (item?.zone_size + " ") : '-'}</Typography>
                              {item?.zone_size_std ?
                                <Typography style={{ marginLeft: "0.25vw" }}>{item?.zone_size_std ? item?.zone_size_std?.length > 20 ?
                                  <Tooltip placement='top' title={item?.zone_size_std} arrow>
                                    <div>{" / " + item?.zone_size_std.slice(0, 20) + '...'}</div>
                                  </Tooltip> : (" / " + item?.zone_size_std) : '-'}</Typography> : null
                              }
                            </div>
                          </TableCell>
                          <TableCell>
                            {item?.interpretation ? item?.interpretation?.length > 20 ?
                              <Tooltip placement='top' title={item?.interpretation} arrow>
                                <Typography>
                                  {item?.interpretation.slice(0, 20) + '...'}
                                </Typography>
                              </Tooltip> :
                              <Typography>{item?.interpretation}</Typography> : '-'}
                          </TableCell>
                          <TableCell>
                            {item?.methodThreeBacteriaResult ? item?.methodThreeBacteriaResult?.length > 20 ?
                              <Tooltip placement='top' title={item?.methodThreeBacteriaResult} arrow>
                                <Typography>
                                  {item?.methodThreeBacteriaResult.slice(0, 20) + '...'}
                                </Typography>
                              </Tooltip> :
                              <Typography>{item?.methodThreeBacteriaResult}</Typography> : '-'}
                          </TableCell>
                        </TableRow>
                      ))
                      : <TableRow>
                        <TableCell align="center" colSpan={6}>No Records To Be Shown</TableCell>
                      </TableRow>}
                  </TableBody>
                </Table>
              </TableContainer>
            </div>
          )
        })}
      </div>
    )
  }

  renderLabHistoryPopup = () => {
    try {
      const { t } = this.props
      this.state.labHistoryData?.forEach((element, index) => element.sno = index + 1)
      const columns = [
        { field: 'sno', headerName: 'S.No', flex: 0.05 },
        {
          field: 'test_date', headerName: 'Bill Date And Time', flex: 0.2,
          renderCell: (params) => (
            <Box component={'div'}>{params?.row?.test_date ? params?.row?.test_date : '-'}</Box>
          )
        },
        {
          field: 'test_name', headerName: 'Test Name', flex: 0.3,
          renderCell: (params) => (<Box component={'div'}>
            {params?.row?.test_name ? params?.row?.test_name?.length > 25 ?
              <Tooltip placement='top' title={params?.row?.test_name}><Typography>{params?.row?.test_name?.slice(0, 25) + '...'}</Typography></Tooltip> :
              <Typography>{params?.row?.test_name}</Typography> : '-'}
          </Box>)
        },
        {
          field: 'status', headerName: 'Result', flex: 0.3,
          renderCell: ({ row }) => (
            (row?.method === "Culture Test Result" && row?.result?.value?.length > 0) ?
              <Button size="small" variant="contained" sx={{ textTransform: "capitalize" }}
                onClick={() => {
                  this.setState({
                    isViewHistory: true,
                    viewCultureData: row?.result?.value?.length > 0 ? row?.result?.value : [],
                    testName: row.test_name,
                  })
                }}>{"View Culture History"}</Button>
              :
              <Box component={'div'}>{row?.result?.value?.length > 20 ?
                <Tooltip placement='top' title={row?.result?.value} arrow><div>{row?.result?.value?.slice(0, 20) + "..."}</div></Tooltip>
                : row?.result?.value ? row?.result?.value
                  : (row?.status === "Result Completed" || row?.status === "Result Delivered") ?
                    "Result Not Entered"
                    : "Result yet to complete"}</Box>)
        },
        {
          field: 'comments', headerName: 'Comments', flex: 0.2,
          renderCell: (params) => (<Box component={'div'}>
            {params?.row?.result?.comment ? params?.row?.result?.comment?.length > 25 ?
              <Tooltip placement='top' title={params?.row?.result?.comment}><Typography>{params?.row?.result?.comment?.slice(0, 25) + '...'}</Typography></Tooltip> :
              <Typography>{params?.row?.result?.comment}</Typography> : '-'}
          </Box>)
        },
      ]
      let patient_name = this.state.labPatientData?.patient_name || this.state.labPatientData?.first_name
      let patient_mobile = this.state.labPatientData?.patient_mobile_number || this.state.labPatientData?.mobile_number
      return (
        <Box component={'div'}>
          <Modal open={this.state.labHistoryPopup}>
            <Box component={'div'} className='eMed_lab_history_popup'>
              <Box component={'div'} height={'7vh'} pl={'1vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                <Typography fontSize={'0.9vw'} fontWeight={600}>Patient History</Typography>
                <Button
                  onClick={() => this.setState({ labHistoryPopup: false, labPatientData: null, page: 0 })}
                >
                  <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                </Button>
              </Box>
              <Box component={'div'} borderTop={'1px solid lightgray'} p={'0.5vw'}>
                <Typography fontSize={'0.8vw'} color={Colors.grayShade}>Patient Details</Typography>
                <Typography fontSize={'0.9vw'} fontWeight={600}>{patient_name}
                  <Typography component={'span'} fontSize={'0.9vw'} fontWeight={600}>{`(${this.state.labPatientData?.patient_age})`}</Typography>
                </Typography>
                <Typography fontSize={'0.8vw'} color={Colors.grayShade}>{`${this.state.labPatientData?.patient_account_number} | `}
                  <Typography component={'span'} fontSize={'0.8vw'} color={Colors.grayShade}>{patient_mobile}</Typography>
                </Typography>
              </Box>
              <Box component={'div'} p={'0.5vw'}>
                <DataGrid
                  columns={columns}
                  rows={this.state.labHistoryData}
                  getRowId={(row) => row['sno']}
                  sx={{ height: '25vw' }}
                  page={this.state.page}
                  pageSize={this.state.pageSize}
                  onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                  onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                  rowsPerPageOptions={[10, 20, 30]}
                  localeText={{
                    toolbarColumns: "",
                    toolbarDensity: "",
                    toolbarExport: "",
                    toolbarFilters: "",
                    toolbarExportPrint: ""
                  }}
                  components={{
                    NoRowsOverlay: () => (
                      <Box height="100%" display={'flex'} alignItems="center" justifyContent="center">
                        {t("NoRecordsFound")}
                      </Box>
                    )
                  }}
                  headerHeight={40}
                />
              </Box>
            </Box>
          </Modal>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getPurposeOfVisitList() {
    try {
      RestAPIService.getAll(Serviceurls.FO_POV_LIST).
        then((response) => {
          if (response.data.status === "success") {
            let data = response?.data && response?.data?.data?.length > 0 ? response.data.data : []
            let PurposeOfVisitList = []
            data.forEach(item => PurposeOfVisitList.push(item))
            localSetItem("PurposeOfVisit", JSON.stringify(PurposeOfVisitList))
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.errorMessage(e.message)
    }
  }

  getListCount = (status) => {
    if (status == true) {
      this.getBadgeCount()
    }
  }
  ClearCalledTrigger = () => {
    if (this.state.CallPatientTrigger) {
      this.setState({ CallPatientTrigger : false})
    }
  }
  updatetransmit = (status) => {
    if (status == true) {
      this.getBadgeCount()
    }
  }

  getRadTransmitBillCount = (status) => {
    if (status === true) {
      this.getRadTransmitList()
    }
  }

  renderDropdown = (label, mt, Data = [], stateKey) => {
    var states = this.state
    return (
      <Box sx={{ width: '45%', mt: mt }}>
        <Autocomplete
          size='small'
          clearIcon={false}
          sx={{ width: '14vw', marginTop: '1vw' }}
          componentsProps={{ popper: { style: { width: label === 'Doctor' ? "23vw" : "14vw" } } }}
          disabled={this.props?.is_Nurse && label === "Specialization" ? true : false}
          options={Data}
          getOptionLabel={(item) => ((label === "Doctor" ? item.doctor_name : label === "Specialization" ? item.name : label === "Patient Tag" ? item.patient_tag_name : label === "Appointment Type" ? item.appoinment_type : label === "Nurse" ? item.nurse : ""))}
          value={states[stateKey]}
          renderOption={label === 'Doctor' ? (props, item) => (
            <Box>
              {props["data-option-index"] === 0 ?
                <Box className="eMed_App_Doctor_table_head">
                  <Typography width={"50%"} fontWeight={'600'} fontSize={"0.8vw"}>Doctor Name</Typography>
                  <Typography width={"25%"} fontWeight={'600'} fontSize={"0.8vw"}>Total App</Typography>
                  <Typography width={"25%"} fontWeight={'600'} fontSize={"0.8vw"}>New App</Typography>
                </Box> : null}
              <Box component="li" className='eMed_App_Doctor_table_data' {...props}>
                <div style={{ width: "50%" }}>{item?.doctor_name.length > 15 ? <Tooltip placement="top" title={item?.doctor_name} arrow><div>{item?.doctor_name.slice(0, 16) + "..."}</div></Tooltip> : item?.doctor_name ? item?.doctor_name : "-"}</div>
                <Typography width={"25%"} textAlign={"center"} fontWeight={'600'} fontSize={"0.8vw"}>{item.total_appointment}</Typography>
                <Typography width={"25%"} textAlign={"center"} fontWeight={'600'} fontSize={"0.8vw"}>{item.new_appointment}</Typography>
              </Box>
            </Box>) : null}
          onChange={(event, newValue) => {
            states[stateKey] = newValue
            if (newValue !== null && "doctor_id" in newValue) {
              let docDetail = { ...newValue }
              docDetail["name"] = docDetail["specialization_name"];
              delete docDetail.specialization_name;
              docDetail["id"] = docDetail["specialization_id"];
              delete docDetail.specialization_id;
              states["RequestSelectedSpecID"] = docDetail;
            }
            if (newValue !== null && "name" in newValue) {
              let filteredLst = states["doctorList"].length > 0 && states["doctorList"].filter(item => item["specialization_id"] === newValue["id"])
              states["filteredDocList"] = filteredLst.length > 0 && filteredLst;
              states["RequestSelectedDocID"] = null;
            }
            if (newValue === null && label === "Specialization") {
              states["filteredDocList"] = states["doctorList"];
            }
            this.setState({ states })
          }}
          onKeyDown={(e) => e.stopPropagation()}
          renderInput={(params) => (
            <TextField
              {...params}
              label={label}
              placeholder={label}
              // onKeyDown={(e) => { e.stopPropagation() }}
            />
          )}
        />
      </Box>
    )
  }

  CreateAppointRequest(fromDoctor = false) {
    try {
      const { t } = this.props;
      var states = this.state;
      if ((states?.RequestMobileNumber.length === 10 && CommonValidation.numberOnly(states?.RequestMobileNumber) && CommonValidation.removeSpace(states?.RequestPatientName) != "") ||
        (states?.docRequestMobileNumber.length === 10 && CommonValidation.numberOnly(states?.docRequestMobileNumber) && CommonValidation.removeSpace(states?.docRequestPatientName) !== "")) {
        var AptDateTime = states.RequestAppointDatetym ? new Date(states.RequestAppointDatetym) : new Date(states.docRequestAppointDatetym)
        var AptDate = DateTime.fromJSDate(AptDateTime).toFormat('yyyy-MM-dd')
        var Aptime = DateTime.fromJSDate(AptDateTime).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let data = {}
        if (fromDoctor) {
          let clinicId = localGetItem('SelectedDoctorClinicId')
          data = {
            "patient_name": CommonValidation.removeSpace(states?.docRequestPatientName),
            "patient_id": states?.docSelectedRequestPatient?.patient_id ? states?.docSelectedRequestPatient?.patient_id : states?.doceditPatientId ? states?.doceditPatientId : null,
            "doctor_id": states?.docRequestSelectedDocID?.doctor_id ? states?.docRequestSelectedDocID?.doctor_id : null,
            "mobile": states?.docRequestMobileNumber,
            "reason": states?.docRequestpurposeOfVisit ? states?.docRequestpurposeOfVisit : "",
            "appointment_date": AptDate,
            "appointment_time": Aptime,
            'clinic_id': clinicId
          }
          if (this.state.doceditAppointmentId) {
            data["id"] = states?.doceditAppointmentId
            data["age"] = states?.docRequestPatientAge
          } else {
            data["age"] = states?.docRequestPatientAge?.replace('Y', '')
          }
        } else {
          data = {
            "patient_name": CommonValidation.removeSpace(states?.RequestPatientName),
            "patient_id": states?.SelectedRequestPatient?.patient_id ? states?.SelectedRequestPatient?.patient_id : states?.editPatientId ? states?.editPatientId : null,
            "doctor_id": states?.RequestSelectedDocID?.doctor_id ? states?.RequestSelectedDocID?.doctor_id : null,
            "mobile": states?.RequestMobileNumber,
            "reason": states?.RequestpurposeOfVisit ? states?.RequestpurposeOfVisit : "",
            "age": states?.SelectedRequestPatient?.patient_id ? null : states?.RequestPatientAge ? states?.RequestPatientAge?.replace('Y', '') : null,
            "appointment_date": AptDate,
            "appointment_time": Aptime,
          }
          if (this.state.editAppointmentId) {
            data["id"] = states?.editAppointmentId
          }
        }
        RestAPIService.create(data, Serviceurls.FO_TELE_APPOINTMENT_REQUEST)
          .then((response) => {
            if (response.data.status === "success") {
              this.successMessage(response.data.message)
              this.setState({ SelectedRequestTab: "appointment_request" })
              if (fromDoctor) {
                this.getDoctorAppointmentList(true)
                this.docClearRequestData()
              } else {
                this.props.history.push({ pathname: "/OPHome", state: { NewAppointmentCreated: true, NewMessage: response?.data?.message } })
                this.getAppointRequests()
                this.ClearRequestData()
              }
            }
          }).catch((e) => {
            if (e?.response?.data?.message) {
              this.setState({ isClked: false })
              this.errorMessage(e.response.data.message)
            } else {
              this.setState({ isClked: false })
              this.errorMessage(e.message)
            }
          })
      } else {
        this.setState({ isClked: false }, () => {
          if (states?.RequestMobileNumber === "") {
            this.errorMessage("Enter Mobile Number")
          }
          else if (CommonValidation.numberOnly(states?.RequestMobileNumber) && states?.RequestMobileNumber.length !== 10) {
            this.errorMessage("Mobile Number Must be in 10 Digits")
          }
          else if (!CommonValidation.numberOnly(states?.RequestMobileNumber) && Object.keys(this.state.SelectedRequestPatient).length === 0) {
            this.errorMessage("Invaild Mobile Number")
          }
          else if (CommonValidation.removeSpace(states?.RequestPatientName) === "") {
            this.errorMessage("Enter Patient Name")
          }
        })
      }
    }
    catch (e) {
      this.setState({ isClked: false })
      this.errorMessage(e.message)
    }
  }

  ClearRequestData() {
    this.setState({
      RequestPatientName: "",
      RequestMobileNumber: "",
      SelectedRequestPatient: {},
      RequestSelectedDocID: null,
      RequestSelectedSpecID: null,
      RequestPatientAge: "",
      RequestPatientName: "",
      RequestAppointDatetym: new Date(),
      RequestpurposeOfVisit: "",
      filteredDocList: [],
      editAppointmentId: null,
      editPatientId: null,
      isClked: false,
      patientSearch: '',
    })
  }

  docClearRequestData() {
    this.setState({
      docRequestPatientName: "",
      docRequestMobileNumber: "",
      docSelectedRequestPatient: {},
      docRequestSelectedDocID: null,
      docRequestSelectedSpecID: null,
      docRequestPatientAge: "",
      docRequestPatientName: "",
      docRequestAppointDatetym: new Date(),
      docRequestpurposeOfVisit: "",
      docfilteredDocList: [],
      doceditAppointmentId: null,
      doceditPatientId: null,
      isClked: false,
      docDatePicker: new Date(),
      patientSearch: '',
      App_DocID: null,
    }, () => { this.getDoctorAppointmentList() })
  }

  handlePatientSearch = () => {
    let data = this.state.duplicateFoReqList
    if (this.state.patientSearch !== "") {
      data = this.state.duplicateFoReqList.filter((item) => (
        (item?.first_name === "" ? "" : item?.first_name?.toLowerCase()?.includes(this.state.patientSearch?.toLowerCase()))
      ))
      this.setState({ FoRequestList: data })
    } else {
      this.getAppointRequests()
    }
  }

  handlePatientSearchClose = () => {
    this.setState({ patientSearch: '' })
    this.getAppointRequests()
  }

  QREntryList = () => {
    const { t } = this.props;
    return (
      <Menu
        open={this.state.QREntryPopUp}
        sx={{ height: '60vh' }}
        className="eMed_Header_Request_Pop"
        anchorEl={this.state.RequestAnchor}
        onClose={() => {
          this.setState({ QREntryPopUp: false, DateOpen: false, RegistrationDate: new Date(), QRPatientName: '' }, () => {
            this.getQREntryList()
          })
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'}>
          <Box>
            <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'}>
              <OutlinedInput
                className="emed_ordersearch"
                placeholder="Patient Name"
                onChange={(e) => {
                  let validate = CommonValidation.alphabetOnly(e.target.value)
                  validate &&
                    this.setState({ QRPatientName: e.target.value }, () => {
                      this.getQREntryList()
                    })
                }}
                inputProps={{ emed_tid: "patient_search" }}
                value={this.state.QRPatientName}
                size="small"
                sx={{ width: "15vw", marginRight: "0.5vw" }}
                endAdornment={
                  <InputAdornment position="end">
                    <i class="fas fa-search"></i>
                  </InputAdornment>
                }
              />

              <div style={{ padding: "0.5vw" }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    open={this.state.DatePickerOpen}
                    onOpen={() => { this.setState({ DatePickerOpen: true }) }}
                    onClose={() => { this.setState({ DatePickerOpen: false }) }}
                    value={this.state.RegistrationDate}
                    name={"datePicker"}
                    inputFormat='DD-MM-YYYY'
                    views={["year", "month", "day"]}
                    onChange={
                      (newDate) => {
                        this.setState({
                          RegistrationDate: newDate
                        }, () => {
                          this.getQREntryList()
                        });
                      }}
                    renderInput={(params) => <TextField
                      onKeyDown={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                      }} style={{ width: '12vw' }} size='small' {...params} inputProps={{ ...params.inputProps, 'emed_tid': 'QR_Date_Search' }} onClick={() => { this.setState({ DatePickerOpen: true }) }} />}
                  />
                </LocalizationProvider>
              </div>
            </Box>
          </Box>
        </Box>
        <Box>
          <Box className="eMed_App_reqest_table_head" sx={{ width: '51.5vw' }}>
            <Typography width={"20%"} fontWeight={'600'} fontSize={"0.8vw"}>Date & Time</Typography>
            <Typography width={"25%"} fontWeight={'600'} fontSize={"0.8vw"}>Patient Details</Typography>
            <Typography width={"35%"} fontWeight={'600'} fontSize={"0.8vw"}>Address</Typography>
            <Typography width={"20%"} fontWeight={'600'} fontSize={"0.8vw"} textAlign={'center'}>Action</Typography>
          </Box>
          <Divider />
          <Box height={"30vh"} overflow={"scroll"}>
            {
              this.state.QrEntryList.length > 0 ?
                this.state.QrEntryList.map((list) => {
                  let PatientName = `${list.patient_name} (${list?.age}/${list?.gender === 'm' ? "M" : list?.gender === 'f' ? "F" : "T"})`;
                  return (
                    <Box>
                      <Box className="eMed_Header_Request_item">
                        <Box width={'20%'}>
                          <Typography fontWeight={'600'} fontSize={"0.85vw"}>{list?.created_date ? formatDate(list.created_date) : "-"}</Typography>
                          <Typography fontSize={"0.7vw"}>{list?.created_date ? `${list?.created_date.split(" ")[1]} ${list?.created_date.split(" ")[2]}` : "-"}</Typography>
                        </Box>
                        <Box width={'25%'}>
                          <Typography fontWeight={'600'} fontSize={"0.85vw"}>
                            {PatientName ? PatientName?.length > 20 ?
                              <Tooltip placement='top' title={PatientName} arrow>
                                <div>{PatientName.slice(0, 20) + "..."}</div></Tooltip> :
                              PatientName : "-"}
                          </Typography>
                          {(list?.mobile_number) ?
                            <Typography fontSize={"0.7vw"}>{list.mobile_number}</Typography> : null}
                        </Box>
                        <Box width={'35%'}>
                          <Typography fontWeight={'600'} fontSize={"0.85vw"}>
                            {list?.address ? list?.address?.length > 20 ?
                              <Tooltip placement='top' title={list?.address} arrow>
                                <div style={{ fontWeight: "600", fontSize: "0.85vw" }}>{list?.address.slice(0, 20) + "..."}</div></Tooltip> :
                              list?.address : "-"}
                          </Typography>
                        </Box>
                        <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} width={'20%'}>
                          <Tooltip title={t("Create Appointment")} arrow placement='top'>
                            <IconButton emed_tid="create_appointment" onClick={() => {
                              this.setState({ QREntryPopUp: false, DateOpen: false, RegistrationDate: new Date(), QRPatientName: '' }, () => {
                                setCachevalue(JSON.stringify(list), "QRRequestPatientData")
                                if (formatDate(list.created_date) === DateTime.fromJSDate(new Date()).toFormat('dd-MM-yyyy')) {
                                  this.props.history.push({ pathname: "/OPHome/Billing/QuickBilling" })
                                } else {
                                  this.props.history.push({ pathname: "/OPHome/Billing/CreateAppointment" })
                                }
                              })
                            }}
                              position="end">
                              <img className="eMed_Tick_img" style={{ height: "2vw", width: "2vw" }} src={ImagePaths.Tick.default} alt={"Tick"} />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t("Cancel Request")} arrow placement='top'>
                            <IconButton emed_tid="cancel_request" onClick={() => { this.CancelQrRequest(list) }}>
                              <CancelIcon sx={{ height: '1.8vw', width: '1.8vw' }} color='error' />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                      <Divider />
                    </Box>
                  )
                }) :
                <Box>
                  <Box className="eMed_Header_Request_item" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '20vh' }}>
                    <Typography>{t("NoRecordsFound")}</Typography>
                  </Box>
                </Box>
            }
          </Box>
        </Box>
      </Menu>
    )
  }

  AppointmentRequestPop = () => {
    const { t } = this.props;
    var states = this.state
    return (
      <Menu
        open={this.state.RequestPopUp}
        sx={{ height: '60vh' }}
        className="eMed_Header_Request_Pop"
        anchorEl={this.state.RequestAnchor}
        onClose={() => {
          this.setState({ RequestPopUp: false, DateOpen: false, SelectedRequestTab: "appointment_request", datePicker: new Date(), App_DocID: null, patientSearch: '' }, () => {
            this.ClearRequestData()
            this.getAppointRequests()
          })
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Tabs

            value={this.state.SelectedRequestTab}
            onChange={(e, newValue) => {
              this.setState({ SelectedRequestTab: newValue, DateOpen: false, datePicker: new Date(), isappointRequest: false }, () => {
                this.ClearRequestData()
                if (this.state.SelectedRequestTab === "appointment_request") { this.getAppointRequests() }
              })
            }}
          // sx={{ backgroundColor: Colors.DataTblHeaderbg }}
          >
            <Tab value="appointment_request" label="Appointment Req" className='eMed_tab_header' />
            <Tab value="create_request" label="Create Tele Req" className='eMed_tab_header' />
          </Tabs>
          <Box>
            {
              this.state.SelectedRequestTab === "appointment_request" ?
                <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'}>
                  <TextField
                    placeholder={t("Patient Name")}
                    onChange={(e) => {
                      let validate = CommonValidation.alphabetOnly(e.target.value)
                      validate &&
                        this.setState({ patientSearch: e.target.value }, () => {
                          this.handlePatientSearch()
                        })
                    }}
                    value={this.state.patientSearch}
                    size="small"
                    sx={{ width: "10vw" }}
                    onKeyDown={(e) => { e.stopPropagation() }}
                    autoComplete='off'
                    inputProps={{ emed_tid: 'ApptReq_PatientSearch' }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {this.state.patientSearch.length ?
                            <IconButton onClick={this.handlePatientSearchClose} emed_tid = {"handlePatientSearchClose_testID"}>
                              <CloseIcon fontSize='small' />
                            </IconButton>
                            : null}
                        </InputAdornment>
                      ),
                    }}
                  />
                  <div style={{ padding: "0.5vw" }}>
                    <Autocomplete
                      size='small'
                      // clearIcon={false}
                      sx={{ width: '10vw' }}
                      options={this.state.doctorList}
                      getOptionLabel={(item) => (item.doctor_name)}
                      value={this.state.App_DocID}
                      onChange={(event, newValue) => {
                        states['App_DocID'] = newValue
                        this.setState({ states }, () => { this.getAppointRequests() })
                      }}
                      onKeyDown={(e) => e.stopPropagation()}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label={'Select Doctor'}
                          placeholder={'Select Doctor'}
                          // onKeyDown={(e) => { e.stopPropagation() }}
                        />
                      )}
                      renderOption={(props, option) => (
                        <li {...props} key={option?.doctor_id}>
                          {option?.doctor_name}
                        </li>
                      )}
                    />
                  </div>
                  <div style={{ padding: "0.5vw" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        open={this.state.DatePickerOpen}
                        onOpen={() => { this.setState({ DatePickerOpen: true }) }}
                        onClose={() => { this.setState({ DatePickerOpen: false }) }}
                        value={this.state.datePicker}
                        name={"datePicker"}
                        inputFormat='DD-MM-YYYY'
                        views={["year", "month", "day"]}
                        onChange={
                          (newDate) => {
                            this.setState({
                              datePicker: newDate
                            }, () => {
                              this.getAppointRequests()
                            });
                          }}
                        renderInput={(params) => <TextField
                          onKeyDown={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                          }} style={{ width: '10vw' }} size='small' {...params} onClick={() => { this.setState({ DatePickerOpen: true }) }} />}
                      />
                    </LocalizationProvider>
                  </div>
                  <Tooltip title={"Print"} placement='top' arrow>
                    <IconButton emed_tid = {"LabPrintIcon_testID"}  size="small" disabled={this.state.FoRequestList.length ? false : true} onClick={() => this.getAppointRequestsPrint()}>
                      <img
                        className='eMed_action_img'
                        src={ImagePaths.LabPrintIcon.default}
                        alt="print"
                      />
                    </IconButton>
                  </Tooltip>
                </Box> : null}
          </Box>
        </Box>
        {
          this.state.SelectedRequestTab === "appointment_request" ?
            <Box>
              <Box className="eMed_App_reqest_table_head" sx={{ width: '52.5vw' }}>
                <Typography width={"20%"} fontWeight={'600'} fontSize={"0.8vw"}>Date & Time</Typography>
                <Typography width={"30%"} fontWeight={'600'} fontSize={"0.8vw"}>Patient Details</Typography>
                <Typography width={"20%"} fontWeight={'600'} fontSize={"0.8vw"}>Clinic / Hospital</Typography>
                <Typography width={"15%"} fontWeight={'600'} fontSize={"0.8vw"} textAlign={'center'}>Source</Typography>
                <Typography width={"20%"} fontWeight={'600'} fontSize={"0.8vw"} textAlign={'center'}>Action</Typography>
              </Box>
              <Divider />
              <Box height={"30vh"} overflow={"scroll"}>
                {
                  this.state.FoRequestList.length > 0 ?
                    this.state.FoRequestList.map((list) => {
                      let PatientName = list.first_name + " " + list.last_name;
                      return (
                        <Box>
                          <Box className="eMed_Header_Request_item">
                            <Box width={'20%'}>
                              <Typography fontWeight={'600'} fontSize={"0.85vw"}>{list?.appointment_date ? formatDate(list.appointment_date) : "-"}</Typography>
                              <Typography fontSize={"0.7vw"}>{list?.appointment_time}</Typography>
                            </Box>
                            <Box width={'30%'}>
                              <Typography fontWeight={'600'} fontSize={"0.85vw"}>
                                {PatientName ? PatientName?.length > 20 ?
                                  <Tooltip placement='top' title={PatientName} arrow>
                                    <div>{PatientName.slice(0, 20) + "..."}</div></Tooltip> :
                                  PatientName : "-"}
                              </Typography>
                              {(list?.mobile_number || list?.patient_account_number) ?
                                <Typography fontSize={"0.7vw"}>{list.mobile_number} | {list.patient_account_number ? list.patient_account_number : "-"}</Typography> : null}
                            </Box>
                            <Box width={'20%'}>
                              <Typography fontWeight={'600'} fontSize={"0.85vw"}>{list?.doctor_name ? list.doctor_name : null}</Typography>
                              <Typography fontSize={"0.7vw"}>{list?.clinic_name}</Typography>
                            </Box>
                            <Box width={'15%'} display={'flex'} alignItems={'center'} justifyContent={'center'} >
                              {
                                list?.booked_through === "Mobile" ?
                                  <Tooltip title={t("MobileAppointment")} arrow placement='top'>
                                    <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.Phone.default}></img>
                                  </Tooltip> : list?.booked_through === "FO" ?
                                    <Tooltip title={t("TelephonicAppointment")} arrow placement='top'>
                                      <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.Telephonic.default}></img>
                                    </Tooltip> :
                                    <Tooltip title={t("WebAppointment")} arrow placement='top'>
                                      <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.Web.default}></img>
                                    </Tooltip>
                              }
                            </Box>
                            <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} width={'20%'} paddingRight={'0.5vw'}>
                              <Tooltip title={t("Edit Appointment")} arrow placement='top'>
                                <IconButton onClick={() => {
                                  let doctorData = {}, spcData = {};
                                  if (list?.doctor_id) {
                                    doctorData = this.state.doctorList?.length > 0 ? this.state.doctorList?.find(item => item?.doctor_id === list?.doctor_id) : {};
                                    spcData = (this.state.spcInfo?.length > 0 && Object.keys(doctorData)?.length > 0) ? this.state.spcInfo?.find(data => data?.id === doctorData?.specialization_id) : {};
                                  }
                                  let age = typeof (list?.age) !== "number" ? list?.age?.split("/") : list?.age;

                                  this.setState({
                                    SelectedRequestTab: "create_request",
                                    editAppointmentId: list?.id,
                                    editPatientId: list?.patient_id,
                                    RequestMobileNumber: list?.mobile_number ? list?.mobile_number : list?.mobile,
                                    RequestPatientName: list?.patient_name ? list?.patient_name : list?.first_name,
                                    RequestPatientAge: age?.length > 0 ? list?.age : age,
                                    RequestSelectedDocID: Object.keys(doctorData)?.length > 0 ? doctorData : null,
                                    RequestSelectedSpecID: Object.keys(spcData)?.length > 0 ? spcData : null,
                                    RequestAppointDatetym: list?.appointment_date + list?.appointment_time,
                                    RequestpurposeOfVisit: list.message,
                                  })
                                }}
                                  position="end">
                                  <Edit color='primary' />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={t("Create Appointment")} arrow placement='top'>
                                <IconButton onClick={() => {
                                  // this.postRequest(list, "Approve")
                                  this.setState({ RequestPopUp: false, DateOpen: false, patientSearch: '', App_DocID: null }, () => {
                                    setCachevalue(JSON.stringify(list), "AppRequestPatientData")
                                    if (list?.appointment_date === DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd')) {
                                      this.props.history.push({ pathname: "/OPHome/Billing/QuickBilling" })
                                    } else {
                                      this.props.history.push({ pathname: "/OPHome/Billing/CreateAppointment" })
                                    }
                                  })
                                }}>
                                  <CheckCircleIcon sx={{ height: '1.8vw', width: '1.8vw' }} color='primary' />
                                </IconButton>
                              </Tooltip>
                              <Tooltip title={t("Cancel Request")} arrow placement='top'>
                                <IconButton onClick={() => { this.postRequest(list, "Cancel") }}>
                                  <CancelIcon sx={{ height: '1.8vw', width: '1.8vw' }} color='error' />
                                </IconButton>
                              </Tooltip>
                            </Box>
                          </Box>
                          <Divider />
                        </Box>
                      )
                    }) :
                    <Box>
                      <Box className="eMed_Header_Request_item" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '20vh' }}>
                        <Typography>{t("NoRecordsFound")}</Typography>
                      </Box>
                    </Box>
                }
              </Box>
            </Box>
            :
            <Box className="eMEd_CreateRequest_Box" height={"35.2vh"} sx={{ width: '52.5vw' }}>
              <Grid container className='eMed_IPappoint_Grid_row'>
                <Grid item xs={4} paddingTop={"1vw"}>
                  <TextField
                    sx={{ width: '14vw', position: "relative" }}
                    size='small'
                    inputProps={{ maxLength: 15 }}
                    label={Object.keys(this.state.SelectedRequestPatient).length > 0 ? "Mobile Number *" : "Mobile / UHID *"}
                    value={this.state.RequestMobileNumber}
                    InputProps={{
                      endAdornment: this.state.isappointRequest ?
                        <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment>
                        : null,
                    }}
                    onChange={(e) => {

                      let number = CommonValidation.numberOnly(e.target.value);
                      this.setState({ RequestMobileNumber: e.target.value, }, () => {
                        if (Object.keys(this.state.SelectedRequestPatient).length > 0 || this.state.editPatientId) {
                          this.setState({
                            SelectedRequestPatient: {},
                            RequestPatientName: "",
                            RequestPatientAge: "",
                            editPatientId: null
                          })
                        }
                        if (number && this.state.RequestMobileNumber.length === 10) {
                          this.setState({}, () => { this.FOPatientSearch(true, this.state.RequestMobileNumber) })
                        }
                      })
                    }}
                    onKeyDown={(e) => {
                      e.stopPropagation()
                      this.setState({
                        RequestPatientListAnchor: e.currentTarget
                      }, () => {
                        if (this.state.RequestMobileNumber.length > 5 && e.key === "Enter") {
                          this.setState({}, () => { this.FOPatientSearch(true, this.state.RequestMobileNumber) })
                        }
                        if (this.state.RequestMobileNumber.length <= 5 && e.key === "Enter") {
                          this.errorMessage("Enter atleast 6 Characters for search")
                        }
                      })
                    }}
                  />
                  <Menu
                    open={this.state.RequestPatientMenuPop}
                    anchorEl={this.state.RequestPatientListAnchor}
                    // sx={{ position: 'absolute', top: '4vw', left: "-9.3vw" }}
                    className="eMed_Header_CreateRequest_Pop"
                    onClose={() => {
                      this.setState({ RequestPatientMenuPop: false })
                    }}
                  >
                    <Typography className='eMed_Request_sugg_Head'>Patient Name | UHID</Typography>
                    {
                      this.state.SearchedRequestPatientList.length > 0 ? this.state.SearchedRequestPatientList.map((item, index) => (
                        <MenuItem key={index} onClick={() => {
                          this.setState({
                            SelectedRequestPatient: item,
                            RequestPatientMenuPop: false,
                            RequestMobileNumber: item?.mobile_number,
                            RequestPatientName: item?.first_name ? item?.first_name + " " + item?.last_name : "-",
                            RequestPatientAge: item?.patient_age ? item?.patient_age : CalculateAgeFromDob(item.dob !== null ? new Date(item?.dob) : new Date(item?.approx_dob))
                          })
                        }}
                          sx={{ width: '14vw' }}>
                          <Box sx={{ display: 'flex', alignItems: 'center' }}>
                            <Typography fontWeight={600} fontSize={"0.8vw"} paddingRight={"0.3vw"}>{item?.first_name ? item?.first_name + " " + item?.last_name : "-"}</Typography> | <Typography paddingX={"0.3vw"} fontSize={"0.8vw"}>{item?.patient_account_number ? item?.patient_account_number : "-"}</Typography>
                          </Box>
                        </MenuItem>
                      )) : null
                    }
                  </Menu>
                </Grid>
                <Grid item xs={4} paddingTop={"1vw"} >
                  <TextField
                    sx={{ width: '14vw' }}
                    disabled={Object.keys(this.state.SelectedRequestPatient).length > 0 || this.state.editPatientId}
                    label="Patient Name *"
                    value={this.state.RequestPatientName}
                    onChange={(e) => {
                      var isAlpha = CommonValidation.alphabetOnly(e.target.value)
                      if (isAlpha || e.target.value == "") {
                        this.setState({ RequestPatientName: e.target.value })
                      }
                    }}
                    size='small'
                    onKeyDown={(e) => { e.stopPropagation() }}
                  />
                </Grid>
                <Grid item xs={4} paddingTop={"1vw"}>
                  <TextField
                    sx={{ width: '14vw' }}
                    inputProps={{ maxLength: 3 }}
                    disabled={Object.keys(this.state.SelectedRequestPatient).length > 0 || this.state.editPatientId}
                    label="Patient Age"
                    value={this.state.RequestPatientAge}
                    onChange={(e) => {
                      let number = CommonValidation.numberOnly(e.target.value);
                      if ((e.target.value < 150) && (number || e.target.value === "")) {
                        this.setState({ RequestPatientAge: e.target.value })
                      }
                    }}
                    size='small'
                    onKeyDown={(e) => { e.stopPropagation() }}
                  />
                </Grid>
              </Grid>
              <Grid container className='eMed_IPappoint_Grid_row' marginTop={"1vw"}>
                <Grid item xs={4}>
                  {this.renderDropdown("Doctor", "0vw", this.state.filteredDocList.length > 0 ? this.state.filteredDocList : this.state.doctorList, "RequestSelectedDocID")}
                </Grid>
                <Grid item xs={4}>
                  {this.renderDropdown("Specialization", "0vw", this.state.spcInfo, "RequestSelectedSpecID")}
                </Grid>
                <Grid item xs={4} >
                  <Box sx={{ width: '93%', mt: '1vw' }}>
                    <LocalizationProvider dateAdapter={AdapterDateFns}>
                      <DateTimePicker
                        open={this.state.DateOpen}
                        onOpen={() => { this.setState({ DateOpen: true }) }}
                        onClose={() => { this.setState({ DateOpen: false }) }}
                        inputFormat='dd-MM-yyyy & hh:mm a'
                        value={this.state.RequestAppointDatetym}
                        minDate={new Date()}
                        maxDate={new Date((new Date).getFullYear(), (new Date).getMonth() + 3, 0)}
                        onChange={(newDate) => {
                          if (newDate === null) {
                            this.setState({ RequestAppointDatetym: new Date() })
                          }
                          else {
                            this.setState({ RequestAppointDatetym: newDate }, () => this.getDoctorsList(true))
                          }
                        }}
                        renderInput={(params) => <TextField
                          sx={{ width: '14vw' }}
                          size='small'
                          inputProps={{ style: { fontSize: '0.8vw' } }}
                          onKeyDown={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                          }}
                          onClick={() => { this.setState({ DateOpen: true }) }}
                          {...params}
                          fullWidth
                          variant='outlined'
                          label="Appointment Date & Time *"
                        />}
                      />
                    </LocalizationProvider>

                  </Box>
                </Grid>
              </Grid>
              <Autocomplete
                inputProps={{ maxLength: 250 }}
                options={this.state.POVList}
                getOptionLabel={(options) => options}
                onChange={(e, value) => {
                  this.setState({
                    RequestpurposeOfVisit: value
                  })
                }}
                onKeyDown={(e) => { e.stopPropagation() }}
                clearIcon={false}
                size='small'
                sx={{ width: '44vw', paddingLeft: '2vw', mt: "2vw" }}
                value={this.state.RequestpurposeOfVisit}
                renderInput={(params) => <TextField sx={{ width: "48vw" }} onChange={(event) => {
                  this.setState({
                    RequestpurposeOfVisit: event.target.value
                  })
                }}
                  {...params}
                  placeholder="Purpose Of Visit"
                />}
              />
              <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography marginLeft={"2vw"}>{Object.keys(this.state.SelectedRequestPatient).length > 0 ? `UHID : ${this.state.SelectedRequestPatient?.patient_account_number}` : ""}</Typography>
                <Box className="eMed_CreateRequest_BtnBox">
                  <Button size='small' id="eMed_Config_btn" sx={{ marginRight: '1vw' }} onClick={() => { this.ClearRequestData() }} variant='outlined'>Clear</Button>
                  <Button size='small' id="eMed_Config_btn" disabled={this.state.isClked} variant='contained' onClick={() => this.setState({ isClked: true }, () => this.CreateAppointRequest())}>{this.state.editAppointmentId ? "Update Request" : "Create Request"}</Button>
                </Box>
              </Box>
            </Box>
        }
      </Menu>
    )
  }

  NotificationPopup = () => {
    return (
      <Modal open={this.state.notifyPopup} onClose={() => { this.setState({ notifyPopup: false }) }} sx={{ top: "9vh", left: "62vw", height: "45vh", width: "30vw" }}>
        <Paper sx={{ height: '100%', padding: '1vw', overflow: 'auto' }}>
          <Box display={'flex'} justifyContent={'space-between'}>
            <Typography fontWeight={'600'}>Notification</Typography>
            <IconButton onClick={() => { this.setState({ notifyPopup: false }) }} size="small" ><img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt={"close"} /></IconButton>
          </Box>
          <hr />
          {this.state.FoNotifyList?.map((item) => {
            let DateTime = item?.reason_type === "In Permission" ? `${item?.session_date} (${item?.from_time} - ${item?.to_time})` : `(${item?.from_date} - ${item?.to_date})`
            return (
              <Box sx={{ display: 'flex', alignItems: 'center', gap: '1vw', borderBottom: '1px solid black', padding: '1vw' }}>
                <Box><img src={ImagePaths.NotiTick.default} alt='tick' style={{ height: '2vw', width: '2vw' }} /></Box>
                <Box>
                  <Typography fontWeight={'600'}>{item?.doctor_name ? item?.doctor_name : ''} - {item?.reason_type ? item?.reason_type : ''}</Typography>
                  <Typography fontWeight={'600'}>{item?.reason_type === "Out For Emergency" ? "" : `${DateTime}`}</Typography>
                  <Typography>{item?.reason ? item?.reason?.length > 32 ? <Tooltip placement="top" title={item.reason} arrow><div>{item.reason?.slice(0 , 30) + '...'}</div></Tooltip>: item?.reason : ''}</Typography>
                </Box>
              </Box>
            )
          })}
        </Paper>
      </Modal>
    )
  }

  //Doctor Module Header Search

  getDoctorClinicData = (isOpthometry = false) => {
    try {
      RestAPIService.getAll(isOpthometry ? Serviceurls.DOC_OPTHOMETRY_CLINIC_LIST_GET : Serviceurls.DOC_CLINIC_LIST_GET)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              ClinicList: response.data.data
            }, () => {
              if (!localGetItem('SelectedDoctorClinicId') && !getCachevalue('SelectedDoctorClinic')) {
                this.setState({
                  SelectedDoctorClinic: isOpthometry ? (this.state.SelectedDoctorClinic === "all" ? "all" : response?.data?.data[0]?.doctor_id) : response?.data?.data[0]?.clinic_id
                }, () => {
                  let UserData = JSON.parse(localGetItem("loggedInUserInfo"))
                  localSetItem('SelectedDoctorClinicId', this.state.SelectedDoctorClinic)
                  setCachevalue(this.state.SelectedDoctorClinic === 'all' ? UserData?.clinic_id : this.state.SelectedDoctorClinic, "SelectedDoctorClinic")
                  this.getFollowUpData()
                  if (isOpthometry) {

                  } else {
                    this.getDoctorAppointmentList()
                  }
                })
              }

            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getTransferAppointmentData = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_TRANSFER_REQUEST)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              transferReqData: response.data.data
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getFollowUpData = () => {
    let clinicId = localGetItem('SelectedDoctorClinicId')
    var date = new Date(this.state.follow_up_date)
    var appointment_date = DateTime.fromJSDate(date).toFormat('yyyy-MM-dd')
      try {
        RestAPIService.getAll(Serviceurls.DOC_FOLLOWUP_GET + `?clinic_id=${clinicId}&follow_up_date=${appointment_date}&follow_up_to_date=${appointment_date}`)
          .then((response) => {
            if (response.data.status === 'success') {
              this.setState({
                followUpData: response.data.data?.follow_up
              })
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.errorMessage(error.message)
            } else {
              this.errorMessage(error.message)
            }
          })
      } catch (error) {
        this.errorMessage(error.message)
      }
  }

  GetOTRoomList(item) {
    try {
      RestAPIService.getAll(Serviceurls.OT_ROOM_CONFIG + `?surgery_date=${(item?.surgery_date).split("T")[0]}`).
        then((response) => {
          if (response.data.status === "success") {
            let data = response.data.data
            this.setState({
              OTRoomData: data
            }, () => {
              this.setState({ OTallocationPopup: true })
            })
          }
          else {
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    }
    catch (error) {
      if (error?.response?.data?.status === "fail") {
        this.errorMessage(error.response.data.message)
      } else {
        this.errorMessage(error.message)
      }
    }
  }

  getotRequestList = () => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_OT_REQUEST_GET)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              OTreqData: response?.data?.data ? response?.data?.data : [],
              AcceptRequest: false
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getUnlockNotesData = () => {
    try {
      let clinicId = localGetItem('SelectedDoctorClinicId')
      if (clinicId) {
        RestAPIService.getAll(Serviceurls.DOC_UNLOCK_DOCTOR_NOTES + `?clinic_id=${clinicId}`)
          .then((response) => {
            if (response.data.status === 'success') {
              this.setState({
                unlockDocData: response.data.data,
                unlockDocDataCount: response.data?.total_count ? response.data?.total_count : 0
              })
            }
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.errorMessage(error.message)
            } else {
              this.errorMessage(error.message)
            }
          })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  DoctorPatientSearch(DocRequest = false, MobNum = null) {
    try {
      let { t } = this.props;
      let API_URL = ''
      if (DocRequest) {
        API_URL = Serviceurls.FO_PATIENT_SEARCH + `?search_key=${MobNum}&address_key=&father_husband_key=&clinic_id=${this.state.SelectedDoctorClinic}`
        this.AppointMobileSrch(true);
      } else if (this.state.isOptometry) {
        API_URL = Serviceurls.FO_PATIENT_SEARCH + `?search_key=${this.state.PatientNameSearchKey}&address_key=${this.state.PatientAddressKey}&father_husband_key=${this.state.PatientFathHusKey}&clinic_id=${this.state.loggedInuserInfo?.clinic_id}&mother_name_key=${this.state.PatientMotherKey}`
      } else {
        API_URL = Serviceurls.FO_PATIENT_SEARCH + `?search_key=${this.state.PatientNameSearchKey}&address_key=${this.state.PatientAddressKey}&father_husband_key=${this.state.PatientFathHusKey}&clinic_id=${this.state.SelectedDoctorClinic}&mother_name_key=${this.state.PatientMotherKey}`
        this.isPatientisSearching(true)
      }
      RestAPIService.getAll(API_URL).
        then((response) => {
          if (response.data.status === "success") {
            if (DocRequest) {
              this.setState({
                docSearchedRequestPatientList: response.data.data,
                docRequestPatientMenuPop: response.data.data.length > 0 ? true : false
              })
            } else {
              this.setState({
                SearchDoctorPatientList: response.data.data,
                // RequestPatientMenuPop: response?.data?.data?.patient?.length > 0 ? true : false
              }, () => {
                if (this.state.PatientNameSearchKey !== "" || this.state.PatientAddressKey !== "" || this.state.PatientFathHusKey !== "" || this.state.PatientMotherKey !== "") {
                  if (response.data.data?.length > 0) {
                    this.setState({ PatientSearchClicked: true, AddressFilterClicked: false })
                  } else {
                    this.errorMessage(t("NoRecordsFound"))
                  }
                }
              })
            }
            this.isPatientisSearching(false)
            this.AppointMobileSrch(false);
          }
          else {
            this.isPatientisSearching(false)
            this.AppointMobileSrch(false);
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.isPatientisSearching(false)
          this.AppointMobileSrch(false);
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.isPatientisSearching(false)
      this.AppointMobileSrch(false);
      this.errorMessage(e.message)
    }
  }

  getDoctorPatientDetailData = (id, status, fromOPT, item, Type) => {
    try {
      RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + id)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.setState({
              patientDetails: response?.data?.data
            }, () => {
              let UserData = JSON.parse(localGetItem("loggedInUserInfo"))
              let fromFO = UserData?.module_name === "front_office" ? true : false
              if (fromOPT && !fromFO) {
                if (Type == "TransferAppoint") {
                  setCachevalue(JSON.stringify(this.state.patientDetails), "DoctorPatientData")
                  this.props.history.push({ pathname: "/DoctorHome/Doctor/Demographics", state: { fromCreate: true, patientId: id, transferID: item?.id } })
                } else {
                  setCachevalue(JSON.stringify(this.state.patientDetails), "DoctorPatientData")
                  this.props.history.push({ pathname: "/DoctorHome/Doctor/Demographics", state: { fromCreate: true, patientId: id, followupID: item?.id, AppointDate: item?.follow_up_date } })
                }
              } else if (fromFO && Type === "follow_ups") {
                setCachevalue(JSON.stringify(this.state.patientDetails), "AppRequestPatientData")
                if (item?.follow_up_date === DateTime.fromJSDate(new Date()).toFormat('dd-MM-yyyy')) {
                  this.props.history.push({ pathname: "/OPHome/Billing/QuickBilling", state: { fromFollowUp: true, followupID: item?.id, AppointDate: item?.appointment_date }})
                } else {
                  this.props.history.push({ pathname: "/OPHome/Billing/CreateAppointment", state: { fromFollowUp: true, followupID: item?.id, AppointDate: item?.appointment_date } })
                }
              }
              else {
                if (status === 'Edit Patient') {
                  setCachevalue(JSON.stringify(this.state.patientDetails), "DoctorPatientData")
                  this.props.history.push({ pathname: "/DoctorHome/Demographics/Update", state: { fromCreate: false, doctorUpdate: true } })
                } else if ((status === 'Create Patient') || (status === 'Request Create')) {
                  setCachevalue(JSON.stringify(this.state.patientDetails), "DoctorPatientData")
                  this.props.history.push({ pathname: "/DoctorHome/Doctor/Demographics", state: { fromCreate: true, patientId: id, followupID: item?.id, AppointDate: item?.follow_up_date } })
                }
              }
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  DoctorHeaderSearch = () => {
    const { t } = this.props;
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    var UserData = myUser
    return (
      <Box sx={{ marginLeft: '3.2vw' }}>
        <Box component={'div'} display={'flex'} flexDirection={'row'} sx={{ borderRadius: "0.3vw" }}>
          <FormControl size='small' sx={{ width: "13vw", borderRadius: '0.2vw', }} >
            <Select
              className='mui_header_card_dropdown_fo'
              value={this.state.SelectedDoctorClinic}
              onChange={(e) => {
                this.setState({ SelectedDoctorClinic: e.target.value }, () => {
                  localSetItem('SelectedDoctorClinicId', this.state.SelectedDoctorClinic)
                  setCachevalue(this.state.SelectedDoctorClinic === 'all' ? myUser?.clinic_id : this.state.SelectedDoctorClinic, "SelectedDoctorClinic")
                  this.props.history.push({ pathname: "/DoctorHome", state: { SelectedDoctorClinic: this.state.SelectedDoctorClinic } })
                })
              }}
              sx={{ backgroundColor: 'white' }}
              MenuProps={{ style: { maxHeight: 250 } }}
            >
              {UserData?.service_type === "OPTOMETRY" ? <MenuItem value={"all"}>{"All Doctors"}</MenuItem> : null}
              {
                (window.location.pathname === "/DoctorHome") ?
                  this.state.ClinicList?.length > 0 && this.state.ClinicList?.map((item, index) => (
                    <MenuItem key={index} value={this.state.isOptometry ? item?.doctor_id : item?.clinic_id}>{this.state.isOptometry ? item?.first_name : item?.clinic_name}</MenuItem>
                  )) : null
              }
            </Select>
          </FormControl>
          {/* {UserData?.service_type === "OPTOMETRY" ? null : */}
          <OutlinedInput
            placeholder={t("SearchPatients")}
            onKeyDown={(event) => {
              if (event.key === "Enter" && event.target.value != "" && event.target.value?.length > 2) {
                this.DoctorPatientSearch()
              }
            }}
            onChange={(e) => {
              this.setState({
                PatientNameSearchKey: e.target.value
              })
            }}
            inputProps={{ maxLength: 25 }}
            value={this.state.PatientNameSearchKey}
            size="small"
            className='eMed_header_Input'
            endAdornment={
              <Box component={'div'} display={'flex'} flexDirection={'row'}>
                <Divider orientation="vertical" variant="middle" flexItem />
                {this.state.isPatientisSearching ?
                  <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center", width: '2.5vw' }}>
                    <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment>
                  </Box> :
                  <Tooltip title={t("Search")} arrow>
                    <IconButton disabled={this.state.PatientNameSearchKey === "" && this.state.PatientAddressKey === "" && this.state.PatientFathHusKey === "" && this.state.PatientMotherKey === ""}
                      onClick={(e) => {
                        if (this.state.PatientNameSearchKey?.length > 2 && this.state.PatientNameSearchKey !== "" || this.state.PatientAddressKey !== "" || this.state.PatientFathHusKey !== "" || this.state.PatientMotherKey !== "") {
                          this.DoctorPatientSearch()
                        }
                      }} position="end">
                      <SearchIcon />
                    </IconButton>
                  </Tooltip>}
                <Tooltip title={t("Filter")} arrow>
                  <IconButton onClick={() => { this.setState({ AddressFilterClicked: true }) }} position="end">
                    <Badge color="error" size='small' variant="dot" max={999} invisible={this.state.PatientAddressKey === "" && this.state.PatientFathHusKey === "" && this.state.PatientMotherKey === ""}>
                      <FilterAltIcon />
                    </Badge>
                  </IconButton>
                </Tooltip>
                <Tooltip title={t("FaceRecognition")} arrow>
                  <IconButton onClick={() => { this.setState({ docFaceRecogClicked: true }) }} position="end">
                    <CenterFocusWeakIcon />
                  </IconButton>
                </Tooltip>
              </Box>
            }
          />
          {/* } */}
        </Box>
        <Menu
          open={this.state.PatientSearchClicked}
          className="eMed_Header_Search_Pop"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            this.setState({
              PatientSearchClicked: false,
              // PatientNameSearchKey: "",
              PatientAddressKey: "",
              PatientFathHusKey: "",
              PatientMotherKey: ""
            })
          }}
        >
          {
            this.state.SearchDoctorPatientList.length > 0 ?
              this.state.SearchDoctorPatientList?.map((list) => {
                let PatientFullName = `${list.first_name} ${list.last_name}`
                list["patient_name"] = PatientFullName
                list["patient_mobile_number"] = list.mobile_number
                list["patient_photo_url"] = list.photo_url
                return (
                  <Box>
                    <Box className="eMed_Header_Search_item">
                      <CommonPatientDetails data={list} fromSearch={true} isCommonUhid={this.state.common_uhid} />
                      {(window.location.pathname === "/DoctorHome") ?
                        (list.appointment_date === "" || list.appointment_status === "Cancelled") ?
                          <Box component={'div'} className="eMed_Header_Search_icons">
                            {this.state.isOptometry ?
                              <>
                                <Tooltip placement='top' title={'View'} arrow>
                                  <IconButton
                                    onClick={() => {
                                      this.setState({
                                        PatientNameSearchKey: "",
                                        PatientAddressKey: "",
                                        PatientFathHusKey: "",
                                        PatientSearchClicked: false,
                                        SearchedPatientList: [],
                                        PatientMotherKey: "",
                                        common_uhid: false,
                                      }, () => this.props.history?.push({ pathname: 'DoctorHome/Reports/DoctorVisit', state: { patientDetails: list ,fromView:true } }))
                                    }}
                                  ><VisibilityIcon color='primary' /></IconButton>
                                </Tooltip>
                              </> :
                              <>
                                <Tooltip title={t("EditPatientDetails")} arrow>
                                  <IconButton onClick={() => {
                                    this.setState({
                                      PatientNameSearchKey: "",
                                      PatientAddressKey: "",
                                      PatientFathHusKey: "",
                                      PatientSearchClicked: false,
                                      SearchedPatientList: [],
                                      PatientMotherKey: "",
                                      common_uhid: false,
                                    }, () => {
                                      this.getDoctorPatientDetailData(list?.patient_id, 'Edit Patient')
                                    })
                                  }}
                                    position="end">
                                    <Edit color='primary' />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title={t("CreateAppointment")} arrow>
                                  <IconButton onClick={() => {
                                    this.setState({
                                      PatientNameSearchKey: "",
                                      PatientAddressKey: "",
                                      PatientFathHusKey: "",
                                      PatientSearchClicked: false,
                                      SearchedPatientList: [],
                                      PatientMotherKey: "",
                                      common_uhid: false,
                                    }, () => {
                                      this.getDoctorPatientDetailData(list?.patient_id, 'Create Patient')
                                    })
                                  }}
                                    position="end">
                                    <PersonAddAlt1Icon color='primary' />
                                  </IconButton>
                                </Tooltip>
                              </>}
                          </Box>
                          :
                          <Box component={'div'} className="eMed_Header_Search_icons">
                            {this.state.isOptometry ?
                              <>
                                <Tooltip placement='top' title={'View'} arrow>
                                  <IconButton
                                    onClick={() => {
                                      this.setState({
                                        PatientNameSearchKey: "",
                                        PatientAddressKey: "",
                                        PatientFathHusKey: "",
                                        PatientSearchClicked: false,
                                        SearchedPatientList: [],
                                        PatientMotherKey: "",
                                        common_uhid: false,
                                      }, () => this.props.history?.push({ pathname: 'DoctorHome/Reports/DoctorVisit', state: { patientDetails: list ,fromView:true } }))
                                    }}
                                  ><VisibilityIcon color='primary' /></IconButton>
                                </Tooltip>
                              </> :
                              <>
                                <Tooltip title={t("EditPatientDetails")} arrow>
                                  <IconButton onClick={() => {
                                    this.setState({
                                      PatientNameSearchKey: "",
                                      PatientAddressKey: "",
                                      PatientFathHusKey: "",
                                      PatientSearchClicked: false,
                                      SearchedPatientList: [],
                                      PatientMotherKey: "",
                                      common_uhid: false,
                                    }, () => {
                                      setCachevalue(JSON.stringify(list), "DoctorPatientData")
                                      this.props.history.push({ pathname: "/DoctorHome/Demographics/update", state: { fromCreate: false, doctorUpdate: true } })
                                    })
                                  }}
                                    position="end">
                                    <Edit color='primary' />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title={t("CreateAppointment")} arrow>
                                  <IconButton onClick={() => {
                                    this.setState({
                                      PatientNameSearchKey: "",
                                      PatientAddressKey: "",
                                      PatientFathHusKey: "",
                                      PatientSearchClicked: false,
                                      SearchedPatientList: [],
                                      PatientMotherKey: "",
                                      common_uhid: false,
                                    }, () => {
                                      this.getDoctorPatientDetailData(list?.patient_id, 'Create Patient')
                                    })
                                  }}
                                    position="end">
                                    <PersonAddAlt1Icon color='primary' />
                                  </IconButton>
                                </Tooltip>
                                {/* <Typography>{list.appointment_date && formatDate(list.appointment_date)}</Typography> */}
                              </>
                            }
                          </Box>
                        : null
                      }
                    </Box>
                    <Divider />
                  </Box>
                )
              }) : null
          }
        </Menu>
        <Menu
          open={this.state.AddressFilterClicked}
          className="eMed_Header_Filter_Pop"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            this.setState({ AddressFilterClicked: false })
          }}
        >
          <Box className="eMed_Header_Filter_item" sx={{ height: this.state.error && this.state.errorFathhus ? "30vh" : "28vh" }}>
            <TextField
              label={t("SearchByAddress")}
              inputProps={{ maxLength: 50 }}
              size='small'
              value={this.state.PatientAddressKey}
              onChange={(e) => { this.setState({ PatientAddressKey: e.target.value }) }}
              onKeyDown={(e) => e.stopPropagation()} />
            <TextField
              label={t("SearchByFatherHusbandName")}
              inputProps={{ maxLength: 50 }}
              size='small'
              value={this.state.PatientFathHusKey}
              onChange={(e) => {
                var isSpecial = /^[ A-Za-z.,/]*$/.test(e.target.value)
                if (isSpecial) {
                  this.setState({ PatientFathHusKey: e.target.value, errorFathhus: false, error: false })
                } else {
                  this.setState({ errorFathhus: true, error: false })
                }
              }}
              error={this.state.errorFathhus}
              helperText={this.state.errorFathhus ? "Enter Only Alphabets" : null}
              onKeyDown={(e) => e.stopPropagation()} />
            <TextField
              label={t("Search By Mother Name")}
              inputProps={{ maxLength: 50 }}
              size='small'
              value={this.state.PatientMotherKey}
              onChange={(e) => {
                var isSpecial = /^[ A-Za-z.,/]*$/.test(e.target.value)
                if (isSpecial) {
                  this.setState({ PatientMotherKey: e.target.value, error: false, errorFathhus: false })
                } else {
                  this.setState({ error: true, errorFathhus: false })
                }
              }}
              error={this.state.error}
              helperText={this.state.error ? "Enter Only Alphabets" : null}
              onKeyDown={(e) => e.stopPropagation()} />
            <Box component={'div'} display='flex' flexDirection='row' justifyContent={'center'}  >
              <Button id="eMed_Config_btn" variant='contained' size='small' disabled={(this.state.PatientAddressKey === "" && this.state.PatientFathHusKey === "" && this.state.PatientMotherKey === "") || (this.state.error || this.state.errorFathhus)} onClick={() => { this.DoctorPatientSearch() }}>Search</Button>
              <Button id="eMed_Config_btn" variant='outlined' size='small' sx={{ marginLeft: "2vw" }} onClick={() => { this.setState({ PatientAddressKey: "", PatientFathHusKey: "", PatientMotherKey: "", error: false, errorFathhus: false }) }}>Clear</Button></Box>
          </Box>
        </Menu>
      </Box>
    )
  }

  checkallfunc() {
    if (this.state.booked && this.state.arrived && this.state.withDoctor && this.state.completed && this.state.cancelled && this.state.withNurse) {
      this.setState({
        all: true
      })
    }
  }

  handleCheckBox = (e, key) => {
    var states = this.state
    states[key] = e.target.checked
    this.setState({ states }, () => {
      switch (key) {
        case 'booked':
          this.setState({
            booked: e.target.checked, all: false
          }, () => { this.checkallfunc() })
          break;
        case 'arrived':
          this.setState({
            arrived: e.target.checked, all: false
          }, () => { this.checkallfunc() })
          break;
        case 'withDoctor':
          this.setState({
            withDoctor: e.target.checked, all: false
          }, () => { this.checkallfunc() })
          break;
        case 'withNurse':
          this.setState({
            withNurse: e.target.checked, all: false
          }, () => { this.checkallfunc() })
          break;
        case 'cancelled':
          this.setState({
            cancelled: e.target.checked, all: false
          }, () => { this.checkallfunc() })
          break;
        case 'completed':
          this.setState({
            completed: e.target.checked, all: false
          }, () => { this.checkallfunc() })
          break;
        case 'all':
          this.setState({
            all: this.state.all
          }, () => {
            this.setState({
              booked: this.state.all, arrived: this.state.all, withDoctor: this.state.all,
              completed: this.state.all, cancelled: this.state.all, withNurse: this.state.all
            })
          })
          break;
        default:
          if (states.booked && states.arrived && states.withDoctor && states.completed && states.cancelled && states.withNurse) {
            this.setState({
              all: true
            })
          } else {
            this.setState({
              all: true, booked: true, arrived: true, withDoctor: true,
              completed: true, cancelled: true, withNurse: true
            })
          }
          break;
      }

      if (key === 'fullReport') {
        if (this.state.isOPT) {
          this.setState({
            fullReport: e.target.checked, complaints: true, ocular: true,
            Illness: true, vision: true,
            gonioscopy: true, autoRefract: true,
            prevGlassPower: true, subjectiveRefract: true,
            retinoscopicRefract: true, IntraPressure: true,
            Anterior: true, Posterior: true, AnteriorImg: true, OtherInvestigation: true,
            fundus: true, Keratometry: true, medication: true,
            glassPrescrib: true, Review: true,
            Treatment: true, investigation: true,
            diagnosis: true, vitals: true, counselling: true, overallNotes: true, OcularResponse: true
          }, () => {
            if (this.state.fullReport) {
              this.setState({
                fullReport: e.target.checked, complaints: true, ocular: true,
                Illness: true, vision: true,
                gonioscopy: true, autoRefract: true,
                prevGlassPower: true, subjectiveRefract: true,
                retinoscopicRefract: true, IntraPressure: true,
                Anterior: true, Posterior: true, AnteriorImg: true, Keratometry: true,
                fundus: true, medication: true, OtherInvestigation: true,
                glassPrescrib: true, Review: true,
                Treatment: true, investigation: true,
                diagnosis: true, vitals: true, counselling: true, overallNotes: true, OcularResponse: true
              })
            } else {
              this.setState({
                fullReport: false, complaints: false, ocular: false,
                Illness: false, vision: false,
                gonioscopy: false, autoRefract: false,
                prevGlassPower: false, subjectiveRefract: false,
                retinoscopicRefract: false, IntraPressure: false,
                Anterior: false, Posterior: false, AnteriorImg: false, Keratometry: false,
                fundus: false, medication: false, OtherInvestigation: false,
                glassPrescrib: false, Review: false,
                Treatment: false, investigation: false,
                diagnosis: false, vitals: false, counselling: false, overallNotes: false, OcularResponse: false
              })
            }
          })
        } else {
          this.setState({
            fullReport: e.target.checked, symptoms: true,
            allergies: true, vitals: true,
            examination: true, diagnosis: true,
            prescription: true, prevPrescrip: true,
            test: true, plan: true,
            counselling: true, generalPatient: true
          }, () => {
            if (this.state.fullReport) {
              this.setState({
                fullReport: e.target.checked, symptoms: true,
                allergies: true, vitals: true,
                examination: true, diagnosis: true,
                prescription: true, prevPrescrip: true,
                test: true, plan: true,
                counselling: true, generalPatient: true
              })
            } else {
              this.setState({
                fullReport: false, symptoms: false,
                allergies: false, vitals: false,
                examination: false, diagnosis: false,
                prescription: false, prevPrescrip: false,
                test: false, plan: false,
                counselling: false, generalPatient: false
              })
            }
          })
        }
      } else {
        if (this.state.isOPT) {
          if (states.complaints && states.ocular && states.Illness && states.vitals && states.vision && states.gonioscopy && states.autoRefract && states.prevGlassPower && states.subjectiveRefract && states.retinoscopicRefract && states.IntraPressure && states.Anterior && states.AnteriorImg && states.OtherInvestigation && states.Posterior && states.Keratometry && states.fundus && states.diagnosis && states.medication && states.glassPrescrib && states.Review && states.Treatment && states.counselling && states.investigation && states.overallNotes && states.OcularResponse) {
            this.setState({ fullReport: true })
          } else {
            this.setState({ fullReport: false })
          }
        } else {
          if (states.symptoms && states.allergies && states.vitals && states.examination && states.diagnosis && states.prescription && states.test && states.plan && states.generalPatient && states.counselling) {
            this.setState({ fullReport: true })
          } else {
            this.setState({ fullReport: false })
          }
        }
      }
    })
  }

  onClickDoctorNotesDefault = () => {
    try {
      if (this.state.isOPT) {
        this.setState({
          fullReport: false, complaints: true, ocular: true,
          Illness: true, vision: true,
          gonioscopy: true, autoRefract: false,
          prevGlassPower: false, subjectiveRefract: false,
          retinoscopicRefract: false, IntraPressure: true,
          Anterior: true, Posterior: true, AnteriorImg: true, Keratometry: false,
          fundus: false, medication: true, OtherInvestigation: true,
          glassPrescrib: true, Review: true,
          Treatment: true, investigation: true,
          diagnosis: true, vitals: true, counselling: true, overallNotes: false, OcularResponse: false
        })
      } else {
        this.setState({
          fullReport: false, symptoms: true,
          allergies: false, vitals: false,
          examination: false, diagnosis: true,
          prescription: true, prevPrescrip: false,
          test: false, plan: false,
          counselling: true, generalPatient: true
        })
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postDoctorPrint = () => {
    try {
      let status = [];
      let states = this.state
      if (states.all) {
        status = []
      } else {
        if (states.booked) {
          status.push('Booked')
        }
        if (states.arrived) {
          status.push('Arrived')
        }
        if (states.withNurse) {
          status.push('With Nurse')
        }
        if (states.withDoctor) {
          status.push('With Doctor')
        }
        if (states.completed) {
          status.push('Completed')
        }
        if (states.cancelled) {
          status.push('Cancelled')
        }
      }
      let clinicId = localGetItem('SelectedDoctorClinicId')
      let startDate = new Date(this.state.doctorAppointmentDate)
      let date = DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd')
      RestAPIService.getAll(Serviceurls.DOC_APPOINTMENT_LIST + `?appointment_date=${date}&clinic_id=${clinicId}&appointment_status=${status}&export=pdf`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({
            doctorAnchor: null, openDoctorPrint: false, all: true,
            booked: true, arrived: true, withNurse: true, withDoctor: true,
            completed: true, cancelled: true
          })
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getDoctorAppointmentList = (fromUpdate = false) => {
    try {
      let clinicId = localGetItem('SelectedDoctorClinicId') ? localGetItem('SelectedDoctorClinicId') : this.state.doctorClinicId
      let startDate = new Date(this.state.datePicker)
      let selectedDate = DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd')
      RestAPIService.getAll(Serviceurls.DOC_APPOINTMENT_REQUEST + '?from_date=' + selectedDate + '&to_date=' + selectedDate + '&clinic_id=' + clinicId)
        .then((response) => {
          if (response?.data?.status === 'success') {
            if (fromUpdate) {
              this.setState({
                doctorAppointmentData: response?.data?.data,
                docSelectedRequestTab: 'appointment_request'
              })
            } else {
              this.setState({
                doctorAppointmentData: response?.data?.data,
              })
            }
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  postDoctorCancelAppointment = (selectedId) => {
    try {
      let data = {
        id: selectedId,
        status: 'Cancel'
      }
      this.setState({ postLoad: true })
      RestAPIService.create(data, Serviceurls.DOC_APPOINTMENT_REQUEST)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              doctorAppointmentAnchor: null,
              openDoctorAppointment: false,
              postLoad: false,
              datePicker: new Date()
            }, () => this.successMessage(response.data.message))
            this.getDoctorAppointmentList()
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ postLoad: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ postLoad: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ postLoad: false })
      this.errorMessage(error.message)
    }
  }

  createOTAppointment = (id, status) => {
    try {
      let data = {
        id: id,
        status: status,
        slot_id: status === "Reject" ? null : this.state?.selectedSlot?.id
      }
      this.setState({ postLoad: true })
      RestAPIService.updateWithOutId(data, Serviceurls.DOC_OT_REQUEST_GET)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.setState({
              OTallocationPopup: false,
              OTrequestPopup: false,
              selectedOT: null, selectedSlot: null,
              AcceptRequest: true, deletePopup: false
            }, () => { this.getotRequestList() })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.setState({ postLoad: false })
            this.errorMessage(error.response.data.message)
          } else {
            this.setState({ postLoad: false })
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ postLoad: false })
      this.errorMessage(error.message)
    }
  }

  getDoctorNotesPrint = () => {
    try {
      let notesList = [];
      let states = this.state
      let flag = false
      if (states.isOPT) {
        flag = states.doctorPrintList?.opt_doctor?.every(item => !item.is_checked)
        let fullReport = states.doctorPrintList?.opt_doctor?.find(item => item?.label === 'Full Report')
        if (fullReport?.is_checked) {
          states.doctorPrintList?.opt_doctor?.filter(item => item?.is_checked && item.label !== 'Full Report')?.forEach(element => {
            notesList?.push(element?.value)
          })
        } else {
          states.doctorPrintList?.opt_doctor?.filter(item => item?.is_checked && item.label !== 'Full Report')?.forEach(element => {
            if (element?.is_checked) {
              notesList?.push(element?.value)
            }
          })
        }
      } else {
        flag = states.doctorPrintList?.opt_doctor?.every(item => !item.is_checked)
        let fullReport = states.doctorPrintList?.other_doctor?.find(item => item?.label === 'Full Report')
        if (fullReport?.is_checked) {
          notesList?.push('all')
        } else {
          states.doctorPrintList?.other_doctor?.filter(item => item?.is_checked && item.label !== 'Full Report')?.forEach(element => {
            if (element?.is_checked) {
              notesList?.push(element?.value)
            }
          })
        }
      }
      if (!flag) {
        let patientDetails = getCachevalue('DoctorPatientData')
        let appointmentId = JSON.parse(patientDetails)
        const ServiceURL = (this.state.isOPT ? Serviceurls.DOC_DOCTOR_NOTES_PRINT_OPT : Serviceurls.DOC_DOCTOR_NOTES_PRINT)
        RestAPIService.getAll(ServiceURL + `?appointment_id=${appointmentId?.appointment_id}&patient_id=${appointmentId?.patient_id}&doctor_notes=${notesList}`)
          .then((response) => {
            const file = new Blob(
              [response.data],
              { type: 'application/pdf' });
            const fileURL = URL.createObjectURL(file);
            window.open(fileURL);
            this.setState({ openDoctorNotesPrintAnchor: null, openDoctorNotesPrint: false, doctorPrintList: JSON.parse(this.state.dupDocPrintList) })
          }).catch((error) => {
            if (error?.response?.data?.message) {
              this.errorMessage(error.response.data.message)
            } else {
              this.errorMessage(error.message)
            }
          })
      } else {
        this.errorMessage('Please Select Atleast one for Print')
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  printDoctorHistoryData = () => {
    try {
      let patientDetails = getCachevalue('DoctorPatientData')
      let appointmentId = JSON.parse(patientDetails)
      RestAPIService.getAll(Serviceurls.DOC_HISTORY_REPORT_PRINT + `?appointment_id=${appointmentId?.appointment_id}&patient_id=${appointmentId?.patient_id}&export=pdf`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  printImmunizationData = () => {
    try {
      let patientDetails = getCachevalue('DoctorPatientData')
      let appointmentId = JSON.parse(patientDetails)
      RestAPIService.getAll(Serviceurls.DOC_IMMUNIZATION_PRINT + `?patient_id=${appointmentId?.patient_id}&export_type=pdf`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  // Doctor Apponintment Request Popup starts =====>
  doctorAppointmentRequest = () => {
    const { t } = this.props;
    return (
      <Menu
        open={this.state.openDoctorAppointment}
        className=""
        sx={{ position: 'absolute', top: '3vw', left: '7vw' }}
        anchorEl={this.state.doctorAppointmentAnchor}
        onClose={() => {
          this.setState({ openDoctorAppointment: false, docDateOpen: false, docSelectedRequestTab: "appointment_request", docDatePicker: new Date() }, () => {
            this.docClearRequestData()
            this.getDoctorAppointmentList()
          })
        }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
      >
        <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
          <Tabs
            value={this.state.docSelectedRequestTab}
            onChange={(e, newValue) => {
              this.setState({ docSelectedRequestTab: newValue, docDateOpen: false, docDatePicker: new Date(), isDocAppointRequest: false }, () => {
                this.docClearRequestData()
                if (this.state.docSelectedRequestTab === "appointment_request") { this.getDoctorAppointmentList() }
              })
            }}
          >
            <Tab value="appointment_request" label="Appointment Request" className='eMed_tab_header' />
            <Tab value="create_request" label="Create Telephonic Request" className='eMed_tab_header' />
          </Tabs>
          <Box component={'div'}>
            {
              this.state.docSelectedRequestTab === "appointment_request" ?
                <Box display={'flex'} flexDirection={'row'} justifyContent={'flex-end'} alignItems={'center'}>
                  <div style={{ padding: "0.5vw" }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DatePicker
                        open={this.state.docDatePickerOpen}
                        onOpen={() => { this.setState({ docDatePickerOpen: true }) }}
                        onClose={() => { this.setState({ docDatePickerOpen: false }) }}
                        value={this.state.docDatePicker}
                        name={"datePicker"}
                        inputFormat='DD-MM-YYYY'
                        views={["year", "month", "day"]}
                        onChange={
                          (newDate) => {
                            this.setState({
                              docDatePicker: newDate
                            }, () => {
                              this.getDoctorAppointmentList()
                            });
                          }}
                        renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} style={{ width: '10vw' }} size='small' {...params} onClick={() => { this.setState({ docDatePickerOpen: true }) }} />}
                      />
                    </LocalizationProvider>
                  </div>
                </Box> : null
            }
          </Box>
        </Box>
        {this.state.docSelectedRequestTab === 'appointment_request' ? this.renderAppointmentTab() : this.renderTelephonicTab()}
      </Menu>
    )
  }

  renderAppointmentTab = () => {
    try {
      const { t } = this.props
      return (
        <Box>
          <Box className="eMed_App_reqest_table_head">
            <Typography width={"20%"} fontWeight={'600'} fontSize={"0.8vw"}>Date & Time</Typography>
            <Typography width={"30%"} fontWeight={'600'} fontSize={"0.8vw"}>Patient Details</Typography>
            <Typography width={"20%"} fontWeight={'600'} fontSize={"0.8vw"}>Clinic / Hospital</Typography>
            <Typography width={"15%"} fontWeight={'600'} fontSize={"0.8vw"} textAlign={'center'}>Source</Typography>
            <Typography width={"20%"} fontWeight={'600'} fontSize={"0.8vw"} textAlign={'center'}>Action</Typography>
          </Box>
          <Divider />
          <Box height={"30vh"} overflow={"auto"}>
            {
              this.state.doctorAppointmentData?.length > 0 ?
                this.state.doctorAppointmentData?.map((list) => {
                  let PatientName = list.first_name + " " + list.last_name;
                  return (
                    <Box>
                      <Box className="eMed_Header_Request_item">
                        <Box width={'20%'}>
                          <Typography fontWeight={'600'} fontSize={"0.85vw"}>{list?.appointment_date ? formatDate(list?.appointment_date) : "-"}</Typography>
                          <Typography fontSize={"0.7vw"}>{list?.appointment_time}</Typography>
                        </Box>
                        <Box width={'30%'}>
                          <Typography fontWeight={'600'} fontSize={"0.85vw"}>
                            {PatientName ? PatientName?.length > 20 ?
                              <Tooltip placement='top' title={PatientName} arrow>
                                <div>{PatientName.slice(0, 20) + "..."}</div></Tooltip> :
                              PatientName : "-"}
                          </Typography>
                          {(list?.mobile_number || list?.patient_account_number) ?
                            <Typography fontSize={"0.7vw"}>{list?.mobile_number} | {list?.patient_account_number ? list?.patient_account_number : "-"}</Typography> : null}
                        </Box>
                        <Box width={'20%'}>
                          <Typography fontWeight={'600'} fontSize={"0.85vw"}>{list?.doctor_name ? list.doctor_name : null}</Typography>
                          <Typography fontSize={"0.7vw"}>{list?.clinic_name}</Typography>
                        </Box>
                        <Box width={'15%'} display={'flex'} alignItems={'center'} justifyContent={'center'} >
                          {
                            list?.booked_through === "Mobile" ?
                              <Tooltip title={t("MobileAppointment")} arrow placement='top'>
                                <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.Phone.default}></img>
                              </Tooltip> : list?.booked_through === "FO" ?
                                <Tooltip title={t("TelephonicAppointment")} arrow placement='top'>
                                  <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.Telephonic.default}></img>
                                </Tooltip> :
                                <Tooltip title={t("WebAppointment")} arrow placement='top'>
                                  <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.Web.default}></img>
                                </Tooltip>
                          }
                        </Box>
                        <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} width={'20%'} paddingRight={'0.5vw'}>
                          <Tooltip title={t("Edit Appointment")} arrow placement='top'>
                            <IconButton onClick={() => {
                              // ======> Commented for no doctor and specialization for doctor module
                              // let doctorData = {}, spcData = {};
                              // if (list?.doctor_id) {
                              //   doctorData = this.state.doctorList?.length > 0 ? this.state.doctorList?.find(item => item?.doctor_id === list?.doctor_id) : {};
                              //   spcData = (this.state.spcInfo?.length > 0 && Object.keys(doctorData)?.length > 0) ? this.state.spcInfo?.find(data => data?.id === doctorData?.specialization_id) : {};
                              // }
                              let age = typeof (list?.age) !== "number" ? list?.age?.split("/") : list?.age;

                              this.setState({
                                docSelectedRequestTab: "create_request",
                                doceditAppointmentId: list?.id,
                                doceditPatientId: list?.patient_id,
                                docRequestMobileNumber: list?.mobile_number ? list?.mobile_number : list?.mobile,
                                docRequestPatientName: list?.patient_name ? list?.patient_name : list?.first_name,
                                docRequestPatientAge: age?.length > 0 ? parseInt(age[0]) : age,
                                // docRequestSelectedDocID: Object.keys(doctorData)?.length > 0 ? doctorData : null,
                                // docRequestSelectedSpecID: Object.keys(spcData)?.length > 0 ? spcData : null,
                                docRequestAppointDatetym: list?.appointment_date + list?.appointment_time,
                                docRequestpurposeOfVisit: list?.message,
                              })
                            }}
                              position="end">
                              <Edit color='primary' />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t("Create Appointment")} arrow placement='top'>
                            <IconButton onClick={() => {
                              // this.postRequest(list, "Approve")
                              this.setState({
                                doctorAppointmentAnchor: null,
                                openDoctorAppointment: false,
                                docDatePicker: new Date()
                              }, () => {
                                if (list?.patient_id) {
                                  setCachevalue(JSON.stringify(list), "AppRequestPatientData")
                                  this.props.history.push({ pathname: "/DoctorHome/Doctor/Demographics", state: { fromRequest: false, fromCreate: true, patientId: list?.patient_id } })
                                  // this.getDoctorPatientDetailData(list?.patient_id, 'Request Create', list)
                                } else {
                                  setCachevalue(JSON.stringify(list), "AppRequestPatientData")
                                  this.props.history.push({ pathname: "/DoctorHome/Doctor/Demographics", state: { fromCreate: true, fromRequest: true } })
                                }
                              })
                            }}>
                              <CheckCircleIcon sx={{ height: '1.8vw', width: '1.8vw' }} color='primary' />
                            </IconButton>
                          </Tooltip>
                          <Tooltip title={t("Cancel Request")} arrow placement='top'>
                            <IconButton onClick={() => { this.postDoctorCancelAppointment(list?.id) }}>
                              <CancelIcon sx={{ height: '1.8vw', width: '1.8vw' }} color='error' />
                            </IconButton>
                          </Tooltip>
                        </Box>
                      </Box>
                      <Divider />
                    </Box>
                  )
                }) :
                <Box>
                  <Box className="eMed_Header_Request_item" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '20vh' }}>
                    <Typography>{t("NoRecordsFound")}</Typography>
                  </Box>
                </Box>
            }
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderAllocationValues = (Heading, value) => {
    return (
      <div style={{ marginLeft: "0.85vw", marginTop: "0.75vw" }}>
        <div style={{ color: Colors.SecondaryText, fontSize: "0.85vw" }}>{Heading}</div>
        <div style={{ fontWeight: 600, marginTop: "0.5vw" }}>
          {value?.length > 15 ?
            <Tooltip placement='top' title={value}>
              <Typography fontSize={'0.9vw'} fontWeight={600}>{value?.slice(0, 15) + '...'}</Typography>
            </Tooltip> :
            <Typography fontSize={'0.9vw'} fontWeight={600}>{value ? value : "-"}</Typography>
          }
        </div>
      </div>
    )
  }

  popupClose = () => {
    this.setState({ deletePopup: false })
  }
  removeappointment = () => {
    this.createOTAppointment(this.state?.selectedId, "Reject")
  }

  OTallocationTable = () => {
    const { t } = this.props
    var states = this.state?.allocationDetails
    try {
      return (
        <div>
          <div style={{ borderBottom: "1px solid #E3E2E2", paddingLeft: "-1vw", paddingRight: "-1vw" }}>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: "0.65vw" }}>
              <div>OT Allocation</div>
              <Button className='Common_Btn_Min_Width' onClick={() => { this.setState({ selectedOT: null, selectedSlot: null }, () => { this.setState({ OTallocationPopup: false }) }) }}>
                <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
              </Button>
            </div>
          </div>
          <div className='eMed_App_DataView' style={{ width: '100%', height: "35vh", overflow: "auto" }}>
            <div style={{ borderBottom: "1px solid #E3E2E2" }}>
              <div style={{ display: "flex", flexDirection: "column", alignItems: "flex-start" }}>
                <Typography fontSize={'0.9vw'} marginY={"0.25vw"}>Patient Details</Typography>
                <Box component={'div'} mr={'0.5vw'} style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
                  <div className="eMed_Com_Patient_Details">
                    <div className="eMed_Com_Patient_Avatar">
                      <img className="eMed_Com_Patient_AvaIcon" style={{ borderColor: '#29B6F6' }}
                        src={states?.photo_url === null || states?.photo_url === "" || states?.photo_url === undefined ? states?.gender === "m" ? ImagePaths.Male.default : ImagePaths.Female.default : states?.photo_url} alt={'Avatar'} />
                    </div>
                  </div>
                  <Box component={'div'}>
                    <Typography component={'div'}>
                      {(states?.patient_name?.length) > 20 ?
                        <Tooltip title={states?.patient_name} placement={'top'} arrow>
                          <div style={{ display: "flex", flexDirection: "row" }}>
                            <Typography id='eMed_Com_Patient_Name' style={{ fontSize: "0.8vw" }}>{(states?.patient_name ? `${states?.patient_title ? states?.patient_title + " " : ""}` + ((states?.patient_name?.slice(0, 20) + '...')) : '-') + ' ' + (states?.patient_age ? states?.patient_age : '-') + '/' + (states?.gender ? states?.gender : '-')}</Typography>
                          </div>
                        </Tooltip> :
                        <div style={{ display: "flex", flexDirection: "row" }}>
                          <Typography id='eMed_Com_Patient_Name' style={{ fontSize: "0.8vw" }}>{(states?.patient_name ? `${states?.patient_title ? states?.patient_title + " " : ""}` + states?.patient_name : '-') + ' ' + (states?.patient_age ? states?.patient_age : '-') + '/' + (states?.gender ? states?.gender : '-')}</Typography>
                        </div>
                      }
                    </Typography>
                    <Typography id='eMed_Com_Patient_UhidText'>{(states?.uhid) + ' | ' + (states?.patients_mobile_number ? states?.patients_mobile_number : '-')}</Typography>
                  </Box>
                </Box>
              </div>
              <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", paddingBottom: "0.5vw", marginLeft: "-0.5vw" }}>
                {this.renderAllocationValues("Surgery", states?.surgery_name)}
                {this.renderAllocationValues("Surgery Doctor", states?.surgeon_name)}
                {this.renderAllocationValues("Anaesthetist", states?.anaesthetist)}
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-around", paddingTop: "1vw" }}>
              <Autocomplete
                disableClearable
                size='small'
                value={this.state.selectedOT}
                options={this.state.OTRoomData}
                getOptionLabel={(option) => (option?.ot_name)}
                style={{ width: "13vw" }}
                onChange={(event, newValue) => {
                  this.setState({
                    selectedOT: newValue,
                    OTdetails: newValue?.slot_data,
                    selectedSlot: null
                  })
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={"Select OT *"}
                  />
                )} />
              <Autocomplete
                disableClearable
                size="small"
                disabled={!this.state.selectedOT}
                style={{ width: "13vw" }}
                value={this.state.selectedSlot}
                options={this.state.OTdetails}
                getOptionLabel={(option) => (option?.slot_name)}
                onChange={(event, newValue) => {
                  this.setState({
                    selectedSlot: newValue
                  })
                }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('Select Slot *')}
                  />
                )} />
            </div>
            <Box component={'div'} display={'flex'} justifyContent={"flex-end"} alignItems={'center'} mt={"1.5vw"}>
              <Typography fontSize={'0.9vw'} color={'red'} style={{ marginRight: "5vw" }}>{this.state?.OTRoomData.length > 0 ? null : "OT not available!"}</Typography>
              <Button
                sx={{ height: '2vw', width: '5vw', mr: '1vw' }}
                variant='outlined'
                onClick={() => { this.setState({ selectedOT: null, selectedSlot: null }, () => { this.setState({ OTallocationPopup: false }) }) }}
              >
                CANCEL
              </Button>
              <Button
                variant='contained'
                sx={{ height: '2vw', width: '7vw' }}
                onClick={() => { this.createOTAppointment(states?.id, "Accept") }}
              >
                ADD TO OT
              </Button>
            </Box>
          </div>
        </div>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  OTRequestTable = () => {
    const { t } = this.props
    try {
      return (
        <div>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: "0.65vw" }}>
            <div>Counsellor Request</div>
            <IconButton onClick={() => { this.setState({ OTrequestPopup: false }) }} size="small" ><img className="eMed_DeletePopup_CrossIcon" src={ImagePaths.LabCloseButton.default} alt={"close"} /></IconButton>
          </div>
          {this.state.OTreqData && this.state.OTreqData.length > 0 ?
            <div className='eMed_App_DataView' style={{ width: '55vw' }}>
              <Box sx={{ maxHeight: '45vw', overflow: 'auto', border: '1px solid lightgray' }} component={'div'}>
                <Table stickyHeader size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Counseller</TableCell>
                      <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Patient Details</TableCell>
                      <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Surgery</TableCell>
                      <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Surgery Date & Time</TableCell>
                      <TableCell align='center' sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.OTreqData?.length > 0 ? this.state.OTreqData?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell>
                          {item?.counsellor_name ? item?.counsellor_name?.length > 30 ?
                            <Tooltip placement='top' title={item?.counsellor_name}>
                              <Typography fontSize={'0.9vw'}>{item?.counsellor_name?.slice(0, 30) + '...'}</Typography>
                            </Tooltip> :
                            <Typography fontSize={'0.9vw'}>{item?.counsellor_name ? item?.counsellor_name : "-"}</Typography>
                            : "-"}
                        </TableCell>
                        <TableCell style={{ fontSize: "0.9vw" }}>
                          <div>
                            <div style={{ fontSize: "0.9vw" }}>{`${item?.patient_name ? `${item?.patient_title ? item?.patient_title : ""} ${item?.patient_name}` : ""}`}</div>
                            <div style={{ fontSize: "0.75vw" }}>{`${item?.patients_mobile_number ? item?.patients_mobile_number : "-"} | ${item?.uhid ? item?.uhid : "-"} `}</div>
                          </div>
                        </TableCell>
                        <TableCell>
                          {item?.surgery_name ? item?.surgery_name?.length > 15 ?
                            <Tooltip placement='top' title={item?.surgery_name}>
                              <Typography fontSize={'0.9vw'}>{item?.surgery_name?.slice(0, 15) + '...'}</Typography>
                            </Tooltip> :
                            <Typography fontSize={'0.9vw'}>{item?.surgery_name ? item?.surgery_name : "-"}</Typography>
                            : "-"}
                        </TableCell>
                        <TableCell>
                          <Typography fontSize={'0.9vw'}>{item?.surgery_date ? `${(item?.surgery_date.split("T")[0]).split("-").reverse().join("-")} | ${timeOnlyConvert(item?.surgery_date.split("T")[1])}` : "-"}</Typography>
                        </TableCell>
                        <TableCell align='center'>
                          <Box component={'div'} display={'flex'} flexDirection={"row"} justifyContent={'center'}>
                            <Tooltip title={t("Create Appointment")} arrow placement='top'>
                              <IconButton onClick={() => {
                                this.setState({ allocationDetails: item }, () => { this.GetOTRoomList(item) })
                              }}>
                                <CheckCircleIcon sx={{ height: '1.8vw', width: '1.8vw' }} color='primary' />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={t("Cancel Request")} arrow placement='top'>
                              <IconButton onClick={() => { this.setState({ deletePopup: true, selectedId: item?.id }) }}>
                                <CancelIcon sx={{ height: '1.8vw', width: '1.8vw' }} color='error' />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )) : null}
                  </TableBody>
                </Table>
              </Box>
            </div> :
            <div><Typography id='eMed_Pres_pop_NORec' style={{ marginTop: "10vw" }}>{"No records found"}</Typography></div>}
        </div>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  FollowupTable = () => {
    const { t } = this.props
    try {
      return (
        <div>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: "0.65vw" }}>
            <div>Follow-Up Appointment</div>
            <div>
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  open={this.state.followDatePickerOpen}
                  onOpen={() => { this.setState({ followDatePickerOpen: true }) }}
                  onClose={() => { this.setState({ followDatePickerOpen: false }) }}
                  value={this.state.follow_up_date}
                  name={"datePicker"}
                  inputFormat='DD-MM-YYYY'
                  views={["year", "month", "day"]}
                  minDate={new Date()}
                  onChange={
                    (newDate) => {
                      this.setState({
                        follow_up_date: newDate
                      }, () => {
                        this.getFollowUpData()
                      });
                    }}
                  renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} style={{ width: '10vw' }} size='small' {...params} onClick={() => { this.setState({ followDatePickerOpen: true }) }} />}
                />
              </LocalizationProvider>
            </div>
          </div>
          {this.state.followUpData && this.state.followUpData?.length > 0 ?
            <div className='eMed_App_DataView' style={{ width: '55vw' }}>
              <Box sx={{ maxHeight: '45vw', overflow: 'auto', border: '1px solid lightgray' }} component={'div'}>
                <Table stickyHeader size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Patient Details</TableCell>
                      <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Follow-Up Date</TableCell>
                      <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Comments</TableCell>
                      <TableCell align='center' sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.followUpData?.length > 0 ? this.state.followUpData?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ fontSize: "0.9vw" }}>
                          <div>
                            <div style={{ fontSize: "0.9vw" }}>{`${item?.patient_fname ? (item?.patient_fname) : ""} ${item?.patient_lname ? item?.patient_lname : ""}`}</div>
                            <div style={{ fontSize: "0.75vw" }}>{`${item?.patient_Mobile_no ? item?.patient_Mobile_no : "-"}`}</div>
                          </div>
                        </TableCell>
                        <TableCell style={{ fontSize: "0.9vw" }}>{item?.follow_up_date ? item?.follow_up_date : '-'}</TableCell>
                        <TableCell>
                          {item?.comments ? item?.comments?.length > 15 ?
                            <Tooltip placement='top' title={item?.comments}>
                              <Typography fontSize={'0.9vw'}>{item?.comments?.slice(0, 15) + '...'}</Typography>
                            </Tooltip> :
                            <Typography fontSize={'0.9vw'}>{item?.comments ? item?.comments : "-"}</Typography>
                            : "-"}
                        </TableCell>
                        <TableCell align='center'>
                          <Box component={'div'} display={'flex'} flexDirection={"row"} justifyContent={'center'}>
                            <Tooltip title={t("Create Appointment")} arrow placement='top'>
                              <IconButton onClick={() => {
                                this.setState({ FollowupPopup: false }, () => {
                                  if (item?.patient_id) {
                                    this.getDoctorPatientDetailData(item?.patient_id, 'Create Patient', true, item, "follow_ups")
                                  }
                                })
                              }}>
                                <CheckCircleIcon sx={{ height: '1.8vw', width: '1.8vw' }} color='primary' />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={t("Cancel Request")} arrow placement='top'>
                              <IconButton onClick={() => { this.removeDataList(item) }}>
                                <CancelIcon sx={{ height: '1.8vw', width: '1.8vw' }} color='error' />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )) : null}
                  </TableBody>
                </Table>
              </Box>
            </div> :
            <div><Typography id='eMed_Pres_pop_NORec' style={{ marginTop: "10vw" }}>{"No records found"}</Typography></div>}
        </div>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  unlockNotesTable = () => {
    const { t } = this.props
    try {
      return (
        <div>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: "0.65vw" }}>
            <div>Unlocked Doctor Notes</div>
          </div>
          <div>
            <Box>
              <Box className="eMed_App_reqest_table_head" width={'35vw'}>
                <Typography width={"50%"} fontWeight={'600'} fontSize={"0.8vw"}>Date</Typography>
                <Typography width={"50%"} fontWeight={'600'} fontSize={"0.8vw"}>No of Patient</Typography>
              </Box>
              <Box height={"57vh"} overflow={"scroll"} mt={"0.5vw"}>
                {this.state.unlockDocData && this.state.unlockDocData.length > 0 ?
                  this.state.unlockDocData.map((list) => {
                    return (
                      <Box>
                        <Box component={'div'} className="eMed_Header_Request_item" style={{ cursor: "pointer" }} width={'35vw'} onClick={() => { this.setState({ unlockDate: list?.appointment_date?.split("T")[0], unlockNotespopup: false }) }}>
                          <Box width={'50%'}>
                            <Typography fontWeight={'600'} fontSize={"0.85vw"}>{list?.appointment_date ? list?.appointment_date : "-"}</Typography>
                          </Box>
                          <Box width={'50%'}>
                            <Typography fontWeight={'600'} fontSize={"0.85vw"}>
                              {list?.total ? list?.total : "-"}
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    )
                  })
                  :
                  <Box>
                    <Box className="eMed_Header_Request_item" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', height: '20vh' }}>
                      <Typography>{t("NoRecordsFound")}</Typography>
                    </Box>
                  </Box>
                }
              </Box>
            </Box>
          </div>
        </div>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  TransferAppointmentTable = () => {
    const { t } = this.props
    try {
      return (
        <div>
          <div style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between", marginBottom: "0.65vw" }}>
            <div>Transfer Appointment</div>
          </div>
          {this.state.transferReqData && this.state.transferReqData.length > 0 ?
            <div className='eMed_App_DataView' style={{ width: '55vw' }}>
              <Box sx={{ maxHeight: '45vw', overflow: 'auto', border: '1px solid lightgray' }} component={'div'}>
                <Table stickyHeader size='small'>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Specialization</TableCell>
                      <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Doctor Name</TableCell>
                      <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Patient Details</TableCell>
                      <TableCell sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Transfer Notes</TableCell>
                      <TableCell align='center' sx={{ fontWeight: 600, fontSize: '0.9vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Action</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {this.state.transferReqData?.length > 0 ? this.state.transferReqData?.map((item, index) => (
                      <TableRow key={index}>
                        <TableCell style={{ fontSize: "0.9vw" }}>{item?.specialization_name ? item?.specialization_name : '-'}</TableCell>
                        <TableCell style={{ fontSize: "0.9vw" }}>{`${item?.from_doctor__title ? item?.from_doctor__title : ""} ${item?.from_doctor__first_name ? item?.from_doctor__first_name : "-"} ${item?.from_doctor__initial ? item?.from_doctor__initial : ""}`}</TableCell>
                        <TableCell style={{ fontSize: "0.9vw" }}>
                          <div>
                            <div style={{ fontSize: "0.9vw" }}>{`${item?.first_name ? item?.first_name : ""}${item?.last_name ? item?.last_name : " "}`}</div>
                            <div style={{ fontSize: "0.75vw" }}>{`${item?.patient_mobile ? item?.patient_mobile : "-"} | ${item?.patient_account_number ? item?.patient_account_number : "-"}`}</div>
                          </div>
                        </TableCell>
                        <TableCell>
                          {item?.transfer_notes ? item?.transfer_notes?.length > 15 ?
                            <Tooltip placement='top' title={item?.transfer_notes}>
                              <Typography fontSize={'0.9vw'} fontWeight={600}>{item?.transfer_notes?.slice(0, 15) + '...'}</Typography>
                            </Tooltip> :
                            <Typography fontSize={'0.9vw'}>{item?.transfer_notes ? item?.transfer_notes : "-"}</Typography>
                            : "-"}
                        </TableCell>
                        <TableCell align='center'>
                          <Box component={'div'} display={'flex'} flexDirection={"row"} justifyContent={'center'}>
                            <Tooltip title={t("Accept Request")} arrow placement='top'>
                              <IconButton onClick={() => {
                                this.setState({ TransAppoint: false }, () => {
                                  this.cancelTransRequest(item?.id, "Approved")
                                })
                              }}>
                                <CheckCircleIcon sx={{ height: '1.8vw', width: '1.8vw' }} color='primary' />
                              </IconButton>
                            </Tooltip>
                            <Tooltip title={t("Cancel Request")} arrow placement='top'>
                              <IconButton onClick={() => { this.cancelTransRequest(item?.id, "Cancelled") }}>
                                <CancelIcon sx={{ height: '1.8vw', width: '1.8vw' }} color='error' />
                              </IconButton>
                            </Tooltip>
                          </Box>
                        </TableCell>
                      </TableRow>
                    )) : null}
                  </TableBody>
                </Table>
              </Box>
            </div> :
            <div><Typography id='eMed_Pres_pop_NORec' style={{ marginTop: "10vw" }}>{"No records found"}</Typography></div>}
        </div>
      );
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderTelephonicTab = () => {
    try {
      return (
        <Box className="eMEd_CreateRequest_Box" height={"35.2vh"}>
          <Grid container className='eMed_IPappoint_Grid_row'>
            <Grid item xs={4} paddingTop={"1vw"}>
              <TextField
                sx={{ width: '14vw', position: "relative" }}
                size='small'
                inputProps={{ maxLength: 15 }}
                label={Object.keys(this.state.docSelectedRequestPatient).length > 0 ? "Mobile Number *" : "Mobile / UHID"}
                value={this.state.docRequestMobileNumber}
                InputProps={{
                  endAdornment: this.state.isDocAppointRequest ?
                    <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment>
                    : null,
                }}
                onChange={(e) => {

                  let number = CommonValidation.numberOnly(e.target.value);
                  this.setState({ docRequestMobileNumber: e.target.value, }, () => {
                    if (Object.keys(this.state.docSelectedRequestPatient).length > 0) {
                      this.setState({
                        docSelectedRequestPatient: {},
                        docRequestPatientName: "",
                        docRequestPatientAge: ""
                      })
                    }
                    if (number && this.state.docRequestMobileNumber?.length >= 10) {
                      this.setState({ docRequestPatientListAnchor: e.currentTarget }, () => { this.DoctorPatientSearch(true, this.state.docRequestMobileNumber) })
                    }
                  })
                }}
                onKeyDown={(e) => {
                  this.setState({
                    RequestPatientListAnchor: e.currentTarget
                  }, () => {
                    if (this.state.docRequestMobileNumber.length > 5 && e.key === "Enter") {
                      this.setState({}, () => { this.DoctorPatientSearch(true, this.state.docRequestMobileNumber) })
                    }
                    if (this.state.docRequestMobileNumber.length <= 5 && e.key === "Enter") {
                      this.errorMessage("Enter atleast 6 Characters for search")
                    }
                  })
                }}
              />
              <Menu
                open={this.state.docRequestPatientMenuPop}
                anchorEl={this.state.docRequestPatientListAnchor}
                sx={{ position: 'absolute', top: '11vw', left: '-10vw', maxHeight: '20vw', overflow: 'auto' }}
                // className="eMed_Header_CreateRequest_Pop"
                onClose={() => {
                  this.setState({ docRequestPatientMenuPop: false })
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
              >
                <Typography className='eMed_Request_sugg_Head'>Patient Name | UHID</Typography>
                {
                  this.state.docSearchedRequestPatientList?.length > 0 ? this.state.docSearchedRequestPatientList?.map((item, index) => (
                    <MenuItem key={index} onClick={() => {
                      this.setState({
                        docSelectedRequestPatient: item,
                        docRequestPatientMenuPop: false,
                        docRequestMobileNumber: item?.mobile_number,
                        docRequestPatientName: item?.first_name ? item?.first_name + " " + item?.last_name : "-",
                        docRequestPatientAge: item?.patient_age ? item?.patient_age : CalculateAgeFromDob(item.dob !== null ? new Date(item?.dob) : new Date(item?.approx_dob)),
                      })
                    }}
                      sx={{ width: '14vw' }}>
                      <Box sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography fontWeight={600} fontSize={"0.8vw"} paddingRight={"0.3vw"}>{item?.first_name ? item?.first_name + " " + item?.last_name : "-"}</Typography> | <Typography paddingX={"0.3vw"} fontSize={"0.8vw"}>{item?.patient_account_number ? item?.patient_account_number : "-"}</Typography>
                      </Box>
                    </MenuItem>
                  )) : null
                }
              </Menu>
            </Grid>
            <Grid item xs={4} paddingTop={"1vw"} >
              <TextField
                sx={{ width: '14vw' }}
                disabled={Object.keys(this.state.docSelectedRequestPatient)?.length > 0}
                label="Patient Name *"
                value={this.state.docRequestPatientName}
                onChange={(e) => {
                  var isAlpha = CommonValidation.alphabetOnly(e.target.value)
                  if (isAlpha || e.target.value == "") {
                    this.setState({ docRequestPatientName: e.target.value })
                  }
                }}
                size='small'
                onKeyDown={(e) => { e.stopPropagation() }}
              />
            </Grid>
            <Grid item xs={4} paddingTop={"1vw"}>
              <TextField
                sx={{ width: '14vw' }}
                inputProps={{ maxLength: 3 }}
                disabled={Object.keys(this.state.docSelectedRequestPatient)?.length > 0}
                label="Patient Age"
                value={this.state.docRequestPatientAge}
                onChange={(e) => {
                  let number = CommonValidation.numberOnly(e.target.value);
                  if ((e.target.value < 150) && (number || e.target.value === "")) {
                    this.setState({ docRequestPatientAge: e.target.value })
                  }
                }}
                size='small'
              />
            </Grid>
          </Grid>
          <Grid container className='eMed_IPappoint_Grid_row' marginTop={"1vw"}>
            {/* ======> Commented for doctor and specilization not in doctor module */}
            {/* <Grid item xs={4}>
              {this.renderDropdown("Doctor", "0vw", this.state.filteredDocList.length > 0 ? this.state.filteredDocList : this.state.doctorList, "RequestSelectedDocID")}
            </Grid>
            <Grid item xs={4}>
              {this.renderDropdown("Specialization", "0vw", this.state.spcInfo, "RequestSelectedSpecID")}
            </Grid> */}
            <Grid item xs={4} >
              <Box sx={{ width: '93%', mt: '1vw' }}>
                <LocalizationProvider dateAdapter={AdapterDateFns}>
                  <DateTimePicker
                    open={this.state.DateOpen}
                    onOpen={() => { this.setState({ docDateOpen: true }) }}
                    onClose={() => { this.setState({ docDateOpen: false }) }}
                    inputFormat='dd-MM-yyyy & hh:mm a'
                    value={this.state.docRequestAppointDatetym}
                    minDate={new Date()}
                    maxDate={new Date((new Date).getFullYear(), (new Date).getMonth() + 3, 0)}
                    onChange={(newDate) => {
                      if (newDate === null) {
                        this.setState({ docRequestAppointDatetym: new Date() })
                        // , () => this.getDoctorsList(true))
                      }
                      else {
                        this.setState({ docRequestAppointDatetym: newDate })
                        // , () => this.getDoctorsList(true))
                      }
                    }}
                    renderInput={(params) => <TextField
                      size='small'
                      inputProps={{ style: { fontSize: '0.8vw' } }}
                      onKeyDown={(e) => e.preventDefault()}
                      onClick={() => { this.setState({ docDateOpen: true }) }}
                      {...params}
                      fullWidth
                      variant='outlined'
                      label="Appointment Date & Time *"
                    />}
                  />
                </LocalizationProvider>
              </Box>
            </Grid>
            <Grid item xs={4}>
              <Box>
                <Autocomplete
                  inputProps={{ maxLength: 250 }}
                  options={this.state.POVList}
                  getOptionLabel={(options) => options}
                  onChange={(e, value) => {
                    this.setState({
                      docRequestpurposeOfVisit: value
                    })
                  }}
                  onKeyDown={(e) => { e.stopPropagation() }}
                  clearIcon={false}
                  size='small'
                  sx={{ mr: '2vw', mt: "1vw", width: '29vw' }}
                  value={this.state.docRequestpurposeOfVisit}
                  renderInput={(params) => <TextField onChange={(event) => {
                    this.setState({
                      docRequestpurposeOfVisit: event.target.value
                    })
                  }}
                    {...params}
                    placeholder="Purpose Of Visit"
                  />}
                />
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography marginLeft={"2vw"}>{Object.keys(this.state.SelectedRequestPatient).length > 0 ? `UHID : ${this.state.SelectedRequestPatient?.patient_account_number}` : ""}</Typography>
            <Box className="eMed_CreateRequest_BtnBox">
              <Button size='small' id="eMed_Config_btn" sx={{ marginRight: '1vw' }} onClick={() => { this.docClearRequestData() }} variant='outlined'>Clear</Button>
              <Button size='small' id="eMed_Config_btn" disabled={this.state.isClked} variant='contained' onClick={() => this.setState({ isClked: true }, () => this.CreateAppointRequest(true))}>{this.state.doceditAppointmentId ? "Update Request" : "Create Request"}</Button>
            </Box>
          </Box>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }
  // Doctor Appointment Request Popup ends =====>

  // Radiology Starts ====> 

  lodaerFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  getRadiologyPatientData = (id, update, billSummaryId, data) => {
    try {
      this.lodaerFunction(true)
      RestAPIService.getAll(Serviceurls.FO_PATIENT_UPDATE + '?patient_id=' + id)
        .then((response) => {
          if (response?.data?.status === 'success') {
            this.lodaerFunction(false)
            this.setState({
              patientDetails: response?.data?.data
            }, () => {
              if (update === 'update') {
                setCachevalue(JSON.stringify(this.state.patientDetails), "RadPatientData")
                this.props.history.push({ pathname: "/RadiologyHome/Demographics/Update", state: { radUpdate: true, } })
              } else if (update === 'savedBill') {
                this.props.history.push({ pathname: "/RadiologyHome/Patient/Billing", state: { fromSavedBill: true, patientData: this.state.patientDetails, billSummaryId: billSummaryId } })
              } else if (update === "TransmitToFoRad"){
                setCachevalue(JSON.stringify(this.state.patientDetails), 'RadPatientData')
                this.props.history.push({ pathname: '/Billing/RadiologyBilling', state: { patientData: this.state.patientDetails, forTransmit : true, TransmitData: data || {} } })
              } else {
                setCachevalue(JSON.stringify(this.state.patientDetails), 'RadPatientData')
                this.props.history.push({ pathname: '/RadiologyHome/Demographics', state: { patientData: this.state.patientDetails, forRadCreate: true, TransmitData: data || {} } })
              }
            })
          }
        }).catch((error) => {
          this.lodaerFunction(false)
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.lodaerFunction(false)
      this.errorMessage(error.message)
    }
  }

  radiologyPatientSearch() {
    try {
      let { t } = this.props;
      let clinicId = this.state.loggedInuserInfo?.clinic_id
      let API_URL = ''
      this.isPatientisSearching(true)
      API_URL = Serviceurls.FO_PATIENT_SEARCH + `?search_key=${this.state.PatientNameSearchKey}&address_key=${this.state.PatientAddressKey}&father_husband_key=${this.state.PatientFathHusKey}&clinic_id=${clinicId}`
      RestAPIService.getAll(API_URL).
        then((response) => {
          if (response.data.status === "success") {
            this.isPatientisSearching(false)
            this.setState({
              searchPatientRadiologyList: response.data.data,
              // RequestPatientMenuPop: response?.data?.data?.patient?.length > 0 ? true : false
            }, () => {
              if (this.state.PatientNameSearchKey !== "" || this.state.PatientAddressKey !== "" || this.state.PatientFathHusKey !== "") {
                if (response.data.data?.length > 0) {
                  this.setState({ PatientSearchClicked: true, AddressFilterClicked: false })
                } else {
                  this.isPatientisSearching(false)
                  this.errorMessage(t("NoRecordsFound"))
                }
              }
            })
          }
          else {
            this.isPatientisSearching(false)
            this.errorMessage(response.data.message)
          }
        }).catch((error) => {
          this.isPatientisSearching(false)
          this.errorMessage(error.message)
        })
    }
    catch (e) {
      this.isPatientisSearching(false)
      this.errorMessage(e.message)
    }
  }

  RadiologyHeaderSearch = () => {
    const { t } = this.props;
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    var UserData = myUser
    return (
      <Box sx={{ marginLeft: '3.2vw' }}>
        <Box component={'div'} display={'flex'} flexDirection={'row'} sx={{ borderRadius: "0.3vw" }}>
          {UserData?.module_name === "Radiology" ?
            <OutlinedInput
              placeholder={t("SearchPatients")}
              onKeyDown={(event) => {
                if (event.key === "Enter" && event.target.value != "" && event.target.value?.length > 2) {
                  this.radiologyPatientSearch()
                }
              }}
              onChange={(e) => {
                this.setState({
                  PatientNameSearchKey: e.target.value
                })
              }}
              value={this.state.PatientNameSearchKey}
              size="small"
              className='eMed_header_Input'
              endAdornment={
                <Box component={'div'} display={'flex'} flexDirection={'row'}>
                  <Divider orientation="vertical" variant="middle" flexItem />
                  {this.state.isPatientisSearching ?
                    <Box sx={{ display: 'flex', alignItems: "center", justifyContent: "center", width: '2.5vw' }}>
                      <InputAdornment position="end"><CircularProgress size={15} /></InputAdornment>
                    </Box> :
                    <Tooltip title={t("Search")} arrow>
                      <IconButton disabled={this.state.PatientNameSearchKey === "" && this.state.PatientAddressKey === "" && this.state.PatientFathHusKey === ""}
                        onClick={(e) => {
                          if (this.state.PatientNameSearchKey?.length > 2 && this.state.PatientNameSearchKey !== "" || this.state.PatientAddressKey !== "" || this.state.PatientFathHusKey !== "") {
                            this.radiologyPatientSearch()
                          }
                        }} position="end">
                        <SearchIcon />
                      </IconButton>
                    </Tooltip>}
                  <Tooltip title={t("Filter")} arrow>
                    <IconButton onClick={() => { this.setState({ AddressFilterClicked: true }) }} position="end">
                      <Badge color="error" size='small' variant="dot" max={999} invisible={this.state.PatientAddressKey === "" && this.state.PatientFathHusKey === ""}>
                        <FilterAltIcon />
                      </Badge>
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t("FaceRecognition")} arrow>
                    <IconButton onClick={() => { this.setState({ docFaceRecogClicked: true }) }} position="end">
                      <CenterFocusWeakIcon />
                    </IconButton>
                  </Tooltip>
                </Box>
              }
            /> : null}
        </Box>
        <Menu
          open={this.state.PatientSearchClicked}
          className="eMed_rad_header_search"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            this.setState({
              PatientSearchClicked: false,
              // PatientNameSearchKey: "",
              PatientAddressKey: "",
              PatientFathHusKey: "",
            })
          }}
        >
          {
            this.state.searchPatientRadiologyList?.length > 0 ?
              this.state.searchPatientRadiologyList?.map((list) => {
                let PatientFullName = `${list.first_name} ${list.last_name}`
                list["patient_name"] = PatientFullName
                list["patient_mobile_number"] = list.mobile_number
                list["patient_photo_url"] = list.photo_url
                return (
                  <Box>
                    <Box className="eMed_Header_Search_item">
                      <CommonPatientDetails data={list} fromSearch={true} />
                      {<Box component={'div'} className="eMed_Header_Search_icons">
                        <Tooltip title={t("EditPatientDetails")} arrow>
                          <IconButton emed_tid='rad_app_lst_edt_btn' onClick={() => {
                            this.setState({
                              PatientNameSearchKey: "",
                              PatientAddressKey: "",
                              PatientFathHusKey: "",
                              PatientSearchClicked: false,
                              SearchedPatientList: []
                            }, () => {
                              this.getRadiologyPatientData(list?.patient_id, 'update')
                            })
                          }}
                            position="end">
                            <Edit color='primary' />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title={t("CreateAppointment")} arrow>
                          <IconButton emed_tid='rad_app_crte_btn' onClick={() => {
                            this.setState({
                              PatientNameSearchKey: "",
                              PatientAddressKey: "",
                              PatientFathHusKey: "",
                              PatientSearchClicked: false,
                              SearchedPatientList: []
                            }, () => {
                              this.getRadiologyPatientData(list?.patient_id)
                            })
                          }}
                            position="end">
                            <PersonAddAlt1Icon color='primary' />
                          </IconButton>
                        </Tooltip>
                        {/* <Typography>{list.appointment_date && formatDate(list.appointment_date)}</Typography> */}
                      </Box>}
                    </Box>
                    <Divider />
                  </Box>
                )
              }) : null
          }
        </Menu>
        <Menu
          open={this.state.AddressFilterClicked}
          className="eMed_Header_Filter_Pop"
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={() => {
            this.setState({ AddressFilterClicked: false })
          }}
        >
          <Box className="eMed_Header_Filter_item">
            <TextField
              label={t("SearchByAddress")}
              inputProps={{ maxLength: 50 }}
              size='small'
              value={this.state.PatientAddressKey}
              onChange={(e) => { this.setState({ PatientAddressKey: e.target.value }) }}
              onKeyDown={(e) => e.stopPropagation()} />
            <TextField
              label={t("SearchByFatherHusbandName")}
              inputProps={{ maxLength: 50 }}
              size='small'
              value={this.state.PatientFathHusKey}
              onChange={(e) => { this.setState({ PatientFathHusKey: e.target.value }) }}
              onKeyDown={(e) => e.stopPropagation()} />
            <Box component={'div'} display='flex' flexDirection='row' justifyContent={'center'}  >
              <Button id="eMed_Config_btn" variant='contained' size='small' disabled={this.state.PatientAddressKey === "" && this.state.PatientFathHusKey === ""} onClick={() => { this.radiologyPatientSearch() }}>Search</Button>
              <Button id="eMed_Config_btn" variant='outlined' size='small' sx={{ marginLeft: "2vw" }} onClick={() => { this.setState({ PatientAddressKey: "", PatientFathHusKey: "" }) }}>Clear</Button></Box>
          </Box>
        </Menu>
      </Box>
    )
  }

  getRadAppointmentListData = () => {
    try {
      let startDate = new Date(this.state.radAppointmentDate)
      let date = DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd')

      RestAPIService.getAll(Serviceurls.RAD_APPOINTMENT_GET + `?search_date=${date}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              radAppointmentList: response.data.data,
              dupAppointmentList: JSON.stringify(response.data.data)
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderRadAppointments = () => {
    try {
      return (
        <Box component={'div'}>
          <Menu
            id="basic-menu"
            anchorEl={this.state.radAppAnchorEL}
            open={this.state.rad_appointment}
            // onClose={() => this.setState({ rad_appointment: false })}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{ position: 'absoulte', top: '2.5vw' }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'bottom',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'bottom',
            }}
          >
            <Box component={'div'} p={'0.5vw'}>
              <Box component={'div'} display={'flex'} justifyContent={'space-between'}>
                <Typography fontWeight={600} fontSize={'0.9vw'}>Appointments</Typography>
                <Box component={'div'} onClick={() => this.setState({ rad_appointment: false })} sx={{ cursor: 'pointer' }}>
                  <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                </Box>
              </Box>
              <Box component={'div'} mt={'0.5vw'} display={'flex'}>
                <TextField
                  size='small'
                  value={this.state.searchRadAppointment}
                  onChange={(e) => this.setState({ searchRadAppointment: e.target.value }, () => {
                    let dupAppointmentList = JSON.parse(this.state.dupAppointmentList)
                    if (this.state.searchRadAppointment !== '') {
                      dupAppointmentList = dupAppointmentList.filter((item) => {
                        return (
                          (item?.patient_name === '' ? '' : item?.patient_name?.toLowerCase()?.includes(this.state.searchRadAppointment?.toLowerCase())))
                      })
                      this.setState({ radAppointmentList: dupAppointmentList })
                    } else {
                      this.setState({ radAppointmentList: dupAppointmentList })
                    }
                  })}
                  sx={{ width: '20vw', mr: '1vw' }}
                  placeholder='Search Appointment....'
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    open={this.state.DatePickerOpen}
                    onOpen={() => { this.setState({ DatePickerOpen: true }) }}
                    onClose={() => { this.setState({ DatePickerOpen: false }) }}
                    value={this.state.radAppointmentDate}
                    name={"datePicker"}
                    inputFormat='DD-MM-YYYY'
                    views={["year", "month", "day"]}
                    onChange={(newDate) => { this.setState({ radAppointmentDate: newDate }, () => this.getRadAppointmentListData()) }}
                    renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()}
                      style={{ width: '10vw' }} size='small' {...params}
                      onClick={() => { this.setState({ DatePickerOpen: true }) }} />}
                  />
                </LocalizationProvider>
              </Box>
              {this.state.radAppointmentList?.length > 0 ? this.state.radAppointmentList?.map((item, index) => {
                return (
                  <Box component={'div'} key={index} display={'flex'} alignItems={'center'} mt={'1vw'}>
                    <Box component={'div'} display={'flex'} alignItems={'center'} width={'22vw'}>
                      <Box component={'div'} display={'flex'} alignItems={'center'} mr={'0.5vw'}>
                        <Box component={'img'}
                          src={item?.patient_photo_url === null || item?.patient_photo_url === "" || item?.patient_photo_url === undefined ? item?.patient_gender === "Male" || item?.patient_gender === "m" || item?.gender === "m" ? ImagePaths.Male.default : ImagePaths.Female.default : item?.patient_photo_url} height={'2.8vw'} width={'2.8vw'}
                          border={`1.8px solid ${(item?.patient_type === "new_patient" || item?.patient__patient_type === 1) ? "#F1584A" : (item?.patient_type === "old_patient") || (item?.patient_type === "establish_patient") || (item?.patient__patient_type === 3) ? "#800080" : '#29B6F6'}`}
                          borderRadius={'50px'}
                        />
                      </Box>
                      <Box component={'div'}>
                        {item?.patient_name ?
                          item?.patient_name?.length > 14 ?
                            <Tooltip placement='top' title={item?.patient_name} arrow><Typography component={'div'} fontWeight={600} fontSize={'0.9vw'}>{item?.patient_name?.slice(0, 14) + '...'}
                              <Typography component={'span'} fontSize={'0.9vw'} fontWeight={600}>{item?.patient_age ? item?.patient_age : '-'}</Typography>
                            </Typography></Tooltip> :
                            <Typography component={'div'} fontWeight={600} fontSize={'0.9vw'}>{item?.patient_name}
                              <Typography component={'span'} fontSize={'0.9vw'} fontWeight={600}>{item?.patient_age ? item?.patient_age : '-'}</Typography>
                            </Typography>
                          : '-'}
                        <Typography component={'div'} color={Colors.grayShade} fontSize={'0.8vw'}>{item?.patient_account_number ? item.patient_account_number : '-'} |
                          <Typography component={'span'} fontSize={'0.8vw'} color={Colors.grayShade}>{item?.patient_mobile_number ? item?.patient_mobile_number : '-'}</Typography>
                        </Typography>
                      </Box>
                    </Box>
                    <Box component={'div'}>
                      <Button variant='contained' size='small' emed_tid='rad_ip_req_btn'
                        onClick={() => this.setState({ radAppAnchorEL: null, searchRadAppointment: '', rad_appointment: false }, () => {
                          this.getRadiologyPatientData(item?.patient_id)
                        })}
                      >Proceed</Button>
                    </Box>
                  </Box>
                )
              }) : <Typography display={'flex'} justifyContent={'center'} mt={'2vw'}>No Records To Be Shown</Typography>}
            </Box>
          </Menu>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getRadIPListData = () => {
    try {
      let startDate = new Date(this.state.radIpListDate)
      let date = DateTime.fromJSDate(startDate).toFormat('yyyy-MM-dd')

      RestAPIService.getAll(Serviceurls.IP_LIST_LABHOME)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              radIpList: response.data.data,
              dupIpListData: JSON.stringify(response.data.data)
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  renderRadIpAppointmentList = () => {
    try {
      return (
        <Box component={'div'}>
          <Menu
            id="basic-menu"
            anchorEl={this.state.radReqAnchorEl}
            open={this.state.rad_request}
            // onClose={() => this.setState({ rad_request: false })}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{ position: 'absoulte', top: '2.5vw', left: '0.9vw' }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'bottom',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'bottom',
            }}
          >
            <Box component={'div'} p={'0.5vw'}>
              <Box component={'div'} display={'flex'} justifyContent={'space-between'}>
                <Typography fontWeight={600} fontSize={'0.9vw'}>IP List</Typography>
                <Box component={'div'} onClick={() => this.setState({ rad_request: false })} sx={{ cursor: 'pointer' }}>
                  <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                </Box>
              </Box>
              <Box component={'div'} mt={'0.5vw'} display={'flex'}>
                <TextField
                  size='small'
                  value={this.state.searchRadIpList}
                  onChange={(e) => this.setState({ searchRadIpList: e.target.value }, () => {
                    let dupIpListData = JSON.parse(this.state.dupIpListData)
                    if (this.state.searchRadIpList !== '') {
                      dupIpListData = dupIpListData.filter((item) => {
                        return (
                          (item?.patient_name === '' ? '' : item?.patient_name?.toLowerCase()?.includes(this.state.searchRadIpList?.toLowerCase())))
                      })
                      this.setState({ radIpList: dupIpListData })
                    } else {
                      this.setState({ radIpList: dupIpListData })
                    }
                  })}
                  sx={{ width: '20vw', mr: '1vw' }}
                  placeholder='Search IP List....'
                />
                {/* commented for if date search asked in further .... */}
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    open={this.state.radDatePickerOpen}
                    onOpen={() => { this.setState({ radDatePickerOpen: true }) }}
                    onClose={() => { this.setState({ radDatePickerOpen: false }) }}
                    value={this.state.radIpListDate}
                    name={"datePicker"}
                    inputFormat='DD-MM-YYYY'
                    views={["year", "month", "day"]}
                    onChange={(newDate) => { this.setState({ radIpListDate: newDate }, () => this.getRadIPListData()) }}
                    renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()}
                      style={{ width: '10vw' }} size='small' {...params}
                      onClick={() => { this.setState({ radDatePickerOpen: true }) }} />}
                  />
                </LocalizationProvider> */}
              </Box>
              {this.state.radIpList?.length > 0 ? this.state.radIpList?.map((item, index) => {
                return (
                  <Box component={'div'} key={index} display={'flex'} alignItems={'center'} mt={'1vw'}>
                    <Box component={'div'} display={'flex'} alignItems={'center'} width={'22vw'}>
                      <Box component={'div'} display={'flex'} alignItems={'center'} mr={'0.5vw'}>
                        <Box component={'img'}
                          src={item?.patient_photo_url === null || item?.patient_photo_url === "" || item?.patient_photo_url === undefined ? item?.patient_gender === "Male" || item?.patient_gender === "m" || item?.gender === "m" ? ImagePaths.Male.default : ImagePaths.Female.default : item?.photo_url} height={'2.8vw'} width={'2.8vw'}
                          border={`1.8px solid ${(item?.patient_type === "new_patient" || item?.patient__patient_type === 1) ? "#F1584A" : (item?.patient_type === "old_patient") || (item?.patient_type === "establish_patient") || (item?.patient__patient_type === 3) ? "#800080" : '#29B6F6'}`}
                          borderRadius={'50px'}
                        />
                      </Box>
                      <Box component={'div'}>
                        {item?.patient_name ?
                          item?.patient_name?.length > 14 ?
                            <Tooltip placement='top' title={item?.patient_name} arrow><Typography component={'div'} fontWeight={600} fontSize={'0.9vw'}>{item?.patient_name?.slice(0, 14) + '...'}
                              <Typography component={'span'} fontSize={'0.9vw'} fontWeight={600}>{`${item?.patient_age ? item?.patient_age : '-'}/${item?.patient__gender ? (item?.patient__gender)?.toUpperCase() : '-'}`}</Typography>
                            </Typography></Tooltip> :
                            <Typography component={'div'} fontWeight={600} fontSize={'0.9vw'}>{item?.patient_name}
                              <Typography component={'span'} fontSize={'0.9vw'} fontWeight={600}>{`${item?.patient_age ? item?.patient_age : '-'}/${item?.patient__gender ? (item?.patient__gender)?.toUpperCase() : '-'}`}</Typography>
                            </Typography>
                          : '-'}
                        <Typography component={'div'} color={Colors.grayShade} fontSize={'0.8vw'}>{item?.patient_account_number ? item?.patient_account_number : '-'} |
                          <Typography component={'span'} fontSize={'0.8vw'} color={Colors.grayShade}>{item?.patient_mobile_number ? item?.patient_mobile_number : '-'}</Typography>
                        </Typography>
                        <Typography component={'div'} color={Colors.grayShade} fontSize={'0.8vw'}>{"IP No."} |
                          <Typography component={'span'} fontSize={'0.8vw'} color={Colors.grayShade}>{item?.ip_number ? item?.ip_number : '-'}</Typography>
                        </Typography>
                      </Box>
                    </Box>
                    <Box component={'div'}>
                      <Button variant='contained' size='small' emed_tid='rad_op_req_btn'
                        onClick={() => this.setState({ radReqAnchorEl: null, searchRadIpList: '', rad_request: false }, () => {
                          this.getRadiologyPatientData(item?.patient_id)
                        })}
                      >Proceed</Button>
                    </Box>
                  </Box>
                )
              }) : <Typography display={'flex'} justifyContent={'center'} mt={'2vw'}>No Records To Be Shown</Typography>}
            </Box>
          </Menu>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  deleteSavedBillData = () => {
    try {
      let data = {}
      this.setState({ removeData: true })
      RestAPIService.delete(Serviceurls.RAD_PATIENT_SAVED_BILL_LIST + '?id=' + this.state.selectedSavedId, data)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.getRadSavedBillList()
            this.setState({
              rad_saved_bill: false,
              radSavedBillAnchcor: null,
              saveReason: false,
              removeData: false,
              selectedSavedId: null
            })
          }
        }).catch((error) => {
          this.setState({ removeData: false })
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ removeData: false })
      this.errorMessage(error.message)
    }
  }

  getRadSavedBillList = () => {
    try {
      RestAPIService.getAll(Serviceurls.RAD_PATIENT_SAVED_BILL_LIST)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              radSavedBillListData: response.data.data,
              dupRadSavedBill: JSON.stringify(response.data.data)
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  radSavedBillList = () => {
    try {
      return (
        <Box component={'div'}>
          <Menu
            id="basic-menu"
            anchorEl={this.state.radSavedBillAnchcor}
            open={this.state.rad_saved_bill}
            // onClose={() => this.setState({ rad_saved_bill: false })}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{ position: 'absoulte', top: '2.5vw', left: '0.9vw' }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'bottom',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'bottom',
            }}
          >
            <Box component={'div'} p={'0.5vw'}>
              <Box component={'div'} display={'flex'} justifyContent={'space-between'}>
                <Typography fontWeight={600} fontSize={'0.9vw'}>Saved Bill List</Typography>
                <Box component={'div'} onClick={() => this.setState({ rad_saved_bill: false })} sx={{ cursor: 'pointer' }}>
                  <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                </Box>
              </Box>
              <Box component={'div'} mt={'0.5vw'} display={'flex'}>
                <TextField
                  size='small'
                  value={this.state.searchRadSavdBill}
                  onChange={(e) => this.setState({ searchRadSavdBill: e.target.value }, () => {
                    let dupIpListData = JSON.parse(this.state.dupRadSavedBill)
                    if (this.state.searchRadSavdBill !== '') {
                      dupIpListData = dupIpListData.filter((item) => {
                        return (
                          (item?.patient_name === '' ? '' : item?.patient_name?.toLowerCase()?.includes(this.state.searchRadSavdBill?.toLowerCase())))
                      })
                      this.setState({ radSavedBillListData: dupIpListData })
                    } else {
                      this.setState({ radSavedBillListData: dupIpListData })
                    }
                  })}
                  sx={{ width: '20vw', mr: '1vw' }}
                  placeholder='Search Saved List....'
                />
                {/* commented for if date search asked in further .... */}
                {/* <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    open={this.state.radDatePickerOpen}
                    onOpen={() => { this.setState({ radDatePickerOpen: true }) }}
                    onClose={() => { this.setState({ radDatePickerOpen: false }) }}
                    value={this.state.radIpListDate}
                    name={"datePicker"}
                    inputFormat='DD-MM-YYYY'
                    views={["year", "month", "day"]}
                    onChange={(newDate) => { this.setState({ radIpListDate: newDate }, () => this.getRadIPListData()) }}
                    renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()}
                      style={{ width: '10vw' }} size='small' {...params}
                      onClick={() => { this.setState({ radDatePickerOpen: true }) }} />}
                  />
                </LocalizationProvider> */}
              </Box>
              {this.state.radSavedBillListData?.length > 0 ? this.state.radSavedBillListData?.map((item, index) => {
                return (
                  <Box component={'div'} key={index} display={'flex'} alignItems={'center'} mt={'1vw'}>
                    <Box component={'div'} display={'flex'} alignItems={'center'} width={'22vw'}>
                      <Box component={'div'} display={'flex'} alignItems={'center'} mr={'0.5vw'}>
                        <Box component={'img'}
                          src={item?.patient_photo_url === null || item?.patient_photo_url === "" || item?.patient_photo_url === undefined ? item?.patient_gender === "Male" || item?.patient_gender === "m" || item?.gender === "m" ? ImagePaths.Male.default : ImagePaths.Female.default : item?.photo_url} height={'2.8vw'} width={'2.8vw'}
                          border={`1.8px solid ${(item?.patient_type === "new_patient" || item?.patient__patient_type === 1) ? "#F1584A" : (item?.patient_type === "old_patient") || (item?.patient_type === "establish_patient") || (item?.patient__patient_type === 3) ? "#800080" : '#29B6F6'}`}
                          borderRadius={'50px'}
                        />
                      </Box>
                      <Box component={'div'}>
                        {item?.patient_name ?
                          item?.patient_name?.length > 14 ?
                            <Tooltip placement='top' title={item?.patient_name} arrow><Typography component={'div'} fontWeight={600} fontSize={'0.9vw'}>{item?.patient_name?.slice(0, 14) + '...'}</Typography></Tooltip> :
                            <Typography component={'div'} fontWeight={600} fontSize={'0.9vw'}>{item?.patient_name}</Typography>
                          : '-'}
                        <Typography component={'div'} color={Colors.grayShade} fontSize={'0.8vw'}>{item?.patient_account_number ? item?.patient_account_number : '-'} |
                          <Typography component={'span'} fontSize={'0.8vw'} color={Colors.grayShade}>{item?.patient_mobile_number ? item?.patient_mobile_number : '-'}</Typography>
                        </Typography>
                      </Box>
                    </Box>
                    <Box component={'div'}>
                      <Button variant='contained' sx={{ mr: '0.5vw' }} size='small' emed_tid={'rad_pat_sav_bill_btn'}
                        onClick={() => this.setState({ radSavedBillAnchcor: null, searchRadSavdBill: '', rad_saved_bill: false }, () => {
                          setCachevalue(JSON.stringify(item), 'RadPatientData')
                          this.getRadiologyPatientData(item?.patient_id, 'savedBill', item?.bill_summary_id)
                        })}
                      >Proceed</Button>
                      <Button variant='contained' color='error' size='small' emed_tid='rad_sav_dlt_btn'
                        onClick={() => this.setState({ saveReason: true, selectedSavedId: item?.bill_summary_id })}
                      ><Close /></Button>
                    </Box>
                  </Box>
                )
              }) : <Typography display={'flex'} justifyContent={'center'} mt={'2vw'}>No Records To Be Shown</Typography>}
            </Box>
          </Menu>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getRadTransmitList = () => {
    try {
      const date = DateTime.fromJSDate(new Date(this.state.radTransFltDate)).toFormat('yyyy-MM-dd');
      RestAPIService.getAll(`${Serviceurls.RAD_PATIENT_TRANSMIT_GET}?search_key=${this.state.srchRadTranskey}&transmit_date=${date}`)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              radTransmitList: response.data.data,
            })
          }
        }).catch((error) => {
          if (error?.response?.data?.message) {
            this.errorMessage(error?.response?.data?.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getSpecialTransmitPrint = (patient_id, transmit_id) => {
    try {
      RestAPIService.getAll(`${Serviceurls.RAD_PATIENT_TRANSMIT_GET}?export_type=pdf&patient_id=${patient_id}&transmitted_id=${transmit_id}`).
        then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
        }).catch((error) => {
          this.errorMessage(error.response?.data?.message)
        })
    } catch (error) {
      this.errorMessage("Error occured on print")
    }
  }

  renderRadTransmit = (fromFo) => {
    try {
      return (
        <Box component={'div'}>
          <Menu
            id="basic-menu"
            anchorEl={this.state.radTransAnchorEL}
            open={this.state.rad_transmit}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            sx={{ position: 'absoulte', top: '2.5vw' }}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'bottom',
            }}
            transformOrigin={{
              vertical: 'bottom',
              horizontal: 'bottom',
            }}
          >
            <Box component={'div'} p={'0.5vw'}>
              <Box component={'div'} display={'flex'} justifyContent={'space-between'}>
                <Typography fontWeight={600} fontSize={'0.9vw'}>Appointments</Typography>
                <Box component={'div'} onClick={() => this.setState({ rad_transmit: false })} sx={{ cursor: 'pointer' }}>
                  <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1vw'} width={'1vw'} />
                </Box>
              </Box>
              <Box component={'div'} mt={'0.5vw'} display={'flex'}>
                <TextField
                  size='small'
                  value={this.state.srchRadTranskey}
                  onChange={(e) => {
                    this.setState({
                      srchRadTranskey: e.target.value
                    }, () => {
                      this.getRadTransmitList()
                    })
                  }}
                  sx={{ width: '20vw', mr: '1vw' }}
                  placeholder='Search Patient...'
                />
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker
                    open={this.state.DatePickerOpen}
                    onOpen={() => { this.setState({ DatePickerOpen: true }) }}
                    onClose={() => { this.setState({ DatePickerOpen: false }) }}
                    value={this.state.radTransFltDate}
                    name={"datePicker"}
                    inputFormat='DD-MM-YYYY'
                    views={["year", "month", "day"]}
                    onChange={(newDate) => { this.setState({ radTransFltDate: newDate }, () => this.getRadTransmitList()) }}
                    renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()}
                      style={{ width: '10vw' }} size='small' {...params}
                      onClick={() => { this.setState({ DatePickerOpen: true }) }} />}
                  />
                </LocalizationProvider>
              </Box>
              {this.state.radTransmitList?.length > 0 ? this.state.radTransmitList?.map((item, index) => {
                return (
                  <Box component={'div'} key={index} display={'flex'} alignItems={'center'} mt={'1vw'}>
                    <Box component={'div'} display={'flex'} alignItems={'center'} width={'22vw'}>
                      <Box component={'div'} display={'flex'} alignItems={'center'} mr={'0.5vw'}>
                        <Box component={'img'}
                          src={item?.patient_photo_url === null || item?.patient_photo_url === "" || item?.patient_photo_url === undefined ? item?.patient_gender === "Male" || item?.patient_gender === "m" || item?.gender === "m" ? ImagePaths.Male.default : ImagePaths.Female.default : item?.patient_photo_url} height={'2.8vw'} width={'2.8vw'}
                          border={`1.8px solid ${(item?.patient_type === "new_patient" || item?.patient__patient_type === 1) ? "#F1584A" : (item?.patient_type === "old_patient") || (item?.patient_type === "establish_patient") || (item?.patient__patient_type === 3) ? "#800080" : '#29B6F6'}`}
                          borderRadius={'50px'}
                        />
                      </Box>
                      <Box component={'div'}>
                        {item?.patient_name ?
                          item?.patient_name?.length > 14 ?
                            <Tooltip placement='top' title={item?.patient_name} arrow><Typography component={'div'} fontWeight={600} fontSize={'0.9vw'}>{item?.patient_name?.slice(0, 14) + '...'}
                              <Typography component={'span'} fontSize={'0.9vw'} fontWeight={600}>{item?.patient_age ? item?.patient_age : '-'}</Typography>
                            </Typography></Tooltip> :
                            <Typography component={'div'} fontWeight={600} fontSize={'0.9vw'}>{item?.patient_name}
                              <Typography component={'span'} fontSize={'0.9vw'} fontWeight={600}>{item?.patient_age ? item?.patient_age : '-'}</Typography>
                            </Typography>
                          : '-'}
                        <Typography component={'div'} color={Colors.grayShade} fontSize={'0.8vw'}>{item?.patient_account_number ? item.patient_account_number : '-'} |
                          <Typography component={'span'} fontSize={'0.8vw'} color={Colors.grayShade}>{item?.patient_mobile_number ? item?.patient_mobile_number : '-'}</Typography>
                        </Typography>
                      </Box>
                    </Box>
                    <Box component={'div'}>
                      <Button variant='contained' size='small' emed_tid='rad_ip_req_btn'
                        onClick={() => {
                          this.setState(
                            { radTransAnchorEL: null, srchRadTranskey: '', rad_transmit: false },
                            () => {
                              const updatedLineItems = [];
                              let specialTestTransmitIds = null;
                              item.line_items.forEach((element) => {
                                if (element.special_test_name) {
                                  specialTestTransmitIds = element.transmit_summary_ids;
                                } else {
                                  updatedLineItems.push(element);
                                }
                              });
                              if (specialTestTransmitIds) {
                                this.getSpecialTransmitPrint(item?.patient_id, specialTestTransmitIds);
                              }
                              const updatedItem = { ...item, line_items: updatedLineItems };
                              this.getRadiologyPatientData(item?.patient_id, fromFo ? "TransmitToFoRad" : "", "", updatedItem);
                            }
                          );
                        }}
                      >Proceed</Button>
                    </Box>
                  </Box>
                )
              }) : <Typography display={'flex'} justifyContent={'center'} mt={'2vw'}>No Records To Be Shown</Typography>}
            </Box>
          </Menu>
        </Box>
      )
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  // printRadiologyAppointment = () => {
  //   try{
  //     let AppDate = JSON.parse(getCachevalue('RadAppointmentDate'))
  //     let startDate = AppDate ? AppDate?.slice(0,10) : DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd') 
  //     let AppStatus = JSON.parse(getCachevalue('RadAppointmentStatus'))


  //     RestAPIService.getAll(Serviceurls.RAD_PATIENT_APPOINTMENT_LIST + `?date=${startDate}&export=pdf`)
  //       .then((response) => {
  //         const file = new Blob(
  //           [response.data],
  //           { type: 'application/pdf' });
  //         const fileURL = URL.createObjectURL(file);
  //         window.open(fileURL);
  //       }).catch((error) => {
  //         if (error?.response?.data?.message) {
  //           this.errorMessage(error.response.data.message)
  //         } else {
  //           this.errorMessage(error.message)
  //         }
  //       })
  //   }catch(error){
  //     this.errorMessage(error.message)
  //   }
  // }

  popupClose = () => {
    this.setState({
      saveReason: false,
    })
  }

  addCancelComments = (comments) => {
    this.deleteSavedBillData(comments)
  }

  // Radiology Ends =====>

  ignoreSmsAlert = () => {
    try {
      let data = {
        show_sms_alert: false
      }
      RestAPIService.create(data, Serviceurls.FO_SMS_CONFIG_ALERT_REMOVE)
        .then((response) => {
          if (response?.data?.status === "success") {
            this.setState({ smsAlert: false })
            localSetItem("show_sms_alert", false)
          }
        }).catch(error => {
          if (error?.response?.data?.status === "fail") {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (e) {
      this.errorMessage(e?.message)
    }
  }

  closeDrQueue = () => {
    if (this.state.DocQueuePopup) {
      this.setState({
        DocQueuePopup: false,
      }, () => this.getDoctorQueueData())
    }
  }

  callPatientSend = (id) => {
    if (this.PaitentCall) {
      this.PaitentCall.send(JSON.stringify({ id: id ? id : null }))
    }
  }
      

  handleNewCheckBoxHandler = (e, index) => {
    try {
      let value = e.target.checked
      if (this.state.isOPT) {
        if (this.state.doctorPrintList['opt_doctor'][index]['label'] === 'Full Report') {
          this.state.doctorPrintList['opt_doctor'][index]['is_checked'] = value
          this.setState({ doctorPrintList: this.state.doctorPrintList }, () => {
            if (this.state.doctorPrintList['opt_doctor'][index]['label'] === 'Full Report' && this.state.doctorPrintList['opt_doctor'][index]['is_checked']) {
              this.state.doctorPrintList?.opt_doctor?.forEach(element => element.is_checked = true)
              this.setState({ doctorPrintList: this.state.doctorPrintList })
            } else {
              this.state.doctorPrintList?.opt_doctor?.forEach(element => element.is_checked = false)
              this.setState({ doctorPrintList: this.state.doctorPrintList })
            }
          })
        } else {
          this.state.doctorPrintList['opt_doctor'][index]['is_checked'] = value
          this.setState({ doctorPrintList: this.state.doctorPrintList }, () => {
            let filterArray = this.state.doctorPrintList?.opt_doctor?.filter(item => item?.label !== 'Full Report')
            let checkAll = filterArray?.every(item => item.is_checked)
            this.state.doctorPrintList?.opt_doctor?.forEach(element => {
              if (element.label === 'Full Report' && checkAll) {
                element.is_checked = true
              } else if (!checkAll && element.label === 'Full Report') {
                element.is_checked = false
              }
            })
            this.setState({ doctorPrintList: this.state.doctorPrintList })
          })
        }

      } else {
        if (this.state.doctorPrintList['other_doctor'][index]['label'] === 'Full Report') {
          this.state.doctorPrintList['other_doctor'][index]['is_checked'] = value
          this.setState({ doctorPrintList: this.state.doctorPrintList }, () => {
            if (this.state.doctorPrintList['other_doctor'][index]['label'] === 'Full Report' && this.state.doctorPrintList['other_doctor'][index]['is_checked']) {
              this.state.doctorPrintList?.other_doctor?.forEach(element => element.is_checked = true)
              this.setState({ doctorPrintList: this.state.doctorPrintList })
            } else {
              this.state.doctorPrintList?.other_doctor?.forEach(element => element.is_checked = false)
              this.setState({ doctorPrintList: this.state.doctorPrintList })
            }
          })
        } else {
          this.state.doctorPrintList['other_doctor'][index]['is_checked'] = value
          this.setState({ doctorPrintList: this.state.doctorPrintList }, () => {
            let filterArray = this.state.doctorPrintList?.other_doctor?.filter(item => item?.label !== 'Full Report')
            let checkAll = filterArray?.every(item => item.is_checked)
            this.state.doctorPrintList?.other_doctor?.forEach(element => {
              if (element.label === 'Full Report' && checkAll) {
                element.is_checked = true
              } else if (!checkAll && element.label === 'Full Report') {
                element.is_checked = false
              }
            })
            this.setState({ doctorPrintList: this.state.doctorPrintList })
          })
        }
      }
    } catch (error) {
      this.errorMessage(error.message)
    }
  }

  getDoctorNotesPrintTemplate = () => {
    try {
      this.setState({ printLoad: true })
      RestAPIService.getAll(Serviceurls.DOC_OPT_CONFIG)
        .then((response) => {
          if (response.data.status === 'success') {
            this.setState({
              doctorPrintList: response.data.data.doc_notes_print_template ? response.data.data.doc_notes_print_template : DOC_PRINT_DEFAULT,
              dupDocPrintList: response.data.data.doc_notes_print_template ? JSON.stringify(response.data.data.doc_notes_print_template) : JSON.stringify(DOC_PRINT_DEFAULT),
              printLoad: false
            })
          }
        }).catch((error) => {
          this.setState({ printLoad: false })
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ printLoad: false })
      this.errorMessage(error.message)
    }
  }

  getOTAppointmentListPrint = () => {
    try {
      let fromDate = getCachevalue('OTFromDate')
      let toDate = getCachevalue('OTToDate')
      let status = getCachevalue('OTStatus')
      this.setState({ printOTApp: true })
      RestAPIService.getAll(Serviceurls.GET_OT_APPOINTMENT_LIST + `?from_date=${fromDate}&to_date=${toDate}&status=${status}&export_type=pdf`)
        .then((response) => {
          const file = new Blob(
            [response.data],
            { type: 'application/pdf' });
          const fileURL = URL.createObjectURL(file);
          window.open(fileURL);
          this.setState({ printOTApp: false })
        }).catch((error) => {
          this.setState({ printOTApp: false })
          if (error?.response?.data?.message) {
            this.errorMessage(error.response.data.message)
          } else {
            this.errorMessage(error.message)
          }
        })
    } catch (error) {
      this.setState({ printOTApp: false })
      this.errorMessage(error.message)
    }
  }


  render() {
    const { isOnline } = this.state;
    if (this.state.clinicId !== null) { localSetItem("clinicId", this.state.clinicId) }
    const { t } = this.props;
    const theme = this.props;
    const { history } = this.props;
    var { clinicData } = this.state
    const menuList = this.returnSideMenus();
    var myUser = JSON.parse(localGetItem("loggedInUserInfo"))
    var UserData = myUser
    let ModuleData = localGetItem("ModulesList") ? JSON.parse(localGetItem("ModulesList")) : []
    let DisableSwitchModule = (ModuleData?.length === 1 && ModuleData[0]?.data?.length === 1) ? true : false
    let noReturn = JSON.parse(localGetItem("pharmacy_rtn_period"))
    if (localGetItem("isLoggedIn")) {
      return (
        <div>
          <Box className='eMed_muiDrawer' >
            <CssBaseline />
            <AppBar sx={{ minHeight: '4.1vw' }} position="fixed" open={this.state.drawer_Open}>

              <Toolbar>
                {(UserData?.module_name === "Laboratory") && this.state.selectedPageName === "MainHome" && this.state.selectedSubScreen === "LabHome" && (!window.location.href.includes("/Results")) && !(window.location.href.includes("/Dashboard")) ?
                  <div className={this.state.labType === "Inhouse Laboratory" ? "" : ""}>
                    {this.LabHeaderSearch()}

                    {/* New Search Has Written */}
                    {/* {this.state.labType === "Inhouse Laboratory" ?
                      <FormControl size='small' sx={{ width: "13vw" }} >
                        <Select
                          className='mui_header_card_dropdown'
                          value={this.state.clinicId}
                          onChange={(e) => {
                            this.setState({ clinicId: e.target.value })
                          }}
                        >
                          {this.state.clinicData && this.state.clinicData.length > 0 && this.state.clinicData.map((item) => (
                            <MenuItem key={item.clinic_id} value={item.clinic_id} >{item.clinic_name}</MenuItem>
                          ))}
                        </Select>
                      </FormControl> : null} 
                     <div className={this.state.labType === "Inhouse Laboratory" ? "mui_header_card_search" : "Lab_Home_Patient_Search"} >
                      <OutlinedInput
                        placeholder="Search Patients Name, Number, UHID"
                        onKeyDown={(event) => {
                          if (event.key === "Enter") {
                            history.push({ pathname: "/MainHome", state: { search_key: this.state.searchkey ? this.state.searchkey : "" } })
                          }
                        }}
                        onChange={(e) => {
                          var data = e.target.value
                          if (data == "") {
                            history.push({ pathname: "/MainHome", state: { search_key: data } })
                          }
                          this.setState({
                            searchkey: e.target.value
                          })
                        }}
                        value={this.state.searchkey}
                        size="small"
                        sx={{ width: "17.5vw", fontSize: "0.8vw" }}
                        endAdornment={
                          <IconButton onClick={() => {
                            history.push({ pathname: "/MainHome", state: { search_key: this.state.searchkey ? this.state.searchkey : "" } })
                          }} position="end">
                            <i class="fas fa-search"></i>
                          </IconButton>
                        }
                      />
                    </div>  */}
                  </div>
                  // :
                  // UserData?.module_name === "Laboratory" && this.state.selectedPageName === "MainHome" && (this.state.selectedSubScreen === "TestReport" || this.state.selectedSubScreen === "Status") ?

                  //   <div className="Lab_Home_Patient_Search">
                  //     <OutlinedInput
                  //       placeholder="Search Patients Name, Mobile No."
                  //       onChange={(e) => {
                  //         var data = e.target.value
                  //         if (data == "") {
                  //           history.push({ pathname: "/MainHome", state: { search_key: data ? data : "" } })
                  //         }
                  //         this.setState({
                  //           searchkey: e.target.value
                  //         })
                  //       }}
                  //       value={this.state.searchkey}
                  //       size="small"
                  //       sx={{ width: "17.5vw", fontSize: "0.8vw", backgroundColor: "#E4F2F0" }}
                  //       onKeyDown={(event) => {
                  //         if (event.key === "Enter") {
                  //           history.push({ pathname: "/MainHome", state: { search_key: this.state.searchkey ? this.state.searchkey : "" } })
                  //         }
                  //       }}
                  //       endAdornment={
                  //         <IconButton onClick={() => {
                  //           history.push({ pathname: "/MainHome", state: { search_key: this.state.searchkey ? this.state.searchkey : "" } })
                  //         }} position="end">
                  //           <i class="fas fa-search"></i>
                  //         </IconButton>
                  //       }
                  //     />
                  //   </div>
                  //   :
                  //   UserData?.module_name === "Laboratory" && this.state.selectedPageName == "Config" ?

                  //     <div className="Lab_Home_Patient_Search">
                  //       <OutlinedInput
                  //         placeholder={this.state.selectedSubScreen === "TestConfig" ? "Search Config..." :
                  //           this.state.selectedSubScreen === "TestGroup" ? "Search Test Group..." :
                  //             this.state.selectedSubScreen === "ConsumableMap" ? "Search Service Name..." :
                  //               this.state.selectedSubScreen === "PatientDemographics" ? "Search Patient Name..." :
                  //                 this.state.selectedSubScreen === "DoctorDemographics" ? "Search Doctor Name..." :
                  //                   this.state.selectedSubScreen === "OutsourceConfig" ? "Search LabName..." :
                  //                     this.state.selectedSubScreen === "OutsourceMapping" ? "Search LabName..." : "Search Config..."}
                  //         onKeyDown={(event) => {
                  //           if (event.key === "Enter") {
                  //             this.setState({
                  //               configSearch: this.state.searchkey
                  //             })
                  //           }
                  //         }}
                  //         onChange={(e) => {

                  //           this.setState({
                  //             searchkey: e.target.value
                  //           })
                  //         }}
                  //         value={this.state.searchkey}
                  //         size="small"
                  //         sx={{ width: "17.5vw", fontSize: "0.8vw", backgroundColor: "#E4F2F0" }}
                  //         endAdornment={
                  //           <IconButton onClick={() => {
                  //             this.setState({
                  //               configSearch: this.state.searchkey
                  //             })
                  //           }} position="end">
                  //             <i class="fas fa-search"></i>
                  //           </IconButton>
                  //         }
                  //       />

                  //     </div>
                  //     : UserData?.module_name === "Laboratory" && this.state.selectedPageName == 'Purchase' && this.state.selectedSubScreen !== "directPo" ?

                  //       <div className="Lab_Home_Patient_Search">
                  //         <OutlinedInput
                  //           placeholder={this.state.selectedSubScreen === "wanted_list" ? "Search Brand Name..." : "Search Vendor..."}
                  //           onKeyDown={(event) => {
                  //             if (event.key === "Enter") {
                  //               this.setState({
                  //                 purchaseSearch: this.state.searchkey
                  //               })
                  //             }
                  //           }}
                  //           onChange={(e) => {

                  //             this.setState({
                  //               searchkey: e.target.value
                  //             })
                  //           }}
                  //           value={this.state.searchkey}
                  //           size="small"
                  //           sx={{ width: "17.5vw", fontSize: "0.8vw", backgroundColor: "#E4F2F0" }}
                  //           endAdornment={
                  //             <IconButton onClick={() => {
                  //               this.setState({
                  //                 purchaseSearch: this.state.searchkey
                  //               })
                  //             }} position="end">
                  //               <i class="fas fa-search"></i>
                  //             </IconButton>
                  //           }
                  //         />
                  //       </div>
                  //       : UserData?.module_name === "Laboratory" && this.state.selectedPageName === "Inventory" ?
                  //         <div className="Lab_Home_Patient_Search">
                  //           <OutlinedInput
                  //             onKeyDown={(event) => {
                  //               if (event.key === "Enter") {
                  //                 this.setState({
                  //                   inventorySearch: this.state.searchkey
                  //                 })
                  //               }
                  //             }}
                  //             placeholder={"Search Brand Name ..."}
                  //             onChange={(e) => {
                  //               this.setState({ searchkey: e.target.value }, () => {
                  //                 if (this.state.searchkey === "") {
                  //                   this.setState({
                  //                     inventorySearch: ''
                  //                   })
                  //                 }
                  //               })

                  //             }}
                  //             value={this.state.searchkey}
                  //             size='small'
                  //             sx={{ width: "17.5vw", fontSize: "0.8vw", backgroundColor: "#E4F2F0" }}
                  //             endAdornment={
                  //               <IconButton onClick={() => {
                  //                 this.setState({
                  //                   inventorySearch: this.state.searchkey
                  //                 })
                  //               }} position="end">
                  //                 <i class="fas fa-search"></i>
                  //               </IconButton>
                  //             }
                  //           />

                  //         </div>
                  //         : UserData?.module_name === "Laboratory" && this.state.selectedPageName === "Reports" ?
                  //           <div className="Lab_Home_Patient_Search">
                  //             <OutlinedInput
                  //               placeholder={this.state.selectedSubScreen === "doctorReport" ? "Search Doctor Name ..." : "Search Patient Name ..."
                  //               }
                  //               onChange={(e) => {
                  //                 this.setState({ searchkey: e.target.value }, () => {
                  //                   if (this.state.searchkey === "") {
                  //                     this.setState({
                  //                       reportSearch: ""
                  //                     })
                  //                   }
                  //                 })
                  //               }}
                  //               value={this.state.searchkey}
                  //               size='small'
                  //               sx={{ width: "17.5vw", fontSize: "0.8vw", backgroundColor: "#E4F2F0" }}
                  //               onKeyDown={(event) => {
                  //                 if (event.key === "Enter") {
                  //                   this.setState({
                  //                     reportSearch: this.state.searchkey
                  //                   })
                  //                 }
                  //               }}
                  //               endAdornment={
                  //                 <IconButton onClick={() => {
                  //                   this.setState({
                  //                     reportSearch: this.state.searchkey
                  //                   })
                  //                 }} position="end">
                  //                   <i class="fas fa-search"></i>
                  //                 </IconButton>
                  //               }

                  //             />
                  //           </div>
                  : null
                }
                {this.renderLabHistoryPopup()}
                {((UserData?.module_name === "front_office" || UserData?.module_name === "nurse") && ((window.location.pathname === "/OPHome") || (window.location.pathname === "/IPHome"))) ? this.FOheaderSearch() : null}
                {(UserData?.module_name === "front_office" && getCachevalue("isForCancelBill") !== "true" && ((window.location.pathname === "/Billing") || (window.location.pathname === "/Billing/LabBilling"))) ? this.LabHeaderSearch(true) : null}
                {(UserData?.module_name === "front_office" && getCachevalue("isForCancelBill") !== "true" && window.location.pathname === "/Billing/RadiologyBilling") ? this.LabHeaderSearch(true, true) : null}
                {(UserData?.module_name === "Pharmacy" && ((window.location.pathname === "/PharmacyBilling"))) ? this.PharmaheaderSearch() : null}
                {((UserData?.module_name === 'Doctor' || UserData?.module_name === 'Optometry') && ((window.location.pathname === "/DoctorHome"))) ? this.DoctorHeaderSearch() : null}
                {((UserData?.module_name === 'Radiology') && ((window.location.pathname === "/RadiologyHome"))) ? this.RadiologyHeaderSearch() : null}

                <div className='eMed_headContent'>
                  {/* <div className='emed_headerLogo'>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      onClick={this.handleDrawerOpen}
                      edge='start'
                      sx={{
                        ...(this.state.drawer_Open && { display: 'none', height: '3px' }),
                      }}> */}
                  {/* <img className='img_logo' src={logo} alt="logo" /> */}
                  {/* <div style={{height: '2vw', width:'2vw'}}></div>
                    </IconButton>
                  </div> */}
                  {/* <div>
                    <TextField
                      placeholder={t('SearchHere')}
                      className="eMed_searchbox"
                      color='primary'
                      size='small'
                      value={this.state.globalSearch}
                      onChange={this.search}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <SearchIcon className="eMed_searchbarIcon" style={{ color: Colors.ThemeColor }} />
                          </InputAdornment>
                        ),
                        endAdornment: (
                          <InputAdornment position="end">
                            <ClearIcon className="eMed_searchbarIcon" style={{ color: Colors.ThemeColor }} onClick={this.clearSearch} />
                          </InputAdornment>
                        ),
                      }}
                    />

                  </div> */}
                </div>

                <div className='eMed_appbar_menu'>
                  {/* <AppBarMenus DialogClose={this.DialogClose}/> */}
                  {AppBarMenus.Menus.map((menu) => (
                    <div >
                      <Tooltip title={t(menu.menuName)} placement='top' arrow>
                        {/* <IconButton id="eMed_menu_icons" onClick={() => this.setState({ menuComp: true })} sx={{ color: Colors.ContrastText }} > */}

                        <Avatar id='eMed_IconAvatar' sx={{ bgcolor: Colors.IconCircleBgColor, boxShadow: 3 }} onClick={() => this.setState({ menuComp: true })}>
                          {menu.icon}
                        </Avatar>
                        {/* </IconButton> */}
                      </Tooltip>
                      {this.state.menuComp ? <Settings DialogClose={this.ModuleDialogClose} Success={this.ModuleSuccess} /> : null}
                    </div>
                  ))}
                </div>
                <div className='savedBillFieldHeader'>
                  {((this.state.selectedPageName === "MainHome") && !(window.location.pathname === "/Dashboard") && (this.state.selectedSubScreen === "LabHome") && (this.state.loginType === 'Laboratory' || this.state.loginType === "Radiology")) || (window.location.pathname === "/CanteenHome" && !getCachevalue("isCanteenOverall")) ?
                    <div>
                      <button emed_tid="IPAdmissionToBill_btn" className='fo_requests_icon' onClick={() => { this.setState({ Lab_IpPatientList: true }) }}>
                        <Badge badgeContent={this.state.badgeCount?.lab_inpatient_count ? this.state.badgeCount?.lab_inpatient_count : this.state.badgeCount?.rad_iplist_count} max={999} color="error">
                          <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.Ip_list.default} alt='Request'></img>
                        </Badge>
                        <Typography fontSize={'0.7vw'}>{t('IP List')}</Typography>
                      </button>
                    </div> : null}
                   
                  {
                    (((window.location.pathname === "/Billing/LabBilling") || (window.location.pathname === "/Billing")) && (!this.state.disableTransmitIcon)) ? (
                      <div>
                        <button
                          emed_tid="IPAdmissionToBill_btn" className='fo_requests_icon'
                          onClick={() => {
                            if ( ((window.location.pathname === "/Billing") || (window.location.pathname === "/Billing/LabBilling")) ) {
                              this.setState({ isTranmitedBill: true, isFrom_fo_lab: true });
                            }
                          }}
                        >
                          <Badge badgeContent={this.state.badgeCount?.lab_test_transmit_count} color="error" >
                            <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.PharmacyIPReturn.default} alt='transmit' />
                          </Badge>
                          <Typography fontSize={'0.7vw'}>{t('Transmit')}</Typography>
                        </button>
                      </div>
                    ) : null
                  }

                  {((this.state.selectedPageName === "MainHome") && !(window.location.pathname === "/Dashboard") && (this.state.selectedSubScreen === "LabHome") && (this.state.loginType === 'Laboratory')) || (window.location.pathname === "/CanteenHome" && !getCachevalue("isCanteenOverall")) ?
                    <div>
                      <button emed_tid="IPAdmissionToBill_btn" className='fo_requests_icon' onClick={() => { this.setState({ is_labOpList_clked: true }) }}>
                        <Badge badgeContent={this.state.badgeCount?.lab_appointment_count} color="error" max={999}>
                          <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.PharmacyApp.default} alt='Request'></img>
                        </Badge>
                        <Typography fontSize={'0.7vw'}>{t('Appointments')}</Typography>
                      </button>
                    </div> : null} 

                  {
                    this.state.labType === "Inhouse Laboratory" && this.state.selectedSubScreen === "LabHome" && !(window.location.pathname === "/Dashboard") ?
                      (this.state.selectedPageName === "MainHome" && window.location.pathname !== "/Results") ?
                        <div>
                          <button emed_tid="IPAdmissionToBill_btn" className='fo_requests_icon' onClick={() => { this.setState({ isTranmitedBill: true }) }}>
                            <Badge badgeContent={this.state.badgeCount?.lab_test_transmit_count} color="error" max={999}>
                              <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.PharmacyIPReturn.default} alt='transmit'></img>
                            </Badge>
                            <Typography fontSize={'0.7vw'}>{t('Transmit')}</Typography>
                          </button>
                        </div>
                        : null
                      : null}
                  {this.state.selectedPageName === "MainHome" && this.state.selectedSubScreen === "LabHome" && !(window.location.pathname === "/Dashboard") && (this.state.loginType === 'Laboratory') ?
                    <Tooltip title={'Saved Bills'} arrow>
                      <div>
                        <button emed_tid="SavedToBill_btn" className='fo_requests_icon' onClick={() => { this.setState({ isSavedBill: true }) }}>
                          <Badge badgeContent={this.state.badgeCount?.lab_bill_count} color="error" max={999}>
                            <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.PharmacySaveBill.default} alt='Request'></img>
                          </Badge>
                          <Typography fontSize={'0.7vw'} whiteSpace={'nowrap'}>{t('Saved Bills')}</Typography>
                        </button>
                      </div>
                    </Tooltip>
                    : null}
                  {
                    (window.location.pathname === "/OPHome") && UserData?.module_name !== "nurse" && UserData?.service_type !== "OPTOMETRY" ?
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {this.state.loggedInuserInfo?.is_qms_enabled ?
                          <Box display={'flex'}>
                            <Tooltip title={t('Doctor Queue')} arrow>
                              <div>
                                <button className='fo_requests_icon' emed_tid='op_home_drQueue_popUp'
                                  onClick={(e) => { this.setState({ DocQueuePopup: true }) }}
                                >
                                  <Badge showZero={true} badgeContent={this.state.drQueueData?.length} color="error">
                                    <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.DocCallOnIcon.default} alt='DrQueue'></img>
                                  </Badge>
                                  <Typography fontSize={'0.7vw'}>{t('Queue')}</Typography>
                                </button>
                              </div>
                            </Tooltip>
                            <Tooltip title={t('Notification')} arrow>
                              <div>
                                <button className='fo_requests_icon' emed_tid='op_home_notification_popUp'
                                  onClick={() => {
                                    this.getNotificationData()
                                    this.setState({ notifyPopup: true })
                                  }}
                                >
                                  <Badge showZero={true} badgeContent={this.state.FoNotifyList?.length} color="error">
                                    <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.Notification.default} alt='Notification'></img>
                                  </Badge>
                                  <Typography fontSize={'0.7vw'}>{t('Notification')}</Typography>
                                </button>
                              </div>
                            </Tooltip>
                          </Box>
                          : null}
                        <Tooltip title={t('AppReq')} arrow>
                          <div>
                            <button className='fo_requests_icon'
                              onClick={(e) => {
                                let POVList = localGetItem("PurposeOfVisit") ? localGetItem("PurposeOfVisit") : '[]'
                                let array = []
                                JSON.parse(POVList)?.forEach(element => array.push(element.reason));
                                this.setState({
                                  RequestPopUp: true,
                                  RequestAnchor: e.currentTarget,
                                  POVList: array
                                }, () => {
                                  this.getAppointRequests(true)
                                  // this.getDoctorsList(true)
                                })
                              }}
                            >
                              <Badge badgeContent={this.state.FoRequestList.length} color="error" max={999}>
                                <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.RequestsIcon.default} alt='Request'></img>
                              </Badge>
                              <Typography fontSize={'0.7vw'}>{t('Request')}</Typography>
                            </button>
                          </div>
                        </Tooltip>
                      </div> : null
                  }
                  {
                    // Uma = 204 , masonic = 173
                    (window.location.pathname === "/OPHome" && this.state.loggedInuserInfo?.show_qr_entry) ?
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Tooltip title={t('QR Entry')} arrow>
                          <div>
                            <button emed_tid="qr_entry_list" className='fo_requests_icon'
                              onClick={(e) => {
                                this.setState({ QREntryPopUp: true, RequestAnchor: e.currentTarget }, () => {
                                  this.getQREntryList()
                                })
                              }}
                            >
                              <Badge badgeContent={this.state.QrEntryList.length} color="error" max={999}>
                                <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.QRIcon.default} alt='QR Entry'></img>
                              </Badge>
                              <Typography fontSize={'0.7vw'}>{t('QR Entry')}</Typography>
                            </button>
                          </div>
                        </Tooltip>
                      </div> : null
                  }
                  {window.location.pathname === "/OPHome" ?
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Tooltip title={t('Follow-up')} arrow>
                        <div>
                          <button className='fo_requests_icon' onClick={() => { this.setState({ FollowupPopup: true, follow_up_date: new Date() }, () => { this.getFollowUpData() }) }}>
                            <Badge badgeContent={this.state.followUpData?.length} color="error" max={999}>
                              <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.TransAppoint.default} alt='Transfer'></img>
                            </Badge>
                            <Typography fontSize={'0.7vw'} style={{ width: "3.5vw" }}>{t('Follow-up')}</Typography>
                          </button>
                        </div>
                      </Tooltip>
                    </div>
                    : null}
                  {((window.location.pathname === "/IPHome") || (window.location.pathname === "/OPHome") || window.location.pathname === "/IPDoctorHome") && UserData?.module_name !== "nurse" ?
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Tooltip title={window.location.pathname === "/OPHome" ? t('AppPrint') : t('IPAppPrint')} arrow>
                        <div>
                          <button emed_tid="appointment_list_print_btn" className='fo_requests_icon' onClick={() => { (window.location.pathname === "/OPHome") ? this.printAppList() : this.printIPAppList() }} disabled={this.state.disableBtn}>
                            <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.Print.default} alt='Request'></img>
                            <Typography fontSize={'0.7vw'}>{t('Print')}</Typography>
                          </button>

                        </div>
                      </Tooltip>
                    </div> : null}
                  {((window.location.pathname === "/Counsellor")) && UserData?.module_name === "counsellor" ?
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Tooltip title={window.location.pathname === "/Counsellor" ? t('AppPrint') : t('AppPrint')} arrow>
                        <div>
                          <button emed_tid="appointment_list_print_btn" className='fo_requests_icon' onClick={() => { this.CounsellerModulePrint() }} disabled={this.state.disableBtn}>
                            <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.Print.default} alt='Request'></img>
                            <Typography fontSize={'0.7vw'}>{t('Print')}</Typography>
                          </button>

                        </div>
                      </Tooltip>
                    </div> : null}
                  {window.location.pathname === "/DoctorHome" ?
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Tooltip title={t('Follow-up')} arrow>
                        <div>
                          <button className='fo_requests_icon' onClick={() => { this.setState({ FollowupPopup: true, follow_up_date: new Date() }, () => { this.getFollowUpData() }) }}>
                            <Badge badgeContent={this.state.followUpData?.length} color="error" max={999}>
                              <img style={{ height: '1.7vw', width: '1.7vw' }} src={ImagePaths.TransAppoint.default} alt='Transfer'></img>
                            </Badge>
                            <Typography fontSize={'0.7vw'} style={{ width: "3.5vw" }}>{t('Follow-up')}</Typography>
                          </button>
                        </div>
                      </Tooltip>
                    </div>
                    : null}
                  {(window.location.pathname === "/DoctorHome") && UserData?.module_name == "Doctor" && UserData?.service_type !== "OPTOMETRY" ?
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Tooltip title={t('Unlocked Notes')} arrow>
                        <div style={{ marginRight: "0.6vw" }}>
                          <button className='fo_requests_icon' onClick={() => { this.setState({ unlockNotespopup: true }) }}>
                            <Badge badgeContent={this.state.unlockDocDataCount} color="error" max={999}>
                              <img style={{ height: '1.7vw', width: '1.7vw' }} src={ImagePaths.LockBtn.default} alt='Transfer'></img>
                            </Badge>
                            <Typography fontSize={'0.7vw'} style={{ width: "6vw" }}>{t('Unlocked Notes')}</Typography>
                          </button>
                        </div>
                      </Tooltip>
                    </div>
                    : null}
                  {(window.location.pathname === "/DoctorHome") && UserData?.module_name == "Doctor" && UserData?.service_type !== "OPTOMETRY" ?
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Tooltip title={t('Transfer Request')} arrow>
                        <div style={{ marginRight: "0.5vw" }}>
                          <button className='fo_requests_icon' onClick={() => { this.setState({ TransAppoint: true }) }}>
                            <Badge badgeContent={this.state.transferReqData?.length} color="error" max={999}>
                              <img style={{ height: '1.7vw', width: '1.7vw' }} src={ImagePaths.TransAppoint.default} alt='Transfer'></img>
                            </Badge>
                            <Typography fontSize={'0.7vw'} style={{ width: "6.5vw" }}>{t('Transfer Request')}</Typography>
                          </button>
                        </div>
                      </Tooltip>
                    </div>
                    : null}
                  {window.location.pathname === "/PharmacyBilling" && UserData?.module_name !== "nurse" ?
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      {UserData?.pharmacy_type === 'Inhouse Pharmacy' ?
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                          <Tooltip title={'In Patients Returns'} arrow>
                            <div>
                              <button emed_tid="IpReturn_btn" className='fo_requests_icon' onClick={() => { if (noReturn?.rtn_type === 'No Return') { this.errorMessage("Return is Not Allowed") } else { this.setState({ ph_returns: true }) } }}>
                                <Badge badgeContent={this.state.badgeCount?.pharmacy_IP_return_count} color="error" max={999}>
                                  <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.PharmacyIPReturn.default} alt='Request'></img>
                                </Badge>
                                <Typography fontSize={'0.7vw'} whiteSpace={'nowrap'}>{t('IP Returns')}</Typography>
                              </button>
                            </div>
                          </Tooltip>
                          <Tooltip title={'In Patients Request'} arrow>
                            <div>
                              <button emed_tid="IpRequest_btn" className='fo_requests_icon' onClick={() => { this.setState({ ph_request: true }) }}>
                                <Badge badgeContent={this.state.badgeCount?.pha_ip_request_count} color="error" max={999}>
                                  <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.PharmacyIPRequest.default} alt='Request'></img>
                                </Badge>
                                <Typography fontSize={'0.7vw'} whiteSpace={'nowrap'}>{t('IP Request')}</Typography>
                              </button>
                            </div>
                          </Tooltip>
                          <Tooltip title={'IP Admission List'} arrow>
                            <div>
                              <button emed_tid="IPAdmissionToBill_btn" className='fo_requests_icon' onClick={() => { this.setState({ ph_IpPatientList: true }) }}>
                                <Badge badgeContent={this.state.badgeCount?.pha_inpatient_count} color="error" max={999}>
                                  <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.Ip_list.default} alt='Request'></img>
                                </Badge>
                                <Typography fontSize={'0.7vw'}>{t('IP List')}</Typography>
                              </button>
                            </div>
                          </Tooltip>
                          <Tooltip title={'Appointments'} arrow>
                            <div>
                              <button emed_tid="AppointsToBill_btn" className='fo_requests_icon' onClick={() => { this.setState({ ph_Appointments: true }) }}>
                                <Badge badgeContent={this.state.badgeCount?.pharmacy_appointment_count} color="error" max={999}>
                                  <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.PharmacyApp.default} alt='Request'></img>
                                </Badge>
                                <Typography fontSize={'0.7vw'}>{t('Appointments')}</Typography>
                              </button>
                            </div>
                          </Tooltip>
                        </div>
                        : null}

                      <Tooltip title={'Saved Bills'} arrow>
                        <div>
                          <button emed_tid="SavedToBill_btn" className='fo_requests_icon' onClick={() => { this.setState({ ph_SavedBill: true }) }}>
                            <Badge badgeContent={this.state.badgeCount?.pharmacy_bill_count} color="error" max={999}>
                              <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.PharmacySaveBill.default} alt='Request'></img>
                            </Badge>
                            <Typography fontSize={'0.7vw'} whiteSpace={'nowrap'}>{t('Saved Bills')}</Typography>
                          </button>
                        </div>
                      </Tooltip>
                      {UserData?.pharmacy_type === 'Inhouse Pharmacy' ?
                        <div>
                          <Tooltip title={'Prescription'} arrow>
                            <div>
                              <button emed_tid="PrescToBill_btn" className='fo_requests_icon' onClick={() => { this.setState({ ph_Prescrition: true }) }}>
                                <Badge badgeContent={this.state.badgeCount?.pha_prescription_count} color="error" max={999}>
                                  <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.Pharmacypres.default} alt='Request'></img>
                                </Badge>
                                <Typography fontSize={'0.7vw'}>{t('Prescription')}</Typography>
                              </button>
                            </div>
                          </Tooltip>
                        </div> : null}

                    </div> : null}
                  {
                    window.location.pathname === "/SubLocationBilling" ?
                      <div style={{ marginRight: "1vw" }}>
                        <Tooltip title={'Saved Bills'} arrow>
                          <div>
                            <button emed_tid="SavedToBill_btn" className='fo_requests_icon' onClick={() => { this.setState({ ph_SavedBill: true }) }}>
                              <Badge badgeContent={this.state.badgeCount?.pharmacy_bill_count} color="error" max={999}>
                                <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.PharmacySaveBill.default} alt='Request'></img>
                              </Badge>
                              <Typography fontSize={'0.7vw'} whiteSpace={'nowrap'}>{t('Saved Bills')}</Typography>
                            </button>
                          </div>
                        </Tooltip>
                      </div> : null
                  }
                  {/* Doctor Print and Appointment Request Starts ========> */}
                  {
                    (window.location.pathname === "/DoctorHome") && UserData?.module_name !== "nurse" && UserData?.service_type !== "OPTOMETRY" ?
                      <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <Tooltip title={t('AppReq')} arrow>
                          <div>
                            <button className='fo_requests_icon'
                              onClick={(e) => {
                                let POVList = localGetItem("PurposeOfVisit") ? localGetItem("PurposeOfVisit") : '[]'
                                let array = []
                                JSON.parse(POVList)?.forEach(element => array.push(element.reason));
                                this.setState({
                                  openDoctorAppointment: true,
                                  doctorAppointmentAnchor: e.currentTarget,
                                  POVList: array
                                })
                              }}
                            >
                              <Badge badgeContent={this.state.doctorAppointmentData.length} color="error" max={999}>
                                <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.RequestsIcon.default} alt='Request'></img>
                              </Badge>
                              <Typography fontSize={'0.7vw'}>{t('Request')}</Typography>
                            </button>
                          </div>
                        </Tooltip>
                      </div> : null
                  }
                  {((window.location.pathname === "/DoctorHome")) && UserData?.module_name !== "nurse" && UserData?.service_type !== "OPTOMETRY" ?
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Tooltip title={'Print'} arrow>
                        <div>
                          <button className='fo_requests_icon' onClick={(e) => { this.setState({ openDoctorPrint: true, doctorAnchor: e.currentTarget }) }}>
                            <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.Print.default} alt='Request'></img>
                            <Typography fontSize={'0.7vw'}>{t('Print')}</Typography>
                          </button>
                        </div>
                      </Tooltip>
                    </div> : null}
                  {((window.location.pathname === "/DoctorHome/DoctorNotes")) && UserData?.module_name !== "nurse" && UserData?.service_type !== "OPTOMETRY" ?
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Tooltip title={'Doctor Notes Print'} arrow>
                        <div>
                          <button className='fo_requests_icon' onClick={(e) => { this.setState({ openDoctorNotesPrint: true, openDoctorNotesPrintAnchor: e.currentTarget }) }}>
                            <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.Print.default} alt='Request'></img>
                            <Typography fontSize={'0.7vw'}>{t('Print')}</Typography>
                          </button>
                        </div>
                      </Tooltip>
                    </div> : null}
                  {((window.location.pathname === "/DoctorHome/DocImmunization")) ?
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Tooltip title={'Doctor Notes Print'} arrow>
                        <div>
                          <button className='fo_requests_icon' onClick={(e) => { this.printImmunizationData() }}>
                            <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.Print.default} alt='Request'></img>
                            <Typography fontSize={'0.7vw'}>{t('Print')}</Typography>
                          </button>
                        </div>
                      </Tooltip>
                    </div> : null}
                  {((window.location.pathname === "/DoctorHome/History/MedicalHistory")) && UserData?.module_name !== "nurse" ?
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Tooltip title={'History Print'} arrow>
                        <div>
                          <button className='fo_requests_icon' onClick={(e) => { this.printDoctorHistoryData() }}>
                            <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.Print.default} alt='Request'></img>
                            <Typography fontSize={'0.7vw'}>{t('Print')}</Typography>
                          </button>
                        </div>
                      </Tooltip>
                    </div> : null}
                  {(window.location.pathname === "/OTHome") && UserData?.module_name === "ot_management" ?
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Tooltip title={t('Requests')} arrow>
                        <div>
                          <button className='fo_requests_icon' onClick={() => { this.setState({ OTrequestPopup: true }) }}>
                            <Badge badgeContent={this.state.OTreqData?.length} color="error" max={999}>
                              <img style={{ height: '1.7vw', width: '1.7vw' }} src={ImagePaths.RequestsIcon.default} alt='Transfer'></img>
                            </Badge>
                            <Typography fontSize={'0.7vw'} style={{ width: "3.5vw" }}>{t('Requests')}</Typography>
                          </button>
                        </div>
                      </Tooltip>
                    </div>
                    : null}
                  {((window.location.pathname === "/OTHome") || (window.location.pathname === "/IPHome/OThome")) ?
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                      <Tooltip title={'Print'} arrow>
                        <div>
                          <button className='fo_requests_icon' disabled={this.state.printOTApp} onClick={(e) => { this.getOTAppointmentListPrint() }}>
                            <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.Print.default} alt='Request'></img>
                            <Typography fontSize={'0.7vw'}>{t('Print')}</Typography>
                          </button>
                        </div>
                      </Tooltip>
                    </div> : null}
                </div>
                {/* Print Menu */}
                <Menu
                  anchorEl={this.state.doctorAnchor}
                  sx={{ position: 'absoulte', top: '2.5vw', left: '0.7vw' }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'bottom',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'bottom',
                  }}
                  open={this.state.openDoctorPrint}
                  onClose={() => {
                    this.setState({ openDoctorPrint: false })
                  }}
                >
                  <Box component={'div'} p={'1vw'}>
                    <FormGroup>
                      <FormControlLabel control={<Checkbox checked={this.state.all} onChange={(e) => { this.handleCheckBox(e, "all") }} />} label="All" />
                      <FormControlLabel control={<Checkbox checked={this.state.booked} onChange={(e) => { this.handleCheckBox(e, "booked") }} />} label="Booked" />
                      <FormControlLabel control={<Checkbox checked={this.state.arrived} onChange={(e) => { this.handleCheckBox(e, "arrived") }} />} label="Arrived" />
                      <FormControlLabel control={<Checkbox checked={this.state.withNurse} onChange={(e) => { this.handleCheckBox(e, "withNurse") }} />} label="With Nurse" />
                      <FormControlLabel control={<Checkbox checked={this.state.withDoctor} onChange={(e) => { this.handleCheckBox(e, "withDoctor") }} />} label="With Doctor" />
                      <FormControlLabel control={<Checkbox checked={this.state.completed} onChange={(e) => { this.handleCheckBox(e, "completed") }} />} label="Completed" />
                      <FormControlLabel control={<Checkbox checked={this.state.cancelled} onChange={(e) => { this.handleCheckBox(e, "cancelled") }} />} label="Cancelled" />
                    </FormGroup>
                    <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                      <Button
                        sx={{ height: '2vw', width: '5vw', mr: '1vw', textTransform: 'capitalize' }}
                        variant='contained'
                        onClick={() => this.postDoctorPrint()}
                      >
                        Print
                      </Button>
                      <Button
                        variant='outlined'
                        sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                        onClick={() => this.setState({ doctorAnchor: null, openDoctorPrint: false })}
                      >
                        cancel
                      </Button>
                    </Box>
                  </Box>
                </Menu>
                {/* <Menu
                  anchorEl={this.state.doctorAppointmentAnchor}
                  sx={{ position: 'absoulte', top: '2.5vw', left: '0vw' }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  open={this.state.openDoctorAppointment}
                  onClose={() => {
                    this.setState({ openDoctorAppointment: false })
                  }}
                >
                  <Box component={'div'} p={'1vw'}>
                    <Box component={'div'}>
                      <Tabs value={this.state.doctorTab} onChange={(e, newValue) => this.setState({ doctorTab: newValue })}
                        sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
                        <Tab label='Appointment Request' sx={{ textTransform: 'capitalize' }} value={'one'} />
                      </Tabs>
                    </Box>
                    {this.state.doctorTab === 'one' ?
                      <Box component={'div'} border={'1px solid lightgray'}>
                        <Table stickyHeader size='small'>
                          <TableHead>
                            <TableRow>
                              <TableCell sx={{ fontSize: '0.9vw', fontWeight: '600', backgroundColor: 'lightgray' }}>Patient Name</TableCell>
                              <TableCell sx={{ fontSize: '0.9vw', fontWeight: '600', backgroundColor: 'lightgray' }}>Mobile Number</TableCell>
                              <TableCell sx={{ fontSize: '0.9vw', fontWeight: '600', backgroundColor: 'lightgray' }}>Reason</TableCell>
                              <TableCell sx={{ fontSize: '0.9vw', fontWeight: '600', backgroundColor: 'lightgray' }}>Appointment Date</TableCell>
                              <TableCell sx={{ fontSize: '0.9vw', fontWeight: '600', backgroundColor: 'lightgray' }}>Medium</TableCell>
                              <TableCell sx={{ fontSize: '0.9vw', fontWeight: '600', backgroundColor: 'lightgray' }}>Action</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {this.state.doctorAppointmentData?.length > 0 ? this.state.doctorAppointmentData?.map((item, index) => (
                              <TableRow key={index}>
                                <TableCell>{item?.first_name}</TableCell>
                                <TableCell>{item?.mobile_number}</TableCell>
                                <TableCell>{item?.message}</TableCell>
                                <TableCell>{formatDate(item?.appointment_date)}</TableCell>
                                <TableCell>{item?.booked_through}</TableCell>
                                <TableCell>
                                  <Box component={'div'} display={'flex'}>
                                    <Box>
                                      <Tooltip title={t("CreateAppointment")} arrow>
                                        <IconButton onClick={() => {
                                          this.setState({
                                            doctorAppointmentAnchor: null,
                                            openDoctorAppointment: false
                                          }, () => {
                                            this.getDoctorPatientDetailData(item?.patient_id, 'Request Create')
                                          })
                                        }
                                        }
                                          position="end">
                                          <PersonAddAlt1Icon color='primary' />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                    <Box>
                                      <Tooltip>
                                        <IconButton onClick={() => { this.postDoctorCancelAppointment(item?.id) }}
                                          position="end">
                                          <Box component={'img'} src={ImagePaths.CancelIcon.default} height={'1.5vw'} width={'1.5vw'} />
                                        </IconButton>
                                      </Tooltip>
                                    </Box>
                                    {this.state.postLoad ?
                                      <CircularProgress size={'small'} color='primary' /> : null}
                                  </Box>
                                </TableCell>
                              </TableRow>
                            )) : <TableRow>
                              <TableCell />
                              <TableCell />
                              <TableCell sx={{ fontSize: '0.9vw' }}>No Records To Be Found</TableCell>
                              <TableCell />
                              <TableCell />
                              <TableCell />
                            </TableRow>}
                          </TableBody>
                        </Table>
                      </Box>
                      : null}
                  </Box>
                </Menu> */}
                {this.doctorAppointmentRequest()}
                <Menu
                  anchorEl={this.state.openDoctorNotesPrintAnchor}
                  sx={{ position: 'absoulte', top: '2.5vw', left: '0.7vw' }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'bottom',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'bottom',
                  }}
                  open={this.state.openDoctorNotesPrint}
                  onClose={() => {
                    this.setState({ openDoctorNotesPrint: false })
                  }}
                >
                  <Box component={'div'} p={'1vw'} maxHeight={'30vw'} overflow={'auto'}>
                    {this.state.isOPT ?
                      this.state.doctorPrintList?.opt_doctor?.map((item, index) => {
                        return (
                          <FormGroup>
                            <FormControlLabel key={index} control={<Checkbox checked={item?.is_checked} onChange={(e) => { this.handleNewCheckBoxHandler(e, index) }} />} label={item?.label} />
                          </FormGroup>
                        )
                      }) : this.state.doctorPrintList?.other_doctor?.map((item, index) => {
                        return (
                          <FormGroup>
                            <FormControlLabel key={index} control={<Checkbox checked={item?.is_checked} onChange={(e) => { this.handleNewCheckBoxHandler(e, index) }} />} label={item?.label} />
                          </FormGroup>
                        )
                      })
                      // <FormGroup>
                      //   <FormControlLabel control={<Checkbox checked={this.state.fullReport} onChange={(e) => { this.handleCheckBox(e, "fullReport") }} />} label="Full Report" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.symptoms} onChange={(e) => { this.handleCheckBox(e, "symptoms") }} />} label="Symptoms" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.allergies} onChange={(e) => { this.handleCheckBox(e, "allergies") }} />} label="Allergies" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.vitals} onChange={(e) => { this.handleCheckBox(e, "vitals") }} />} label="Vitals" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.examination} onChange={(e) => { this.handleCheckBox(e, "examination") }} />} label="Examination" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.diagnosis} onChange={(e) => { this.handleCheckBox(e, "diagnosis") }} />} label="Diagnosis" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.prescription} onChange={(e) => { this.handleCheckBox(e, "prescription") }} />} label="Prescription" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.prevPrescrip} onChange={(e) => { this.handleCheckBox(e, "prevPrescrip") }} />} label="Previous Prescription" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.test} onChange={(e) => { this.handleCheckBox(e, "test") }} />} label="Test" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.plan} onChange={(e) => { this.handleCheckBox(e, "plan") }} />} label="Plan" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.generalPatient} onChange={(e) => { this.handleCheckBox(e, "generalPatient") }} />} label="General-Patient Advice" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.counselling} onChange={(e) => { this.handleCheckBox(e, "counselling") }} />} label="Counselling" />
                      // </FormGroup> :
                      // <FormGroup>
                      //   <FormControlLabel control={<Checkbox checked={this.state.fullReport} onChange={(e) => { this.handleCheckBox(e, "fullReport") }} />} label="Full Report" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.complaints} onChange={(e) => { this.handleCheckBox(e, "complaints") }} />} label="Complaints" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.ocular} onChange={(e) => { this.handleCheckBox(e, "ocular") }} />} label="Ocular History" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.Illness} onChange={(e) => { this.handleCheckBox(e, "Illness") }} />} label="Systemic Illness" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.vitals} onChange={(e) => { this.handleCheckBox(e, "vitals") }} />} label="Vitals" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.vision} onChange={(e) => { this.handleCheckBox(e, "vision") }} />} label="Vision Assessment" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.OcularResponse} onChange={(e) => { this.handleCheckBox(e, "OcularResponse") }} />} label="Ocular Response Analyzer" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.gonioscopy} onChange={(e) => { this.handleCheckBox(e, "gonioscopy") }} />} label="Gonioscopy" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.autoRefract} onChange={(e) => { this.handleCheckBox(e, "autoRefract") }} />} label="Auto Refractrometry" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.prevGlassPower} onChange={(e) => { this.handleCheckBox(e, "prevGlassPower") }} />} label="Previous Glass Power" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.subjectiveRefract} onChange={(e) => { this.handleCheckBox(e, "subjectiveRefract") }} />} label="Subjective Refraction" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.retinoscopicRefract} onChange={(e) => { this.handleCheckBox(e, "retinoscopicRefract") }} />} label="Retinoscopic Refraction" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.IntraPressure} onChange={(e) => { this.handleCheckBox(e, "IntraPressure") }} />} label="Intraocular Pressure" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.Anterior} onChange={(e) => { this.handleCheckBox(e, "Anterior") }} />} label="Anterior Segment" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.AnteriorImg} onChange={(e) => { this.handleCheckBox(e, "AnteriorImg") }} />} label="Anterior Segment Image" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.Posterior} onChange={(e) => { this.handleCheckBox(e, "Posterior") }} />} label="Posterior Segment" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.fundus} onChange={(e) => { this.handleCheckBox(e, "fundus") }} />} label="Fundus" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.OtherInvestigation} onChange={(e) => { this.handleCheckBox(e, "OtherInvestigation") }} />} label="Other Investigation" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.Keratometry} onChange={(e) => { this.handleCheckBox(e, "Keratometry") }} />} label="Keratometry" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.diagnosis} onChange={(e) => { this.handleCheckBox(e, "diagnosis") }} />} label="Diagnosis" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.medication} onChange={(e) => { this.handleCheckBox(e, "medication") }} />} label="Medication" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.glassPrescrib} onChange={(e) => { this.handleCheckBox(e, "glassPrescrib") }} />} label="Glass Prescription" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.Review} onChange={(e) => { this.handleCheckBox(e, "Review") }} />} label="Review Plan" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.Treatment} onChange={(e) => { this.handleCheckBox(e, "Treatment") }} />} label="Treatment Plan" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.counselling} onChange={(e) => { this.handleCheckBox(e, "counselling") }} />} label="Counselling" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.investigation} onChange={(e) => { this.handleCheckBox(e, "investigation") }} />} label="Investigation" />
                      //   <FormControlLabel control={<Checkbox checked={this.state.overallNotes} onChange={(e) => { this.handleCheckBox(e, "overallNotes") }} />} label="Notes" />
                      // </FormGroup>
                    }
                  </Box>

                  <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'}>
                    <Button
                      sx={{ height: '2vw', width: '5vw', mr: '1vw', textTransform: 'capitalize' }}
                      variant='contained'
                      onClick={() => this.getDoctorNotesPrint()}
                    >
                      Print
                    </Button>
                    <Button
                      sx={{ height: '2vw', width: '5vw', mr: '1vw', textTransform: 'capitalize' }}
                      variant='contained'
                      onClick={() => this.onClickDoctorNotesDefault()}
                    >
                      Default
                    </Button>
                    <Button
                      variant='outlined'
                      sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                      onClick={() => this.setState({ openDoctorNotesPrintAnchor: null, openDoctorNotesPrint: false, doctorPrintList: JSON.parse(this.state.dupDocPrintList) })}
                    >
                      cancel
                    </Button>
                  </Box>
                </Menu>
                {/* Doctor Print and Appointment Menu Ends */}

                {/* Radiology Appointment Starts ====> */}
                {((window.location.pathname === "/RadiologyHome")) && UserData?.module_name !== "nurse" ?
                  <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div>
                      <button emed_tid="saveToBill_btn" id="basic-button" className='fo_requests_icon' onClick={(e) => { this.setState({ rad_saved_bill: true, radSavedBillAnchcor: e.currentTarget }) }}>
                        <Badge badgeContent={this.state.radSavedBillListData?.length} color="error" max={999}>
                          <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.PharmacySaveBill.default} alt='Appointment'></img>
                        </Badge>
                        <Typography fontSize={'0.7vw'} whiteSpace={'nowrap'}>Saved Bill</Typography>
                      </button>
                      {this.radSavedBillList()}
                    </div>
                    <div>
                      <button emed_tid="IpRequest_btn" className='fo_requests_icon' onClick={(e) => { this.setState({ rad_request: true, radReqAnchorEl: e.currentTarget }) }}>
                        <Badge badgeContent={this.state.radIpList?.length} color="error" max={999}>
                          <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.Ip_list.default} alt='Request'></img>
                        </Badge>
                        <Typography fontSize={'0.7vw'} whiteSpace={'nowrap'}>Ip List</Typography>
                      </button>
                      {this.renderRadIpAppointmentList()}
                    </div>
                    <div>
                      <button emed_tid="AppointsToBill_btn" id="basic-button" className='fo_requests_icon' onClick={(e) => { this.setState({ rad_appointment: true, radAppAnchorEL: e.currentTarget }) }}>
                        <Badge badgeContent={this.state.radAppointmentList?.length} color="error" max={999}>
                          <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.RequestsIcon.default} alt='Appointment'></img>
                        </Badge>
                        <Typography fontSize={'0.7vw'} whiteSpace={'nowrap'}>Appointments</Typography>
                      </button>
                      {this.renderRadAppointments()}
                    </div>
                    {/* <div>
                      <button className='fo_requests_icon' onClick={(e) => { this.printRadiologyAppointment() }}>
                        <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.Print.default} alt='Request'></img>
                        <Typography fontSize={'0.7vw'}>{t('Print')}</Typography>
                      </button>
                    </div> */}
                  </div> : null}

                {/* Radiology Appointment Ends ====> */}
                {/* radiology and fo radiology bill transmit  */}
                {((window.location.pathname === "/RadiologyHome") && UserData?.module_name !== "nurse") || (window.location.pathname === "/Billing/RadiologyBilling") ? <div>
                  <button emed_tid="IPAdmissionToBill_btn" className='fo_requests_icon' onClick={(e) => { this.setState({ rad_transmit: true, radTransAnchorEL: e.currentTarget }) }}>
                    <Badge badgeContent={this.state.radTransmitList?.length || 0} color="error">
                      <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.PharmacyIPReturn.default} alt='transmit'></img>
                    </Badge>
                    <Typography fontSize={'0.7vw'} whiteSpace={'nowrap'}>{t('Transmit')}</Typography>
                  </button>
                  {this.renderRadTransmit(true)}
                </div> : null}

                {/* <Menu
                  open={this.state.RequestPopUp}
                  className="eMed_Header_Request_Pop"
                  anchorEl={this.state.RequestAnchor}
                  onClose={() => {
                    this.setState({ RequestPopUp: false })
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                  transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                  }}
                > */}
                {/* <Box display={'flex'} flexDirection={'row'} justifyContent={'space-between'} alignItems={'center'}>
                    <Typography sx={{ padding: '0.5vw', fontWeight: '600', fontSize: '0.9vw', paddingLeft: '1vw' }}>Appointment Requests</Typography>
                    {/* <IconButton onClick={() => { this.setState({ RequestPopUp: false }) }}> <CloseIcon color='primary' /></IconButton> */}
                {/* <div style={{ padding: "0.5vw" }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                          open={this.state.DatePickerOpen}
                          onOpen={() => { this.setState({ DatePickerOpen: true }) }}
                          onClose={() => { this.setState({ DatePickerOpen: false }) }}
                          value={this.state.datePicker}
                          name={"datePicker"}
                          inputFormat='DD-MM-YYYY'
                          views={["year", "month", "day"]}
                          onChange={
                            (newDate) => {
                              this.setState({
                                datePicker: newDate
                              }, () => {
                                this.getAppointRequests()
                              });
                            }}
                          renderInput={(params) => <TextField onKeyDown={(e) => e.preventDefault()} style={{ width: '10vw' }} size='small' {...params} onClick={() => { this.setState({ DatePickerOpen: true }) }} />}
                        />
                      </LocalizationProvider>
                    </div>
                  </Box>
                  <Box className="eMed_App_reqest_table_head">
                    <Typography width={"20%"} fontWeight={'600'} fontSize={"0.8vw"}>Date & Time</Typography>
                    <Typography width={"30%"} fontWeight={'600'} fontSize={"0.8vw"}>Patient Details</Typography>
                    <Typography width={"20%"} fontWeight={'600'} fontSize={"0.8vw"}>Clinic / Hospital</Typography>
                    <Typography width={"15%"} fontWeight={'600'} fontSize={"0.8vw"} textAlign={'center'}>Source</Typography>
                    <Typography width={"20%"} fontWeight={'600'} fontSize={"0.8vw"} textAlign={'center'}>Action</Typography>
                  </Box>
                  <Divider />
                  {
                    this.state.FoRequestList.length > 0 ?
                      this.state.FoRequestList.map((list) => {
                        let PatientName = list.first_name + " " + list.last_name;
                        return (
                          <Box>
                            <Box className="eMed_Header_Request_item">
                              <Box width={'20%'}>
                                <Typography fontWeight={'600'} fontSize={"0.85vw"}>{list.appointment_date ? formatDate(list.appointment_date) : "-"}</Typography>
                                <Typography fontSize={"0.7vw"}>{list.appointment_time}</Typography>
                              </Box>
                              <Box width={'30%'}>
                                <Typography fontWeight={'600'} fontSize={"0.85vw"}>
                                  {PatientName ? PatientName?.length > 20 ?
                                    <Tooltip placement='top' title={PatientName} arrow>
                                      <div>{PatientName.slice(0, 20) + "..."}</div></Tooltip> :
                                    PatientName : "-"}
                                </Typography>
                                <Typography fontSize={"0.7vw"}>{list.mobile} | {list.patient_account_number}</Typography>
                              </Box>
                              <Box width={'20%'}>
                                <Typography fontWeight={'600'} fontSize={"0.85vw"}>{list.doctor_name}</Typography>
                                <Typography fontSize={"0.7vw"}>{list.clinic_name}</Typography>
                              </Box>
                              <Box width={'15%'} display={'flex'} alignItems={'center'} justifyContent={'center'} >
                                {
                                  list.booked_through === "Mobile" ?
                                    <Tooltip title={t("MobileAppointment")} arrow placement='top'>
                                      <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.Phone.default}></img>
                                    </Tooltip> :
                                    <Tooltip title={t("WebAppointment")} arrow placement='top'>
                                      <img style={{ height: '1.5vw', width: '1.5vw' }} src={ImagePaths.Web.default}></img>
                                    </Tooltip>
                                }
                              </Box>
                              <Box display={'flex'} flexDirection={'row'} justifyContent={'center'} width={'20%'} paddingRight={'0.5vw'}>
                                <Tooltip title={t("Accept")} arrow placement='top'>
                                  <IconButton onClick={() => { this.postRequest(list, "Approve") }}>
                                    <CheckCircleIcon sx={{ height: '2vw', width: '2vw' }} color='primary' />
                                  </IconButton>
                                </Tooltip>
                                <Tooltip title={t("Cancel")} arrow placement='top'>
                                  <IconButton onClick={() => { this.postRequest(list, "Cancel") }}>
                                    <CancelIcon sx={{ height: '2vw', width: '2vw' }} color='error' />
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            </Box>
                            <Divider />
                          </Box>
                        )
                      }) :
                      <Box>
                        <Box className="eMed_Header_Request_item" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <Typography> {t("NoRecordsFound")}</Typography>
                        </Box>
                      </Box> */}
                {/* } */}
                {/* </Menu> */}

                {this.AppointmentRequestPop()}
                {this.NotificationPopup()}
                {this.QREntryList()}
                <Dialog
                  className="emed_DialogboxOrder"
                  open={this.state.isSavedBill}
                  onClose={this.savedBillClose}
                  maxWidth={"md"}
                  PaperProps={{
                    style: {
                      //  marginLeft: '76vw',
                      //  marginTop: "-13vw"
                    }
                  }}
                >
                  <div className='emed_Dialogboxwidth'>
                    <Saved_Bill properties={this.props} onClickSavedBills={this.onClickSavedBills} selectedSubScreen={this.state.selectedSubScreen} savedBillStatus={this.getListCount} />
                  </div>
                </Dialog>
                <Dialog
                  className="emed_DialogboxOrder"
                  open={this.state.isTranmitedBill}
                  onClose={this.transmitedClose}
                  maxWidth={"md"}
                >
                  <div className={this.state.isFrom_fo_lab ? 'emed_DialogboxwidthFO' : 'emed_Dialogboxwidth'}>
                    <Transmit properties={this.props} onClickTranmittedBills={this.onClickTranmittedBills} labTransmit={this.state.isFrom_fo_lab} TransmitBillCount={this.getListCount} />
                  </div>
                </Dialog>
                <Dialog
                  className="emed_DialogboxOrder"
                  open={this.state.ph_returns}
                  onClose={this.returnClose}
                  maxWidth={"md"}
                >
                  <div className='emed_Dialog_Pres'>
                    <IPReturns properties={this.props} onClickreturnClose={this.onClickreturnClose} />
                  </div>
                </Dialog>
                <Dialog
                  className="emed_DialogboxOrder"
                  open={this.state.ph_Prescrition}
                  onClose={this.PrescriptionClose}
                  maxWidth={"md"}
                >
                  <div className='emed_Dialog_Pres'>
                    <Prescription properties={this.props} getPharmaPrescriptionCount={this.getListCount} onClickPrescriptionClose={this.onClickPrescriptionClose} />
                  </div>
                </Dialog>
                <Dialog
                  className="emed_DialogboxOrder"
                  open={this.state.DocQueuePopup}
                  onClose={this.closeDrQueue}
                  maxWidth={"md"}
                >
                  <div className='emed_Dialog_Pres'>
                    <DrQueuePopup data={this.state.drQueueData} date={this.state.datePicker} onClose={this.closeDrQueue} callPatient={this.callPatientSend.bind(this)} />
                  </div>
                </Dialog>
                <Dialog
                  className="emed_DialogboxOrder"
                  open={this.state.ph_SavedBill}
                  onClose={this.savedBillClose}
                  maxWidth={"md"}
                >
                  <div className='emed_Dia_SaveBill'>
                    <SavedBill properties={this.props} isFromSubLocation={this.state.loginType === "sub_location"} getPharmaSavedBillCount={this.getListCount} onClickSavedBillClose={this.onClickSavedBillClose} />
                  </div>
                </Dialog>
                <Dialog
                  className="emed_DialogboxOrder"
                  open={this.state.ph_request}
                  onClose={this.requestClose}
                  maxWidth={"md"}
                >
                  <div className='emed_Dialog_Pres'>
                    <IPRequest properties={this.props} onClickrequestClose={this.onClickrequestClose} />
                  </div>
                </Dialog>

                <Dialog
                  className="emed_DialogboxOrder"
                  open={this.state.ph_IpPatientList}
                  onClose={() => { this.setState({ ph_IpPatientList: false }) }}
                  maxWidth={"md"}
                >
                  <div className='emed_Dialog_Appoin' style={{ width: '38vw' }}>
                    <Appointments properties={this.props} onClickAppointClose={(flag) => { this.setState({ ph_IpPatientList: flag }) }} isFromIpAdmission={true} />
                  </div>
                </Dialog>

                <Dialog
                  className="emed_DialogboxOrder"
                  open={this.state.ph_Appointments}
                  onClose={() => { this.setState({ ph_Appointments: false }) }}
                  maxWidth={"md"}
                >
                  <div className='emed_Dialog_Appoin'>
                    <Appointments properties={this.props} onClickAppointClose={(flag) => { this.setState({ ph_Appointments: flag }) }} isFromPharmaApp={true} />
                  </div>
                </Dialog>

                <Dialog
                  className="emed_DialogboxOrder"
                  open={this.state.Lab_IpPatientList}
                  onClose={() => { this.setState({ Lab_IpPatientList: false }) }}
                  maxWidth={"md"}
                >
                  <div className='emed_Dialog_Appoin' style={{ width: '38vw' }}>
                    <LabIPList properties={this.props} onClickIPListClose={(flag) => { this.setState({ Lab_IpPatientList: flag }) }} IPListLabCount={this.getListCount} />
                  </div>
                </Dialog>

                <Dialog
                  className="emed_DialogboxOrder"
                  open={this.state.is_labOpList_clked}
                  onClose={() => { this.setState({ is_labOpList_clked: false }) }}
                  maxWidth={"md"}
                >
                  <div className='emed_Dialog_Appoin' style={{ width: '38dvw' }}>
                    <Appointments properties={this.props} onClickAppointClose={(flag) => { this.setState({ is_labOpList_clked: flag }) }} isFromLabHome = {true}/>
                  </div>
                </Dialog>

                <Dialog
                  className="emed_DialogboxOrder"
                  open={this.state.TransAppoint}
                  onClose={() => { this.setState({ TransAppoint: false }) }}
                  maxWidth={"md"}
                >
                  <div className='emed_Dialog_Appoin' style={{ width: '57vw' }}>
                    {this.TransferAppointmentTable()}
                  </div>
                </Dialog>

                <Dialog
                  className="emed_DialogboxOrder"
                  open={this.state.unlockNotespopup}
                  onClose={() => { this.setState({ unlockNotespopup: false }) }}
                  maxWidth={"md"}
                >
                  <div className='emed_Dialog_Appoin' style={{ width: '38vw' }}>
                    {this.unlockNotesTable()}
                  </div>
                </Dialog>

                <Dialog
                  className="emed_DialogboxOrder"
                  open={this.state.FollowupPopup}
                  onClose={() => { this.setState({ FollowupPopup: false, follow_up_date: new Date() },() => this.getFollowUpData()) }}
                  maxWidth={"md"}
                >
                  <div className='emed_Dialog_Appoin' style={{ width: '57vw' }}>
                    {this.FollowupTable()}
                  </div>
                </Dialog>

                <Dialog
                  className="emed_DialogboxOrder"
                  open={this.state.OTrequestPopup}
                  onClose={() => { this.setState({ OTrequestPopup: false }) }}
                  maxWidth={"md"}
                >
                  <div className='emed_Dialog_Appoin' style={{ width: '57vw' }}>
                    {this.OTRequestTable()}
                  </div>
                </Dialog>

                <Dialog
                  className="emed_DialogboxOrder"
                  open={this.state.OTallocationPopup}
                  onClose={() => { this.setState({ OTallocationPopup: false }) }}
                  maxWidth={"md"}
                >
                  <div className='emed_Dialog_Appoin' style={{ width: '30vw', height: "45vh" }}>
                    {this.OTallocationTable()}
                  </div>
                </Dialog>

                <div className='fieldHeaderfordoctor'>
                  {window.location.pathname === "/Dashboard" ?
                    <div className='eMed_profile'>
                      <div className='eMed_user'>
                        <label className='eMed_loginuser'>{localGetItem("LogedUserName")}</label>
                      </div>
                    </div> :
                    <div className='eMed_profile'>
                      <div className='eMed_user'>
                        <label className='eMed_loginuser'>{this.state.loginName}</label>
                      </div>
                      <div className='eMed_servicetype'>
                        <label className='eMed_LoginType'>{this.state.loginType === "ot_management" ? "Operation Theatre" : this.state.loginType === "asset_management" ? "Asset Management" : this.state.loginType === "front_office" ? "Front Office" : this.state.loginType}</label>
                      </div>
                    </div>}
                  <div className='eMed_Avatar'>
                    <Box >
                      <Tooltip title="Profile" arrow>
                        <IconButton disabled={(getCachevalue("isForCancelBill") === "true" || getCachevalue("IsForCheckedOut") === "true" || getCachevalue("isInRadiologyBilling") === "true")} onClick={(e) => { this.setState({ anchorElUser: true, anchorElUser: e.currentTarget }) }}>
                          <Avatar sx={{ bgcolor: Colors.IconCircleBgColor, boxShadow: 3, color: Colors.ThemeColor }} id="eMed_Icons_Avatar" />
                        </IconButton>
                      </Tooltip>
                      <Menu
                        id="eMed_profileMenu"
                        // anchorEl={this.state.anchorElUser}
                        anchorOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        keepMounted
                        transformOrigin={{
                          vertical: 'top',
                          horizontal: 'right',
                        }}
                        open={this.state.anchorElUser}
                        onClose={this.handleCloseUserMenu}
                        sx={{ marginRight: '5vw' }}
                        TransitionProps={{
                          timeout: 0
                        }}
                      >

                        <div className='eMed_AvatarMenu'>
                          {/* <Box className="eMed_Profile_details_div">
                            <Box>
                            <Avatar sx={{ bgcolor: Colors.IconCircleBgColor, boxShadow: 3, color: Colors.ThemeColor }} id="eMed_Icons_Avatar" />
                            </Box>
                            <Box component={'div'} className='eMed_Profile_details_text'>
                            {window.location.pathname === "/Dashboard" ?
                              <Typography fontSize={'0.9vw'}>{localGetItem("LogedUserName")}</Typography>
                              :
                              <div >
                                <Typography fontSize={'0.9vw'} fontWeight={'600'}>{this.state.loginName}</Typography>
                                <Typography fontSize={'0.9vw'}>{this.state.loginType}</Typography>
                                <Typography fontSize={'0.9vw'}>{localGetItem("LogedUserName")}</Typography>
                              </div>}

                          </Box>
                          </Box> */}
                          {/* <MenuItem onClick={this.handleCloseUserMenu}>
                            <AccountBoxIcon sx={{ color: Colors.ThemeColor, marginRight: '0.5vw' }} />
                            {t('Profile')}
                          </MenuItem> */}
                          {(this.state.loginType === 'Doctor' || DisableSwitchModule) ? null :
                            !(window.location.href.includes("/Dashboard")) ?
                              <MenuItem onClick={() => {
                                this.handleCloseUserMenu()
                                this.setState({
                                  loginName: "",
                                  loginType: '',
                                  clinicId: null,
                                  badgeCount: {},
                                  anchorElUser: false,
                                  PatientNameSearchKey: '',
                                  pharmaNameSearchKey: '',
                                  searchkey: '',
                                }, () => {
                                  this.WebSocketDisconnect()
                                  localClearItem("AccessToken")
                                  localClearItem("loggedInUserInfo")
                                  localClearItem("clinicId")
                                  localClearItem("DoctorsList")
                                  clearCachevalue("isCanteenOverall")
                                  clearCachevalue("IsPORequestEdit")
                                  clearCachevalue('Laboratory_id')
                                  history.push('/Dashboard');
                                  this.setState({
                                    selectedMenuId: null,
                                    selectedPageName: ""
                                  })
                                })
                              }}>
                                <GridViewIcon sx={{ color: Colors.ThemeColor, marginRight: '0.5vw' }} />
                                {t('SwitchModule')}
                              </MenuItem>
                              : null
                          }
                          {(this.state.loginType === 'Doctor' && myUser?.is_qms_enabled) ?
                            <MenuItem onClick={() => {
                              this.setState({
                                anchorElUser: false,
                              }, () => {
                                history.push('/ViewDoctorProfile')
                              })
                            }}>
                              <AccountBoxIcon sx={{ color: Colors.ThemeColor, marginRight: '0.5vw' }} />
                              {`${t('View Profile')}`}
                            </MenuItem> : null}
                          <MenuItem onClick={() => { this.WebSocketDisconnect(); this.handleCloseUserMenu(); this.logout(); history.push('/'); }}>
                            <ExitToAppIcon sx={{ color: Colors.ThemeColor, marginRight: '0.5vw' }} />
                            {`${t('Logout')} / ${localGetItem("LogedUserName")}`}
                          </MenuItem>
                        </div>

                      </Menu>
                    </Box>
                  </div>
                </div>
              </Toolbar>
            </AppBar>
            {this.state.searchPatientDetails && this.state.searchPatientDetails.length > 0 && this.state.isPatientSearch ?
              <Paper className='Patient_Details_Search'>
                {this.state.searchPatientDetails.map((item, index) => {
                  return (
                    <div className='Lab_Home_Saved_Bill' >
                      <Avatar id='eMed_IconAvatar' src={item.photo_url ? item.photo_url : ImagePaths.Default} sx={{ boxShadow: 3, minWidth: '13%', marginTop: '1vh', marginRight: '2vh', marginBottom: '1vh' }} />
                      <div style={{ minWidth: '55%' }}>
                        <Typography sx={{ margin: "0vw" }}>{item.first_name}</Typography>
                        <div className='search_sub_container'>
                          <Typography className='savedTextLight' sx={{ fontSize: "0.8vw" }}>{item.mobile_number}</Typography>
                          <Typography className='savedTextLight' sx={{ fontSize: "0.8vw", marginLeft: "0.5vw" }}>{" | " + item.patient_account_number ? item.patient_account_number : ""}</Typography>
                        </div>
                      </div>
                      <button size="small" className='Lab_Search_Button' onClick={() => {
                        history.push({ pathname: "/LabHome", state: { searchPatientDetails: item } })
                        this.setState({
                          isPatientSearch: false
                        })
                      }}><p className='Lab_saved_Bill_Text'>{'Proceed'}</p></button>
                    </div>
                  )
                })
                }
              </Paper> : null
            }
            {!(window.location.href.includes("/Dashboard")) ?
              <Drawer className='eMed_Drawer' sx={{}}
                variant="permanent" open={this.state.drawer_Open} >
                <DrawerHeader className='eMed_drawerlogo' sx={{ backgroundColor: Colors.pageTopBackground }}>
                  {/* <img className='img_logo1' src={Fulllogo} alt="logo" /> */}
                  <IconButton onClick={this.handleDrawerClose}>
                    {theme.direction === 'rtl' ? <ChevronRightIcon sx={{ color: Colors.ListIcons }} /> : <ChevronLeftIcon sx={{ color: Colors.ListIcons }} />}
                  </IconButton>
                </DrawerHeader>
                <List id="eMed_side_list">

                  {menuList.map((item, index) => {
                    let DisplayLabel = item.label.split(" ")
                    return (
                      <React.Fragment key={index}>
                        <ListItem
                          disabled={(getCachevalue("isForCancelBill") === "true") || (getCachevalue("isForVisitreport") === "true") || (getCachevalue("IsForCheckedOut") === "true") || (getCachevalue("IsPORequestEdit") === "true") || (getCachevalue("isCanteenOverall") === "true") || (getCachevalue("isInRadiologyBilling") === "true") }  // disable all sidemenu during from cancel bill
                          className='eMed_menuItems'
                          sx={{
                            // color: window.location.href.toLowerCase().includes(item.menuName.replace(/\s/g, '').toLowerCase()) ? Colors.ContrastText : Colors.ListItems,
                            // backgroundColor: window.location.href.toLowerCase().includes(item.menuName.replace(/\s/g, '').toLowerCase()) ? Colors.ThemeColor : Colors.pageTopBackground,
                            // borderRadius: this.state.drawer_Open ? "5%" : "20%",
                            maxWidth: '3.3vw',
                            marginBottom: '3vh',
                            alignItems: 'center',
                            display: 'flex',
                            justifyContent: 'center',
                            padding: 0
                          }}
                          // button 
                          key={item.menuName}
                          checked={this.state.checked}
                          onClick={() => {
                            if (!(getCachevalue("isForCancelBill") === "true") && !(getCachevalue("IsForCheckedOut") === "true") && !(getCachevalue("IsPORequestEdit") === "true") && !(getCachevalue("isCanteenOverall") === "true") && !(getCachevalue("isInRadiologyBilling") === "true")) {
                              this.setState({
                                selectedMenuId: item.id,
                                selectedSubMenuId: null,
                                selectedPageName: item.menuName,
                                searchkey: "",
                                configSearch: '',
                                inventorySearch: "",
                                reportSearch: "",
                                purchaseSearch: '',
                                clinic_name: null,
                                PatientNameSearchKey: "",
                                PatientAddressKey: "",
                                PatientFathHusKey: "",
                                badgeCount: {},
                                PatientMotherKey: ""
                              }, () => {
                                this.onClick(this.props.history.push(`/${item.menuName.replace(/\s/g, '')}`),)
                                if (item.menuName !== "IPHome") {
                                  this.setState({
                                    isLoader: true
                                  })
                                }
                                clearCachevalue("patientData")
                                clearCachevalue("IppatientData")
                                clearCachevalue("withoutAppointment")
                                clearCachevalue("OP_CreateAppointment_PatientId")
                                clearCachevalue("OTpatientData")
                                clearCachevalue("AppRequestPatientData");
                                clearCachevalue('DoctorPatientData')
                                clearCachevalue('updatePatient')
                                clearCachevalue('filteredData')
                                clearCachevalue('RadPatientData')
                                clearCachevalue('Laboratory_id')
                                this.isPatientisSearching(false)
                              })
                            }
                          }}>
                          <Tooltip placement='right' title={t(item.label)} disableInteractive arrow>
                            <ListItemIcon emed_tid={`${'ph_' + item.label}`} className='eMed_menuIcon' sx={{ display: 'flex', flexDirection: 'column', padding: 0, alignItems: 'center' }}>
                              <Avatar
                                src={
                                  (window.location.pathname.split("/")[1] === item.menuName.replace(/\s/g, '')) ? item.OnIcon.default : item.OffIcon.default
                                }
                                sx={(window.location.pathname.split("/")[1] === item.menuName.replace(/\s/g, '')) ? { width: '3vw', height: '3vw' } : { width: '2.5vw', height: '2.5vw' }}
                                variant="square"
                              />
                              {
                                DisplayLabel.map((list) => (
                                  <Typography component={'p'} style={{ color: window.location.href.toLowerCase().includes(item.menuName.replace(/\s/g, '').toLowerCase()) ? Colors.themeDark : Colors.disableLabelColor, whiteSpace: "pre-line", alignContent: "center", justifyContent: "center" }} variant="caption" sx={{ maxWidth: '3.3vw', fontSize: '0.6vw' }}>{list}</Typography>
                                ))
                              }

                            </ListItemIcon></Tooltip>
                          {/* <ListItemText primary={t(item.menuName)} className='eMed_menuText' /> */}
                        </ListItem>
                      </React.Fragment>
                    )
                  })}

                </List>
              </Drawer> : null}
            <Box component="main" sx={{ flexGrow: 1, minWidth: '10vw', pl: '3vw', pr: '1vw' }} >
              <RouterComponent reportSearch={this.state.reportSearch} inventorySearch={this.state.inventorySearch}
                purchaseSearch={this.state.purchaseSearch} configSearch={this.state.configSearch}
                clearSearch={this.clearSearch} changeScreen={this.changeScreen}
                subScreenChange={this.subScreenChange} isSearchClear={this.isSearchClear}
                clinicId={this.state.clinicId} getSavedBillStatus={this.getListCount}
                getTransmitBillCount={this.getListCount} getRadTransmitCount={this.getRadTransmitBillCount}
                getIPListCount={this.getListCount} getPharmaSavedBillCount={this.getListCount}
                getPharmaPrescriptionCount={this.getListCount} changeDate={this.state.unlockDate}
                isOPT={this.state.isOPT} OTRequest={this.state.AcceptRequest}
                CallPatientTrigger={this.state.CallPatientTrigger}
                ClearCalledTrigger={this.ClearCalledTrigger}
                updatetransmit={this.updatetransmit} />
            </Box>
          </Box>
          <Loader loaderOpen={this.state.isLoader} />
          {this.state.ModuleSucessPop ?
            <DialogPop
              type={"Notification"}
              DialogClose={this.dialogCloseFunction}
              header={"Message"}
              content={"Primary Modules Updated Successfully"}
              icon={<img className="eMed_dilboxAnimations" alt="Warning" src={Success} />}
              secondary={"Close"}
              closeButton={this.dialogCloseFunction} />
            : null}
          <Box sx={{ height: '1vh', width: '100%', zIndex: 1200, position: 'fixed', borderTop: '1px solid lightgrey', backgroundColor: Colors.BgColor, }}></Box>
          <Box sx={{ height: '5vh', width: '100%', zIndex: 1200, position: 'fixed', backgroundColor: Colors.ThemeColor, alignItems: 'center', justifyContent: 'center', display: 'flex', flexDirection: 'row' }}>
            <Avatar
              src={ImagePaths.Logo.default}
              sx={{ width: '1vw', height: '1vw', marginRight: '0.25vw' }}
            />
            <Typography color={Colors.white} fontSize={'1vw'}>www.emedhub.in</Typography>
            <Typography sx={{ paddingLeft: 2, paddingRight: 5 }} color={Colors.white} fontSize={'1vw'}>V-4.0</Typography>
            <Typography color={Colors.white} fontSize={'1vw'}>Call us: 7397757434, 9500468385</Typography>
            {(UserData?.module_name === "front_office" || UserData?.module_name === "Pharmacy" || UserData?.module_name === "Laboratory") ? window.location.pathname === '/Dashboard' ? null :
              <IconButton color="primary" aria-label="add item" sx={{ marginLeft: 5, marginRight: '0.25vw' }}
                onClick={() => {
                  this.setState({
                    isHelpdeskClicked: true
                  })
                }}
              >
                <HelpOutline color="action" sx={{ width: '1.2vw', height: '1.2vw', marginLeft: '0.25vw' }} />
                <Typography color={Colors.white} fontSize={'1vw'}>Customer Support</Typography>
              </IconButton> : null}
          </Box>
          {this.state.deletePopup ? <DeletePopup
            DeletTitle={`Are you sure you want to cancel this appointment ?`}
            deleteAlertPopupClose={this.popupClose.bind(this)}
            removeData={this.removeappointment.bind(this)}
          /> : null}
          {
            this.state.isErrorMsg ?
              <ToastMsg
                severity={'error'}
                msg={this.state.isErrorMsgText}
                msgPop={this.msgClose.bind(this)}
              />
              : this.state.isSuccessMsg ?
                <ToastMsg
                  severity={'success'}
                  msg={this.state.isSuccessMsgText}
                  msgPop={this.msgClose.bind(this)}
                /> : null
          }
          {
            this.state.isInfoMsg ?
              <ToastMsg
                severity={'info'}
                msg={this.state.isInfoMsgText}
                msgPop={this.msgClose.bind(this)}
                rightside={true}
              /> : null
          }
          {
            this.state.isViewHistory ?
              <CommonPopUp width={"75vw"}
                backgroundColor={Colors.white}
                popUpClose={this.PopUpClose.bind(this)}
                title={this.state.testName}
                component={this.viewCultureTest.bind(this, this.state.viewCultureData)} />
              : null
          }
          {this.state.IdleAlertPopup ?
            <Box className="IdleNotiContainer">
              <Paper>
                <div className='IdleNotiWrapper'>
                  <div className='NotiHeaderContainer'>
                    <div className='NotiIcon'><WarningIcon sx={{ height: '1.5vw', width: '1.5vw', color: "white" }} /></div>
                    <div className='NotiText' style={{ fontWeight: 600 }}>System Logout Alert ! </div>
                  </div>
                  <div className='NotiPara' style={{ paddingLeft: "10vw" }}>
                    {"Your session will expire in few minutes due to inactivity. Please continue your activity to stay logged in."}
                  </div>
                </div>
              </Paper>
            </Box>
            : null}
          {this.state.smsAlert ?
            <Box className="IdleNotiContainer">
              <Paper>
                <div className='IdleNotiWrapper' style={{ justifyContent: 'space-between' }}>
                  <div className='NotiHeaderContainer'>
                    <div className='NotiIcon'><WarningIcon sx={{ height: '1.5vw', width: '1.5vw', color: "white" }} /></div>
                    <div className='NotiText' style={{ fontWeight: 600 }}>Account has reached the threshold limit. Renew your subscription to maintain lag free SMS service</div>
                  </div>
                  <div>
                    <Button variant='outlined' sx={{ color: 'white' }} onClick={this.ignoreSmsAlert}>Ignore</Button>
                  </div>
                </div>
              </Paper>
            </Box>
            : null}
          {this.state.FaceRecogClicked ? this.OpenFaceRecogPop() : this.state.docFaceRecogClicked ? this.OpenFaceRecogPop(true) : null}
          {this.renderHelpDeskPopup()}
          {!isOnline ?
            <Box className="NotiContainer">
              <Paper>
                <div className='NotiWrapper'>
                  <div className='NotiHeaderContainer'>
                    <div className='NotiIcon'><ErrorIcon sx={{ height: '1.5vw', width: '1.5vw', color: "white" }} /></div>
                    <div className='NotiText'>Notification </div>
                  </div>
                  <div className='NotiPara'>
                    {NO_Internet_Connection}
                  </div>
                </div>
              </Paper>
            </Box>
            : null}
          {this.state.saveReason ?
            <DeletePopup
              DeletTitle={`Are you sure do you want to delete the saved bill ?`}
              deleteAlertPopupClose={this.popupClose}
              removeData={this.deleteSavedBillData}
              disable={this.state.removeData}
            />
            : null}
          <SnackbarProvider maxSnack={4} variant='info' anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }} autoHideDuration={4000} />
        </div>
      )
    } else {
      return (<RouterComponent />)
    }
  }
}
export default withRouter(withTranslation()(MiniDrawer));