import React, { Component } from 'react'
import '../Configuration.css';
import {
  Box, Grid, Paper, InputLabel, MenuItem, FormControl, Select,
  TextField, Button, Typography, FormControlLabel, Checkbox, Tooltip, IconButton,
  TableContainer, Table, TableHead, TableRow, TableBody, TableCell, Autocomplete,
  List, ListItem, ListItemButton, ListItemText, Divider, Dialog, DialogActions,
  DialogTitle, DialogContent, RadioGroup, Radio
} from '@mui/material'
import ToastMsg from '../../../Components/ToastMsg/ToastMsg'
import { Stack } from '@mui/system';
import { withTranslation } from 'react-i18next';
import { Colors } from '../../../Styles/Colors';
import { Dis_Summary_Layout_JSON } from '../../../Utility/Constants'
import { ImagePaths } from '../../../Utility/ImagePaths';
import CommonValidation from '../../../Components/CommonFunctions/CommonValidation';
import RestAPIService from '../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../Utility/API/Serviceurls';
import { ConfirmPopup } from '../../../Components/Common Components/CommonComponents';
import { ReasonPopup } from '../../../Components/Common Components/CommonComponents';
import { CheckAccessFunc } from '../../../Components/CommonFunctions/CommonFunctions';
import Loader from '../../../Components/Loader';
import { localGetItem } from '../../../Utility/Services/CacheProviderService';

const tblData = [
  { delete: '', icd: '', sdis: '', ldis: '' },
  { delete: '', icd: '', sdis: '', ldis: '' },
  { delete: '', icd: '', sdis: '', ldis: '' }
]
const tblMediData = [
  { route: '', mname: '', dosage: '', time: '', ins: '' },
  { route: '', mname: '', dosage: '', time: '', ins: '' },
  { route: '', mname: '', dosage: '', time: '', ins: '' }
]
const tblTestData = [
  { test_name: '', tested_on: '', test_time: '', action: '' },
  { test_name: '', tested_on: '', test_time: '', action: '' },
  // { date: '', report_type: '', test_details: '', action:'' }
]
const ITEM_HEIGHT = 80;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: '15vw',
    },
  },
};

let LAyout = Dis_Summary_Layout_JSON

let category_list = [
  { label: 'New Born', value: 'New_Born' },
  { label: 'General', value: 'General' },
  { label: 'Death', value: 'Death' },
  { label: 'Day Care', value: 'Day_Care' },
]
class DisSumLayoutConfig extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: '',
      selectTemplate: '',
      selectSpeciality: null,
      summaryFeilds: LAyout,
      dragLabel: null,
      addFieldName: '',
      addLayoutName: '',
      disSumlayoutData: [],
      alertPop: false,
      selectedId: null,
      splItem: [],
      Open: true,
      AddnewLayType: false,
      editLayName: false,
      layoutFinData: [],
      deleteLayout: false,
      renameLayout: false,
      catagoryList: [],
      deleteCatagory: false,
      selectCatagory: '',
      selectedCataID: null,
      deathBirthSummary: ''
    }
  }

  componentDidMount() {
    this.getDisSumLayout()
    this.resetDisSumLayout()
    this.getSpeciality()
    this.getCatagoryList()
  }

  LoaderFunction = (key) => {
    this.setState({
      isLoader: key
    })
  }

  getSpeciality = () => {
    try {
      RestAPIService.getAll(Serviceurls.FO_CONFIG_OPSPECIALIZATION)
        .then((response => {
          if (response.data.status === 'success') {
            this.setState({
              splItem: response.data.data ? response.data.data : []
            })
          }
        })).catch(e => {
          if (e?.response?.data?.message) {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }

        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }
  getCatagoryList = () => {
    try {
      RestAPIService.getAll(Serviceurls.IP_DIS_SUM_CATAGORY)
        .then((response => {
          if (response.data.status === 'success') {
            this.setState({
              catagoryList: response.data.data ? response.data.data : []
            })
          }
        })).catch(e => {
          if (e?.response?.data?.message) {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }

        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  getDisSumLayout = () => {
    try {
      this.LoaderFunction(true)
      RestAPIService.getAll(Serviceurls.DIS_SUM_LAYOUT)
        .then((response => {
          if (response.data.status === 'success') {
            this.setState({
              disSumlayoutData: response.data.data ? response.data.data : []
            },()=>{this.LoaderFunction(false)})
          }
        })).catch(e => {
          this.LoaderFunction(false)
          if (e?.response?.data?.message) {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }

        })

    } catch (e) {
      this.LoaderFunction(false)
      this.errorMessage(e.message)

    }
  }

  postDisSumLayout = () => {
    try {
      var states = this.state
      var data = {
        "category_id": states.selectCatagory.id,
        "speciality_id": states.selectSpeciality.id,
        "layout_name": states.addLayoutName,
        "dis_sum_json": states.summaryFeilds
      }
      if (states.selectTemplate) {
        data['discharge_id'] = states.selectTemplate
      }
      this.setState({
        alertPop: false,
      })
      this.LoaderFunction(true)
      RestAPIService.create(data, Serviceurls.DIS_SUM_LAYOUT)
        .then((response => {
          if (response.data.status === 'success') {
            this.getDisSumLayout()
            this.resetDisSumLayout()
            this.LoaderFunction(false)
            this.successMessage(response.data.message)
          }
        })).catch((e => {
          if (e?.response?.data?.message) {
            this.LoaderFunction(false)
            this.errorMessage(e.response.data.message)
          } else {
            this.LoaderFunction(false)
            this.errorMessage(e.message)
          }
        }))
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  deleteDisSumLay = (cmt) => {
    try {
      var data = {
        "id": this.state.selectedId,
        'cancel_reason': cmt
      }
      RestAPIService.delete(Serviceurls.DIS_SUM_LAYOUT, data)
        .then((response => {
          if (response.data.status === 'success') {
            this.successMessage(response?.data?.message)
            this.getDisSumLayout()
            this.resetDisSumLayout()
            this.setState({
              deleteLayout: false
            })
          }
        })).catch(e => {
          if (e?.response?.data?.message) {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }
  errorMessage = (message) => {
    this.setState({
      isErrorMsg: true,
      isErrorMsgText: message
    })
  }
  successMessage = (message) => {
    this.setState({
      successMsg: true,
      successMsgText: message
    })
  }

  renderDisSumLayoutLeft = () => {
    try {
      const { t } = this.props
      var states = this.state
      var Active_Array = (states.summaryFeilds).filter((item) => {
        return item.is_active == true
      })
      return (
        <Stack component={'div'}>
          <Paper className='eMed_refdoc_left'>
            <Box className='eMed_DisSum_Lay_LeftHead' sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
              <Typography>{t('DischargeSummary') + (states.selectSpeciality ? ' (' + this.state.selectSpeciality.name + ')' : '(-)')}</Typography>
            </Box>
            <Box className='eMed_DisSum_Lay_leftCon'>
              {Active_Array.map((item) => {
                if (item.label === 'Admission Diagnosis') {
                  return (
                    <Box>
                      {this.renderAdmissionDiagnosis('Admission Diagnosis', item)}
                      {this.renderTextBox('Admission Provisional Diagnosis', '57.6vw', true, item)}
                    </Box>
                  )
                } else if (item.label === 'Provisional Diagnosis') {
                  return (
                    this.renderTextBox('Provisional Diagnosis', '57.6vw', true, item))
                } else if (item.label === 'Symptoms/Key Findings') {
                  return (
                    this.renderTextBox('Symptoms/Key Finding', '57.6vw', true, item))
                } else if (item.label === 'Examination') {
                  return (
                    this.renderTextBox('Examination', '57.6vw', true, item)
                  )
                } else if (item.label === 'Investigation and Test Report') {
                  return (
                    <Box>
                      {this.renderTextBox('Investigation', '57.6vw', true, item)}
                      <Box className='eMed_DisSum_Lay_CommonBox2'>
                        <Typography>{"Test Reports"}</Typography>
                        {this.renderTestTbl()}
                      </Box>
                    </Box>
                  )
                } else if (item.label === 'Summary of Key Finding During Hospitailzation') {
                  return (
                    this.renderTextBox('Summary of Key Finding During Hospitalization', '57.6vw', true, item)
                  )
                } else if (item.label === 'Procedure/Treatment Given During Hospitailzation') {
                  return (
                    this.renderTextBox('Procedure/Treatment Given During Hospitalization', '57.6vw', true, item)
                  )
                } else if (item.label === 'Operations/Surgery Notes') {
                  return (
                    this.renderTextBox('Operations/Surgery Notes', '57.6vw', true, item)
                  )
                } else if (item.label === 'Name Anaesthetist and Type of Anaesthesia') {
                  return (
                    <Box className='eMed_DisSum_Lay_Anaesthsia'>
                      {this.renderTextBox(t('Type of Anaesthesias'), '27.77vw', false, item)}
                      {this.renderTextBox(t('Name of Anaesthetist'), '27.77vw', false, item)}
                    </Box>
                  )
                } else if (item.label === 'Discharge Diagnosis') {
                  return (
                    <Box>
                      {this.renderAdmissionDiagnosis('Discharge Diagnosis', item)}
                      {this.renderTextBox('Discharge Provisional Diagnosis', '57.6vw', true, item)}
                    </Box>
                  )
                } else if (item.label === 'Condition on Discharge') {
                  return (
                    this.renderTextBox('Conditionon Discharge', '57.6vw', true, item)
                  )
                } else if (item.label === 'Medication' || item.label === 'In Hospital Medication') {
                  return (
                    this.renderMedication(item.label, item)
                  )
                } else if (item.label === 'Discharge Instruction') {
                  return (
                    this.renderDisInstruction('Discharge Instruction', item)
                  )
                } else {
                  return (
                    this.renderTextBox(item.label, '57.6vw', true, item)
                  )
                }
              })}
            </Box>
          </Paper>
        </Stack>
      )
    } catch (e) {
      this.errorMessage(e)
    }

  }
  renderAdmissionDiagnosis = (label, item) => {
    return (
      <Box className='eMed_DisSum_Lay_CommonBox'>
        <Box className='eMed_DisSum_Lay_AdmDig'>
          <Typography>{item.is_default_mantatory ? label + "*" : label}</Typography>
          <TextField
            className='eMed_DisSum_Icd_TxtBox'
            size='small'
            id="icd-code"
            inputProps={{ emed_tid: "LongDescription_TestID001" }}
            placeholder='Enter ICD Code / Short Description / Long Description'
            variant="outlined"
            InputProps={{
              readOnly: true,
            }} />
          <Button emed_tid ={"Add_TestID001"} id='eMed_Config_btn' variant='contained'>Add</Button>
        </Box>
        <Box sx={{ marginTop: '1vw' }}>
          <TableContainer component={Paper}>
            <Table sx={{ minWidth: '30vw' }} aria-label="simple table">
              <TableHead sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
                <TableRow>
                  <TableCell>{<img className='eMed_DisSum_Lay_TblDel' src={ImagePaths.Delete.default} alt='Delete' />}</TableCell>
                  <TableCell>ICD 10 Codes</TableCell>
                  <TableCell>Short Description</TableCell>
                  <TableCell>Long Description</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {tblData.map((item) => (
                  <TableRow>
                    <TableCell>{item.delete}</TableCell>
                    <TableCell>{item.icd}</TableCell>
                    <TableCell>{item.sdis}</TableCell>
                    <TableCell>{item.ldis}</TableCell>
                  </TableRow>
                ))}

              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </Box>
    )
  }

  renderTextBox = (label, width, isrow, item) => {
    return (
      <Box className='eMed_DisSum_Lay_CommonBox1'>
        <TextField
          sx={{ width: width }}
          id="icd-code"
          multiline={isrow}
          rows={3}
          inputProps={{ emed_tid: `cb_${label}` }}
          label={item.is_default_mantatory ? label + '*' : label}
          defaultValue={' '}
          variant="outlined"
          InputProps={{
            readOnly: true,
          }} />
      </Box>
    )
  }
  renderTestTbl = () => {
    return (
      <Box component={'div'}>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: '30vw' }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
              <TableRow>
                <TableCell>{"Test Name/Test Group"}</TableCell>
                <TableCell>{"Tested On"}</TableCell>
                <TableCell>{"Turn Over Time"}</TableCell>
                <TableCell>{"Action"}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tblTestData.map((item) => (
                <TableRow>
                  <TableCell>{item.test_name}</TableCell>
                  <TableCell>{item.tested_on}</TableCell>
                  <TableCell>{item.test_time}</TableCell>
                  <TableCell>{item.action}</TableCell>
                </TableRow>
              ))}

            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }
  renderMedication = (label, item) => {
    return (
      <Box className='eMed_DisSum_Lay_CommonBox1'>
        <Typography>{item.is_default_mantatory ? label + '*' : label}</Typography>
        <TableContainer component={Paper}>
          <Table sx={{ minWidth: '30vw' }} aria-label="simple table">
            <TableHead sx={{ backgroundColor: Colors.DataTblHeaderbg }}>
              <TableRow>
                <TableCell>S.No</TableCell>
                <TableCell>Route</TableCell>
                <TableCell>Medicine Name</TableCell>
                <TableCell>Dosage</TableCell>
                <TableCell>Timing</TableCell>
                <TableCell>Instructions</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tblMediData.map((item) => (
                <TableRow>
                  <TableCell>{item.sno}</TableCell>
                  <TableCell>{item.route}</TableCell>
                  <TableCell>{item.mname}</TableCell>
                  <TableCell>{item.dosage}</TableCell>
                  <TableCell>{item.time}</TableCell>
                  <TableCell>{item.ins}</TableCell>
                </TableRow>
              ))}

            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    )
  }
  renderDisInstruction = (label, item) => {
    const { t } = this.props
    return (
      <Box className='eMed_DisSum_Lay_disBox' >
        <Box className='eMed_DisSum_Lay_CommonBox'>
          <Typography>{item.is_default_mantatory ? label + '*' : label}</Typography>
          <Box className='eMed_DisSum_Lay_Dietcon'>
            {/* <FormControl size='small' className='eMed_DisSum_Lay_Diet'>
              <InputLabel id="Dis_lay_Diet">{t('Diet')}</InputLabel>
              <Select
                labelId="Dis_lay_Diet"
                id="Dis_lay_Diet"
                value={''}
                label={t('Diet')}
                inputProps={{ readOnly: true }}
              >
              </Select>
            </FormControl>
            <FormControl size='small' className='eMed_DisSum_Lay_Diet'>
              <InputLabel id="Dis_lay_Diet">{t('Activity')}</InputLabel>
              <Select
                labelId="Dis_lay_Diet"
                id="Dis_lay_Diet"
                value={''}
                label={t('Activity')}
                inputProps={{ readOnly: true }}
              >
              </Select>
            </FormControl> */}

            <TextField className='eMed_DisSum_Template_Diet'
              autoComplete="off"
              size="small"
              label={t('Diet')}
              value={""}
              inputProps={
                { readOnly: true , emed_tid :"Diet_TestIDD"}
              }
            />
            <TextField className='eMed_DisSum_Template_Diet'
              autoComplete="off"
              size="small"
              label={t('Activity')}
              value={""}
              inputProps={
                { readOnly: true, emed_tid :"Activity_TestIDD" }
              }
            />
          </Box>
          <TextField
            sx={{ width: '55.5vw', marginTop: '1vw' }}
            id="icd-code"
            multiline
            rows={3}
            label={t('AdviceonDischarge')}
            defaultValue={' '}
            variant="outlined"
            InputProps={{
              readOnly: true, emed_tid :"AdviceonDischarge_TestIDD"
            }} />
        </Box>
      </Box>
    )
  }

  renderDisSumLayoutRight = () => {
    try {
      var states = this.state
      const { t } = this.props
      return (
        <Stack component={'div'}>
          <Paper className='eMed_refdoc_right'>
            <Box className='eMed_DisSum_Lay_Select'>
              <Typography paddingLeft={'1vw'}>{t('DischargeSummary')}</Typography>
              <FormControl size="small" sx={{ width: '13vw', paddingRight: '0.5vw' }}>
                <InputLabel id="dis_sum_Layout">{t('select Category')}</InputLabel>
                <Select
                  labelId="dis_sum_Layout"
                  inputProps={{ emed_tid: "select_01Category_TestID" }}
                  id="demo-simple-select"
                  value={states.selectCatagory}
                  label={t('select Category')}
                  onChange={(e) => {
                    this.setState({
                      editcataName: true,
                      selectCatagory: e.target.value
                    })
                  }}
                  MenuProps={MenuProps}
                >
                  {states.catagoryList.length > 0 ? states.catagoryList.map((item) => (
                    <MenuItem value={item}>{item.category_name}</MenuItem>
                  )) : <Typography align='center'>{t('NDF')}</Typography>}
                  <Divider />
                  <List>
                    <ListItem disablePadding>
                      <ListItemButton inputProps={{ emed_tid:"disSumlayoutData_TestID001"}}  onClick={() => {
                        var newData = states.disSumlayoutData.find((item) => item.id === 0)
                        if (newData) {
                          this.errorMessage(t('PSPL'))
                        } else {
                          this.setState({ addCataItem: true, addcatagoryName: '', addCataPrefix: '', renamecatagory: false, selectedId: null,deathBirthSummary:'' })
                        }
                      }
                      }>
                        <ListItemText primary={t('Add New Category Name')} />
                      </ListItemButton>
                    </ListItem>
                    {this.state.editcataName ?
                      <List>
                        <ListItem disablePadding>
                          <ListItemButton inputProps={{ emed_tid:"selectCatagoryData_TestID001"}} onClick={() => {
                            this.setState({
                              addCataItem: true,
                              renamecatagory: true,
                              addcatagoryName: states.selectCatagory.category_name,
                              addCataPrefix: states.selectCatagory.prefix,
                              selectedCataID: states.selectCatagory.id,
                              deathBirthSummary: states.selectCatagory?.category_type
                            })
                          }}>
                            <ListItemText primary={t("Rename")} />
                          </ListItemButton>
                        </ListItem>
                        <ListItem disablePadding>
                          <ListItemButton inputProps={{ emed_tid:"DeleteData_TestID001"}}  onClick={() => { this.setState({ deleteCatagory: true, selectedCataID: states.selectCatagory.id }) }}>
                            <ListItemText primary={t("Delete")} />
                          </ListItemButton>
                        </ListItem>
                      </List> : null}
                  </List>
                </Select>
              </FormControl>
            </Box>
            <Box className='eMed_Dissum_lay_Con'>
              <Box className='eMed_DisSum_Lay_Spec'>
                <Autocomplete
                  noOptionsText={states.specTxt === '' ? t('PLS') : t('NDF')}
                  options={states.splItem}
                  // defaultValue={states.splItem.options[0]}
                  disabled={this.state.selectCatagory ? false : true}
                  getOptionLabel={(options) => options.name__name}
                  onChange={(e, value) => {
                    this.setState({
                      selectSpeciality: value,
                      editLayName: false
                    }, () => {
                      var states = this.state
                      var final = states.disSumlayoutData ? states.disSumlayoutData.filter((item) => (
                        states.selectSpeciality?.id === item.speciality_id
                      )) : null
                      this.setState({ layoutFinData: final })
                    })
                  }}
                  value={states.selectSpeciality}
                  size='small'
                  className='eMed_DisSum_Lay_select2'
                  disablePortal
                  id="Spec"
                  renderInput={(params) => <TextField onChange={(event) => {
                    const value = event.target.value
                    if (value === "" || value != "") { this.setState({ specTxt: value }) }
                  }} {...params} label={t('Speciality')} inputProps={{...params.inputProps,'emed_tid': 'Speciality_TestID001', }} />}
                />
                <FormControl size="small" className='eMed_DisSum_TxtBox'>
                  <InputLabel id="dis_sum_Layout">{t('LayoutName')}</InputLabel>
                  <Select
                    labelId="dis_sum_Layout"
                    id="demo-simple-select"
                    value={states.selectTemplate}
                    label={t('LayoutName')}
                    inputProps={{ emed_tid:"LayoutName_TestID" }}
                    disabled={states.selectSpeciality === null ? true : false}
                    onChange={this.handleTemplate}
                    MenuProps={MenuProps}
                  >
                    {states.layoutFinData.length > 0 ? states.layoutFinData.map((item) => (
                      <MenuItem value={item.id}>{item.layout_name}</MenuItem>
                    )) : <Typography align='center'>{t('NDF')}</Typography>}
                    <Divider />
                    <List>
                      <ListItem disablePadding>
                        <ListItemButton inputProps={{ emed_tid:"PSPL_TestID001"}} onClick={() => {
                          var newData = states.disSumlayoutData.find((item) => item.id === 0)
                          if (newData) {
                            this.errorMessage(t('PSPL'))
                          } else {
                            this.setState({ AddnewLayType: true, addLayoutName: '', renameLayout: false, selectedId: null })
                          }
                        }
                        }>
                          <ListItemText primary={t('AddNewLN')} />
                        </ListItemButton>
                      </ListItem>
                      {this.state.editLayName ?
                        <List>
                          <ListItem disablePadding>
                            <ListItemButton inputProps={{ emed_tid:"PSPLRename_TestID001"}} onClick={() => { this.setState({ AddnewLayType: true, renameLayout: true }) }}>
                              <ListItemText primary={t("Rename")} />
                            </ListItemButton>
                          </ListItem>
                          <ListItem disablePadding>
                            <ListItemButton inputProps={{ emed_tid:"DeleteRename_TestID001"}} onClick={() => { this.setState({ deleteLayout: true }) }}>
                              <ListItemText primary={t("Delete")} />
                            </ListItemButton>
                          </ListItem>
                        </List> : null}
                    </List>
                  </Select>
                </FormControl>
              </Box>
              <Box className='eMed_DisSum_Lay_right_Head'>
                <Box>
                  <Typography id='eMed_DisSum_Lay_right_HeadTxt'>{t('DischargeSummaryFields')}</Typography>
                  <Typography id='eMed_DisSum_Lay_right_HeadTxt2'>(Switch the fields according to your practice)</Typography>
                </Box>
                <Typography id='eMed_DisSum_Lay_right_HeadTxt2'>Mark as Important</Typography>
              </Box>
              <Box className='eMed_DisSum_Lay_right_Feilds'>
                {states.summaryFeilds.map((item, index) => (
                  this.renderDisSummaryFields(item, index)
                ))}
                <Box className='eMed_DisSum_AddIcon'>
                  <TextField
                    className='eMed_DisSum_TxtBox'
                    size='small'
                    id="outlined-bsic"
                    variant="outlined"
                    value={states.addFieldName}
                    inputProps={{ emed_tid:"addDisFields01_TestID001"}}
                    onChange={(event) => {
                      let alpha = CommonValidation.alphabetOnly(event.target.value);
                      if (alpha || event.target.value === '') {
                        this.setState({
                          addFieldName: event.target.value
                        })
                      }
                    }} />
                  <IconButton emed_tid ={"addDisFields_testID"}>
                    <img className='eMed_DisSum_Lay_TblDel' onClick={() => { this.addDisFields() }} src={ImagePaths.AddIcon.default} alt='Add'></img>
                  </IconButton>
                </Box>
              </Box>

            </Box>
            <Stack className='eMed_DisSum_right_Btn' spacing={2} direction="row" sx={{ backgroundColor: Colors.DataTblHeaderbg }} alignItems="center" justifyContent="center" height={"6vh"}>
              <Button emed_tid={"resetDisSumLayout_testID"} variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.resetDisSumLayout(true) }}>{t("Reset")}</Button>
              <Button emed_tid={"clearDisSumLayout_testID"} variant='outlined' size="small" id="eMed_Config_btn" onClick={() => { this.resetDisSumLayout() }}>{t("Clear")}</Button>
              <Button emed_tid={"addDisSumLayout_testID"} variant='contained' size="small" id="eMed_Config_btn"
                disabled={CheckAccessFunc("front_office", "Settings", "Layout Configuration", "Discharge Summary Layout", "SubTab")?.editAccess ? (states.selectSpeciality && (states.selectTemplate || states.selectTemplate === 0) ? false : true) : true}
                onClick={() => { this.addDisSumLayout() }}>{t("Save")}
              </Button>
            </Stack>
          </Paper>

        </Stack>
      )
    } catch (e) {
      this.errorMessage(e)
    }
  }
  addDisSumLayout = () => {
    var states = this.state
    if (states.selectedId) {
      this.setState({
        alertPop: true,
        AddnewLayType: false,
      })
    } else if (states.selectSpeciality.name &&
      states.addLayoutName &&
      states.summaryFeilds && states.addFieldName === "") {
      // this.checkMandatory()
      this.postDisSumLayout()
    } else if (states.selectSpeciality.name === '') {
      this.errorMessage('Please Select Speciality')
    } else if (states.selectSpeciality === '') {
      this.errorMessage('Please Add the Layout Name')
    } else {
      this.errorMessage('Please Add the Field or Remove')
    }
  }

  checkMandatory = () => {
    var states = this.state
    var Admission = states.summaryFeilds.find((item) => (
      item.field_name === "admission_diagnosis"
    ))
    var provisional = states.summaryFeilds.find((item) => (
      item.field_name === "provisional_diagnosis"
    ))
    if (Admission?.is_mantatory || provisional?.is_mantatory) {
      this.postDisSumLayout()
    } else {
      this.errorMessage('Admission Diagnosis should Mark as Important')
    }
  }

  resetDisSumLayout = (OnlyReset = false) => {
    if (OnlyReset) {
      var Data = [
        { id: 1, field_name: "admission_diagnosis", label: 'Admission Diagnosis', is_active: true, is_mantatory: false, is_default_mantatory: false },
        // { id: 2, field_name: "provisional_diagnosis", label: 'Provisional Diagnosis', is_active: true, is_mantatory: false, is_default_mantatory: false },
        { id: 2, field_name: "symptoms_key_findings", label: 'Symptoms/Key Findings', is_active: true, is_mantatory: false, is_default_mantatory: false },
        { id: 3, field_name: "examination", label: 'Examination', is_active: true, is_mantatory: false, is_default_mantatory: false },
        { id: 4, field_name: "investigation_and_test_report", label: 'Investigation and Test Report', is_active: true, is_mantatory: false, is_default_mantatory: false },
        { id: 5, field_name: "summary_of_Key_finding_during_hospitalization", label: 'Summary of Key Finding During Hospitalization', is_active: true, is_mantatory: false, is_default_mantatory: false },
        { id: 6, field_name: "procedure_treatment_given_during_hospitalization", label: 'Procedure/Treatment Given During Hospitalization', is_active: true, is_mantatory: false, is_default_mantatory: false },
        { id: 7, field_name: "operations_surgery_notes", label: 'Operations/Surgery Notes', is_active: true, is_mantatory: false, is_default_mantatory: false },
        { id: 8, field_name: "name_anaesthetist_and_type_of_anaesthesia", label: 'Name Anaesthetist and Type of Anaesthesia', is_active: true, is_mantatory: false, is_default_mantatory: false },
        { id: 9, field_name: "discharge_diagnosis", label: 'Discharge Diagnosis', is_active: true, is_mantatory: false, is_default_mantatory: false },
        { id: 10, field_name: "condition_on_discharge", label: 'Condition on Discharge', is_active: true, is_mantatory: false, is_default_mantatory: false },
        { id: 11, field_name: "medication", label: 'Medication', is_active: true, is_mantatory: false, is_default_mantatory: false },
        { id: 12, field_name: "discharge_instruction", label: 'Discharge Instruction', is_active: true, is_mantatory: false, is_default_mantatory: false },
        { id: 13, field_name: "in_hospital_medication", label: 'In Hospital Medication', is_active: true, is_mantatory: false, is_default_mantatory: false, is_new: false },
      ]
      this.setState({
        addFieldName: '',
        summaryFeilds: Data,
      })
    } else {
      var Data = [
        { id: 1, field_name: "admission_diagnosis", label: 'Admission Diagnosis', is_active: true, is_mantatory: false, is_default_mantatory: false },
        // { id: 2, field_name: "provisional_diagnosis", label: 'Provisional Diagnosis', is_active: true, is_mantatory: false, is_default_mantatory: false },
        { id: 2, field_name: "symptoms_key_findings", label: 'Symptoms/Key Findings', is_active: true, is_mantatory: false, is_default_mantatory: false },
        { id: 3, field_name: "examination", label: 'Examination', is_active: true, is_mantatory: false, is_default_mantatory: false },
        { id: 4, field_name: "investigation_and_test_report", label: 'Investigation and Test Report', is_active: true, is_mantatory: false, is_default_mantatory: false },
        { id: 5, field_name: "summary_of_Key_finding_during_hospitalization", label: 'Summary of Key Finding During Hospitalization', is_active: true, is_mantatory: false, is_default_mantatory: false },
        { id: 6, field_name: "procedure_treatment_given_during_hospitalization", label: 'Procedure/Treatment Given During Hospitalization', is_active: true, is_mantatory: false, is_default_mantatory: false },
        { id: 7, field_name: "operations_surgery_notes", label: 'Operations/Surgery Notes', is_active: true, is_mantatory: false, is_default_mantatory: false },
        { id: 8, field_name: "name_anaesthetist_and_type_of_anaesthesia", label: 'Name Anaesthetist and Type of Anaesthesia', is_active: true, is_mantatory: false, is_default_mantatory: false },
        { id: 9, field_name: "discharge_diagnosis", label: 'Discharge Diagnosis', is_active: true, is_mantatory: false, is_default_mantatory: false },
        { id: 10, field_name: "condition_on_discharge", label: 'Condition on Discharge', is_active: true, is_mantatory: false, is_default_mantatory: false },
        { id: 11, field_name: "medication", label: 'Medication', is_active: true, is_mantatory: false, is_default_mantatory: false },
        { id: 12, field_name: "discharge_instruction", label: 'Discharge Instruction', is_active: true, is_mantatory: false, is_default_mantatory: false },
        { id: 13, field_name: "in_hospital_medication", label: 'In Hospital Medication', is_active: true, is_mantatory: false, is_default_mantatory: false, is_new: false },
      ]
      this.setState({
        selectSpeciality: null,
        selectTemplate: '',
        addLayoutName: '',
        addFieldName: '',
        summaryFeilds: Data,
        selectedId: null,
        selectCatagory: '',
        addcatagoryName: '',
        selectedCataID: null
      })
      this.getDisSumLayout()
    }
  }

  addDisFields = () => {
    var states = this.state
    if (states.addFieldName) {
      var search = states.summaryFeilds.find((item) => (
        item.label.toLowerCase() === states.addFieldName.toLowerCase()
      ))
      var AdmDiagnosis = states.summaryFeilds.find((item) => (("admission_diagnosis" === item.field_name.toLowerCase())));
      var DisDiagnosis = states.summaryFeilds.find((item) => (("discharge_diagnosis" === item.field_name.toLowerCase())));

      if (search || (states.addFieldName.toLowerCase() === "provisional diagnosis" && (AdmDiagnosis?.is_active || DisDiagnosis?.is_active))) {
        if (states.addFieldName.toLowerCase() === "provisional diagnosis" && (AdmDiagnosis?.is_active || DisDiagnosis?.is_active)) {
          this.errorMessage(`Provisional Diagnosis Already Enclosed with Admission Diagnosis, Discharge Diagnosis`)
        } else {
          this.errorMessage(`${states.addFieldName} Already Exist`)
        }
      } else {
        var ID = states.summaryFeilds.length
        var data = states.addFieldName.toLowerCase().replace(/\s+/g, '_')
        states['summaryFeilds'].push({
          'id': ID + 1,
          'field_name': data,
          'label': states.addFieldName,
          'is_active': true,
          'is_mantatory': false,
          'is_default_mantatory': false,
          'is_new': true

        })
        this.setState({
          states
        }, () => {
          this.setState({
            addFieldName: ''
          })
        })
      }
    } else {
      this.errorMessage('Please add Field Name')
    }
  }
  renderDisSummaryFields = (data, i) => {
    var states = this.state
    var { summaryFeilds } = this.state
    const Index = summaryFeilds.findIndex((item) => {
      return item.id == data.id
    })
    return (
      <Stack>
        <div
          draggable={true}
          onDragOver={(e) => e.preventDefault()}
          onDragStart={this.handleDrag}
          onDrop={this.handleDrop}
          id={data.label}
          className="eMed_demolayout_right2_field1">
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img className="eMed_DisSum_Lay_DragImg" src={ImagePaths.DragIndicator.default} alt="Dot" />
            {data.label?.length > 25 ?
              <Tooltip title={data.is_default_mantatory ? data.label + "*" : data.label} placement='top' arrow>
                <FormControlLabel control={<Checkbox  inputProps={{ emed_tid:data.label }} id={data.id} onChange={this.handleCheckClick} checked={data.is_active} />} label={data.label.slice(0, 25) + '...'} />
              </Tooltip> : <FormControlLabel control={<Checkbox  inputProps={{ emed_tid:data.label }} id={data.id} onChange={this.handleCheckClick} checked={data.is_active} />} label={data.is_default_mantatory ? data.label + '*' : data.label} />
            }
          </div>
          <div>
            {data.is_new ?
              <IconButton  emed_tid ={"summaryFeilds_testID"} className='eMed_DisSum_Lay_StarBtn' onClick={() => {
                states.summaryFeilds.splice(i, 1)
                this.setState({
                  states
                })
              }}>
                <img className="eMed_DisSum_Lay_StarIcon" src={ImagePaths.Delete.default} alt="Delete" />
              </IconButton>
              : null}
            <IconButton   emed_tid ={"StarUnfill_testID"} className='eMed_DisSum_Lay_StarBtn' disabled={summaryFeilds[Index].is_active === false ? true : false} onClick={() => { this.handleStarClick(data) }}>
              <img className="eMed_DisSum_Lay_StarIcon" src={data.is_mantatory ? ImagePaths.StarFill.default : ImagePaths.StarUnfill.default} alt="Star" />
            </IconButton>
          </div>

        </div>
      </Stack>
    )
  }
  handleDrag = (e) => {
    this.setState({
      dragLabel: e.currentTarget.id
    })
  }

  handleDrop = (e) => {
    var states = this.state
    const dragBox = (states.summaryFeilds).find((field) => field.label === states.dragLabel);
    const dropBox = (states.summaryFeilds).find((field) => field.label === e.currentTarget.id);

    const dragBoxID = dragBox.id;
    const dropBoxID = dropBox.id;

    const dragBoxField = dragBox.field_name;
    const dropBoxField = dropBox.field_name;

    const dragBoxCheck = dragBox.is_active;
    const dropBoxCheck = dropBox.is_active;

    const newBoxState = (states.summaryFeilds).map((field) => {
      if (field.label === states.dragLabel) {
        field.id = dropBoxID;
        field.field_name = dragBoxField;
        field.is_active = dragBoxCheck;
      }
      if (field.label === e.currentTarget.id) {
        field.id = dragBoxID;
        field.field_name = dropBoxField;
        field.is_active = dropBoxCheck;
      }
      return field;
    });

    const SortData = newBoxState.sort((a, b) => { return a.id - b.id })

    this.setState({
      summaryFeilds: SortData
    })
  }
  handleCheckClick = (e) => {
    var { summaryFeilds } = this.state

    const ID = e.target.id
    const Index = summaryFeilds.findIndex((data) => {
      return data.id == ID
    })

    if (summaryFeilds[Index].is_default_mantatory) {

    } else {
      summaryFeilds[Index].is_active = e.target.checked
      summaryFeilds[Index].is_mantatory = false
    }

    this.setState({
      summaryFeilds
    }, () => {
      if (summaryFeilds[Index].field_name === "admission_diagnosis" || summaryFeilds[Index].field_name === "discharge_diagnosis") {
        const ProvDiagIndex = summaryFeilds.findIndex((data) => { return data.field_name == "provisional_diagnosis" })
        if (ProvDiagIndex != -1 && e.target.checked) {
          summaryFeilds.splice(ProvDiagIndex, 1)
          this.setState({ summaryFeilds })
        }
      }
    })
  }
  handleStarClick = (item) => {
    var { summaryFeilds } = this.state
    const Index = summaryFeilds.findIndex((data) => {
      return data.id == item.id
    })
    if (summaryFeilds[Index].is_default_mantatory) {
      this.errorMessage('Can not be Modify')
    } else {
      summaryFeilds[Index].is_mantatory = !item.is_mantatory
    }

    this.setState({
      summaryFeilds
    })
  }
  handleTemplate = (event) => {
    this.setState({
      editLayName: true,
      selectTemplate: event.target.value,
    }, () => {
      var lay_data = this.state.disSumlayoutData.map((data) => {
        if (this.state.selectTemplate === data.id) {
          this.setState({
            addLayoutName: data.layout_name,
            summaryFeilds: data.dis_sum_json,
            selectedId: data.id,
          })
        }
        this.setState({
          lay_data
        })
      })
      if (this.state.layoutFinData.length > 0) {
        var layoutData = this.state.layoutFinData.map((data) => {
          if (this.state.selectTemplate === data.id) {
            this.setState({
              addLayoutName: data.layout_name,
              summaryFeilds: data.dis_sum_json,
              selectedId: data.id,
            })
          }
          this.setState({
            layoutData
          })
        })
      }
    })


  }
  handleSpeciality = (event) => {
    this.setState({
      selectSpeciality: event.target.value
    })
  }
  popClode = () => {
    this.resetDisSumLayout()
    this.setState({
      alertPop: false,
      deleteCatagory: false,
    })
  }
  confirmSave = () => {
    this.postDisSumLayout()
  }

  renderLayAdd = () => {
    const { t } = this.props
    return (
      <Dialog open={this.state.Open} >
        <DialogTitle className='eMed_CreditPoP_TitleDiv' sx={{ borderColor: Colors.themeDark }}>
          <Typography marginLeft={"1vw"}>{t("AddLayName")}</Typography>
          <Button  emed_tid ={"AddnewLayType01_testID"} onClick={() => { this.setState({ AddnewLayType: false }) }} >
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </DialogTitle>
        <DialogContent sx={{ padding: "2vw" }}>
          <TextField
            inputProps={{ maxLength: 30 ,emed_tid :"LayoutName_TestID001"}}
            sx={{ width: "20vw", marginTop: "2vw" }}
            size='small'
            label={t("LayoutName")}
            value={this.state.addLayoutName}
            onChange={(event) => {
              let alphanum = CommonValidation.alphanumValidation(event.target.value);
              if (alphanum || event.target.value === '') {
                this.setState({
                  addLayoutName: event.target.value
                })
              }
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button emed_tid ={"Clear_testID"}   variant='outlined' size='small' className='eMed_Filter_Btns' onClick={() => { this.setState({ addLayoutName: '' }) }}>{t("Clear")}</Button>
          <Button emed_tid ={"layoutFinData_testID"}  variant='contained' size='small' className='eMed_Filter_Btns' onClick={() => {
            var states = this.state
            if (states.selectedId) {
              this.addDisSumLayout()
            } else if (states.addLayoutName != '') {
              states['layoutFinData'].push({
                "id": 0,
                "speciality_name": states.selectSpeciality?.name,
                "layout_name": states.addLayoutName,
                "dis_sum_json": states.summaryFeilds

              })
              this.setState({
                states,
                AddnewLayType: false,
              })
            } else {
              this.errorMessage(t('PALN'))
            }
          }}>{this.state.renameLayout ? t("Rename") : t("Add")}</Button>
        </DialogActions>
      </Dialog>
    )
  }
  deleteCatagory = () => {
    try {
      let clinicID = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
      var states = this.state
      let data = {
        "clinic_id": clinicID.clinic_id,
        "id": states.selectedCataID,
      }
      RestAPIService.delete(Serviceurls.IP_DIS_SUM_CATAGORY, data)
        .then((response) => {
          if (response.data.status === 'success') {
            this.successMessage(response.data.message)
            this.getCatagoryList()
            this.setState({
              deleteCatagory: false,
              selectCatagory: null
            })
          }
        })
        .catch(e => {
          if (e?.response?.data?.message) {
            this.errorMessage(e.response.data.message)
          } else {
            this.errorMessage(e.message)
          }
        })
    } catch (e) {
      this.errorMessage(e.message)
    }
  }

  postCataName = () => {
    try {
      let clinicID = localGetItem("loggedInUserInfo") === null ? {} : JSON.parse(localGetItem("loggedInUserInfo"))
      var states = this.state
      let data = {
        "clinic_id": clinicID.clinic_id,
        "category_name": states.addcatagoryName,
        "prefix": states.addCataPrefix,
        'category_type': states.deathBirthSummary
      }
      if (states.selectedCataID) {
        data['category_id'] = states.selectedCataID
      }
      if (states.deathBirthSummary === '') {
        this.errorMessage("Select Atleast One Category Type")
      } else {
        RestAPIService.create(data, Serviceurls.IP_DIS_SUM_CATAGORY)
          .then((response) => {
            if (response.data.status === 'success') {
              this.successMessage(response.data.message)
              this.getCatagoryList()
              this.setState({
                addCataItem: false,
                deathBirthSummary: ''
              })
            }
          })
          .catch(e => {
            if (e?.response?.data?.message) {
              this.errorMessage(e.response.data.message)
            } else {
              this.errorMessage(e.message)
            }
          })
      }
    } catch (e) {
      this.errorMessage(e.message)
    }
  }
  renderCataAdd = () => {
    const { t } = this.props
    return (
      <Dialog open={this.state.Open} >
        <DialogTitle className='eMed_CreditPoP_TitleDiv' sx={{ borderColor: Colors.themeDark }}>
          <Typography marginLeft={"1vw"}>{t("Add Category Name")}</Typography>
          <Button emed_tid ={"addCataItem_testID"}   onClick={() => { this.setState({ addCataItem: false }) }} >
            <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
          </Button>
        </DialogTitle>
        <DialogContent sx={{ padding: "2vw", width: '25vw' }}>
          <TextField
            inputProps={{ maxLength: 30 ,emed_tid :"01Category_Name_TestID"}}
            sx={{ width: "20vw", marginTop: "2vw" }}
            size='small'
            label={t("Category Name")}
            value={this.state.addcatagoryName}
            onChange={(event) => {
              let alphanum = CommonValidation.alphanumValidation(event.target.value);
              if (alphanum || event.target.value === '') {
                this.setState({
                  addcatagoryName: event.target.value
                })
              }
            }}
          />
          <TextField
            inputProps={{ maxLength: 30, emed_tid :"Category_Prefix_TestID" }}
            sx={{ width: "20vw", marginTop: '1vw' }}
            size='small'
            label={t("Category Prefix")}
            value={this.state.addCataPrefix}
            onChange={(event) => {
              let alphanum = CommonValidation.alphanumValidation(event.target.value);
              if (alphanum || event.target.value === '') {
                this.setState({
                  addCataPrefix: event.target.value
                })
              }
            }}
          />
          {/* <FormControl>
            <RadioGroup row
              value={this.state.deathBirthSummary}
              onChange={(e) => {
                this.setState({ deathBirthSummary: e.target.value }, () => console.log(this.state.deathBirthSummary))
              }}
            >
              <FormControlLabel value="New_Born" control={<Radio size='small' />} label="New Born" />
              <FormControlLabel value="General" control={<Radio size='small' />} label="General" />
              <FormControlLabel value="Death" control={<Radio size='small' />} label="Death" />
            </RadioGroup>
          </FormControl> */}
            <FormControl size='small' sx={{width:"20vw",mt:'1vw'}}>
              <InputLabel>Select Category Type *</InputLabel>
              <Select
                inputProps={{ maxLength: 30, emed_tid :"deathBirthSummary001_TestID" }}
                value={this.state.deathBirthSummary}
                onChange={(e) => { this.setState({ deathBirthSummary: e.target.value }) }}
                label={'Select Category Type *'}
              >
                {category_list?.map((item,index) => (
                  <MenuItem key={index} value={item.value}>{item?.label}</MenuItem>
                ))}
              </Select>
            </FormControl>
        </DialogContent>
        <DialogActions>
          <Button  emed_tid ={"addcatagoryName_testID"}   variant='outlined' size='small' className='eMed_Filter_Btns' onClick={() => { this.setState({ addcatagoryName: '',deathBirthSummary:'' }) }}>{t("Clear")}</Button>
          <Button  emed_tid ={"renamecatagory_testID"} variant='contained' size='small' className='eMed_Filter_Btns' onClick={() => { this.postCataName() }}>{this.state.renamecatagory ? t("Rename") : t("Add")}</Button>
        </DialogActions>
      </Dialog>
    )
  }
  popupClose() {
    this.setState({
      deleteLayout: false,
    })
  }
  addCancelComments(comments) {
    this.deleteDisSumLay(comments)
  }
  msgClose() {
    this.setState({
      isErrorMsg: false,
      isErrorMsgText: '',
      successMsg: false,
      successMsgText: ''
    })
  }
  deleteCata() {
    this.deleteCatagory()
  }
  render() {
    const { t } = this.props
    try {
      return (
        <Box component={"div"} className='eMed_refdoc_container'>
          <Grid container spacing={2} className='eMed_refdoc_content' >
            <Grid item xs={8}>
              {this.renderDisSumLayoutLeft()}
            </Grid>
            <Grid item xs={4}>
              {this.renderDisSumLayoutRight()}
            </Grid>
            {this.state.AddnewLayType ? this.renderLayAdd() : null}
            {this.state.addCataItem ? this.renderCataAdd() : null}
          </Grid>
          {this.state.isErrorMsg ?
            <ToastMsg
              severity={'error'}
              msg={this.state.isErrorMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.successMsg ?
            <ToastMsg
              severity={'success'}
              msg={this.state.successMsgText}
              msgPop={this.msgClose.bind(this)}
            />
            : null}
          {this.state.alertPop ?
            <ConfirmPopup
              cnfTitle={t('Confirmation')}
              cnfContent={t('Are you sure, Do you want to save the changes it will be affect the entire configuration')}
              cnfAlertPopupClose={this.popClode.bind(this)}
              cnfData={this.confirmSave.bind(this)}
            />
            : null}
          {this.state.deleteLayout ? <ReasonPopup
            title={t('ADDL')}
            AlertPopupClose={this.popupClose.bind(this)}
            label={t('ER')}
            btntext={t('Cancel')}
            btnvarient={'outlined'}
            btncolor={'error'}
            btntext1={t('Confirm')}
            DeleteNotify={t('ifDelete')}
            btnvarient1={'contained'}
            sendCmt={this.addCancelComments.bind(this)}
          /> : null}
          {this.state.deleteCatagory ? <ConfirmPopup
            cnfTitle={t('Confirmation')}
            cnfContent={t('Are you sure, Do you want Delete the Catagory, It will be effect the entire settings')}
            cnfAlertPopupClose={this.popClode.bind(this)}
            cnfData={this.deleteCata.bind(this)}
          /> : null}
          <Loader loaderOpen={this.state.isLoader} />
        </Box>
      )
    } catch (error) {
      this.errorMessage(error)
    }

  }
}
export default withTranslation()(DisSumLayoutConfig);