import { Autocomplete, Box, Button, Checkbox, CircularProgress, Divider, Fab, FormControl, FormControlLabel, List, ListItem, ListItemButton, ListItemText, Modal, Paper, Table, TableBody, TableCell, TableHead, TableRow, TextField, Tooltip, Typography } from '@mui/material'
import React, { Component } from 'react'
import { Colors } from '../../../../Styles/Colors'
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg'
import RestAPIService from '../../../../Utility/Services/RestAPIService'
import { Serviceurls } from '../../../../Utility/API/Serviceurls'
import { getCachevalue } from '../../../../Utility/Services/CacheProviderService'
import { ImagePaths } from '../../../../Utility/ImagePaths'
import { DataGrid } from '@mui/x-data-grid'
import { DeletePopup } from '../../../../Components/Common Components/CommonComponents'
import ShowComponents from './ShowComponent';

export default class DocExamination extends Component {

    constructor(props) {
        super(props)
        this.state = {
            patientDetails: {},
            clinicId: this.props.clinicId,
            appointmentId: this.props.appointmentId,
            isLocked: false,
            patientId: this.props.patientId,
            openPopup: false,
            examGroupData: [],
            selectedExamId: '',
            defaultExamList: [],
            selectedGroupSymptom: [],
            openAddSymptompopup: false,
            searchSymptomData: [],
            selectedSymptom: '',
            selectedExamName: '',
            fromSymptom: false,
            comments: false,
            templateList: [],
            selectedTemplateName: null,
            openPopupAddNew: false,
            templateName: '',
            tempType: '',
            template: null,
            templateData: [],
            page: 0,
            pageSize: 10,
            selectedExaminationDatas: [],
            duplicateDefaultExamList: '[]',
            selectedSymptomDatas: [],
            selectedId: null,
            deletePopup: false,
            removeData: false,
            duplicateSymptomsDatas: '[]',
            isEdit: false,

            postLoad: false,
            loading: false,
            templateLoad: false,
            examLoad: false,
            groupLoad: false,
            sympLoad: false
        }
    }

    componentDidMount() {
        // this.apiCalls();
        // let clinicId = getCachevalue('SelectedDoctorClinic')
        // let appointmentId = JSON.parse(getCachevalue('DoctorPatientData'))
        // this.setState({
        //     clinicId: clinicId,
        //     appointmentId: appointmentId?.appointment_id,
        //     patientId: appointmentId?.patient_id,
        //     isLocked: appointmentId?.is_locked
        // }, () => {
        //     this.getPatientDetailData()
        //     this.getExaminationData()
        // })
    }

    apiCalls = () => {
        this.getExamGroupData()
        this.getExamTemplateData()
        this.getPatientDetailData()
        this.getExaminationData()
    }

    getPatientDetailData = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_PATIENT_DETAIL_GET + '?patient_id=' + this.props.patientId)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            patientDetails: response?.data?.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getExamGroupData = () => {
        try {
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_EXAM_GROUP_GET)
                .then((response) => {
                    if (response?.data?.status === 'Success') {
                        this.setState({
                            examGroupData: response?.data?.data,
                            selectedExamId: response?.data?.data[0]?.id,
                            selectedExamName: response?.data?.data[0]?.name
                        }, () => this.getDefaultExamList(this.state.selectedExamId))
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getDefaultExamList = (groupId) => {
        try {
            this.setState({ sympLoad: true })
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_DEFAULT_EXAM_SYMPTOM)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({ sympLoad: false })
                        if (groupId) {
                            let selectedGroupSymptom = []
                            response?.data?.data?.filter((item) => item?.group_id === groupId ? selectedGroupSymptom?.push(item) : [])
                            this.setState({
                                defaultExamList: selectedGroupSymptom,
                                duplicateDefaultExamList: JSON.stringify(response?.data?.data)
                            }, () => {
                                this.state.defaultExamList?.forEach(element => {
                                    element.is_checked = false
                                    element.is_comment = false
                                    element.comments = ''
                                })
                            })
                        } else {
                            this.setState({
                                defaultExamList: response?.data?.data,
                                duplicateDefaultExamList: JSON.stringify(response?.data?.data)
                            }, () => {
                                this.state.defaultExamList?.forEach(element => {
                                    element.is_checked = false
                                    element.is_comment = false
                                    element.comments = ''
                                })
                            })
                        }
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ sympLoad: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ sympLoad: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ sympLoad: false })
            this.errorMessage(error.message)
        }
    }

    getExamTemplateData = (favorite_id) => {
        try {
            let params = ''
            if (favorite_id) {
                params = `?favorite_id=${favorite_id}`
            } else {
                params = ''
            }
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_EXAM_TEMPLATE_LIST + params)
                .then((response) => {
                    if (response.data.status === 'success') {
                        if (favorite_id) {
                            this.setState({
                                templateList: response.data.data
                            }, () => this.postFavListData())
                        } else {
                            this.setState({
                                templateList: response.data.data
                            })
                        }
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    getExaminationData = () => {
        try {
            let appointmentId = this.props.prevAppointmentId ? this.props.prevAppointmentId : this.props.appointmentId
            this.setState({ loading: true })
            RestAPIService.getAll(Serviceurls.DOC_DOCTOR_NOTES_EXAMINATION_GET + `?appointment_id=${appointmentId}&new_system=true`)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.setState({
                            templateData: response?.data?.data?.examinations,
                            loading: false
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ loading: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ loading: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ loading: false })
            this.errorMessage(error.message)
        }
    }

    postNewExamination = () => {
        try {
            let data = {
                display_order: 1,
                examination_name: this.state.selectedSymptom,
                group_id: this.state.selectedExamId
            }
            this.setState({ examLoad: true })
            RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_ADD_NEW_EXAM_SYMPTOM)
                .then((response) => {
                    if (response?.data?.status === 'Success') {
                        this.successMessage(response.data.message)
                        this.setState({ examLoad: false, isEdit: false })
                        this.setState({ openAddSymptompopup: false, selectedSymptom: '' }, () => {
                            this.getDefaultExamList(this.state.selectedExamId)
                        })

                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ examLoad: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ examLoad: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ examLoad: false })
            this.errorMessage(error.message)
        }
    }

    postNewTemplateData = () => {
        try {
            let templateData = []
            this.state.templateData?.forEach(element => {
                templateData?.push({
                    display_order: element?.display_order,
                    examination_id: element?.examination_id,
                    value: element?.value
                })
            })
            let data = {}
            if (this.state.tempType === 'rename') {
                data = {
                    favorite_name: this.state.templateName,
                    id: this.state.selectedTemplateName?.id
                }
            } else {
                data = {
                    examinations: templateData,
                    favorite_name: this.state.templateName
                }
            }
            this.setState({ templateLoad: true })
            RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_EXAM_TEMPLATE_LIST)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.successMessage(response.data.message)
                        this.setState({ templateLoad: false })
                        this.setState({ openPopupAddNew: false, templateName: '', selectedTemplateName: '', isEdit: false })
                        this.getExamTemplateData()
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ templateLoad: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ templateLoad: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ templateLoad: false })
            this.errorMessage(error.message)
        }
    }

    postExaminationGroupName = () => {
        try {
            let data = {
                name: this.state.selectedSymptom
            }
            this.setState({ groupLoad: true })
            RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_EXAM_GROUP_GET)
                .then((response) => {
                    if (response?.data?.status === 'Success') {
                        this.setState({ groupLoad: false })
                        this.successMessage(response.data.message)
                        this.getExamGroupData()
                        this.setState({ openAddSymptompopup: false, selectedSymptom: '', isEdit: false })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ groupLoad: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ groupLoad: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ groupLoad: false })
            this.errorMessage(error.message)
        }
    }

    deletedIds = () => {
        try {
            let states = this.state
            let removedIds = [];
            let duplicate = JSON.parse(this.state.duplicateSymptomsDatas)
            states.defaultExamList?.forEach(element => {
                if ((element.examination_id === duplicate?.examination_id) && (element?.is_checked !== duplicate?.is_checked)) {
                    removedIds?.push(duplicate?.id)
                }
            })
            return removedIds;
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    postExaminationData = (onlySave) => {
        try {
            let states = this.state
            let examinationData = []
            let removedIds = this.deletedIds()
            states.defaultExamList?.forEach(element => {
                if (element?.is_checked) {
                    examinationData?.push({
                        display_order: element?.display_order,
                        examination_id: element?.examination_id,
                        examination_name: element?.examination_name,
                        group_id: element?.group_id,
                        value: element?.value ? element?.value : ''
                    })
                }
            })
            let data = {
                examination_data: examinationData,
                appointment_id: this.props.appointmentId,
                deleted_ids: removedIds
            }
            if (examinationData?.length > 0) {
                this.setState({ postLoad: true })
                RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_EXAM_POST)
                    .then((response) => {
                        if (response?.data?.status === 'success') {
                            this.successMessage(response.data.message)
                            if (onlySave) {
                                this.setState({ postLoad: false })
                                this.setState({
                                    selectedExamId: '',
                                    selectedExamName: '',
                                    isEdit: false
                                })
                                this.getExamGroupData()
                            } else {
                                this.setState({ postLoad: false })
                                this.setState({
                                    openPopup: false,
                                    selectedExamId: '',
                                    selectedExamName: '',
                                    isEdit: false
                                })
                                this.getExamGroupData()
                            }
                            this.getExaminationData()
                        }
                    }).catch((error) => {
                        if (error?.response?.data?.message) {
                            this.setState({ postLoad: false })
                            this.errorMessage(error.response.data.message)
                        } else {
                            this.setState({ postLoad: false })
                            this.errorMessage(error.message)
                        }
                    })
            } else {
                this.errorMessage('Please Select atleast one symptoms')
            }
        } catch (error) {
            this.setState({ postLoad: false })
            this.errorMessage(error.message)
        }
    }

    postFavListData = () => {
        try {
            let examination = []
            this.state.templateList?.forEach(element => {
                examination?.push({
                    display_order: element?.display_order,
                    examination_id: element?.examination_id,
                    value: element?.value
                })
            })
            let data = {
                appointment_id: this.props.appointmentId,
                examinations: examination
            }
            RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_EXAMINATION_GET)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.getExaminationData()
                        this.getExamTemplateData()
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    deleteExaminationData = () => {
        try {
            let data = {
                appointment_id: this.props.appointmentId,
                patient_examination_id: this.state.selectedId ? this.state.selectedId : ''
            }
            this.setState({ removeData: true })
            RestAPIService.create(data, Serviceurls.DOC_DOCTOR_NOTES_EXAM_DELETE)
                .then((response) => {
                    if (response?.data?.status === 'success') {
                        this.successMessage(response?.data?.message)
                        this.setState({ deletePopup: false, removeData: false, selectedId: null, isEdit: false })
                        this.getExaminationData()
                    }
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ removeData: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ removeData: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    deleteTemplate = () => {
        try {
            let data = {
                favorite_id: this.state.selectedTemplateName?.id
            }
            this.setState({ removeData: true })
            RestAPIService.delete(Serviceurls.DOC_DOCTOR_NOTES_EXAM_TEMPLATE_LIST, data)
                .then((response) => {
                    this.successMessage(response.data.message)
                    this.setState({
                        openPopupAddNew: false,
                        templateName: '',
                        selectedTemplateName: '',
                        deletePopup: false,
                        removeData: false,
                        isEdit: false
                    })
                    this.getExamTemplateData()
                }).catch((error) => {
                    if (error?.response?.data?.message) {
                        this.setState({ removeData: false })
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.setState({ removeData: false })
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.setState({ removeData: false })
            this.errorMessage(error.message)
        }
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    successMessage = (message) => {
        this.setState({
            successMsg: true,
            successMsgText: message
        })
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
            successMsg: false,
            successMsgText: ''
        })
    }

    onClearHandler = () => {
        try {
            this.setState({
                selectedExamId: '',
                selectedExamName: ''
            }, () => this.getExamGroupData())
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onEditHandler = (item) => {
        try {
            let selectedGroupSymptom = []
            let duplicate = JSON.parse(this.state.duplicateDefaultExamList)
            duplicate?.filter((dupItem) => dupItem?.group_id === item?.group_id ? (
                dupItem?.examination_id === item?.examination_id ?
                    selectedGroupSymptom?.push({
                        examination_name: item?.examination_name,
                        group_id: item?.group_id,
                        display_order: item?.display_order,
                        examination_id: item?.examination_id,
                        is_checked: item?.is_checked,
                        is_comment: item?.is_comments,
                        value: item?.value
                    }) : selectedGroupSymptom?.push({
                        examination_name: dupItem?.examination_name,
                        group_id: dupItem?.group_id,
                        display_order: dupItem?.display_order,
                        examination_id: dupItem?.examination_id,
                        is_checked: false,
                        is_comment: false
                    })
            ) : [])
            this.state.selectedSymptomDatas?.push(item)
            this.setState({ openPopup: true, isEdit: true }, () => {
                this.setState({
                    selectedExamId: item?.group_id,
                    defaultExamList: selectedGroupSymptom,
                    selectedExamName: item?.examination_name,
                    duplicateSymptomsDatas: JSON.stringify(item)
                })
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    onDeleteHandler = (item) => {
        try {
            if (!this.state.isEdit) {
                this.setState({
                    deletePopup: true,
                    selectedId: item?.id
                })
            } else {
                this.errorMessage("You have clicked edit Button.So,You cannot able to delete")
            }
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    popupClose = () => {
        try {
            this.setState({
                deletePopup: false
            })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderAddNewSymptomPopup = () => {
        try {
            return (
                <Box component={'div'}>
                    <Modal open={this.state.openAddSymptompopup}>
                        <Box component={'div'} className='eMed_Doc_Notes_Prescrip_Fav_Pop_Up'>
                            <Box component={'div'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} p={'0.5vw'}>
                                <Typography fontWeight={600} fontSize={'0.9vw'}>{this.state.fromSymptom ? 'Add New Symptom' : 'Add New Examination'}</Typography>
                                <Button emed_tid='exm_new_exe_symp_btn'
                                    onClick={() => this.setState({ openAddSymptompopup: false, selectedSymptom: '' })}
                                >
                                    <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1.1vw'} width={'1.1vw'} />
                                </Button>
                            </Box>
                            {this.state.fromSymptom ?
                                <Box component={'div'} p={'0.5vw'}>
                                    <Typography>Selected Examination :
                                        <Typography component={'span'} fontWeight={600}>{this.state.selectedExamName}</Typography>
                                    </Typography>
                                </Box> : null
                            }
                            <Box component={'div'} ml={'2.5vw'}>
                                <TextField
                                    sx={{ width: '20vw' }}
                                    size='small'
                                    label={this.state.fromSymptom ? 'Enter Symptom Name' : 'Enter Examination Name'}
                                    value={this.state.selectedSymptom}
                                    onChange={(e) => this.setState({ selectedSymptom: e.target.value })}
                                    autoComplete='off'
                                    inputProps={{emed_tid:'exm_symp_name'}}
                                />
                            </Box>
                            <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
                                <Button emed_tid='exm_new_clr_btn'
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                                    variant='outlined'
                                    onClick={() => this.setState({ selectedSymptom: '' })}
                                >Clear</Button>
                                <Button emed_tid='exm_new_add_btn'
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                    variant='contained'
                                    disabled={this.state.fromSymptom ? (this.state.examLoad || (this.state.selectedSymptom === '')) : (this.state.groupLoad || (this.state.selectedSymptom === ''))}
                                    onClick={() => this.state.fromSymptom ? this.postNewExamination() : this.postExaminationGroupName()}
                                >Add</Button>
                            </Box>
                        </Box>
                    </Modal>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderTemplatePopup = () => {
        try {
            return (
                <Box component={'div'}>
                    <Modal open={this.state.openPopupAddNew}>
                        <Box component={'div'} className='eMed_Doc_Notes_Prescrip_Fav_Pop_Up'>
                            <Box component={'div'} display={'flex'} justifyContent={'space-between'} p={'0.5vw'} alignItems={'center'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600}>
                                    {this.state.tempType === 'Add' ? 'Add New Template' : this.state.tempType === 'rename' ? 'Rename Template' : 'Delete Template'}
                                </Typography>
                                <Button className='Common_Btn_Min_Width' emed_tid='exm_tmp_cls_btn'
                                    onClick={() => this.setState({ openPopupAddNew: false }, () => this.setState({ templateName: '' }))}
                                >
                                    <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1.1vw'} width={'1.1vw'} />
                                </Button>
                            </Box>
                            <Box component={'div'} ml={'2.5vw'}>
                                <TextField
                                    size='small'
                                    sx={{ width: '20vw' }}
                                    label='Enter Template Name'
                                    value={this.state.templateName}
                                    onChange={(e) => this.setState({ templateName: e.target.value })}
                                    inputProps={{emed_tid:'exm_tmp_new_name'}}
                                />
                            </Box>
                            <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
                                <Button emed_tid='exm_tmp_clr_btn'
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                                    variant='outlined'
                                    onClick={() => this.setState({ templateName: '' })}
                                >Clear</Button>
                                <Button emed_tid='exm_tmp_add_btn'
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize' }}
                                    variant='contained'
                                    onClick={() => this.postNewTemplateData()}
                                    disabled={this.state.templateLoad}
                                >Add</Button>
                            </Box>
                        </Box>
                    </Modal>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderPopup = () => {
        try {
            return (
                <Box component={'div'}>
                    <Modal open={this.state.openPopup}>
                        <Box component={'div'} className='eMed_Doc_Notes_Exam_Pop_Up'>
                            <Box component={'div'} display={'flex'} p={'0.5vw'} height={'4vw'} justifyContent={'space-between'} alignItems={'center'}>
                                <Typography fontSize={'0.9vw'} fontWeight={600} color={Colors.SecondaryText}>Add New Examination</Typography>
                                <Box component={'div'} display={'flex'}>
                                    {/* <Autocomplete
                                        clearIcon
                                        size='small'
                                        sx={{ width: '15vw' }}
                                        disabled={this.state.isEdit}
                                        options={this.state.templateList}
                                        getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.favorite_name}
                                        value={this.state.selectedTemplateName}
                                        onChange={(e, value) => {
                                            this.setState({
                                                selectedTemplateName: value
                                            })
                                        }}
                                        renderInput={(params) => <TextField {...params} label='Select Template Name' />}
                                        PaperComponent={({ children }) => {
                                            return (
                                                <Paper sx={{ maxHeight: '25vw', overflow: 'scroll' }}>
                                                    {children}
                                                    <Divider />
                                                    <Button
                                                        color="primary"
                                                        fullWidth
                                                        sx={{ justifyContent: "flex-start", pl: 2, textTransform: 'capitalize' }}
                                                        onMouseDown={(e) => {
                                                            if (this.state.selectedExamName) {
                                                                this.setState({ openPopupAddNew: true, tempType: 'Add' })
                                                            } else {
                                                                this.errorMessage('Please select Examination and Symptoms')
                                                            }
                                                        }}
                                                    >
                                                        Add Template
                                                    </Button>
                                                    {this.state.selectedTemplateName ?
                                                        <><Button
                                                            color="primary"
                                                            fullWidth
                                                            sx={{ justifyContent: "flex-start", pl: 2, textTransform: 'capitalize' }}
                                                            onMouseDown={(e) => {
                                                                this.setState({ openPopupAddNew: true, tempType: 'rename' })
                                                            }}
                                                        >
                                                            Rename
                                                        </Button>
                                                            <Button
                                                                color="primary"
                                                                fullWidth
                                                                sx={{ justifyContent: "flex-start", pl: 2, textTransform: 'capitalize' }}
                                                                onMouseDown={(e) => {
                                                                    this.setState({ openPopupAddNew: true, tempType: 'delete' })
                                                                }}
                                                            >
                                                                Delete
                                                            </Button>
                                                        </> : null
                                                    }

                                                </Paper>
                                            )
                                        }}
                                    /> */}
                                    <Button emed_tid='exm_cls_btn'
                                        className='Common_Btn_Min_Width'
                                        onClick={() => this.setState({ openPopup: false, isEdit: false, selectedExamId: '', selectedExamName: '' })}
                                    >
                                        <Box component={'img'} src={ImagePaths.LabCloseButton.default} height={'1.1vw'} width={'1.1vw'} />
                                    </Button>
                                </Box>
                            </Box>
                            <Box component={'div'} p={'0.5vw'} display={'flex'}>
                                <Box component={'div'} flex={'0.5vw'} border={'1px solid gray'}>
                                    <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} borderBottom={'1px solid gray'} height={'2.5vw'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>Examination</Typography>
                                        <Button emed_tid='exm_add_new_btn'
                                            className='Common_Btn_Min_Width'
                                            onClick={() => this.setState({ openAddSymptompopup: true, fromSymptom: false })}
                                        >
                                            <Box component={'img'} src={ImagePaths.AddIcon.default} height={'1.5vw'} width={'1.5vw'} />
                                        </Button>
                                    </Box>
                                    <Box component={'div'} maxHeight={'25vw'} overflow={'scroll'}>
                                        {this.state.examinationLoad ? <Box component={'div'} display={'flex'} justifyContent={'center'} mt={'5vw'}><CircularProgress color='primary' /></Box> :
                                            this.state.examGroupData?.length > 0 && this.state.examGroupData?.map((item, index) => (
                                                <List sx={{ padding: '0vw' }} key={index}>
                                                    <ListItem disablePadding>
                                                        <ListItemButton className='Common_Btn_Min_Width' emed_tid='exm_lst_btn'
                                                            sx={{ padding: '0vw 0vw 0vw 0.5vw' }}
                                                            onClick={() => {
                                                                if (!this.state.isEdit) {
                                                                    this.setState({
                                                                        selectedExamId: item?.id,
                                                                        selectedExamName: item?.name
                                                                    }, () => {
                                                                        if (this.state.selectedExamId) {
                                                                            let selectedGroupSymptom = []
                                                                            let duplicate = JSON.parse(this.state.duplicateDefaultExamList)
                                                                            duplicate?.filter((item) => item?.group_id === this.state.selectedExamId ? selectedGroupSymptom?.push(item) : [])
                                                                            this.setState({
                                                                                defaultExamList: selectedGroupSymptom
                                                                            })
                                                                        }
                                                                    })
                                                                } else {
                                                                    this.errorMessage('You cannot able to change')
                                                                }
                                                            }}
                                                        >
                                                            <ListItemText>
                                                                <Typography fontWeight={this.state.selectedExamId === item?.id ? 600 : 500}>{item?.name}</Typography>
                                                            </ListItemText>
                                                        </ListItemButton>
                                                    </ListItem>
                                                </List>
                                            ))}
                                    </Box>
                                </Box>
                                <Box component={'div'} flex={'0.5vw'} border={'1px solid gray'}>
                                    <Box component={'div'} p={'0.5vw'} display={'flex'} justifyContent={'space-between'} alignItems={'center'} borderBottom={'1px solid gray'} height={'2.5vw'}>
                                        <Typography fontSize={'0.9vw'} fontWeight={600}>Symptoms</Typography>
                                        <Button emed_tid='exm_symp_new_btn'
                                            className='Common_Btn_Min_Width'
                                            disabled={this.state.selectedExamId ? false : true}
                                            onClick={() => this.setState({ openAddSymptompopup: true, fromSymptom: true })}
                                        >
                                            <Box component={'img'} src={ImagePaths.AddIcon.default} height={'1.5vw'} width={'1.5vw'} />
                                        </Button>
                                    </Box>
                                    <Box component={'div'} maxHeight={'25vw'} overflow={'scroll'}>
                                        {this.state.sympLoad ? <Box component={'div'} display={'flex'} justifyContent={'center'} mt={'5vw'}><CircularProgress color='primary' /></Box> :
                                            this.state.selectedExamName ?
                                                this.state.defaultExamList?.length > 0 && this.state.defaultExamList?.map((item, index) => (
                                                    <List sx={{ padding: '0vw 0vw 0vw 0.5vw' }} key={index}>
                                                        <ListItem disablePadding>
                                                            <FormControlLabel sx={{ padding: '0vw' }} checked={item?.is_checked} control={<Checkbox />} onClick={(e) => {
                                                                if (item?.group_id === this.state.selectedExamId) {
                                                                    this.state.defaultExamList[index]['is_checked'] = !this.state.defaultExamList[index]['is_checked']
                                                                    this.setState({
                                                                        defaultExamList: this.state.defaultExamList,
                                                                    })
                                                                }
                                                            }} />
                                                            <ListItemText>{item?.examination_name}</ListItemText>
                                                            {item?.is_comment ?
                                                                <TextField
                                                                    size='small'
                                                                    variant='standard'
                                                                    sx={{ width: '10vw', mr: '0.5vw' }}
                                                                    placeholder='Enter Comments'
                                                                    value={item?.value}
                                                                    onChange={(e) => {
                                                                        if (item?.is_checked) {
                                                                            let value = e.target.value
                                                                            if (value?.length <= 250) {
                                                                                this.state.defaultExamList[index]['value'] = e.target.value
                                                                                this.setState({
                                                                                    defaultExamList: this.state.defaultExamList
                                                                                })
                                                                            } else {
                                                                                this.errorMessage('Allowed only 250 characters')
                                                                            }
                                                                        }
                                                                    }}
                                                                    inputProps={{emed_tid:'exm_symp_cmts'}}
                                                                /> :
                                                                <Tooltip placement='top' title='Comments'>
                                                                    <Box component={'img'} sx={{ cursor: 'pointer' }} src={ImagePaths.Notes.default} height={'1.5vw'} width={'1.5vw'} emed_tid='exm_cmt_btn'
                                                                        onClick={() => {
                                                                            if (item?.is_checked) {
                                                                                this.state.defaultExamList[index]['is_comment'] = !this.state.defaultExamList[index]['is_comment']
                                                                                this.setState({
                                                                                    defaultExamList: this.state.defaultExamList
                                                                                })
                                                                            } else {
                                                                                this.errorMessage('Please click checkbox to add comments')
                                                                            }
                                                                        }}
                                                                    />
                                                                </Tooltip>
                                                            }
                                                        </ListItem>
                                                    </List>
                                                )) : null}
                                    </Box>
                                </Box>
                            </Box>
                            <Box component={'div'} display={'flex'} justifyContent={'center'} alignItems={'center'} height={'3vw'}>
                                <Button emed_tid='exm_clr_btn'
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                                    variant='outlined'
                                    onClick={() => this.onClearHandler()}
                                >Clear</Button>
                                <Button emed_tid='exm_sve_btn'
                                    sx={{ height: '2vw', width: '5vw', textTransform: 'capitalize', mr: '1vw' }}
                                    variant='contained'
                                    onClick={() => this.postExaminationData('save')}
                                    disabled={this.state.postLoad}
                                >Save</Button>
                                <Button emed_tid='exm_sve_ext_btn'
                                    sx={{ height: '2vw', textTransform: 'capitalize' }}
                                    variant='contained'
                                    disabled={this.state.postLoad}
                                    onClick={() => this.postExaminationData()}
                                >Save & Exit</Button>
                            </Box>
                        </Box>
                    </Modal>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    renderExaminationTable = () => {
        try {
            return (
                <Box sx={{ height: '13vw', overflow: 'scroll', border: '1px solid lightgray' }} component={'div'}>
                    <Table stickyHeader size='small'>
                        <TableHead>
                            <TableRow>
                                <TableCell width={'25%'} sx={{ fontWeight: 600, fontSize: '0.8vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Examination Name</TableCell>
                                <TableCell width={'25%'} sx={{ fontWeight: 600, fontSize: '0.8vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Symptoms</TableCell>
                                <TableCell width={'25%'} sx={{ fontWeight: 600, fontSize: '0.8vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Notes</TableCell>
                                <TableCell align='center' width={'25%'} sx={{ fontWeight: 600, fontSize: '0.8vw', color: Colors.SecondaryText, backgroundColor: Colors.Background }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.templateData?.length > 0 ? this.state.templateData?.map((item, index) => (
                                <TableRow key={index}>
                                    <TableCell>
                                        {item?.group_name ? item?.group_name?.length > 15 ?
                                            <Tooltip placement='top' title={item?.group_name}>
                                                <Typography fontSize={'0.8vw'} fontWeight={600}>{item?.group_name?.slice(0, 15) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.8vw'} fontWeight={600}>{item?.group_name}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.examination_name ? item?.examination_name?.length > 15 ?
                                            <Tooltip placement='top' title={item?.examination_name}>
                                                <Typography fontSize={'0.8vw'} fontWeight={600}>{item?.examination_name?.slice(0, 15) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.8vw'} fontWeight={600}>{item?.examination_name}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell>
                                        {item?.value ? item?.value?.length > 15 ?
                                            <Tooltip placement='top' title={item?.value}>
                                                <Typography fontSize={'0.8vw'} fontWeight={600}>{item?.value?.slice(0, 15) + '...'}</Typography>
                                            </Tooltip> :
                                            <Typography fontSize={'0.8vw'} fontWeight={600}>{item?.value}</Typography> : '-'}
                                    </TableCell>
                                    <TableCell align='center'>
                                        <Box component={'div'} display={'flex'} justifyContent={'center'}>
                                            <Button emed_tid='exm_edt_btn'
                                                className='Common_Btn_Min_Width'
                                                onClick={() => this.onEditHandler(item)}
                                                disabled={this.state.isLocked || this.props.isLocked}
                                            >
                                                <Box component={'img'} src={ImagePaths.UserEditOn.default} height={'1vw'} width={'1vw'} />
                                            </Button>
                                            <Button emed_tid='exm_del_btn'
                                                className='Common_Btn_Min_Width'
                                                onClick={() => this.onDeleteHandler(item)}
                                                disabled={this.state.isLocked || this.props.isLocked}
                                            >
                                                <Box component={'img'} src={ImagePaths.DeleteOutline.default} height={'1vw'} width={'1vw'} />
                                            </Button>
                                        </Box>
                                    </TableCell>
                                </TableRow>
                            )) :
                                <TableRow>
                                    <TableCell />
                                    <TableCell align='right' sx={{fontSize: '0.7vw'}}>No Records To Be Shown</TableCell>
                                    <TableCell />
                                    <TableCell />
                                </TableRow>
                            }
                        </TableBody>
                    </Table>
                </Box>
            )
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    render() {
        this.state.templateData?.forEach((element, index) => element.sno = index + 1)
        return (
            <ShowComponents onIntersection={this.apiCalls.bind(this)}>
                <Box component={'div'} sx={{ backgroundColor: Colors.white }}>
                    <Box component={'div'} height={'2.5vw'} backgroundColor={Colors.Background} borderBottom={'1px solid lightgray'} display={'flex'} alignItems={'center'} p={'0.5vw'} justifyContent={'space-between'}>
                        <Typography fontSize={'0.8vw'} fontWeight={600}>Examination</Typography>
                        <Box component={'div'} display={'flex'} alignContent={'center'}>
                            <Autocomplete
                                clearIcon
                                size='small'
                                sx={{ width: '10vw', }}
                                options={this.state.templateList}
                                getOptionLabel={(option) => typeof (option) === 'string' ? option : option?.favorite_name}
                                value={this.state.selectedTemplateName}
                                onChange={(e, value) => {
                                    this.setState({
                                        selectedTemplateName: value
                                    }, () => {
                                        if (this.state.selectedTemplateName) {
                                            this.getExamTemplateData(this.state.selectedTemplateName?.id)
                                        } else {
                                            this.getExamTemplateData()
                                        }
                                    })
                                }}
                                renderInput={(params) => <TextField {...params} label='Select Template Name' inputProps={{...params.inputProps,emed_tid:'exm_tmp'}} />}
                                PaperComponent={({ children }) => {
                                    return (
                                        <Paper sx={{ maxHeight: '25vw', overflow: 'scroll' }}>
                                            {children}
                                            <Divider />
                                            <Button emed_tid='exm_tmp_add_btn'
                                                color="primary"
                                                fullWidth
                                                sx={{ justifyContent: "flex-start", pl: 2, textTransform: 'capitalize' }}
                                                onMouseDown={(e) => {
                                                    this.setState({ openPopupAddNew: true, tempType: 'Add' })
                                                }}
                                            >
                                                Add Template
                                            </Button>
                                            {this.state.selectedTemplateName ?
                                                <><Button emed_tid='exm_tmp_rname_btn'
                                                    color="primary"
                                                    fullWidth
                                                    sx={{ justifyContent: "flex-start", pl: 2, textTransform: 'capitalize' }}
                                                    onMouseDown={(e) => {
                                                        this.setState({ openPopupAddNew: true, tempType: 'rename', templateName: this.state.selectedTemplateName?.favorite_name })
                                                    }}
                                                >
                                                    Rename
                                                </Button>
                                                    <Button emed_tid='exm_tmp_del_btn'
                                                        color="primary"
                                                        fullWidth
                                                        sx={{ justifyContent: "flex-start", pl: 2, textTransform: 'capitalize' }}
                                                        onMouseDown={(e) => {
                                                            this.setState({
                                                                // openPopupAddNew: true,
                                                                tempType: 'delete',
                                                                templateName: this.state.selectedTemplateName?.favorite_name,
                                                                deletePopup: true
                                                            })
                                                        }}
                                                    >
                                                        Delete
                                                    </Button>
                                                </> : null
                                            }

                                        </Paper>
                                    )
                                }}
                            />
                            <Button emed_tid='exm_add_btn'
                                disabled={this.state.isLocked || this.props.isLocked}
                                variant='contained'
                                sx={{ width: '5vw', textTransform: 'capitalize', mt: '0.2vw', ml: '0.5vw', mr: '1vw' }}
                                onClick={() => {
                                    this.setState({ openPopup: true })
                                }}
                            >Add</Button>
                        </Box>
                    </Box>
                    <Box component={'div'} m={'.5vw'}>
                        {this.renderExaminationTable()}
                    </Box>
                    {this.renderPopup()}
                    {this.renderAddNewSymptomPopup()}
                    {this.renderTemplatePopup()}
                    {this.state.isErrorMsg ?
                        <ToastMsg
                            severity={'error'}
                            msg={this.state.isErrorMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.successMsg ?
                        <ToastMsg
                            severity={'success'}
                            msg={this.state.successMsgText}
                            msgPop={this.msgClose.bind(this)}
                        />
                        : null}
                    {this.state.deletePopup ? <DeletePopup
                        DeletTitle={`Are you sure you want to delete ${this.state.tempType === 'delete' ? this.state.templateName : ''} ?`}
                        deleteAlertPopupClose={this.popupClose.bind(this)}
                        removeData={this.state.tempType === 'delete' ? this.deleteTemplate.bind(this) : this.deleteExaminationData.bind(this)}
                        disable={this.state.removeData}
                    /> : null}
                </Box>
            </ShowComponents>
        )
    }
}
