import { Autocomplete, Box, Button, Drawer, FormControl, InputLabel, MenuItem, Select, Stack, TextField, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import CommonGridHeader, { CommonGridToolBarWithFilterText, CommonGridToolBarWithFilterTextCustom, CommonPatientDetails, CommonTimeRangePicker } from "../../../../Components/Common Components/CommonComponents";
import '../reports.css';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../../Utility/ImagePaths';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { AmountFormat, formatDate, timeOnlyConvert } from "../../../../Components/CommonFunctions/CommonFunctions";
import { DateTime } from 'luxon';
import { CurrencySymbol } from '../../../../Utility/Constants';
import Loader from '../../../../Components/Loader';


class RefSourceReport extends Component {

    constructor(props) {
        super(props)
        this.state = {
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            sourceType: [],
            dateIndex: 1,
            refSourceData: [],
            refSourceAmount: null,
            refFilterSourceData: [],
            FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilterDatas: {
                "startTime":null,
                "endTime":null,
                "onError":false,
            },
            timeError: false,
            printLoading: false,
            disableBtn: false,
            refSourceSplitAmt: []
        }
    }

    componentDidMount() {
        this.getRefSourceData()
        this.getRefSourceFilterData()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    onChangeHandler = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index,
        }, ()=> {
            this.state.FilterDatas.endTime = null
            this.setState({
                FilterDatas:this.state.FilterDatas
            })
        })
    }

    getRefSourceData = () => {
        try {
            this.LoaderFunction(true)
            let sourceTypeSearch = [];
            this.state.sourceType.forEach(element => sourceTypeSearch.push(element.source_name))
            var {FilterDatas} = this.state
            let start_time = FilterDatas.startTime ? new Date(FilterDatas.startTime) : ''
            let end_time = FilterDatas.endTime ? new Date(FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            RestAPIService.getAll(Serviceurls.FO_REPORTS_REFERRAL_SOURCE + `?source_type=${sourceTypeSearch}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}&start_time=${setStartTime}&end_time=${setEndTime}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            refSourceData: response.data.data,
                            refSourceAmount: response.data.total_bill_amount,
                            refSourceSplitAmt: response.data.referral_count ? response.data.referral_count : [],
                            FilteredFromDate: this.state.fromDate,
                            FilteredToDate: this.state.toDate,
                        },()=>{this.LoaderFunction(false)})
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }
    printReports = () => {
        try {
            let sourceTypeSearch = [];
            this.state.sourceType.forEach(element => sourceTypeSearch.push(element.source_name))
            var {FilterDatas} = this.state
            let start_time = FilterDatas.startTime ? new Date(FilterDatas.startTime) : ''
            let end_time = FilterDatas.endTime ? new Date(FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            this.setState({disableBtn: true, printLoading: true})
            RestAPIService.getAll(Serviceurls.FO_REPORTS_REFERRAL_SOURCE + `?source_type=${sourceTypeSearch}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}&export_type=pdf&start_time=${setStartTime}&end_time=${setEndTime}`)
                .then((response) => {
                    const file = new Blob(
                        [response.data],
                        { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(file);
                    window.open(fileURL);
                    this.setState({disableBtn: false, printLoading: false})
                }).catch((error) => {
                    this.errorMessage(error.message)
                    this.setState({disableBtn: false, printLoading: false})
                })
        } catch (error) {
            this.errorMessage(error.message)
            this.setState({disableBtn: false, printLoading: false})
        }
    }
    getRefSourceFilterData = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_REF_SOURCE)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            refFilterSourceData: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    selectedTime = (start, end,error) => {
        this.state.FilterDatas.startTime = start
        this.state.FilterDatas.endTime = end
        this.setState({ FilterDatas: this.state.FilterDatas, timeError: error })
    }
    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t("Filter")}</Typography>
                    <Button  emed_tid = {"LabCloseButton_btn_TestID"}  onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)} 
                            emed_tid = {"FilterDate_TestID"} 
                            HideAllMenu={true}/>
                    </Box>
                    <Box sx={{width: '22vw'}}>
                    <CommonTimeRangePicker
                        startTime={this.state.FilterDatas?.startTime}
                        endTime={this.state.FilterDatas?.endTime}
                        selectedTime={this.selectedTime.bind(this)}
                        emed_tid = {"selectedTime_TestID"} 
                        FromDate={this.state.fromDate}
                        ToDate={this.state.toDate}
                        onError={this.state.FilterDatas.onError}
                    />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={this.state.refFilterSourceData?.map((item) => item)}
                            getOptionLabel={(option) => (option?.source_name)}
                            value={this.state.sourceType}
                            onChange={(event, newValue) => {
                                this.setState({ sourceType: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{ ...params.inputProps,'emed_tid': 'select_source' }}
                                    label={t('selectSource')}
                                    emed_tid = {"selectSource_TestID"} 
                                />
                            )}
                        />
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small" emed_tid = {"Clear_TestID"} 
                        onClick={() => {
                            this.setState({ 
                                sourceType: [],
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                FilterDatas: {
                                    "startTime":null,
                                    "endTime":null,
                                    "onError": false
                                },
                                timeError: false,
                            }, () => this.getRefSourceData())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small"  emed_tid = {"Search_TestID"} disabled={this.state.FilterDatas.startTime ? (this.state.FilterDatas.startTime && this.state.FilterDatas.endTime && this.state.timeError != true) ? false : true : false}
                        onClick={() => {
                            this.getRefSourceData()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }
    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }
    gridToolBar = () => {
        var { t } = this.props
        let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
        let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
        let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let TextArray = [
            {label:"From Date", value: formatDate(this.state.FilteredFromDate)},
            {label:"To Date", value:formatDate(this.state.FilteredToDate)},
        ]
        if (this.state.FilterDatas.startTime !== null && this.state.FilterDatas.endTime !== null) {
            TextArray.push(
                { label: "Start Time", value: timeOnlyConvert(setStartTime) },
                { label: "End Time", value: timeOnlyConvert(setEndTime) }
            )
        }
        var ExportData = [];
        this.state.refSourceData?.length > 0 && this.state.refSourceData.map((item) => {
            ExportData.push({
                "Patient Name": item.patient_names, 
                "Source Type": item.referral_source ? item.referral_source : "-", 
                "Total Bill Amount": item.bill_amount, 
             })
            });
        return(
          <Box>
             <CommonGridToolBarWithFilterTextCustom data={ExportData} FilterTextArray={TextArray} title={"Referral Source Report"} filename={"ReferralSource"}/>
          </Box>
    
        )
      }
    render() {
        this.state.refSourceData.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            {
                field: "patient_name", headerName: t("Patient Details"), flex: 0.333,
                renderCell: (params) => ( <CommonPatientDetails data={params?.row} showDetailed={false}/> )
            },
            {
                field: "referral_source", headerName: t("srcType"), flex: 0.333,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.referral_source && params?.row?.referral_source ? params?.row?.referral_source : "-"}</Box>)
            },
            {
                field: "bill_amount", headerName: `${t("TotalBillAmount")} (${CurrencySymbol})`, flex: 0.333, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.bill_amount ? AmountFormat(params?.row?.bill_amount).replace(`${CurrencySymbol}`, "") : "0"}</Box>)
            }
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_amount'>
                        {AmountsCard(t("TotalAmount"), this.state.refSourceAmount)}
                    </Box>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {this.state.refSourceSplitAmt.length > 0 && this.state.refSourceSplitAmt.map((item) => (
                            AmountsCard(t(item.referral_source), item?.count, true)
                        ))}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button  emed_tid = {"eMed_usrconf_btn_TestID"} className='eMed_usrconf_btn' onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                        <Button className='eMed_usrconf_btn' emed_tid = {"LabPrintIcon_btn_TestID"}   disabled={this.state.disableBtn} onClick={() => { this.printReports() }}>
                            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                        </Button>
                        {/* <Button className='eMed_usrconf_btn'>
                            <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
                        </Button> */}
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={this.state.refSourceData}
                        columns={columns}
                        getRowId={(row) => row['sno']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
                <Loader loaderOpen={this.state.printLoading} forPrint={true} label={"Building PDF..."} />
            </Box>
        )
    }
    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }
}


export default withTranslation()(RefSourceReport)