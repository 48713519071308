import { Autocomplete, Box, Button, Checkbox, Drawer, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, TextField, Tooltip, Typography } from '@mui/material'
import { DataGrid } from '@mui/x-data-grid'
import React, { Component } from 'react'
import { withTranslation } from 'react-i18next';
import CommonGridHeader, { CommonGridToolBarWithFilterText, CommonTimeRangePicker } from "../../../../Components/Common Components/CommonComponents";
import '../reports.css';
import { AmountsCard } from '../../../../Components/CommonCards/CommonCards'
import { ImagePaths } from '../../../../Utility/ImagePaths';
import CommonDatePicker from '../../../../Components/Common Components/CommonDatePicker';
import ToastMsg from '../../../../Components/ToastMsg/ToastMsg';
import RestAPIService from '../../../../Utility/Services/RestAPIService';
import { Serviceurls } from '../../../../Utility/API/Serviceurls';
import { formatDate, AmountFormat, timeOnlyConvert } from '../../../../Components/CommonFunctions/CommonFunctions';
import { DateTime } from 'luxon';
import { CurrencySymbol } from '../../../../Utility/Constants';
import { localGetItem } from '../../../../Utility/Services/CacheProviderService';

class CopPatientReport extends Component {

    constructor() {
        super()
        this.state = {
            page: 0,
            pageSize: 10,
            fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            companyName: [],
            employeeName: "",
            dateIndex: 1,
            corporatePatientData: [],
            corporateFilter: [],
            corporatePatientPayable: null,
            corporateTotalAmount: null,
            corporateFilterList: [],
            FilteredFromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilteredToDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
            FilterDatas: {
                "startTime":null,
                "endTime":null,
                "onError":false,
                "opCreditBills": true,
                "ipCreditBills": true,
                "pharmaCreditBills": true,
                "labCreditBills": true,
                "BillType": localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? ['op', 'ip', 'lab'] : ['op', 'ip', 'pharmacy', 'lab'],
            },
            timeError: false,
            return_amount: 0,
            corporateTotalNetAmount: null,
        }
    }

    componentDidMount() {
        this.getCorportatePatientData()
        this.getCorportateFilterList()
    }

    errorMessage = (message) => {
        this.setState({
            isErrorMsg: true,
            isErrorMsgText: message
        })
    }

    onChangeHandler = (e) => {
        const { name, value } = e.target
        this.setState({
            [name]: value
        })
    }

    LoaderFunction = (key) => {
        this.setState({
            isLoader: key
        })
    }

    FilterDate = (from, to, index) => {
        this.setState({
            fromDate: from,
            toDate: to,
            dateIndex: index
        }, ()=> {
            this.state.FilterDatas.endTime = null
            this.setState({
                FilterDatas:this.state.FilterDatas
            })
        })
    }

    getCorportatePatientData = () => {
        try {
            this.LoaderFunction(true)
            let companyName = [];
            this.state.companyName.forEach(element => companyName.push(element.company_name))
            var {FilterDatas} = this.state
            let start_time = FilterDatas.startTime ? new Date(FilterDatas.startTime) : ''
            let end_time = FilterDatas.endTime ? new Date(FilterDatas.endTime) : ''
            let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
            RestAPIService.getAll(Serviceurls.FO_REPORTS_CORPORATE_PATIENT + `?employee_name=${this.state.employeeName}&company_name=${companyName}&from_date=${this.state.fromDate}&to_date=${this.state.toDate}&start_time=${setStartTime}&end_time=${setEndTime}&bill_type=${this.state.FilterDatas?.BillType}`)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            corporatePatientData: response.data.data,
                            corporateTotalAmount: response.data.total_amount,
                            corporatePatientPayable: response.data.corporate_payable,
                            FilteredFromDate: this.state.fromDate,
                            FilteredToDate: this.state.toDate,
                            return_amount: response.data.return_amount,
                            corporateTotalNetAmount: response.data.net_total_amount,
                        },()=>{this.LoaderFunction(false)})
                    }
                }).catch((error) => {
                    this.LoaderFunction(false)
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.LoaderFunction(false)
            this.errorMessage(error.message)
        }
    }

    getCorportateFilterList = () => {
        try {
            RestAPIService.getAll(Serviceurls.FO_CONFIG_CORPORATE)
                .then((response) => {
                    if (response.data.status === "success") {
                        this.setState({
                            corporateFilterList: response.data.data
                        })
                    }
                }).catch((error) => {
                    if (error?.response?.data?.status === 'fail') {
                        this.errorMessage(error.response.data.message)
                    } else {
                        this.errorMessage(error.message)
                    }
                })
        } catch (error) {
            this.errorMessage(error.message)
        }
    }

    selectedTime = (start, end,error) => {
        this.state.FilterDatas.startTime = start
        this.state.FilterDatas.endTime = end
        this.setState({ FilterDatas: this.state.FilterDatas, timeError: error })
    }

    handleCheckBox = (e, key) => {
        this.state.FilterDatas[key] = e.target.checked
        let BillType = [];
        let states = this.state
        
        if (states.FilterDatas.ipCreditBills) {
            BillType.push("ip")
        }
        if (states.FilterDatas.opCreditBills) {
            BillType.push("op")
        }
        if (states.FilterDatas.pharmaCreditBills) {
            BillType.push("pharmacy")
        }
        if (states.FilterDatas.labCreditBills) {
            BillType.push('lab')
        }
        this.state.FilterDatas.BillType = BillType
        this.setState({
            FilterDatas: this.state.FilterDatas
        })
    }

    renderFilterScreen = () => {
        const { t } = this.props
        return (
            <Box component={"div"} className='eMed_rts_filter_container'>
                <Box component={"div"} className='eMed_filter_Top'>
                    <Typography variant='h6' fontWeight={600}>{t('Filter')}</Typography>
                    <Button emed_tid={'close_btn'} onClick={() => { this.setState({ FilterOpen: false }) }} className='eMed_usrconf_btn'>
                        <img src={ImagePaths.LabCloseButton.default} alt="close" className='eMed_action_img_small' />
                    </Button>
                </Box>
                <Box className='eMed_rts_middle'>
                    <Box className='eMed_date_filter'>
                        <CommonDatePicker
                            FromDate={this.state.fromDate}
                            emed_tid = {"CommonDatePicker_TestID"} 
                            ToDate={this.state.toDate}
                            DateIndex={this.state.dateIndex}
                            SelectedDates={this.FilterDate.bind(this)} 
                            HideAllMenu={true}/>
                    </Box>
                    <Box sx={{width: '22vw'}}>
                    <CommonTimeRangePicker
                        startTime={this.state.FilterDatas?.startTime}
                        endTime={this.state.FilterDatas?.endTime}
                        selectedTime={this.selectedTime.bind(this)}
                        FromDate={this.state.fromDate}
                        emed_tid = {"selectedTime_TestID"} 
                        ToDate={this.state.toDate}
                        onError={this.state.FilterDatas.onError}
                    />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <TextField
                              inputProps={{ emed_tid: 'employee_name' }}
                            fullWidth
                            size='small'
                            emed_tid = {"EmployeeName_TestID"} 
                            label={t("EmployeeName")}
                            name="employeeName"
                            value={this.state.employeeName}
                            onChange={(e) => { this.onChangeHandler(e) }}
                        />
                    </Box>
                    <Box className='eMed_rts_dropdown'>
                        <Autocomplete
                            multiple
                            limitTags={1}
                            size='small'
                            options={this.state.corporateFilterList?.map((item) => item)}
                            getOptionLabel={(option) => (option?.company_name)}
                            value={this.state.companyName}
                            onChange={(event, newValue) => {
                                this.setState({ companyName: newValue })
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    inputProps={{...params.inputProps,'emed_tid': 'select_company', }}   
                                    label={t("selectCom")}
                                    emed_tid = {"selectCom_TestID"} 
                                />
                            )}
                        />
                    </Box>
                    <Box>
                        <Box className="eMed_Filter_Header">
                            <Typography marginLeft={"1vw"}>{t("Bill Type")}</Typography>
                        </Box>
                        <Box className="eMed_CheckBox_Group">
                            <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox sx={{ fontSize: '0.4vw' }} checked={this.state.FilterDatas.ipCreditBills} onChange={(e) => { this.handleCheckBox(e, "ipCreditBills",) }} size='small' />} label={t("InPatient")} />
                            <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.opCreditBills} onChange={(e) => { this.handleCheckBox(e, "opCreditBills") }} size='small' />} label={t("OutPatient")} />
                            <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox disabled={localGetItem("Show_Pharmacy_Bills_In_FO") === "false"} checked={this.state.FilterDatas.pharmaCreditBills} onChange={(e) => { this.handleCheckBox(e, "pharmaCreditBills") }} size='small' />} label={t("Pharmacy")} />
                            <FormControlLabel className='eMed_CheckBoxDiv' control={<Checkbox checked={this.state.FilterDatas.labCreditBills} onChange={(e) => { this.handleCheckBox(e, "labCreditBills") }} size='small' />} label={t("Laboratory")} />
                        </Box>
                    </Box>
                </Box>
                <Stack spacing={2} direction="row" alignItems="center" justifyContent="center">
                    <Button variant='outlined' size="small"  emed_tid = {"FilterDatasTestID"} 
                        onClick={() => {
                            this.setState({ companyName: [], employeeName: [],
                                fromDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                toDate: DateTime.fromJSDate(new Date()).toFormat('yyyy-MM-dd'),
                                dateIndex: 1,
                                FilterDatas: {
                                    "startTime":null,
                                    "endTime":null,
                                    "onError": false,
                                    "opCreditBills": true,
                                    "ipCreditBills": true,
                                    "pharmaCreditBills": true,
                                    "labCreditBills": true,
                                    "BillType": localGetItem("Show_Pharmacy_Bills_In_FO") === "false" ? ['op', 'ip', 'lab'] : ['op', 'ip', 'pharmacy', 'lab'],
                                },
                                timeError: false,
                             }, () => this.getCorportatePatientData())
                        }}
                        id="eMed_Config_btn">{t("Clear")}</Button>
                    <Button variant='contained' size="small" emed_tid = {"Search_TestID"} 
                    disabled={this.state.FilterDatas.startTime ? (this.state.FilterDatas.startTime && this.state.FilterDatas.endTime && this.state.timeError != true && this.state.FilterDatas?.BillType?.length !== 0) ? false : true : (this.state.FilterDatas?.BillType?.length === 0) ? true : false}
                        onClick={() => {
                            this.getCorportatePatientData()
                            this.setState({ FilterOpen: false })
                        }}
                        id="eMed_Config_btn">{t("Search")}</Button>
                </Stack>
            </Box>
        )
    }

    msgClose() {
        this.setState({
            isErrorMsg: false,
            isErrorMsgText: '',
        })
    }
    gridToolBar = () => {
        var { t } = this.props
        let start_time = this.state.FilterDatas.startTime ? new Date(this.state.FilterDatas.startTime) : ''
        let end_time = this.state.FilterDatas.endTime ? new Date(this.state.FilterDatas.endTime) : ''
        let setStartTime = start_time === '' ? '' : DateTime.fromJSDate(start_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let setEndTime = end_time === '' ? '' : DateTime.fromJSDate(end_time).toLocaleString(DateTime.TIME_24_WITH_SECONDS);
        let TextArray = [
            {label:"From Date", value: formatDate(this.state.FilteredFromDate)},
            {label:"To Date", value:formatDate(this.state.FilteredToDate)},
        ]
        if (this.state.FilterDatas.startTime !== null && this.state.FilterDatas.endTime !== null) {
            TextArray.push(
                { label: "Start Time", value: timeOnlyConvert(setStartTime) },
                { label: "End Time", value: timeOnlyConvert(setEndTime) }
            )
        }
        return(
          <Box>
             <CommonGridToolBarWithFilterText FilterTextArray={TextArray}/>
          </Box>
    
        )
      }
    render() {
        this.state.corporatePatientData.forEach((element, index) => element.sno = index + 1)
        const { t } = this.props
        const columns = [
            {
                field: "employee_name", headerName: t("Employee Details"), flex: 0.143,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.employee_name ? params?.row?.employee_name?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.employee_name} arrow><div>{params?.row?.employee_name.slice(0, 20)}</div></Tooltip> : params?.row?.employee_name : "-"}
                    {params?.row?.employee_id ? <Typography id='eMed_Com_Patient_UhidText'>ID: {params?.row?.employee_id}</Typography> : ""}
                    </Box>)
            },
            {
                field: "company_name", headerName: t("CompanyName"), flex: 0.143,
                renderCell: (params) => (<Box component={'div'}>{params?.row?.company_name ? params?.row?.company_name?.length > 20 ?
                    <Tooltip placement='top' title={params?.row?.company_name} arrow><div>{params?.row?.company_name.slice(0, 20)}</div></Tooltip> : params?.row?.company_name : "-"}</Box>)
            },
            {
                field: "invoice_number", headerName: t("BillNumber"), flex: 0.143, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_number ? <Tooltip placement='top' title={params?.row?.invoice_number} arrow>
                    <Box>{params?.row?.invoice_number ?
                        "..." + params?.row?.invoice_number.slice(9) : params?.row?.invoice_number}</Box>
                </Tooltip> : "-"}</Box>)
            },
            {
                field: "invoice_date", headerName: t("BillDate"), flex: 0.143, headerAlign: "center", align: "center",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.invoice_date ? params?.row?.invoice_date : "-"}</Box>)
            },
            {
                field: "module_type", headerName: t("BillType"), flex: 0.143,
                renderCell: (params) => (<Box component={'div'}>
                    <Typography>{params?.row?.module_type ? params?.row?.module_type : "-"}</Typography>
                    {params?.row?.module_type === "Pharmacy" ?
                        <Typography id='eMed_Com_Patient_UhidText'>{(params?.row?.bill_type === "Return" ? params?.row?.bill_type : '')}
                        </Typography> : null}
                </Box>)
            },
            {
                field: "gross_amount", headerName: `${t("TotalValue")}`, flex: 0.143, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.gross_amount ? AmountFormat(params?.row?.gross_amount).replace(`${CurrencySymbol}`, "") : "-"}</Box>)
            },
            {
                field: "payable_amount", headerName: `${t("CorporatePayable")} (${CurrencySymbol})`, flex: 0.143, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.payable_amount ? AmountFormat(params?.row?.payable_amount).replace(`${CurrencySymbol}`, "") : "-"}</Box>)
            },
            {
                field: "patient_payable", headerName: `${t("Patient Payable")} (${CurrencySymbol})`, flex: 0.143, type: "number",
                renderCell: (params) => (<Box component={'div'}>{params?.row?.patient_payable ? AmountFormat(params?.row?.patient_payable).replace(`${CurrencySymbol}`, "") : "-"}</Box>)
            }
        ]
        return (
            <Box component={'div'} className='eMed_rts_container'>
                <Box component={"div"} className='eMed_Top_Container'>
                    <Box component={"div"} className='eMed_Cards_Container'>
                        {AmountsCard(t("TotalAmount"), this.state.corporateTotalAmount)}
                        {AmountsCard(t("Total Net Amount"), this.state.corporateTotalNetAmount)}
                        {AmountsCard(t("CorporatePayable"), this.state.corporatePatientPayable)}
                        {AmountsCard(t("Return Amount"), this.state.return_amount)}
                    </Box>
                    <Box component={"div"} className='eMed_Action_Container'>
                        <Box component={"div"} className='eMed_Filter_Icon_div'>
                            <Button className='eMed_usrconf_btn' emed_tid = {"eMed_action_img_TestID"} onClick={() => { this.setState({ FilterOpen: true }) }}>
                                <img src={ImagePaths.Filter.default} alt="filter" className='eMed_action_img' />
                            </Button>
                        </Box>
                        {/* <Button className='eMed_usrconf_btn'>
                            <img src={ImagePaths.LabPrintIcon.default} alt="print" className='eMed_action_img' />
                        </Button>
                        <Button className='eMed_usrconf_btn'>
                            <img src={ImagePaths.LabUploadButton.default} alt="upload" className='eMed_action_img' />
                        </Button> */}
                    </Box>
                </Box>
                <Box component={'div'} className='eMed_rts_table'>
                    <DataGrid
                        rows={this.state.corporatePatientData}
                        columns={columns}
                        getRowId={(row) => row['sno']}
                        page={this.state.page}
                        pageSize={this.state.pageSize}
                        localeText={{
                            toolbarColumns: "",
                            toolbarDensity: "",
                            toolbarExport: "",
                            toolbarFilters: "",
                            toolbarExportPrint: ""
                        }}
                        components={{
                            Toolbar: this.gridToolBar,
                            NoRowsOverlay: () => (
                                <Stack className='eMed_conf_nodata'>
                                    {t("NoRecordsFound")}
                                </Stack>
                            )
                        }}
                        onPageChange={(newPage) => { this.setState({ page: newPage }) }}
                        onPageSizeChange={(newPageSize) => this.setState({ pageSize: newPageSize })}
                        rowsPerPageOptions={[10, 20, 30]}
                        pagination
                        headerHeight={40}
                        disableSelectionOnClick
                        loading={this.state.isLoader}
                    />
                </Box>
                <Drawer
                    anchor={'right'}
                    open={this.state.FilterOpen}
                    ModalProps={{ onBackdropClick: this.closeFilter }}
                >
                    {this.renderFilterScreen()}
                </Drawer>
                {this.state.isErrorMsg ?
                    <ToastMsg
                        severity={'error'}
                        msg={this.state.isErrorMsgText}
                        msgPop={this.msgClose.bind(this)}
                    />
                    : null}
            </Box>
        )
    }
    closeFilter = () => {
        this.setState({ FilterOpen: false })
    }
}


export default withTranslation()(CopPatientReport)